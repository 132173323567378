import React, {useState, useContext, useEffect} from 'react';
import {Card, ButtonNewSeasons, CardExtras, CardVideo, ContainerSeasons, FlexImg, FlexVideo, Icon, Info, Prevew, PrevewImg, PrevewVideo, PreviewVideo, Submit, SubTitleSeasons, Tam, TitleCard, TitleSeasons } from './styles';
import {MdOutlineUpload, MdAdd, MdOutlineDone} from 'react-icons/md'
import { Close } from '@material-ui/icons';
import FormDetails from './FormDetails';
import { useMutation } from '@apollo/react-hooks';
import { UploadContext } from '../index'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import {createCourseTwo} from "../validation";
import {useDropzone} from 'react-dropzone';
import Material from './Material';
import GlobalContext from '../../../../../context'
import Episodes from './Episodes';
import { FormHelperText } from "@material-ui/core";
import {
  Typography,
  CircularProgress,
  Box,
  LinearProgress
} from '@material-ui/core';
import AWS from 'aws-sdk'


AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_USER_KEY,
  secretAccessKey: process.env.REACT_APP_S3_USER_SECRET
})

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME},
  region: process.env.REACT_APP_S3_REGION,
})

const params = {
  ACL: 'public-read',
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
};


export const Seasons=({index, course, nextStep})=> {
  const {
    selectedCourse: {
      createCourse, updateCourse
    },
    visualization: { visualization },
  } = GlobalContext();
  const {data, setData} = useContext(UploadContext);
  const [thumbnail, setThumbnail] = useState('')
  const [background, setBackground] = useState('')
  const [trailer, setTrailer] = useState('')
  const [files, setFiles] = useState([]);
  const [epsData, setEpsData] = useState([])
  const [percentState, setPercent] = useState(0)
  const [uploading, setUploading] = useState(false)
  console.log(index)
  let loaded = 0;
  let actualLoaded = 0
  let totalSize = 0;


  console.log(data)

  const { handleSubmit, register, control, watch, setValue, formState: { errors, isValid }  } = useForm({resolver: yupResolver(createCourseTwo)});

  useEffect(()=> {
    console.log('errors')
    console.log(errors)
  }, [errors])

  useEffect(()=> {
    setValue('thumbnail', data?.thumbnail);
    setValue('trailer', data?.trailer);
    setValue('folder', data?.folder);
  }, [])

  const {getRootProps, getInputProps} = useDropzone({noKeyboard: true,
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/ogg': ['.ogg']
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const delThumb =()=>{
    setValue('thumbnail', null)
    setData({...data, thumbnailFile: null, thumbnail: null})
  }

  const delFolder =()=>{
    setValue('folder', null)
    setData({...data, folderFile: null, folder: null})
  }

  const delTrailer =()=>{
    setValue('trailer', null)
    setData({...data, trailerFile: null, trailer: null})
  }

  const delBackground =()=>{setBackground('')}

  const handleRemoveFile = name => {
    setFiles(files.filter(file => file.name !== name))
  }


  const onError = (errors, e) => {
    console.log(errors)
  }

  const loadVideo = file => new Promise((resolve, reject) => {
    try {
      console.log("FILE AQ: ", file)
        let video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
            resolve(this)
        }

        video.onerror = function () {
            reject("Invalid video. Please select a video file.")
        }

        video.src = window.URL.createObjectURL(file.contentFile)
    } catch (e) {
        reject(e)
    }
})


  const handleSubmitForm = async (props) => {

    console.log(data)

    // console.log({...data, tags: data?.tags?.map(e => e.id)})

    totalSize += data?.thumbnailFile ? data?.thumbnailFile?.size : 0
    totalSize += data?.folderFile ? data?.folderFile?.size : 0
    totalSize += data?.trailerFile ? data?.trailerFile?.size : 0
    totalSize += data?.modules[index]?.units ? data?.modules[index]?.units.reduce((a, b) => a + b.contentFile ? b.contentFile?.size : 0, 0) : 0

    console.log(totalSize)


    setUploading(true)


    let videosUrls = [];
    let videosNames = [];
    let videosDuration = [];
    let videosDescription = [];

    for (const video of data?.modules[index]?.units) {
      if(video?.contentFile){
        console.log("ENTROU: ", video.contentFile)
        let uploadVideos = myBucket.upload({...params, Body: video?.contentFile, Key: `cientik/${data?.name}/Videos/${video.name}`})
          .on('httpUploadProgress', function(evt) {
            console.log('carregando...')
            loaded += evt.loaded - actualLoaded
            setPercent(Math.round((loaded / totalSize)*100))
            actualLoaded = evt.loaded
          })
          .promise();

          const url = await uploadVideos.then(function(el, err) {
            actualLoaded = 0
            return el.Location
          })
          .catch(e=> console.log(e))

          //videosUrls.push[url]
          console.log(url)

          videosUrls.push(url)
          videosNames.push(video.name)
          videosDescription.push(video.description)     
          const videoDuration = await loadVideo(video)
          videosDuration.push(parseInt(videoDuration.duration))
         
      } else {
        console.log("N ENTROU: ", video.content)
        videosUrls.push(video.content)
        videosNames.push(video.name)
        videosDescription.push(video.description)  
        videosDuration.push(video.duration)   
      }

      
      
      continue;
    }

    const legendasUrls = [];
    const legendasIdiom = [];
    var i = 0 
    var y = 0
    for (const episodio of data?.modules[index]?.units) {
      const legendasEp = []
      const legendasEpIdiom = []
      if(episodio.subtitles){
        for (const legenda of episodio.subtitles) {
          if(legenda?.fileContent){
            var uploadLegendas = myBucket.upload({...params, Body: legenda.fileContent, Key: `cientik/${data?.name}/Legendas/Ep. ${i}/${legenda.idiom}`})
              .on('httpUploadProgress', function(evt) {
                loaded += evt.loaded - actualLoaded
                setPercent(Math.round((loaded / totalSize)*100))
                actualLoaded = evt.loaded
              })
              .promise();

            const url = await uploadLegendas.then(function(el, err) {
              actualLoaded = 0
              return el.Location
            });


            console.log(url)
            legendasEpIdiom.push(legenda.idiom)
            legendasEp.push(url)
            
            
          } else {
            legendasEpIdiom.push(legenda.idiom)
            legendasEp.push(legenda.content)
          }
          y++
          continue;
        }
      }

      legendasIdiom.push(legendasEpIdiom)
      legendasUrls.push(legendasEp)
      i++
      continue;
    }



  console.log({
    name: data?.name,
    description: data?.description,
    id: data?.id,
    endDate: data?.endDate,
    startDate: data?.startDate,
    year: parseInt(data?.year),
    tags: data?.categories.map(e => e.id),
    idModule: data?.modules[index]?.id,
    nameModule: data?.modules[index]?.name,
    units: videosUrls,
    unitsName: videosNames,
    unitsDuration: videosDuration,
    unitsDescription: videosDescription,
    legendasUrls,
    legendasIdiom
    // module: data?.modules
    // videos: videosUrls
  })



    updateCourse({
      variables: {
        name: data?.name,
        description: data?.description,
        id: data?.id,
        endDate: data?.endDate,
        startDate: data?.startDate,
        year: parseInt(data?.year),
        tags: data?.categories.map(e => e.id),
        idModule: data?.modules[index]?.id,
        nameModule: data?.modules[index]?.name,
        units: videosUrls,
        unitsName: videosNames,
        unitsDuration: videosDuration,
        unitsDescription: videosDescription,
        subtitles: legendasUrls,
        idiomSubtitles: legendasIdiom
        // module: data?.modules
        // videos: videosUrls
      }
    }).then(e => {
      console.log(e)
      setUploading(false)
      NotificationManager.success("Conteúdo editado com sucesso!");
    })
    .catch(err => {
      console.log(err)
      setUploading(false)
      NotificationManager.error(
        "Não foi possível finalizar a edição do conteúdo."
      );
    })



  }

//   const uploadFile = (file) => {

//     const params = {
//         ACL: 'public-read',
//         Body: file,
//         Bucket: S3_BUCKET,
//         Key: file.name
//     };

//     myBucket.putObject(params)
//         .on('httpUploadProgress', (evt) => {
//             setProgress(Math.round((evt.loaded / evt.total) * 100))
//         })
//         .send((err) => {
//             if (err) console.log(err)
//         })
// }

//   const upload = () => {
//     console.log(data)
//     S3FileUpload
//     .uploadFile(thumbnail, config)
//     .then(data => console.log(data))
//     .catch(err => console.error(err))
//   }

  const renderProgressBar = () => {
    return(
        <>
            <Box  sx={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '-20px' }}>
                <Box sx={{ width: '94%', mr: 1 }}>
                    <div>
                        <LinearProgress variant="determinate" value={percentState} />
                    </div>

                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${percentState}%`}</Typography>
                </Box>
            </Box>
            {percentState >= 100 && !uploading ? (
                <Typography style={{marginTop: '20px'}} variant="body2" color="text.secondary">
                <MdOutlineDone style={{width: '12px', height: '12px', marginRight: '5px'}} color="inherit" />
                        Upload feito com sucesso!
                </Typography>
                ):(
                  <Typography style={{marginTop: '20px'}} variant="body2" color="text.secondary">
                  <CircularProgress style={{width: '12px', height: '12px', marginRight: '5px'}} color="inherit" />
                          Seu filme está sendo processado aguarde um estante.
                  </Typography>
                )}

        </>
    )
}



    return(

    <div style={{justifyContent: 'center', display: 'flex'}}>
    <form onSubmit={handleSubmit(handleSubmitForm, onError)}>
        <ContainerSeasons>

        <TitleSeasons>
        Informações da temporada
        </TitleSeasons>

        <FormDetails index={index} errors={errors} control={control}/>
{/*          <div>
        <SubTitleSeasons>
        Imagens do conteúdo
        </SubTitleSeasons>
 
          <FlexImg>
            <Card style={{maginRight:"60px"}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Thumbnail
              </TitleCard>
              <Tam>
              1080 x 1920 px
              </Tam>

              <Controller
                name="thumbnail"
                control={control}
                // defaultValue={data?.thumbnail}
                render={props => (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="thumbnail"
                    name="thumbnail"
                    // onChange={(e) => setThumbnail(e.target.files[0])}
                    onChange={(e) => {setData({...data, thumbnailFile: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                  />
                )}
              />
               <FormHelperText error={Boolean(errors?.thumbnail)}>
            {errors?.thumbnail?.message}
            </FormHelperText>

          <label htmlFor="thumbnail"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar a thumbnail do curso
          </label>
              <Info>
              Formato: JPG ou PNG
              </Info>
              {data?.thumbnail || data?.thumbnailFile ?
              <>
              <PrevewImg src={data?.thumbnail || URL.createObjectURL(data?.thumbnailFile)}/>
              <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={()=>delThumb()}><u>remover</u></button>
              </>
              :null }
            </Card>

            <Card>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Cartaz
              </TitleCard>
              <Tam>
              1920 x 1080 px
              </Tam>
              <Controller
                name="folder"
                control={control}
                // defaultValue={data?.folder}
                render={props => (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="folder"
                    name="folder"
                    onChange={(e) => {setData({...data, folderFile: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                  )}
                />
                   <FormHelperText error={Boolean(errors?.folder)}>
            {errors?.folder?.message}
            </FormHelperText>
          <label htmlFor="folder"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o cartaz do curso
          </label>
          <Info>
            Formato: JPG ou PNG
              </Info>
          {data?.folder || data?.folderFile ? <>
          <PrevewImg src={data?.folder || URL.createObjectURL(data?.folderFile)}/>
          <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={()=> delFolder()}><u>remover</u></button>
          </>:null }

            </Card>
          </FlexImg>
        </div> */}
         {/*<div>
        <SubTitleSeasons>
        Trailers
        </SubTitleSeasons>
        <FlexVideo>
             <CardVideo style={{maginRight:"60px"}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Trailer
              </TitleCard>
              <Controller
                name="trailer"
                control={control}
                // defaultValue={data?.trailer}
                render={props => (
                  <input
                    type="file"
                    multiple
                    style={{ display: 'none'}}
                    name="trailer"
                    id="trailer"
                    accept=".mp4, .webm, .ogg"
                    onChange={(e)=>{setData({...data, trailerFile: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                )}
                />
                 <FormHelperText error={Boolean(errors?.trailer)}>
            {errors?.trailer?.message}
            </FormHelperText>
          <label htmlFor="trailer"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o trailer da temporada
          </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {data?.trailer || data?.trailerFile? <>
              <video width="730" controls>
              <source src={data?.trailer || URL.createObjectURL(data?.trailerFile)} />
              </video>
              <button style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delTrailer}><u>remover</u></button>
          </>:null }
            </CardVideo>

            <CardExtras style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
              Adicionar Extras
              </TitleCard>
              <input
            {...getInputProps()}/>
            <label htmlFor="extras"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u {...getRootProps({className: 'dropzone'})}>Clique aqui</u> para adicionar extras da temporada
            </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {files ?
              <div>
              {files.map(file => {
                return(
                <div key={file.name} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                 <video width="730" controls>
                 <source src={file.preview} />
                 </video>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
                 onClick={() =>handleRemoveFile(file.name)}><u>remover</u></button>
                </div>
                )}
              )}
              </div>
              :null }
            </CardExtras> 
          </FlexVideo>
         </div>

         <Material errors={errors} control={control}/> */}
      <Episodes index={index} setEpsData={setEpsData} watch={watch} epsData={epsData} setValue={setValue} errors={errors} control={control}/>


        </ContainerSeasons>

        {uploading ?
          renderProgressBar() :
          <button type='submit'>
              Publicar
          </button>
        }
         </form>
         </div>
    )
}

export default Seasons;
