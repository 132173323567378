import { TrainRounded } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { VIEW_COURSE_BY_DATA } from "./dashboard"
import { ColumnTitle, TableComponent, TableContainer, TableData, NoData } from "./styles";
import {
  CircularProgress,
} from '@material-ui/core';
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import { ButtonGroup, ButtonHeader, ColumnLeft, ColumnRight, Content, ContentInfo, Header, Table, Td, TdTitle, Th, Title, Tr, TrList } from "./styles";



const MostAccessedCourses = () => {

  const [activeData, setActiveData] = useState(1);

  const [viewsCourse, {data: dataViewsCourse, loading: loadingViewsCourse, refetch}] = useLazyQuery(VIEW_COURSE_BY_DATA, {
    onCompleted: (e) => {
      console.log(e)
    },
    fetchPolicy: 'no-cache',
  })


  useEffect(()=> {
    viewsCourse({
      variables: {
        days: activeData
      }
    })
  }, [])

  useEffect(()=>{
    console.log(activeData)
    viewsCourse({
      variables: {
        days: activeData
      }
    })
  }, [activeData])


  const CourseName = [
    "China Pro Games - Nome de Grande Bem Grande",
    "Multiletramentos",
    "Robótica em Ação",
    "Introdução a Robótica",
    "Sementes da Educação"
  ]

  const CourseNumber = [
    "500 acessos",
    "156 acessos",
    "148 acessos",
    "145 acessos",
    "126 acessos",
  ]


    return (
      <Content>
      <Header>
        <Title>Cursos mais acessados</Title>


        <ButtonGroup>
          {activeData === 1?
          <ButtonHeader
            style={{border: "1px solid #DCDDDF",
            borderRadius: "24px"}}
            onClick={(e)=> setActiveData(1)}>
          Dia
          </ButtonHeader>
          :
          <ButtonHeader  onClick={(e)=> setActiveData(1)}>
            Dia
          </ButtonHeader>}

          {activeData === 7?
          <ButtonHeader
            style={{border: "1px solid #DCDDDF",
            borderRadius: "24px"}}
            onClick={(e)=> setActiveData(7)}>
          Semana
          </ButtonHeader>
          :
          <ButtonHeader onClick={(e)=> setActiveData(7)}>
              Semana
          </ButtonHeader>}

          {activeData === 365?
          <ButtonHeader
            style={{border: "1px solid #DCDDDF",
            borderRadius: "24px"}}
            onClick={(e)=> setActiveData(365)}>
          Ano
          </ButtonHeader>
          :
          <ButtonHeader onClick={(e)=> setActiveData(365)}>
              Ano
          </ButtonHeader>}
        </ButtonGroup>
      </Header>
      <ContentInfo>
        {activeData === 1 ?
        <TableContainer>
        <TableComponent>
            <thead>
                <ColumnTitle>

                    <th></th>
                    <th></th>

                </ColumnTitle>
            </thead>

            <tbody>

        <TableData>
        {!loadingViewsCourse ? 
          dataViewsCourse?.getCourseViewsByData?.length < 1 ? (
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <NoData>Não houve nenhum acesso</NoData>
            </div>
          ) : (
             <>
            <td> <ol>
              {dataViewsCourse?.getCourseViewsByData?.map((item)=>(
                <li>{item.name}</li>
              ))}
            </ol></td>
              <td>{dataViewsCourse?.getCourseViewsByData?.map((item)=>(
                <li style={{listStyleType:"none"}}
                >{item.UnitVideoPlayHistorics.length + ' acessos'}</li>
              ))}</td>
              </>
          )           
           :
          (
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
            
          )}

      </TableData>

            </tbody>
        </TableComponent>
       </TableContainer>
        :null}


{activeData === 7 ?
        <TableContainer>
        <TableComponent>
            <thead>
                <ColumnTitle>

                    <th></th>
                    <th></th>

                </ColumnTitle>
            </thead>

            <tbody>

        <TableData>
        {!loadingViewsCourse ? 
          dataViewsCourse?.getCourseViewsByData?.length < 1 ? (
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <NoData>Não houve nenhum acesso</NoData>
            </div>
          ) : (
             <>
            <td> <ol>
              {dataViewsCourse?.getCourseViewsByData?.map((item)=>(
                <li>{item.name}</li>
              ))}
            </ol></td>
              <td>{dataViewsCourse?.getCourseViewsByData?.map((item)=>(
                <li style={{listStyleType:"none"}}
                >{item.UnitVideoPlayHistorics.length + ' acessos'}</li>
              ))}</td>
              </>
          )           
           :
          (
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
            
          )}

      </TableData>

            </tbody>
        </TableComponent>
       </TableContainer>
        :null}


{activeData === 365 ?
        <TableContainer>
        <TableComponent>
            <thead>
                <ColumnTitle>

                    <th></th>
                    <th></th>

                </ColumnTitle>
            </thead>

            <tbody>

        <TableData>
        {!loadingViewsCourse ? 
          dataViewsCourse?.getCourseViewsByData?.length < 1 ? (
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <NoData>Não houve nenhum acesso</NoData>
            </div>
          ) : (
             <>
            <td> <ol>
              {dataViewsCourse?.getCourseViewsByData?.map((item)=>(
                <li>{item.name}</li>
              ))}
            </ol></td>
              <td>{dataViewsCourse?.getCourseViewsByData?.map((item)=>(
                <li style={{listStyleType:"none"}}
                >{item.UnitVideoPlayHistorics.length + ' acessos'}</li>
              ))}</td>
              </>
          )           
           :
          (
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
            
          )}
      </TableData>

            </tbody>
        </TableComponent>
       </TableContainer>
        :null}


      </ContentInfo>
      </Content>
      )}
export default MostAccessedCourses;
