import React, { useState } from 'react';
import {
  Paper, Card, CardHeader, Grid, Typography, CardContent, IconButton, Table, TableHead, TableRow, TableCell, TableBody, Container, Icon, Tooltip,
} from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { QuestionTitle, StyledContainer, StyledDiv } from './styles';
import SubjectiveQuestion from './SubjectiveQuestion';
import GlobalContext from '../../../../context';
import useManualQuery from '../../../../context/useManualQuery';
import { GET_ANSWERS_FROM_QUESTION } from '../../../../services/queries/questions';
import Answer from './Answer';
import ModalScore from './ModalScore';
import ObjectiveQuestion from './ObjectiveQuestion';
import { StyledButton, StyledTableCell } from '../styles';
import { Delete, Edit } from '@material-ui/icons';
import { MdListAlt } from 'react-icons/md';
import TrueFalseQuestion from './TrueFalseQuestion';

const ExerciseQuestion = ({ exerciseQuestion, index }) => {
  const {
    visualization: {
      visualization,
    },
    selectedCourse: {
      exercises: {
        questions: {
          getQuestions,
          setDeleteDialogQuestionOpen,
          removeQuestion,
          setRemoveQuestion,
          setModalNewAternativeOpen,
          setEditQuestion,
          setEditDialogQuestionOpen
        },
      },
    },
  } = GlobalContext();

  console.log('getQuestion',  getQuestions)
  const [selected, setSelected] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [modalScoreOpen, setModalScoreOpen] = useState(false);


  const closeCard = (e) => {
    e.stopPropagation();
    setSelected(false);
  };

  const getAnswers = useManualQuery(GET_ANSWERS_FROM_QUESTION,{
    variables:{
      id: exerciseQuestion.id
    },
    onCompleted: e => console.log(e),
    onError: e=> console.log(e)
  }, "no-cache");

  const openCard = () => {
    if (!getAnswers.data) {
      getAnswers.fetch({
        variables: {
          id: exerciseQuestion.id,
        },
      });
    }
    setSelected(true);
  };

  const renderAnswers = () => {
    if (!getAnswers.data) {
      return 'Carregando...';
    }

    const { data: { getAnswersFromExerciseQuestion: answers } } = getAnswers;


    if (answers.length === 0) {
      return (
        <TableRow>
          <TableCell>
            Nenhuma resposta ainda.
          </TableCell>
        </TableRow>
      );
    }

    if(answers[0].__typename === 'TrueFalseAnswer'){
      const answersFiltered = [];
      const answersFormatted = [];

      const map = new Map();

      answersFiltered.push(...answers.filter(answer => answer.value === true));
      for(let i = 0; i < answersFiltered.length; i++){
        if(!map.has(answersFiltered[i].user.id)){
          answersFiltered[i].alternatives = [answersFiltered[i].trueFalseQuestionAlternative];
          map.set(answersFiltered[i].user.id, answersFiltered[i]);
        } else {
          const alternatives = map.get(answersFiltered[i].user.id).alternatives;
          
          let repeatedText = false;
          for(let j = 0; j < alternatives.length; j++){
            if(alternatives[j].text === answersFiltered[i].trueFalseQuestionAlternative.text){
              repeatedText = true;
              break;
            }
          }

          if(!repeatedText){
            alternatives.push(answersFiltered[i].trueFalseQuestionAlternative);
            answersFiltered[i].alternatives = alternatives;
            map.set(answersFiltered[i].user.id, answersFiltered[i]);
          }
        }
      }

      map.forEach((value, key) => console.log(`VALUE FOR KEY ${key}: `, value))

      for (const value of map.values()) {
        answersFormatted.push(value);
      }

      return answersFormatted.map(i => (
        <Answer
          key={i.id}
          answer={i}
          setSelectedAnswer={setSelectedAnswer}
          setModalScoreOpen={setModalScoreOpen}
        />
      ));
    }

    return answers.map(i => (
      <Answer
        key={i.id}
        answer={i}
        setSelectedAnswer={setSelectedAnswer}
        setModalScoreOpen={setModalScoreOpen}
      />
    ));
  };

  const renderQuestion = (exerciseQuestion, index) => {
    console.log('A: ',exerciseQuestion)
    const { question: { __typename } } = exerciseQuestion;
    if (__typename === 'SubjectiveQuestion') {
      return <SubjectiveQuestion exerciseQuestion={exerciseQuestion} index={index} />;
    } if (__typename === 'ObjectiveQuestion') {
      return <ObjectiveQuestion exerciseQuestion={exerciseQuestion} index={index} />;
    }
    if (__typename === 'TrueFalseQuestion') {
      return <TrueFalseQuestion exerciseQuestion={exerciseQuestion} index={index} />;
    }
  };

  console.log('exerciseQuestion', exerciseQuestion)


  return (
    <Grid
      key={exerciseQuestion.id}
      item
      xs={12}
      onClick={openCard}
    >
      {selected && visualization !== 'Estudante' ? (
        <>
          <Card>
            <CardContent>
              <Grid container justify="flex-start" alignItems="center">
                <Grid item>
                  <IconButton
                    onClick={closeCard}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <Typography variant="h6" component="span">
                    {index + 1}
                    .
                    {' '}
                  </Typography>
                </Grid>
                <Grid item>
                  <QuestionTitle className="question-title" component="span">{exerciseQuestion.question.stem}</QuestionTitle>
                </Grid>
              </Grid>
              <Container>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Aluno
                      </TableCell>
                      <TableCell>
                        Resposta
                      </TableCell>
                      {exerciseQuestion.question.__typename === 'SubjectiveQuestion' && (
                        <TableCell>
                          Nota
                        </TableCell>
                      )}
                      {exerciseQuestion.question.__typename === 'ObjectiveQuestion' && (
                        <TableCell>
                          Resultado
                        </TableCell>
                      )}
            {/* <TableCell>
            {visualization === 'Administrador'?
            <> */}

            {/* {exerciseQuestion.question.__typename !== 'SubjectiveQuestion' && (
              <Tooltip title="Criar Alternativas">
               <StyledButton
               onChange={()=> setModalNewAternativeOpen(true)}>
                <MdListAlt size={24} />
                </StyledButton>
            </Tooltip>
                      )}
                          <Tooltip title="Editar">
                         <StyledButton
                         onClick={() =>{
                          setEditQuestion(exerciseQuestion.question);
                          setEditDialogQuestionOpen(true)
                         }}
                         >
                           <Icon component={Edit} />
                         </StyledButton>
                         </Tooltip>
                         {' '}

                         <Tooltip title="Excluir">
                         <StyledButton
                         onClick={() => {
                           setRemoveQuestion(exerciseQuestion.question.id);
                           setDeleteDialogQuestionOpen(true);
                         }}
                       >
                         <Icon component={Delete} />
                       </StyledButton>
                       </Tooltip>
                       </>
          :null} */}
          {/* </TableCell> */}

                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {renderAnswers()}
                  </TableBody>
                </Table>
              </Container>
            </CardContent>
          </Card>
        </>
      ) : (
        <StyledDiv>
          <Typography variant="h6" component="span">
            {index + 1}
                .
            {' '}
          </Typography>
          <QuestionTitle className="question-title" component="span">{exerciseQuestion.question.stem}</QuestionTitle>
          {visualization === 'Estudante' && (
            <StyledContainer>
              {renderQuestion(exerciseQuestion, index)}
            </StyledContainer>
          )}
        </StyledDiv>
      )}
      <ModalScore
        open={modalScoreOpen}
        setOpen={setModalScoreOpen}
        selectedAnswer={selectedAnswer}
        refetch={getAnswers.refetch}
      />
    </Grid>
  );
};

export default ExerciseQuestion;
