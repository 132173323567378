import React from "react";
import { Container, MenuContainer, MenuItem, NavLink } from './styles';
import { MdOutlineVideoLibrary,
  MdOutlineFolderSpecial,
  MdOutlineTableChart,
  MdOutlinePeopleAlt,
  MdOutlineDashboard,
  MdOutlineInventory,
  MdDomainVerification} from 'react-icons/md';
import { useState } from "react";
import PublisherContents from "../../contents";
import PublisherCategories from "../../categories";
import PublisherTables from "../../tables";
import PublisherPlans from "../../plans";
import PublisherNotifications from "../../notifications";
import PublisherMembers from "../../members";
import PublisherDashboard from "../../dashboard";
import Header from "../header";
import PublisherCertificates from "../../certificates";
import PublisherPrivacityTerms from "../../privacity-terms";
import PublisherUseTerms from "../../use-terms";
import { ContainerDashboard } from "../../dashboard/styles";


const SideBar = () => {

  const [selected, setSelected] = useState(<PublisherDashboard/>);
  console.log("select", selected)

  return (
<>

<Container>
<MenuContainer>

<MenuItem onClick={() => { setSelected(<PublisherDashboard/>)}}>
    <MdOutlineDashboard size={25}/>
    <NavLink to="/publishermain/dashboard" >
        Dashboard
    </NavLink>
</MenuItem>

  <MenuItem onClick={() => { setSelected(<PublisherContents/>)}}>
  <MdOutlineVideoLibrary size={25}/>
   <NavLink to="/publishermain/contents">
    Cursos
    </NavLink>
</MenuItem>

<MenuItem onClick={() => { setSelected(<PublisherMembers/>)}}>
    <MdOutlinePeopleAlt size={25}/>
    <NavLink to="/publishermain/members" >
        Membros
    </NavLink>
</MenuItem>



<MenuItem onClick={() => { setSelected(<PublisherCategories/>)}}>
    <MdOutlineFolderSpecial size={25}/>
    <NavLink to="/publishermain/categories">
        Categorias
    </NavLink>
</MenuItem>



<MenuItem onClick={() => { setSelected(<PublisherTables/>)}}>
    <MdOutlineTableChart size={25}/>
    <NavLink to="/publishermain/tables" >
        Tabelas
    </NavLink>
</MenuItem>

{/* <MenuItem onClick={() => { setSelected(<PublisherPlans/>)}}>
    <MdAttachMoney size={25}/>
    <NavLink to="/publishermain/plans">
        Planos
    </NavLink>
</MenuItem> */}

 {/* <MenuItem onClick={() => { setSelected(<PublisherNotifications/>)}}>
    <MdOutlineNotifications size={25}/>
    <NavLink to="/publishermain/notifications" >
        Notificações
    </NavLink>
</MenuItem> */}


<MenuItem onClick={() => { setSelected(<PublisherCertificates/>)}}>
    <MdDomainVerification size={25}/>
    <NavLink to="/publishermain/certificates" >
        Certificados
    </NavLink>
</MenuItem>

<MenuItem onClick={() => { setSelected(<PublisherUseTerms/>)}}>
    <MdOutlineInventory size={25}/>
    <NavLink to="/publishermain/use-terms" >
    Termos de Uso
    </NavLink>
</MenuItem>

<MenuItem onClick={() => { setSelected(<PublisherPrivacityTerms/>)}}>
    <MdOutlineInventory size={25}/>
    <NavLink to="/publishermain/privacity-terms" >
    Termos de Privacidade
    </NavLink>
</MenuItem>


</MenuContainer>

      <ContainerDashboard>
    {selected}
      </ContainerDashboard>

</Container>
</>
)}
export default SideBar;
