import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  TextField,
  MenuItem,
  Select,
  OutlinedInput,
} from '@material-ui/core';
import React from 'react';
import GlobalContext from '../../../context';

const ModalNewAlgorithmTest = () => {
  const {
    selectedCourse: {
      course: { id },
      exercises: {
        getExercises,
        newAlgorithmTestOpen,
        setNewAlgorithmTestOpen,
        newAlgorithmTest: {
          quantity,
          setQuantity,
          createAlgorithmTest,
          selectedModule,
          setSelectedModule,
          setTitle,
          title,
        },
        getCourseModules,
      },
    },
  } = GlobalContext();

  const closeModal = () => {
    setQuantity(3);
    setNewAlgorithmTestOpen(false);
  };

  const submit = async () => {
    await createAlgorithmTest({
      variables: {
        id, module: selectedModule, title, quantity,
      },
    });
    getExercises.refetch();
  };

  const renderGetCourseModules = () => {
    if (!getCourseModules.data) {
      return <MenuItem>Carregando...</MenuItem>;
    }

    return getCourseModules.data.getCourseById.modules.map(i => (
      <MenuItem key={i.id} value={i.id}>
        {i.name}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      open={newAlgorithmTestOpen}
      onClose={closeModal}
    >
      <DialogTitle>Criar nova atividade inteligente</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              label="Título"
              value={title}
              onChange={e => setTitle(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="number"
              label="Número de questões"
              value={quantity}
              onChange={e => setQuantity(e.target.value)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <Select
              value={selectedModule}
              onChange={e => setSelectedModule(e.target.value)}
              input={<OutlinedInput fullWidth name="institution" id="institution" />}
            >
              {renderGetCourseModules()}
            </Select>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={closeModal}
            >
            Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={submit}
            >
            Gerar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalNewAlgorithmTest;
