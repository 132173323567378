import React from "react";
import { string, number, func, shape, arrayOf, object } from "prop-types";

import {
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
  Checkbox,
  IconButton,
} from "@material-ui/core";

import { Edit } from "@material-ui/icons";

import { StyledCard } from "./styles";

const TrailerCard = ({ name, trailer, folder, checkFunction, editFunction, enterFunction }) => {

  return (
    <StyledCard>
      <CardMedia
        className="cardMedia"
        image={folder}
        style={enterFunction ? { cursor: "pointer" } : null}
        onClick={enterFunction ? () => enterFunction(trailer) : null}
      >
        <Typography
          className="title"
          color="textPrimary"
          gutterBottom
          variant="h2"
          component="h5"
        >
          {name}
        </Typography>
        <div className="actions">
          {checkFunction && (
            <Checkbox
              className="action"
              onClick={() => checkFunction(trailer)}
              value="checkedB"
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          )}
          {editFunction && (
            <IconButton
              className="action"
              color="default"
              onClick={() => editFunction(trailer)}
            >
              <Edit />
            </IconButton>
          )}
        </div>
        {/* percent && percent !== 0 && (
          <LinearProgress
            className="progressBar"
            variant="determinate"
            color="primary"
            value={percent}
          />
        ) */}
      </CardMedia>
    </StyledCard>
  );
};

TrailerCard.defaultProps = {
  checkFunction: null,
  editFunction: null,
  enterFunction: null,
};

export default TrailerCard;
