import { Input } from '@material-ui/core';
import React from 'react';
import * as Dialog from '@radix-ui/react-dialog'
import { useState } from 'react';
import GlobalContext from '../../../../context';
import { FormItem, Label, Small } from '../styles';
import { NotificationManager } from "react-notifications";
import { CloseButton, Content, Overlay } from '../DialogPassword/styles';
import { Close} from '@material-ui/icons';

export function DialogEmail() {
  const {
    user,
    users: {
      getUsers,
      updateUser,
    },

  } = GlobalContext();

  const [email, setEmail] = useState(user.email);

  const handleSubmitEmail = async (e) => {
    e.preventDefault();
    if (!email) {
      return NotificationManager.error('Todos as informações são obrigatórias');
    }
    if (email) {
      try {
        await updateUser({ variables: { email: email } });
      } catch {
        return NotificationManager.error('Já existe um usuário com esse e-mail');
      }
    }
    return NotificationManager.success('Operação realizada com sucesso');
  };



  console.log("email", email)

  return (
    <Dialog.Portal>
      <Overlay/>

      <Content>
        <Dialog.Title>Mudar Email</Dialog.Title>
        <CloseButton>
        <Close/>
        </CloseButton>

        <form action="">
        <FormItem>
        <Label>
          Novo email
        </Label>
        <Input
        type="text"
        name="user.email"
        value={user.email}
        onChange={(e) => setEmail(e.target.value)}
        style={{color:"#ffffff"}}/>
        </FormItem>

          <button type='submit'>
            Salvar alterações
          </button>
        </form>
      </Content>

    </Dialog.Portal>
  )
}
