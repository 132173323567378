import gql from "graphql-tag";

const COURSE_PRIMARY_FIELDS = `
  id
  name
  description
  initials
  version
  timeOfDedication
  startDate
  endDate
  public
  studentQuantity
  moduleQuantity
  unitQuantity
  price
  minimumGrade
  folder
  thumbnail
  trailer
  year
  age
  tags {
    id
    name
  }
  modules{
    id
    name
    units{
      id
      name
      folder
      content
      hlsContent
      type
      duration
      subtitles {
        id
        idiom
        content
      }
      unitUserPlayHistoric {
        id
        UserId
        ElapsedTime
        Ended
      }
    }
  }
  categories{
    id
    name
  }
  material{
    id
    name
    url
  }
`;

export const GET_MODULES_BY_COURSE = gql`
  query ($id: ID!, $filter: Boolean) {
    getCourseById(id: $id) {
      modules(filterVisible: $filter) {
        id
        name
        startDate
        endDate
        units {
          id
          name
          order
          folder
          content
          hlsContent
          type
          duration
          topic {
            id
          }
          subtitles {
            id
            idiom
            content
          }
          unitUserPlayHistoric {
            id
            UserId
            ElapsedTime
            Ended
          }
        }
      }
    }
  }
`;

export const GET_POSTS_BY_TOPIC = gql`
  query ($id: ID!) {
    getTopicById(id: $id) {
      posts {
        id
        text
        fixed
        createdAt
        creator {
          id
          fullName
          email
          avatar
        }
      }
    }
  }
`;
