import React, { useEffect, useState } from "react";
import { shape, func } from "prop-types";
import { withRouter } from "react-router-dom";
import { Grid} from "@material-ui/core";
import { Container, CourseDesktop, CourseMobile } from "../styles";
import CourseCard from "../../../components/CourseCard";
import { StyledContainer } from "../../../styles/global";
import GlobalContext from "../../../context";
import CustomSlider from "../../../components/Sliders/CustomSlider";
import { Linha, Title } from "../styles";
import Slider from "react-slick";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import gql from 'graphql-tag';

const COURSES = `
    id
    name
    year
    age
    description
    initials
    version
    timeOfDedication
    startDate
    endDate
    public
    studentQuantity
    moduleQuantity
    unitQuantity
    price
    minimumGrade
    folder
    thumbnail
    trailer
    tags {
      id
      name
    }
    modules{
      id
      name
      units{
        id
        name
        folder
        content
        duration
        description
        subtitles {
          id
          idiom
          content
        }
        unitUserPlayHistoric {
          id
          UserId
          ElapsedTime
          Ended
        }
      }
    }
    categories{
      id
      name
    }
    material{
      id
      name
      url
    }
`

export const GET_COURSES_CATEGORIES = gql`
query {
  getCategories{
      id
      name
      courses{
         ${COURSES}
      }

  }
 }
`;


const GET_NEWEST_COURSES = gql`
 query{
  getNewestCourses{
    ${COURSES}
  }
 }
`



const MyCourseList = ({ history }) => {
  const {
    user: { id, institution },
    signIn: {loading},
    selectedCourse: {
      setCourse,
      getMyCoursesByUser,
      setCourseAccess,
      getCoursesByInstitution,
    },
    visualization: { visualization },
  } = GlobalContext();

  const { data: dataCategorias, loading: loadingCategories } = useQuery(GET_COURSES_CATEGORIES,{
    onCompleted: e =>console.log(e.getCategories),
    fetchPolicy: 'no-cache',
  })

  const { data: dataNewestCourses, loading: loadingNewestCourses } = useQuery(GET_NEWEST_COURSES,{
    onCompleted: e =>console.log(e),
    fetchPolicy: 'no-cache',
  })


  useEffect(() => {
    let role;
    switch (visualization) {
      case "Estudante":
        role = "STUDENT";
        break;
      case "Professor":
        role = "TEACHER";
        break;
      default:
        break;
    }

  }, []);

  useEffect(()=>{
    if(!loading){
      console.log('OXE MUDOU1 ', id)
      getMyCoursesByUser.fetch({ variables: { id } });
    }

  }, [id])

  useEffect(() => {
    getMyCoursesByUser.refetch();
    console.log("Courses: ", getMyCoursesByUser)
    getCoursesByInstitution.fetch({ variables: { id: institution.id } });
  }, []);

  if (!getMyCoursesByUser.data || !getCoursesByInstitution.data || loading || !id) {
    return "Carregando..." ;
  }

  const {
    data: {
      getUserById: { courses },
    },
  } = getMyCoursesByUser;

  const {
    data: {
      getInstitutionById: { courses: allCourses },
    },
  } = getCoursesByInstitution;

  const enterCourse = (course) => {
    console.log(getMyCoursesByUser)
    console.log(course)
    console.log(courses)
    setCourse(course);
    setCourseAccess(false);

    for (let i = 0; i < courses.length; i += 1) {
      if (courses[i].id === course.id) {
        setCourseAccess(true);
        break;
      }
    }

    history.push("/course");
  };

  const courseList = courses.map((course) => (
    <Grid item key={course.id} xs={12} sm={6} md={3}>
      <CourseCard course={course} enterFunction={enterCourse} />
    </Grid>
  ));


  const courseEntry = (course) => {
      console.log(course)

      return course.map((item) => (
        <Grid item key={item.id} xs={12} sm={6} md={3}>
          <CourseCard course={item} enterFunction={enterCourse} />
        </Grid>
      ));
    }

    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "35px",
      slidesToShow: 1,
      speed: 500,
      arrows: false
    };

  return (
    <div>
      {courses.length > 0 && (
        <>
          <Title>
            <h3 style={{ paddingRight: "10px", color:"#ffffff" }}>{"Continuar assistindo"}</h3>
            <Linha></Linha>
          </Title>

          <CourseDesktop>
          <CustomSlider itemWidth={280} courses={courseList} />
          </CourseDesktop>

          <CourseMobile>
         <Slider {...settings}>
         {courseList}
          </Slider>
        </CourseMobile>

          {/* courses.length === 0 && (
          <Typography variant="h6">Nenhum curso no momento</Typography>
        ) */}
      </>
      )}

      {!loadingNewestCourses && dataNewestCourses?.getNewestCourses && (
        <>
          <Title>
            <h3 style={{ paddingRight: "10px", color:"#ffffff" }}> {"Lançamentos"}</h3>
            <Linha></Linha>
          </Title>
          <CourseDesktop>
          <CustomSlider itemWidth={280} courses={courseEntry(dataNewestCourses?.getNewestCourses)} />
          </CourseDesktop>
        <CourseMobile>
          <Slider {...settings}>
          {courseEntry(dataNewestCourses?.getNewestCourses)}
            </Slider>
          </CourseMobile>
        </>
      )}


      {!loading && id && getMyCoursesByUser.data && !loadingCategories && dataCategorias?.getCategories.map((item)=> (visualization === "Estudante" && item.name === 'Capacitação de Professores') || item.courses.length < 1 ? null : (
          <>
            <Title>
                <h3 style={{ paddingRight: "10px", color:"#ffffff" }}> {item.name}</h3>
                <Linha></Linha>
            </Title>
            <CourseDesktop>
              <CustomSlider itemWidth={280} courses={courseEntry(item.courses)} />
            </CourseDesktop>
            <CourseMobile>
              <Slider {...settings}>
                {courseEntry(item.courses)}
              </Slider>
            </CourseMobile>
          </>
        )
      )}




 {/*

        <Title>
          <h3 style={{ paddingRight: "10px", color:"#ffffff" }}> {"Cursos Tecnológicos"}</h3>
          <Linha></Linha>
        </Title>
        <CourseDesktop>
        <CustomSlider itemWidth={280} courses={formationCoursesList} />
        </CourseDesktop>

      <CourseMobile>
         <Slider {...settings}>
         {formationCoursesList}
          </Slider>
        </CourseMobile>




        <Title>
          <h3 style={{ paddingRight: "10px", color:"#ffffff" }}>{"Temas Transversais"}</h3>
          <Linha></Linha>
        </Title>
        <CourseDesktop>
        <CustomSlider itemWidth={280} courses={transversalThemesList} />
        </CourseDesktop>
      <CourseMobile>
         <Slider {...settings}>
         {transversalThemesList}
          </Slider>
        </CourseMobile>



      {visualization !== "Estudante" && (
        <>
          <Title>
            <h3 style={{ paddingRight: "10px", color:"#ffffff" }} >
              {" "}
              {"Capacitação de Professores"}
            </h3>
            <Linha></Linha>
          </Title>
          <CourseDesktop>
          <CustomSlider itemWidth={280} courses={teachersTrainingList} />
          </CourseDesktop>

          <CourseMobile>
         <Slider {...settings}>
         {teachersTrainingList}
          </Slider>
        </CourseMobile>
          </>

       )} */}
    </div>
  );
};

MyCourseList.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(MyCourseList);
