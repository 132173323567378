import * as yup from "yup";

export const createModuleTwo = yup.object().shape({
  name: yup.string().required('Campo "Título" é obrigatório'),
  // synopsis: yup.string().required('Campo "Resumo" é obrigatório'),
  tags: yup.array().required('Campo "Categorias" é obrigatório').min(1),
  year: yup.string().required('Campo "Ano de lançamento" é obrigatório').nullable(),
  startDate: yup.date().max(new Date(), 'Data tem que ser menor que a data atual').required('Campo "Período de destaque" é obrigatório'),
  endDate: yup.date().min(new Date(), 'Data tem que ser maior que a data atual').required('Campo "Período de destaque" é obrigatório'),
  description: yup.string().required('Campo "Descrição" é obrigatório'),
  age: yup.string().required('Campo "Faixa Etária" é obrigatório').nullable(),
  // nameDuration: yup.string(),
  },
);


