import gql from 'graphql-tag';

export const GET_QUESTIONS = gql`
  query {
    getQuestions {
      question{
        id, type
      }
    }
  }
`;

export const GET_ANSWERS_FROM_QUESTION = gql`
  query($id: ID!) {
    getAnswersFromExerciseQuestion(id: $id) {
      __typename
      ... on SubjectiveAnswer {
        id
        text
        isCorrect
        user {
          id
          fullName
        }
        grade        
      }
      ... on ObjectiveAnswer {
        id
        alternative {
          id
          text
          isCorrect
        }
        user {
          id
          fullName
        }
      }
      ... on TrueFalseAnswer {
        id
        trueFalseQuestionAlternative {
          id
          text
        }
        exerciseQuestions{
          id
        }
        value
        user {
          id
          fullName
        }
      }
    }
  }
`;

export const GET_OBJECTIVE_QUESTION_BY_ID = gql`
  query($id: ID!) {
    question: getObjectiveQuestionById(id: $id) {
      id
      stem
      alternatives {
        id
        text
        isCorrect
      }
      tags {
        id
        name
        description
      }
    }
  }
`;

export const GET_TRUEFALSE_QUESTION_BY_ID = gql`
  query($id: ID!) {
    question: getTrueFalseQuestionById(id: $id) {
      id
      stem
      alternatives {
        id
        text
        isCorrect
      }
      tags {
        id
        name
        description
      }
    }
  }
`;

export const GET_SUBJECTIVE_QUESTION_BY_ID = gql`
  query($id: ID!) {
    question: getSubjectiveQuestionById(id: $id) {
      id
      stem
      tags {
        id
        name
        description
      }
    }
  }
`;
