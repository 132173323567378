import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import UserAvatar from '../../../components/UserAvatar';
import { AvatarImg, BoxDetails, ButtonDetails, ButtonLogout, Buttons, DetailsText, FormContainer, FormDate, FormItem, ImageContainer, ImageDescription, Input, Label, Select, Small, Submit, SubText, TextDescription, Title } from './styles';
import {FiHelpCircle} from 'react-icons/fi';
import {AiOutlineMail}  from 'react-icons/ai';
import {MdOutlineModeEdit} from 'react-icons/md';
import GlobalContext from '../../../context';
import AppBarWrapper from '../../../components/AppBarWrapper';
import Header from '../Header/Header';
import { Container } from '../styles';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import * as Dialog from '@radix-ui/react-dialog'
import { DialogPassword } from './DialogPassword/DialogPassword';
import { DialogEmail } from './DialogEmail/DialogEmail';
import {userAvatar} from '../../../assets/images/user.png'


const ProfileData = () => {
  const {
    user,
    users: {
      getUsers,
      updateUserProfileInfo,
    },

  } = GlobalContext();


  const [name, setName] = useState(user.fullName);
  const [birthDate, setBirthDate]  = useState(user.birthDate);
  const [avatar, setAvatar]  = useState( user.avatar ?? "https://thumbs.dreamstime.com/b/vetor-de-%C3%ADcone-perfil-do-avatar-padr%C3%A3o-foto-usu%C3%A1rio-m%C3%ADdia-social-183042379.jpg");


  // updateUserById: checkPermission('user:edit', updateUserById),
  // updateUser: checkAuth(updateUser),
  // updateUserPassword: checkAuth(updateUserPassword),

const delImage =()=>{
setAvatar('https://thumbs.dreamstime.com/b/vetor-de-%C3%ADcone-perfil-do-avatar-padr%C3%A3o-foto-usu%C3%A1rio-m%C3%ADdia-social-183042379.jpg')
}


  const handleSubmit = () => {
    if (!name || !birthDate || !avatar) {
      return NotificationManager.error('Todos as informações são obrigatórias');
    }
    if (name || birthDate || avatar) {
    try {
     updateUserProfileInfo({ variables: {
      fullName: name,
      birthDate: birthDate,
      avatar: avatar
    } });
    NotificationManager.success('Alteração realizada com sucesso');
    getUsers.refetch();
  }
  catch {
    return NotificationManager.error('Algo de errado ocorreu. Tente Novamente.');
  };}
}

useEffect(() => {
}, [name, avatar, birthDate]);
  console.log("fullName", name)
  console.log("birthDate", birthDate)
  console.log('avatar', avatar)


 return (
  <>
  <AppBarWrapper/>
  <Header/>
  <Container>
  <SideBarMenu/>
  <FormContainer>
    <Title>Seus dados</Title>
      <p style={{paddingBottom:"5px" }}>Foto</p>
      <ImageContainer>
      <AvatarImg src={avatar} alt="image"/>
      <ImageDescription>
      <TextDescription>
      Recomendamos usar uma imagem de até 4 MB com pelo menos 98 x 98 pixels. Use um arquivo no formato PNG ou JPEG
      </TextDescription>

      <input
      type="file"
      accept="image/png, image/jpeg"
      style={{ display: 'none'}}
      id="avatar"
      name="avatar"
      onChange={(e) => setAvatar(URL.createObjectURL(e.target.files[0]))}
      />

    <Buttons>
     <label htmlFor="avatar"
     style={{color: "#FFB799", padding: "15px", cursor:"pointer", fontSize:"14px" }}>
      alterar
     </label>

      <button
      style={{color: "#FFB799", padding: "15px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none" }}
      htmlFor="remove-avatar" id="remove-avatar"
      name="remove-avatar"
      onClick={delImage}
      >remover</button>

     </Buttons>

      </ImageDescription>
      </ImageContainer>

      <FormDate>

        <FormItem>
        <Label>
          Seu nome
          <Small>(obrigatório)</Small>
        </Label>
        <Input
        type="text"
        name="user.fullName"
        onChange={(e) => setName(e.target.value)}
        defaultValue={user.fullName || ''}
        placeholder={user.fullName}/>
        </FormItem>

        <FormItem>
        <Label>
          Data de Nascimento
          <Small>(obrigatório)</Small>
        </Label>
        <Input
        name="user.birthDate"
        onChange={(e) => setBirthDate(e.target.value  )}
        type="date"
        defaultValue={user.birthDate || ''}
        placeholder={user.birthDate}/>
        </FormItem>

        <FormItem>
        <Label>
          Perfil
          <Small>(obrigatório)</Small>
          <FiHelpCircle size={18}/>
        </Label>
        <Input
        type="text"
        disabled
        name="user.roles"
        value={user.roles[0].name}
        style={{color:"#ffffff"}}/>
        </FormItem>

      </FormDate>


      <Title style={{paddingTop:"60px"}}>Detalhes da conta</Title>

      <FormDate>

        <FormItem>
        <BoxDetails>
          <div style={{display:"flex"}}>
          <AiOutlineMail size={30} style={{color:"#FF4C00"}}/>
          <DetailsText>
          Seu email
          <SubText>
            {user.email}
          </SubText>
          </DetailsText>
          </div>
          <Dialog.Root>
          <Dialog.Trigger asChild>
          <ButtonDetails>
            Editar
            <MdOutlineModeEdit/>
            </ButtonDetails>
          </Dialog.Trigger>

          <DialogEmail/>
        </Dialog.Root>

         </BoxDetails>
        </FormItem>
        <FormItem>
        <BoxDetails>
          <div style={{display:"flex"}}>
          <AiOutlineMail size={30} style={{color:"#FF4C00"}}/>
          <DetailsText>
          Sua senha
          <SubText>
          ••••••••••••••••••••
          </SubText>
          </DetailsText>
          </div>
          <Dialog.Root>
          <Dialog.Trigger asChild>
          <ButtonDetails>
            Editar
            <MdOutlineModeEdit/>
            </ButtonDetails>
          </Dialog.Trigger>

          <DialogPassword/>
        </Dialog.Root>

         </BoxDetails>
        </FormItem>


      </FormDate>

        <ButtonLogout>Sair de todos os dispositivos</ButtonLogout>
        <div style={{display:"flex", justifyContent: "flex-end"}}>
        <Submit onClick={handleSubmit}> Salvar alterações </Submit>
        </div>

  </FormContainer>
  </Container>
</>
 )
};


export default ProfileData
