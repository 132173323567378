import * as yup from "yup";

export const updateCourse = yup.object().shape({
    name: yup.string().required('Campo "Título" é obrigatório'),
    // synopsis: yup.string().required('Campo "Resumo" é obrigatório'),
    categories: yup.array().required('Campo "Categorias" é obrigatório').min(1),
    year: yup.string().required('Campo "Ano de lançamento" é obrigatório').nullable(),
    startDate: yup.date().max(new Date(), 'Data tem que ser menor que a data atual').required('Campo "Período de destaque" é obrigatório'),
    endDate: yup.date().min(new Date(), 'Data tem que ser maior que a data atual').required('Campo "Período de destaque" é obrigatório'),
    description: yup.string().required('Campo "Descrição" é obrigatório'),
    age: yup.string().required('Campo "Faixa Etária" é obrigatório').nullable(),
    thumbnail: yup.mixed().required('Você deve adicionar uma thumbnail.'),
    folder: yup.mixed().required('Você deve adicionar um cartaz.'),
    trailer: yup.mixed(),
    },
    // ['thumbnail', 'thumbnail2']
);

export const createCourseTwo = yup.object().shape({
    videos: yup.array().of(yup.mixed()).required('Você deve adicionar ao menos um episódio.').min(1),
    nameUnit: yup.string(),
    nameModule: yup.string().required('Campo "Nome da temporada" é obrigatório'),
    // nameDuration: yup.string(),
    },
);


export const updateModule = yup.object().shape({
  videos: yup.array().of(yup.mixed()).required('Você deve adicionar ao menos um episódio.').min(1),
  nameUnit: yup.string(),
  name: yup.string(),
  // nameDuration: yup.string(),
  },
);
