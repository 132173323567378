import React from 'react';

import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {
  DescriptionPaper,
} from './../styles';


export function getTrajectoryReportTable(report) {
  if(!report)
    return ("Carregando...");

  if(Object.keys(report).length === 0)
    return (
      "Sem resultados :-("
    );
  return (
    report.map(element => (
      <Grid style={{marginLeft: "10%", marginRight: "10%"}}  container>
        <DescriptionPaper square={true}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Typography gutterBottom variant="p">
              Tipo: <b>Trajetória {element.type === 'ORDERED' ? 'Linear' : 'Não Linear'}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="p">
              Porcentagem: <b>{element.quantity * 100}%</b>
              </Typography>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </DescriptionPaper>
        <Typography variant="p">
          Trajetória do aluno
        </Typography>
        <Grid item xs={12}>
        {element.students.map(student => (
          <ExpansionPanel>
            <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}>
              <Typography gutterBottom variant="p">
                Nome: <b>{student.fullName}</b>
              </Typography>
            </ExpansionPanelSummary>
          
            <ExpansionPanelDetails>
              <Table title="Trajetória do aluno">
                <TableHead>
                  <TableRow>
                    <TableCell>Ordem</TableCell>
                    <TableCell>Aula</TableCell>
                    <TableCell align="right">Data de Acesso</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {student.lessons.map((lesson, index) => (
                  <TableRow key={lesson.id}>       
                    <TableCell component="th" scope="row">{index + 1}°</TableCell>
                    <TableCell component="th" scope="row">
                      {lesson.name}
                    </TableCell>
                    <TableCell align="right">{lesson.lastAccess}</TableCell>
                  </TableRow>
                ))}              
                </TableBody>
              </Table>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          ))}
        </Grid>
    </Grid>
  )));
}