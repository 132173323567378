import { Accordion, AccordionDetails,AccordionSummary} from '@material-ui/core';
import React, {useState} from 'react';
import {  MdAddCircle, MdRemoveCircle} from 'react-icons/md';
import { AccordionTitle, Container } from '../styles.js';
import Seasons from './index.jsx';



export function AccordeonSeasons() {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

    return(
      <Container>

      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}
      style={{borderRadius: "16px"}}>
      <AccordionSummary
      aria-controls="panel1d-content"
      id="panel1d-header"
      expandIcon={!expanded ? <MdAddCircle />: <MdRemoveCircle />}
      style={{borderBottom:"1px solid #dedede"}}>
      <AccordionTitle sx={{ width: '33%', flexShrink: 0 }}
      >
      <strong style={{paddingRight:"5px"}}>01</strong>
      Temporada
      </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
      <Seasons/>
      </AccordionDetails>
      </Accordion>


      {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
      <AccordionSummary aria-controls="panel2d-content" id="panel2d-header"
      expandIcon={!expanded ? <MdAddCircle />: <MdRemoveCircle />}>
      <AccordionTitle sx={{ width: '33%', flexShrink: 0 }}>
            <strong style={{paddingRight:"5px"}}>02</strong>
             Temporada
          </AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>

      </AccordionDetails>
      </Accordion> */}

    </Container>


    )
}

export default AccordeonSeasons;
