import gql from 'graphql-tag';

export const CREATE_POST = gql`
  mutation createPost($id: ID!, $text: String!) {
    createPost(TopicId: $id, text: $text) {
      id
    }
  }
`;

export const REMOVE_POST = gql`
  mutation removePost($id: ID!) {
    removePost(id: $id)
  }
`;

export const FIXED_POST = gql`
  mutation fixedPost($id: ID!, $fixed: Boolean!) {
    updatePost(id: $id, fixed: $fixed) {
      id
    }
  }
`;
