export function getReportTypes() {
  return ([{
    id: 1,
    value: 'Relatório de Estudantes',
    icon: 'school',
    path: 'studentsReport',
  },
  {
    id: 2,
    value: 'Relatório de Atividades',
    icon: 'assignment',
    path: 'evaluativeActivitiesReport',
  },
  {
    id: 3,
    value: 'Relatório de Trajetórias Percorridas',
    icon: 'call_split',
    path: 'trajectoryReport',
  }]);
}