import React, { useEffect, useState } from "react";
import Pagination from "../../components/pagination";
import { ColumnTitle, TableComponent, TableContainer, TableData } from "../../contents/ContentTable/styles";

const TablesTable = ({ refetchCounter, searchedString, setContentSelected, setShowModal, setShowDialog }) => {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const columnTitles = ['Nome', 'Criado em'];
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    const [currentContent, setCurrentContent] = useState();


    return (
        <>
            <TableContainer>
                <TableComponent>
                    <thead>
                        <ColumnTitle>
                            {columnTitles.map((title, index) => {
                                return <th key={index}>{title}</th>
                            })}
                        </ColumnTitle>
                    </thead>

                    <tbody>
                                 <TableData>
                                    <td>-</td>
                                    <td>-</td>

                                </TableData>
                    </tbody>
                </TableComponent>
            </TableContainer>
        <Pagination page={page} setPage={setPage} limit={limit} totalNumberOfPages={totalNumberOfPages} />
        </>
    )
}

export default TablesTable;
