import styled from 'styled-components';
import { Card } from '@material-ui/core';

const Container = styled(Card)`
  padding: 15px;
  background: #f6f6f6;
  border: 1px solid #dadada;
  margin: 10px 0 10px 0;

  .avatar {
    margin: 0px auto 4px auto;
  }

  .infoUser {
    text-align: center;
    width: 100px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
  }

  .infoPost {
    margin-left: 15px;
    display: inline-block;
    width: calc(100% - 160px);

    .text {
      text-align: justify;
      font-style: normal;
      font-size: 16px;
      color: #1c1c1c;
    }

    .date {
      font-size: 14px;
      color: #757575;
      margin-top: 5px;
    }
  }

  .menu {
    display: inline-block;
    vertical-align: top;
    margin-left: 6px;

    > * {
      cursor: pointer;
      margin: 6px;
      display: block;
    }

    .button.disable {
      cursor: initial;
      opacity: 0.6;
    }
  }
`;

export default Container;
