import gql from 'graphql-tag';

export const CREATE_OBJECTIVE_QUESTION = gql`
  mutation ($stem: String!, $exercise: ID, $questionBank: ID) {
    createObjectiveQuestion(
      stem: $stem,
      exercise: $exercise,
      questionBank: $questionBank,
    ) {
      id
    }
  }
`;

export const UPDATE_OBJECTIVE_QUESTION = gql`
  mutation ($id: ID!, $stem: String!) {
    updateObjectiveQuestion(
      id: $id,
      stem: $stem,
    ) {
      id
    }
  }
`;

export const REMOVE_OBJECTIVE_QUESTION = gql`
  mutation ($id: ID!) {
    removeObjectiveQuestion(id: $id)
  }
`;

export const CREATE_OBJECTIVE_QUESTION_ALTERNATIVE = gql`
  mutation ($objectiveQuestion: ID!, $text: String!, $isCorrect: Boolean!) {
    createObjectiveQuestionAlternative(
      objectiveQuestion: $objectiveQuestion,
      text: $text,
      isCorrect: $isCorrect,
    ) {
      id
    }
  }
`;

export const UPDATE_OBJECTIVE_QUESTION_ALTERNATIVE = gql`
  mutation ($id: ID!, $text: String!) {
    updateObjectiveQuestionAlternative(
      id: $id,
      text: $text,
    ) {
      id
    }
  }
`;

export const REMOVE_OBJECTIVE_QUESTION_ALTERNATIVE = gql`
  mutation ($id: ID!) {
    removeObjectiveQuestionAlternative(id: $id)
  }
`;

export const SET_CORRECT_OBJECTIVE_QUESTION_ALTERNATIVE = gql`
  mutation ($objectiveQuestion: ID!, $objectiveQuestionAlternative: ID!) {
    setCorrectObjectiveQuestionAlternative (
      objectiveQuestion: $objectiveQuestion,
      objectiveQuestionAlternative: $objectiveQuestionAlternative,
    )
  }
`;

export const CREATE_TRUEFALSE_QUESTION = gql`
  mutation ($stem: String!, $exercise: ID, $questionBank: ID) {
    createTrueFalseQuestion(
      stem: $stem,
      exercise: $exercise,
      questionBank: $questionBank,
    ) {
      id
    }
  }
`;

export const UPDATE_TRUEFALSE_QUESTION = gql`
  mutation ($id: ID!, $stem: String!) {
    updateTrueFalseQuestion(
      id: $id,
      stem: $stem,
    ) {
      id
    }
  }
`;

export const REMOVE_TRUEFALSE_QUESTION = gql`
  mutation ($id: ID!) {
    removeTrueFalseQuestion(id: $id)
  }
`;

export const CREATE_TRUEFALSE_QUESTION_ALTERNATIVE = gql`
  mutation ($trueFalseQuestion: ID!, $text: String!, $isCorrect: Boolean!) {
    createTrueFalseQuestionAlternative(
      trueFalseQuestion: $trueFalseQuestion,
      text: $text,
      isCorrect: $isCorrect,
    ) {
      id
    }
  }
`;

export const UPDATE_TRUEFALSE_QUESTION_ALTERNATIVE = gql`
  mutation ($id: ID!, $text: String!, $isCorrect: Boolean!) {
    updateTrueFalseQuestionAlternative(
      id: $id,
      text: $text,
      isCorrect: $isCorrect,
    ) {
      id
    }
  }
`;

export const REMOVE_TRUEFALSE_QUESTION_ALTERNATIVE = gql`
  mutation ($id: ID!) {
    removeTrueFalseQuestionAlternative(id: $id)
  }
`;

export const CREATE_SUBJECTIVE_QUESTION = gql`
  mutation ($stem: String!, $exercise: ID, $questionBank: ID) {
    createSubjectiveQuestion(
      stem: $stem,
      exercise: $exercise,
      questionBank: $questionBank,
    ) {
      id
    }
  }
`;

export const UPDATE_SUBJECTIVE_QUESTION = gql`
  mutation ($id: ID!, $stem: String!) {
    updateSubjectiveQuestion(
      id: $id,
      stem: $stem,
    ) {
      id
    }
  }
`;

export const REMOVE_SUBJECTIVE_QUESTION = gql`
  mutation ($id: ID!) {
    removeSubjectiveQuestion(id: $id)
  }
`;

export const CREATE_SUBJECTIVE_ANSWER = gql`
  mutation($exercisesQuestion: ID!, $subjectiveQuestion: ID!, $text: String!) {
    createSubjectiveAnswer (
      exercisesQuestion: $exercisesQuestion
      subjectiveQuestion: $subjectiveQuestion
      text: $text
    ) {
      id
      text
    }
  }
`;

export const CREATE_TRUEFALSE_ANSWER = gql`
  mutation($exercisesQuestion: ID!, $trueFalseQuestionAlternatives: [ID!], $values: [Boolean]) {
  createTrueFalseAnswer (
    exercisesQuestion: $exercisesQuestion
    trueFalseQuestionAlternatives: $trueFalseQuestionAlternatives
    values: $values
  )
      {
      id
      trueFalseQuestionAlternative{
        id
      }
      user{
        id
        fullName
      }
    }
  }
`;

export const CREATE_OBJECTIVE_ANSWER = gql`
  mutation($exercisesQuestion: ID!, $objectiveQuestionAlternative: ID!) {
    createObjectiveAnswer(
      exercisesQuestion: $exercisesQuestion
      objectiveQuestionAlternative: $objectiveQuestionAlternative
    ) {
      id
      alternative {
        id
        text
      }
      user {
        id
        fullName
      }
    }
  }
`;

export const EVALUATE_SUBJECTIVE_ANSWER = gql`
  mutation($id: ID!, $isCorrect: Float!) {
    evaluateSubjectiveAnswer(id: $id, isCorrect: $isCorrect) {
      id
    }
  }
`;

export const ADD_QUESTION_TO_EXERCISE = gql`
  mutation($type: QuestionTypes, $exercise: ID!, $question: ID!) {
    addQuestionToExercise(type: $type, question: $question, exercise: $exercise) {
      id
    }
  }
`;
