import { TrainRounded } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import { MdOutlineThumbUpOffAlt } from "react-icons/md";
import { LIKES_UNITS_BY_DATA } from "./dashboard"
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import {
  CircularProgress,
} from '@material-ui/core';
import { ColumnTitle, TableComponent, TableContainer, TableData } from "./styles";
import { ButtonGroup, ButtonHeader, ColumnLeft, ColumnRight, Content, ContentInfo, Header, Table, Td, TdTitle, Th, Title, Tr, TrList } from "./styles";



const BestEstimates = () => {

  const [activeData, setActiveData] = useState('day');


  const {data: dataLikesUnits, loading: loadingLikesUnits, refetch} = useQuery(LIKES_UNITS_BY_DATA, {
    onCompleted: (e) => {
      console.log(e)
    },
    fetchPolicy: 'no-cache',
  })


  const LessonsName = [
    "Episídio 1 - China Pro Games - Nome Grande Bem Grande",
    "Episídio 1 - Multiletramentos",
    "Episódio 1 - Robótica em Ação",
    "Episódio 4 - Introdução a Robótica",
    "Episódio 1 - Sementes da Educação"
  ]

  const LessonsNumber = [
    "500",
    "156",
    "148",
    "145",
    "126",
  ]


    return (
      <Content>
      <Header>
        <Title>Aulas com melhores avaliações</Title>

      </Header>
      <ContentInfo>


        <TableContainer>
        <TableComponent>
            <thead>
                <ColumnTitle>

                    <th></th>
                    <th></th>

                </ColumnTitle>
            </thead>

            <tbody>

        <TableData>
        {!loadingLikesUnits ? (
            <>
        <td> <ol>
        {dataLikesUnits?.getMostLikedUnits?.map((item)=>(
          <li>{item.name + ' - ' + item.module.course.name}</li>
        ))}
      </ol></td>
        <td>{dataLikesUnits?.getMostLikedUnits?.map((item)=>(
          <li style={{listStyleType:"none"}}
          >
            <MdOutlineThumbUpOffAlt style={{color: "#12805C", marginRight:"10px"}}/>
            {item.LikedUnitsUsers.length}</li>
        ))}</td>
        </>
        ):(
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
        )}

      </TableData>

            </tbody>
        </TableComponent>
       </TableContainer>


      </ContentInfo>
      </Content>
      )}
export default BestEstimates;
