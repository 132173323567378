import { useState } from 'react';

const useVisualization = () => {
  const [visualizationModalOpen, setVisualizationModalOpen] = useState(false);
  const [visualization, setVisualization] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isStudent, setIsStudent] = useState(false);

  return {
    visualizationModalOpen,
    setVisualizationModalOpen,
    visualization,
    setVisualization,
    isAdmin,
    setIsAdmin,
    isStudent,
    setIsStudent,
  };
};

export default useVisualization;
