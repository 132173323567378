import styled from 'styled-components';
import {
  Paper, Typography,
} from '@material-ui/core';

export const Main = styled.main`
  flex-grow: 1;
  margin: 1em auto;
  height: 100vh;
  justify-content: space-between;
  background-color: #080808;

  .container {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

export const TitlePaper = styled(Paper)`
    border-radius: 0;
    border-top: 1px solid;
    border-bottom: 1px solid;
    text-align: center;
    width: 100%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 1%;
    margin-bottom: 1%;
`;

export const DescriptionPaper = styled(Paper)`
    border-radius: 0;
    text-align: center;
    margin-top: 1%;
    margin-bottom: 1%;
    width: 100%;
    background-color: #E8E8E8 !important;
    height: 3em;
`;

export const TypographyReport = styled(Typography)`
  vertical-align: middle !important;
`;
