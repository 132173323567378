import gql from 'graphql-tag';

export const GET_TERMS = gql`
  query {
    getTermsConditions {
      id
      text
    }
  }
`;

export const UPDATE_TERMS = gql`
  mutation($text: String){
    updateTermsConditions(text: $text){
        id
        text
    }
  }
`