import React, { useEffect, useRef, useState } from "react";
import MoreVert from '@material-ui/icons/MoreVert';
import { CardContent, ColumnTitle, Container, TableComponent, TableContainer, TableData } from "./styles";
import Pagination from "../../components/pagination";
import { ReactComponent as Livre } from '../../../../assets/icons/faixa_livre.svg';
import { ReactComponent as DezAnos } from '../../../../assets/icons/faixa_10.svg';
import { ReactComponent as DozeAnos } from '../../../../assets/icons/faixa_12.svg';
import { ReactComponent as QuatorzeAnos } from '../../../../assets/icons/faixa_14.svg';
import { ReactComponent as DeseseisAnos } from '../../../../assets/icons/faixa_16.svg';
import { ReactComponent as DesoitoAnos } from '../../../../assets/icons/faixa_18.svg';
import GlobalContext from "../../../../context";
import { ButtonModal, ColumnRight, ContainerSearchBar, TitleSearch } from "../../components/searchbar/styles";
import SearchBar from "../../components/searchbar";
import {MdOutlineVideoCall, MdOutlineVisibility} from 'react-icons/md';
import * as Dialog from '@radix-ui/react-dialog'
import DialogContent from "../DialogAdd";
import { useQuery } from '@apollo/react-hooks';
import { GET_INSTITUTIONS } from "../../../../services/queries/institution";
import { GET_COURSES } from "../../../../services/queries/courses";
import { formatDateToUTC, formatDateToUTCWithBars } from "../../../../components/Helpers/formatDate";
import { Delete, Edit } from "@material-ui/icons";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditContent } from "../DialogEdit";
import Loading from "../../../../components/Loading/Loading";
import LoadingAdmin from "../../../../components/Loading/LoadingAdmin";

const ContentTable = ({enterFunction}) => {
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(5);
    const columnTitles = ['Curso',
    'Título do Curso ',
    'Visibilidade',
    // 'Categoria',
    //  'Direção',
     'Ano de Lançamento',
     'Data de Criação',
     'Restrições',
     "Ações"];
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    const [searchedString, setSearchedString] = useState('');
    const [modalMarginTop, setModalMarginTop] = useState();
    const [currentContents, setCurrentContents] = useState();
    const { loading, data, error, refetch} = useQuery(GET_COURSES, {
      variables: {
          name: searchedString,
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        setCurrentContents(data?.getCourses);
        console.log(data)
    }
    });

    const RenderList = () => {
      if (loading) {
        return (
          <LoadingAdmin/>
        );
      }
      return (
        <TableComponent>
        <thead>
            <ColumnTitle >
                {columnTitles.map((title, index) => {
                    return <th key={index}>{title}</th>
                })}
            </ColumnTitle>
        </thead>

        <tbody>
        {(data && currentContents) ?
        currentContents.map((course) => (
        <TableData  key={course.id }>
        <td>
        <CardContent folder={course.thumbnail}>
        {course.timeOfDedication ?? "-"}
         </CardContent></td>
        <td>{course.name}</td>
        {course.public ? (
          <td style={{display:"flex",alignItems:"center"}}>
          <MdOutlineVisibility size={16}
          style={{color:"#12805C"}}/>Público</td>
        ): (
          <td style={{display:"flex",alignItems:"center"}}>
          <MdOutlineVisibility size={16}
          style={{color:"#e62323"}}/>Privado</td>
        )}
        <td>{course.year ?? "-"}</td>
        <td>{formatDateToUTCWithBars(course.createdAt) ?? "-"}</td>
        {/* <td>Temas Transversais</td> */}
        {/* <td>-</td> */}
        {course.age == "L" || course.age == null ? <Livre style={{display: 'flex'}}/> : null}
        {course.age == "10" ? <DezAnos style={{display: 'flex'}}/> : null}
        {course.age == "12" ? <DozeAnos style={{display: 'flex'}}/> : null}
        {course.age == "14" ? <QuatorzeAnos style={{display: 'flex'}}/> : null}
        {course.age == "16" ? <DeseseisAnos style={{display: 'flex'}}/> : null}
        {course.age == "18" ? <DesoitoAnos style={{display: 'flex'}}/> : null}
        <td>
        <Dialog.Root>
        <Dialog.Trigger asChild>
        <Tooltip title="Editar">
        <IconButton>
        <Edit/>
        </IconButton>
        </Tooltip>
        </Dialog.Trigger>
        <EditContent marginTop={modalMarginTop} refetch={refetch} course={course}/>
        </Dialog.Root></td>
        </TableData>
      ))
      : null}

        </tbody>
      </TableComponent>
      )
    }

    const {
      user: { id, institution },
    selectedCourse: {
      getCoursesByInstitution,
      getCourses,
    },

    visualization: { visualization },
    } = GlobalContext();
    const isManager = visualization !== 'Estudante';

  const divRef = useRef(null);


  useEffect(() => {
    setModalMarginTop(divRef.current.clientHeight);
  }, []);

    useEffect(() => {
      getCoursesByInstitution.fetch({ variables: { id: institution.id } });
  }, []);

  useEffect(()=> {
    getCourses.fetch({variables: { name: searchedString }})
  }, [searchedString])

  const onChangeHandler = (e) => {
    setSearchedString(e.target.value);
}


    return (


        <Container>
              <ContainerSearchBar ref={divRef}>
              <TitleSearch>
              Cursos
              </TitleSearch>
              <ColumnRight>
              <SearchBar customPlaceholder='Buscar conteúdo'
                        onChangeHandler={onChangeHandler}
                        searchedString={searchedString}
                        setSearchedString={setSearchedString}/>
          <Dialog.Root>
          <Dialog.Trigger asChild>
          <ButtonModal>
                <MdOutlineVideoCall
                style={{color:"#FF4C00", paddingRight:"5px"}}
                size={25}/>
              Adicionar
              </ButtonModal>
             </Dialog.Trigger>
             <DialogContent refetch={refetch} marginTop={modalMarginTop}/>
            </Dialog.Root>
              </ColumnRight>
              </ContainerSearchBar>

            <TableContainer>
            {RenderList()}
            </TableContainer>
        <Pagination page={page} setPage={setPage} limit={limit} totalNumberOfPages={totalNumberOfPages} />
       </Container>
    )
}

export default ContentTable;
