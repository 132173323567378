import styled, { css } from "styled-components";

export const PaginationContainer = styled.section`
    width: 100%;
    display: flex;
    height: 40px;
    color: #000;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;
`
export const SubSection = styled.section`
    display: flex;
    font-size: 14px;
    color: #6D6D6D;
    p {
        margin-right: 10px;
    }
`

export const PageContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
`
    // ${props => props.activePage && css`
    //     background-color: #DCDDDF;
    //     color: #080808;
    //     font-weight: bold;
    // `}
export const Page = styled.div`
    padding: 5px 10px;
    margin-right: 2px;
    cursor: pointer;
    font-weight: normal;
    color: #6D6D6D;
    ${props => props.active && css`
        background: #DCDDDF;
        border-radius: 4px;
        font-weight: bold;
        color: #080808;
    `}
`

export const PageButton = styled.button`
    background-color: #fff;
    outline: none;
    margin-right: 2px;
    border: none;
    color: #080808;
    ${props => props.disabled && css`
    color: #6D6D6D;
`}
`

export const Ellipsis = styled.h3`
    margin: '8px';
    cursor: pointer;
`;
