import { LinkLogout, Logout, NavBar, Visualization, VisualizationButton } from "./styles";
import CientikIcon from '../../../../assets/images/LogoCientikBranca.svg'
import UserAvatar from "../../../../components/UserAvatar";
import GlobalContext from "../../../../context";
import React from "react";
import { oneOfType, arrayOf, node, shape, func } from "prop-types";
import { withRouter } from "react-router-dom";

const Header = ({ history }) => {
  const {
    user,
    user: { fullName, roles },
    visualization: {
      setVisualization,
      setVisualizationModalOpen,
      visualization,
      visualizationModalOpen,
    },
    dropdown: {
      open,
      setOpen,
      anchorRef,
    },
    drawer: { isOpen, toggle },
    signIn: { signOut }
  } = GlobalContext();

  React.useEffect(() => {
    let role;
    switch (visualization) {
      case "Estudante":
        role = "STUDENT";
        break;
      case "Professor":
        role = "TEACHER";
        break;
      default:
        break;
    };
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  const selectVisualization = (e) => {
    setVisualization(e.target.value);
    setVisualizationModalOpen(false);
  };

  return (
    <NavBar>

         <Visualization>
        <div onClick={() => history.push("/publishermain")}
        style={{cursor:"pointer"}}>
        <img
        src={CientikIcon}
        alt="logo"
        />
        </div>

            <VisualizationButton onClick={() => history.push("/")}>
            Visualizar como Usuário
            </VisualizationButton>

         </Visualization>

         <Logout>

          <div style={{marginRight:"16px"}}>
          <p><strong>
          {user.fullName}
          </strong></p>
          <LinkLogout disablePadding onClick={() => signOut()}
          style={{cursor:"pointer"}}>
          Sair
          </LinkLogout>
          </div>
          <UserAvatar/>
         </Logout>
    </NavBar>
)}

Header.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(Header);
