import styled from 'styled-components';

import { Container, Dialog } from '@material-ui/core';

export const SpeedDialStyled = styled(Container)`
  .speedDial {
    position: absolute;
    bottom: 24px;
    right: 16px;
    margin-right: 12px;
  }
`;

export const DialogStyled = styled(Dialog)`
  .MuiPaper-root {
    min-width: 320px;
  }

  .MuiDialogTitle-root {
    padding: 0 2px 0 24px;
  }

  .titleModal {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .MuiDialogContent-root {
    padding: 20px 24px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 1rem;
  }

  ul {
    padding-top: 20px;
    padding-left: 40px;
  }
`;
