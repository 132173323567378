
import React from 'react';
import { Link, SideBar, SideBarContainer } from './styles';
import { NavLink, withRouter } from "react-router-dom";
import { oneOfType, arrayOf, node, shape, func } from "prop-types";


const SideBarMenu = ({history}) => {

  return (
<>

    <SideBar>
          <NavLink  style={{textDecoration:"none"}}
           to="/info">
          <Link>Dados do usuário</Link>
          </NavLink>
          <NavLink  style={{textDecoration:"none"}}
          to="/results">
          <Link>Notas</Link>
          </NavLink>
          <NavLink  style={{textDecoration:"none"}}
          to="/likes">
          <Link>Favoritos</Link>
          </NavLink>
          <NavLink  style={{textDecoration:"none"}}
          to="/history">
          <Link>Histórico de visualizações</Link>
          </NavLink>
          <NavLink
          style={{textDecoration:"none"}}
           to="/certification">
          <Link>Cursos e certificados</Link>
          </NavLink>
    </SideBar>

</>
  );
  }

  SideBarMenu.propTypes = {
    history: shape({
      push: func,
    }).isRequired,
  };


export default withRouter(SideBarMenu);


