import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import React, { useState, useEffect, useContext } from 'react';
import {createCourse} from "./validation";
import { ReactComponent as Livre } from '../../../../assets/icons/faixa_livre.svg';
import { ReactComponent as DezAnos } from '../../../../assets/icons/faixa_10.svg';
import { ReactComponent as DozeAnos } from '../../../../assets/icons/faixa_12.svg';
import { ReactComponent as QuatorzeAnos } from '../../../../assets/icons/faixa_14.svg';
import { ReactComponent as DeseseisAnos } from '../../../../assets/icons/faixa_16.svg';
import { ReactComponent as DesoitoAnos } from '../../../../assets/icons/faixa_18.svg';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { StyledFormControlLabel } from './styles'
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText, FormControl, CircularProgress} from "@material-ui/core";
import { GET_CATEGORIES } from '../../../../services/queries/categories';
import { useForm, Controller } from 'react-hook-form';
import {MdOutlineUpload, MdAdd, MdOutlineDone} from 'react-icons/md'
import { UploadContext } from './index'
import Material from './Seasons/Material';
import { CategoryInputData, TextArea, DataTitle, FlexData, FlexDataInput, Input,
   InputContainer, Small, TextAreaContainer, TitleSeasons, TextAreaDescription, TextAreaContainerDescription, Count, Info,
   SubTitleSeasons, FlexImg, Card, Icon, Tam, TitleCard, PrevewImg, ContainerSeasons, FlexVideo, CardVideo } from './styles';

export  function IndexDialogForm({nextStep}){
  const [data, setData] = useContext(UploadContext);
  const [count, setCount] = useState(0);
  const [countContent, setCountContent] = useState(0);
  const [categories, setCategories] = useState([]);
  const [thumbnail, setThumbnail] = useState('')
  const [background, setBackground] = useState('')



  const { handleSubmit, register, control, watch, setValue, formState: { errors, isValid }  } = useForm({resolver: yupResolver(createCourse)});

  const { data: dataCategorias, loading: loadingCategories} = useQuery(GET_CATEGORIES, {
    onCompleted: (e) => {
      console.log("dataCategorias", dataCategorias)
    }
  })

  console.log("dataCategorias", dataCategorias)

  const handleName = (e) => {
    e.preventDefault();
     setCount(e.target.value.length);
   };
   const handleContent = (e) => {
    setCountContent(e.target.value.length);
   };

   const handleSubmitForm = ({name, tags, year, startDate, endDate, description, age})=> {
    console.log({name, tags, year, startDate, endDate, description, age})
    console.log(countContent)
    setData({ ...data, name, tags, year, startDate, endDate, description, age})
    nextStep()
   }

   const delThumb =()=>{
    setValue('thumbnail', null)
    setData({...data, thumbnailFile: null, thumbnail: null})
  }

  const delFolder =()=>{
    setValue('folder', null)
    setData({...data, folderFile: null, folder: null})
  }

  const delTrailer =()=>{
    setValue('trailer', null)
    setData({...data, trailerFile: null, trailer: null})
  }

  const delMaterial = name => {
    setValue('material', null)

    const newMaterial = [...data].material.filter(material => material.name !== name)
    setData({...data, material: newMaterial})
  }
  
   const delBackground =()=>{setBackground('')}

   const handleCategory = (data, onChange)=>{
    let selectedCategories = [];
    const category = categories.find(e => e.id === data.id)
    if(category) {
      selectedCategories = [...categories.filter((item) => item.id !== data.id)]
    } else {
      selectedCategories = [...categories, data]
    }

    onChange(selectedCategories)
    setCategories(selectedCategories)
    console.log(selectedCategories)
  }


  useEffect(()=>{
    console.log("data", data)
  },[])

  return(
    <>
        <TitleSeasons>
        Dados gerais do curso
        </TitleSeasons>
        <Info>
        Salve as informações antes de prosseguir para temporadas.
        </Info>
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <form onSubmit={handleSubmit(handleSubmitForm)} >
        <ContainerSeasons>
            <SubTitleSeasons>
        Imagens do conteúdo
        </SubTitleSeasons>

          <FlexImg>
            <Card style={{marginRight: 20}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Thumbnail
              </TitleCard>
              <Tam>
              1080 x 1920 px
              </Tam>

              <Controller
                name="thumbnail"
                control={control}
                defaultValue={data?.thumbnail}
                render={props => (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="thumbnail"
                    name="thumbnail"
                    // onChange={(e) => setThumbnail(e.target.files[0])}
                    onChange={(e) => {setData({...data, thumbnail: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                  />
                )}
              />
               <FormHelperText error={Boolean(errors?.thumbnail)}>
            {errors?.thumbnail?.message}
            </FormHelperText>

          <label htmlFor="thumbnail"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar a thumbnail do curso
          </label>
              <Info>
              Formato: JPG ou PNG
              </Info>
              {data?.thumbnail ?
              <>
              <PrevewImg src={URL.createObjectURL(data?.thumbnail)}/>
              <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delThumb}><u>remover</u></button>
              </>
              :null }
            </Card>

            <Card>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Cartaz
              </TitleCard>
              <Tam>
              1920 x 1080 px
              </Tam>
              <Controller
                name="folder"
                control={control}
                defaultValue={data?.folder}
                render={props => (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="folder"
                    name="folder"
                    onChange={(e) => {setData({...data, folder: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                  )}
                />
                   <FormHelperText error={Boolean(errors?.thumbnail)}>
            {errors?.thumbnail?.message}
            </FormHelperText>
          <label htmlFor="folder"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o cartaz do curso
          </label>
          <Info>
            Formato: JPG ou PNG
              </Info>
          {data?.folder ? <>
          <PrevewImg src={URL.createObjectURL(data?.folder)}/>
          <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delFolder}><u>remover</u></button>
          </>:null }
            </Card>
          </FlexImg>
          </ContainerSeasons>
          
          <label>
            Título do conteúdo <Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainer>
          <Controller
            name="name"
            control={control}
            defaultValue={data?.name}
            render={props => (
             <TextArea
                type="text"
                defaultValue={data?.name}
                maxLength="100"
                placeholder='Insira aqui o título do conteúdo'
                name="name"
                onChange={(e)=>props.field.onChange(e.target.value)}
              />
            )}
          />
            <Count>
              <span>{count}</span>/100
            </Count>
            <FormHelperText error={Boolean(errors?.name)}>
              {errors?.name?.message}
            </FormHelperText>
            </TextAreaContainer>



          <label>
          Seção | Categoria <Small>(Obrigatório)</Small>
          </label>
          {!loadingCategories? (
           <Controller
              name="tags"
              control={control}
              defaultValue={data?.tags}
              render={props => (
              <FormControl  name="tags">
                {dataCategorias?.getCategories?.map(e => (
                  <FormControlLabel key={e.id} control={<Checkbox />} checked={data?.tags?.find(el => el.id === e.id)} onChange={(_,data) => { handleCategory(e, props.field.onChange)}} label={e.name} />
                ))}
              </FormControl>
               )}
             />
          ):(
            <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
          )}
        
            <FormHelperText error={Boolean(errors?.tags)}>
              {errors?.tags?.message}
            </FormHelperText>


            <label>Faixa etária
          <Small>(Obrigatório)</Small></label>

          <Controller
                name="age"
                control={control}
                defaultValue={data?.age}
                render={props => (

                <FormControl style={{alignSelf:"space-between"}}>
                    <RadioGroup
                         row
                         aria-labelledby="demo-row-radio-buttons-group-label"
                         defaultValue={data?.age}
                         onChange={(_,data) => props.field.onChange(data)}
                    >
                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="L" control={<Radio />} label=""/>
                            <Livre style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="10" control={<Radio />} label=""/>
                            <DezAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="12" control={<Radio />} label=""/>
                            <DozeAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="14" control={<Radio />} label=""/>
                            <QuatorzeAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="16" control={<Radio />} label=""/>
                            <DeseseisAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="18" control={<Radio />} label=""/>
                            <DesoitoAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>
                    </RadioGroup>
                </FormControl>
                 )}
                 />

            <FormHelperText error={Boolean(errors?.age)}>
              {errors?.age?.message}
            </FormHelperText>



          <label>
            Ano de lançamento <Small>(Obrigatório)</Small>
          </label>
          <Controller
            name="year"
            control={control}
            defaultValue={data?.year}
            render={props => (
              <Input type="number" defaultValue={data?.year} onChange={(e)=>props.field.onChange(parseInt(e.target.value))} name="year" placeholder='Ano de lançamento. Ex.: 2020'/>
           )}
           />
            <FormHelperText error={Boolean(errors?.year)}>
              {errors?.year?.message}
            </FormHelperText>
          <label>
            Período de destaque <Small>(Obrigatório)</Small>
          </label>
          <FlexData>
              <FlexDataInput>
                <DataTitle>De</DataTitle>
                <InputContainer>
                <Controller
                    name="startDate"
                    defaultValue={data?.startDate?.toISOString().substring(0,10)}
                    control={control}
                    render={props => (
                      <CategoryInputData
                            type="date"
                            name="startDate"
                            defaultValue={data?.startDate?.toISOString().substring(0,10)}
                            onChange={(e)=>props.field.onChange(e.target.value)}
                          />
                  )}
                  />

                </InputContainer>
              </FlexDataInput>

              <FlexDataInput>
                <DataTitle>Até</DataTitle>
                <InputContainer>
                   <Controller
                    name="endDate"
                    control={control}
                    defaultValue={data?.endDate?.toISOString().substring(0,10)}
                    render={props => (
                      <CategoryInputData
                            type="date"
                            name="endDate"
                            defaultValue={data?.endDate?.toISOString().substring(0,10)}
                            onChange={(e)=>props.field.onChange(e.target.value)}
                          />
                  )}
                  />
                </InputContainer>
              </FlexDataInput>
            </FlexData>
            <FormHelperText error={Boolean(errors?.startDate || errors?.endDate)}>
              {errors?.startDate ? errors?.startDate.message : errors?.endDate?.message}
            </FormHelperText>

          <label>
            Descrição<Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainerDescription>
               <Controller
                    name="description"
                    control={control}
                    defaultValue={data?.description}
                    render={props => (
                      <TextAreaDescription
                        type="text"
                        maxLength="500"
                        defaultValue={data?.description}
                        placeholder='Fale sobre o conteúdo'
                        name="description"
                        onChange={(e)=>props.field.onChange(e.target.value)}
                      />
                  )}
                  />
              <Count>
                <small>{countContent}</small>/500
              </Count>
              <FormHelperText error={Boolean(errors?.description)}>
              {errors?.description?.message}
            </FormHelperText>
            </TextAreaContainerDescription>

            <div>
        <SubTitleSeasons>
        Trailers 
        </SubTitleSeasons>
        <FlexVideo>
            <CardVideo style={{maginRight:"60px"}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Trailer
              </TitleCard>
              <Controller
                name="trailer"
                control={control}
                defaultValue={data?.trailer}
                render={props => (
                  <input
                    type="file"
                    multiple
                    style={{ display: 'none'}}
                    name="trailer"
                    id="trailer"
                    accept=".mp4, .webm, .ogg"
                    onChange={(e)=>{setData({...data, trailer: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                )}
                />
                 <FormHelperText error={Boolean(errors?.trailer)}>
            {errors?.trailer?.message}
            </FormHelperText>
          <label htmlFor="trailer"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o trailer da temporada
          </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {data?.trailer ? <>
              <video width="730" controls>
              <source src={URL.createObjectURL(data?.trailer)} />
              </video>
              <button style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delTrailer}><u>remover</u></button>
          </>:null }
            </CardVideo>
{/* 
            <CardExtras style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
              Adicionar Extras
              </TitleCard>
              <input
            {...getInputProps()}/>

            <label htmlFor="extras"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u {...getRootProps({className: 'dropzone'})}>Clique aqui</u> para adicionar extras da temporada
            </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {files ?
              <div>
              {files.map(file => {
                return(
                <div key={file.name} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                 <video width="730" controls>
                 <source src={file.preview} />
                 </video>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
                 onClick={() =>handleRemoveFile(file.name)}><u>remover</u></button>
                </div>
                )}
              )}
              </div>
              :null }
            </CardExtras> */}
          </FlexVideo>
        </div>
      

      <Material errors={errors} control={control}/>





          <button type='submit'>
            Salvar Informações
          </button>
        </form>
        </div>

    </>
  )

}
export default IndexDialogForm;
