import React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import {
  CardFlexEpisodes,
  GridVideo,
  TitleEpisode,
  DescriptionEpisode,
  Watch,
  ButtonGroup,
  TimeEpisode,
  ProgressVideo,
  ProgressBar,
  ButtonPlay,
  Card,
} from "./styles";
import { string, number, func, shape, arrayOf, object } from "prop-types";

const CardEpisodes = ({
  course,
  checkFunction,
  editFunction,
  enterFunction,
}) => {
  const {
    name,
    teachers,
    description,
    price,
    generalInfo,
    folder,
    percent,
    duration,
    institution,
  } = course;

  return (
    <CardFlexEpisodes>
      <Card>
      <GridVideo
        style={enterFunction ? { cursor: "pointer" } : null}
        folder={folder ?? "https://nova-escola-producao.s3.amazonaws.com/hcaW9mPfJsha8h6ThnREm5b87jSCrjJNg3FWKTqJWNxZVEgVUUNAbH7HEThH/ser-professor-professora-auxiliando-aluna-em-sala-getty-images.jpg"}
        onClick={enterFunction ? () => enterFunction(course) : null}
      >
        <ButtonPlay>
          <PlayCircleOutlineIcon
            style={{ color: "#f4f4f4", fontSize: "40px" }}
          />
        </ButtonPlay>
        {course.unitUserPlayHistoric && course.duration && (
          <ProgressBar>
            <ProgressVideo
              value={course.unitUserPlayHistoric && ((course.unitUserPlayHistoric.ElapsedTime / (course.duration)) * 100) }
            />
          </ProgressBar>
        )}


      </GridVideo>
      <TitleEpisode style={{color:"#ffffff"}}>{name}</TitleEpisode>

      <DescriptionEpisode>
        {description}
      </DescriptionEpisode>
      <ButtonGroup>
        <Watch style={{ color: "#e84810" }}>
          <PlayArrowOutlinedIcon style={{ paddingRight: "5px" }} />
          <strong
            style={enterFunction ? { cursor: "pointer" } : null}
            onClick={enterFunction ? () => enterFunction(course) : null}
          >
            Assistir Episódio
          </strong>
        </Watch>
        {duration ?
        <TimeEpisode style={{ color: "#f4f4f4" }}>
          <AccessTimeIcon />
          {parseInt(duration/60)} m
        </TimeEpisode>
        : null}
      </ButtonGroup>

      </Card>
    </CardFlexEpisodes>


  );
};

CardEpisodes.propTypes = {
  course: shape({
    id: string.isRequired,
    name: string.isRequired,
    description: string,
    image: string,
    price: number,
    timeOfDedication: number,
    percent: number,
    teachers: arrayOf(object),
  }).isRequired,
  checkFunction: func,
  editFunction: func,
  enterFunction: func,
};

CardEpisodes.defaultProps = {
  checkFunction: null,
  editFunction: null,
  enterFunction: null,
};

export default CardEpisodes;
