import gql from 'graphql-tag';

export const CREATE_MODULE = gql`
  mutation createModule(
  $id: ID!,
  $name: String!,
  $displayName: String,
  $startDate: Date,
  $endDate: Date,
  $nameUnit: [String],
  $durationUnit: [Int],
  $videos: [String],
  $subtitles: [[String]],
  $idiomSubtitles: [[String]],
  $descriptionUnit: [String]) {
    createModule(id: $id,
    name: $name,
    displayName: $displayName,
    startDate: $startDate,
    endDate: $endDate,
    nameUnit: $nameUnit,
    durationUnit: $durationUnit,
    videos: $videos,
    subtitles: $subtitles, 
    idiomSubtitles: $idiomSubtitles, 
    descriptionUnit: $descriptionUnit) {
      id
      name
    }
  }
`;

export const UPDATE_MODULE = gql`
  mutation updateModule($id: ID!,
  $name: String,
  $displayName: String,
  $startDate: Date,
  $endDate: Date,
  $nameUnit: [String],
  $durationUnit: [Int],
  $videos: [String]), {
    updateModule(id: $id,
    name: $name,
    displayName: $displayName,
    startDate: $startDate,
    endDate: $endDate,
    nameUnit: $nameUnit,
    durationUnit: $durationUnit,
    videos: $videos) {
      id
    }
  }
`;

export const REMOVE_MODULE = gql`
  mutation removeModule($id: ID!) {
    removeModule(id: $id)
  }
`;
