import {
  Dialog,
  DialogTitle,
  Grid,
  DialogActions,
  Button,
} from '@material-ui/core';
import React from 'react';
import GlobalContext from '../../../context';

const ModalDelete = () => {
  const {
    selectedCourse: {
      exercises: {
        getExercises,
        deleteDialogOpen,
        setDeleteDialogOpen,
        removedExercise,
        setRemovedExercise,
        removeExercise,
      },
    },
  } = GlobalContext();

  const cleanAndClose = () => {
    setRemovedExercise();
    setDeleteDialogOpen(false);
  };

  const submit = async () => {
    await removeExercise({
      variables: { id: removedExercise.id },
    });
    getExercises.refetch();
    cleanAndClose();
  };

  return removedExercise ? (
    <Dialog
      open={deleteDialogOpen}
      onClose={() => cleanAndClose()}
      maxWidth="sm"
    >
      <DialogTitle>
        {`Desejeja excluir a atividade ${removedExercise.title}?`}
      </DialogTitle>
      <DialogActions>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => setDeleteDialogOpen(false)}
            >
            Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => submit()}
            >
            Excluir
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default ModalDelete;
