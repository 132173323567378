import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { CREATE_ALGORITHM_EXERCISE } from '../../../services/mutations/exercise';

const useNewAlgorithmTestDialog = () => {
  const [quantity, setQuantity] = useState(3);
  const [createAlgorithmTest] = useMutation(CREATE_ALGORITHM_EXERCISE);
  const [selectedModule, setSelectedModule] = useState('');
  const [title, setTitle] = useState('');

  return {
    quantity,
    setQuantity,
    createAlgorithmTest,
    selectedModule,
    setSelectedModule,
    title,
    setTitle,
  };
};

export default useNewAlgorithmTestDialog;
