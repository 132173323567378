import React from 'react';

import { func } from 'prop-types';

import {
  BrowserRouter, Switch, Route, Redirect, withRouter,
} from 'react-router-dom';

import Overview from './pages/overview';
import Institutions from './pages/institutions';
import Users from './pages/users';
import Tags from './pages/tags';
import Course from './pages/course';
import ManageCourse from './pages/manageCourse';
import Lesson from './pages/lesson';
import Topic from './pages/topic';
import SignIn from './pages/authentication/signin';
import SignUp from './pages/authentication/signup';
// Reports
import Reports from './pages/reports';
import StudentsReport from './pages/reports/studentsReport';
import EvaluativeActivitiesReport from './pages/reports/evaluativeActivitiesReport';
import TrajectoryReport from './pages/reports/trajectoryReport';

import { isAuthenticated } from './services/auth';
import GlobalContext from './context';
import Exercise from './pages/course/ExercisesList/Exercise';
import MyListStudent from './pages/MyListStudent';
import Privacidade from './pages/Privacidade';
import ComplementaryMaterial from './pages/course/ComplementaryMaterial.jsx';
import SearchPage from './pages/overview/Search/Search';
import Loading from './components/Loading/Loading';
import Profile from './pages/profile';
import ProfileData from './pages/profile/ProfileData/ProfileData';
import HistoryVideos from './pages/profile/History';
import LikesVideos from './pages/profile/Likes';
import Certification from './pages/profile/Certification';
import PublisherHome from './pages/publisher';
import PublisherContents from './pages/publisher/contents';
import PublisherCategories from './pages/publisher/categories';
import PublisherTables from './pages/publisher/tables';
import PublisherPlans from './pages/publisher/plans';
import PublisherNotifications from './pages/publisher/notifications';
import PublisherMembers from './pages/publisher/members';
import PublisherDashboard from './pages/publisher/dashboard';
import { IndexDialogForm } from './pages/publisher/contents/DialogAdd/IndexDialogForm';
import Seasons from './pages/publisher/contents/DialogAdd/Seasons';
import PublisherCertificates from './pages/publisher/certificates';
import PublisherPrivacityTerms from './pages/publisher/privacity-terms';
import PublisherUseTerms from './pages/publisher/use-terms';
import ExercisesContents from './pages/course/ExercisesMain';
import TableResults from './pages/profile/TestResults';
import ExerciseBank from './pages/course/QuestionBank';
import { CourseForum } from './pages/course/Forum';
import ForumAnswer from './pages/course/Forum/ForumAnswer';
import Trailer from './pages/lesson/Trailer';
import Terms from './pages/terms/index'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { signIn: { token } } = GlobalContext();
  return (
    <Route
      {...rest}
      render={() => (token ? <Component /> : <Redirect to={{ pathname: '/login' }} />)}
    />
  );
};

PrivateRoute.propTypes = {
  component: func.isRequired,
};

const Routes = () => {
  const context = GlobalContext();

  if (context.signIn.token && !context.user) {
    return <Loading/>;
  }

  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/login"
          render={() => (isAuthenticated() ? <Redirect to="/" /> : <SignIn />)}
        />
        <Route path="/cadastro" component={SignUp} />
        <PrivateRoute path="/" exact component={Overview} />
        <PrivateRoute path="/publishermain" component={PublisherHome} />
        <PrivateRoute path="/publishermain/contents" component={PublisherContents} />
        <PrivateRoute path="/publishermain/contents/details" component={IndexDialogForm} />
        <PrivateRoute path="/publishermain/contents/seasons" component={Seasons} />
        <PrivateRoute path="/publishermain/categories" component={PublisherCategories} />
        <PrivateRoute path="/publishermain/tables" component={PublisherTables} />
        <PrivateRoute path="/publishermain/plans" component={PublisherPlans} />
        <PrivateRoute path="/publishermain/notifications" component={PublisherNotifications} />
        <PrivateRoute path="/publishermain/members" component={PublisherMembers} />
        <PrivateRoute path="/publishermain/dashboard" component={PublisherDashboard} />
        <PrivateRoute path="/publishermain/use-terms" component={PublisherUseTerms} />
        <PrivateRoute path="/publishermain/privacy-terms" component={PublisherPrivacityTerms} />
        <PrivateRoute path="/institutions" component={Institutions} />
        <PrivateRoute path="/complementaryMaterial" component={ComplementaryMaterial} />
        <PrivateRoute path="/course/exercises" component={ExercisesContents} />
        <PrivateRoute path="/course/foruns" component={CourseForum} />
        <PrivateRoute path="/course/forum/Answer" component={ForumAnswer}/>
        <PrivateRoute path="/usuarios" component={Users} />
        <PrivateRoute path="/tags" component={Tags} />
        <PrivateRoute path="/course" component={Course} />
        <PrivateRoute path="/mylist" component={MyListStudent} />
        <PrivateRoute path="/termos-de-uso" component={Terms} />
        <PrivateRoute path="/privacidade" component={Privacidade} />
        <PrivateRoute path="/manageCourse" component={ManageCourse} />
        <PrivateRoute path="/lesson" component={Lesson} />
        <PrivateRoute path="/trailer" component={Trailer} />
        <PrivateRoute path="/exercise" component={Exercise} />
        <PrivateRoute path="/questionbank" component={ExerciseBank} />
        <PrivateRoute path="/topic" component={Topic} />
        <PrivateRoute path="/reports" component={Reports} />
        <PrivateRoute path="/profile" component={Profile} />
        <PrivateRoute path="/info" component={ProfileData} />
        <PrivateRoute path="/history" component={HistoryVideos} />
        <PrivateRoute path="/likes" component={LikesVideos} />
        <PrivateRoute path="/results" component={TableResults} />
        <PrivateRoute path="/certification" component={Certification} />
        <PrivateRoute path="/studentsReport" component={StudentsReport} />
        <PrivateRoute path="/evaluativeActivitiesReport" component={EvaluativeActivitiesReport} />
        <PrivateRoute path="/trajectoryReport" component={TrajectoryReport} />
        <PrivateRoute path="/searchpage" component={SearchPage} />
        <Route render={() => <h1>Página não encontrada! :(</h1>} />
      </Switch>
    </BrowserRouter>
  );
};

export default withRouter(Routes);
