import Headers from "./components/header";
import { StylePublisher } from "./styles";
import React from "react";
import SideBar from "./components/sidebar";

const PublisherHome = () => {

  return (
          <StylePublisher>
            <Headers/>
            <SideBar/>
          </StylePublisher>
)}
export default PublisherHome;
