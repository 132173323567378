import React from 'react';
import {
  func, shape,
} from 'prop-types';
import {
  Divider, List, ListItem, ListItemIcon, ListItemText,
} from '@material-ui/core';
import {
  AccountBalance, ExitToApp, Group, Style, PictureAsPdf,
} from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import UserAvatar from '../UserAvatar';
import { StyledDrawer } from './styles';
import GlobalContext from '../../context';

const Drawer = ({ history }) => {
  const {
    user,
    drawer: { isOpen, toggle },
    signIn: { signOut },
    visualization: { visualization },
  } = GlobalContext();
  const handleToggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggle();
  };
  const items = [
    {
      name: 'INSTITUIÇÕES',
      onclick: () => history.push('/institutions'),
      icon: <AccountBalance />,
      hidden: visualization !== 'Administrador',
    },
    {
      name: 'USUÁRIOS',
      onclick: () => history.push('/usuarios'),
      icon: <Group />,
      hidden: visualization !== 'Administrador',
    },
    {
      name: 'TAGS',
      onclick: () => history.push('/tags'),
      icon: <Style />,
    },
    {
      name: 'RELATÓRIOS',
      onclick: () => history.push('/reports'),
      icon: <PictureAsPdf />,
    },
    {
      name: 'SAIR',
      onclick: () => signOut(),
      icon: <ExitToApp />,
    },
  ];

  return (
    <div>
      <StyledDrawer className="drawer" open={isOpen} onClose={handleToggleDrawer}>
        <div role="presentation" onClick={handleToggleDrawer} onKeyDown={handleToggleDrawer}>
          <List>
            <ListItem>
              <UserAvatar size={50} user={user} />
              <div className="info-profile">
                <span className="name">{user.fullName}</span>
                <span className="page">{visualization}</span>
              </div>
            </ListItem>
          </List>
          <Divider />
          <List>
            {items.map(item => !item.hidden && (
              <ListItem
                button
                key={item.name}
                onClick={item.onclick}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
        </div>
      </StyledDrawer>
    </div>
  );
};

Drawer.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(Drawer);
