import { TrainRounded } from "@material-ui/icons";
import React, { useState } from "react";
import {
  CircularProgress,
} from '@material-ui/core';
import { ColumnTitle, ContainerVideos, ContentInfoVideos, HeaderIndex, HeaderVideos, NumberIndex, TableComponent, TableContainer, TableData, TitleIndex, Total } from "./styles";
import { ButtonGroup, ButtonHeader, ColumnLeft, ColumnRight, Content, ContentInfo, Header, Table, Td, TdTitle, Th, Title, Tr, TrList } from "./styles";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";

import gql from 'graphql-tag';

export const GET_CATEGORIES = gql`
  query {
    getCategories {
      id
      name       
    }
  }
`;

export const GET_COURSES_CATEGORIES = gql`
query {
  getTotalCoursesCategories{
      id
      name
      courses{
          id
          name
          unitQuantity
          year
      }
  }
 }
`;




const VideosByCategories = () => {
  const [totalUnits, setTotalUnits] = useState(0)

  const sumUnitsCategories = (category) => {
    return category.courses.reduce((a, b) => a + b.unitQuantity, 0)
  }

  const { data: dataCategorias, loading } = useQuery(GET_COURSES_CATEGORIES, {
    onCompleted: (e) => {
      console.log(e)
      setTotalUnits(e.getTotalCoursesCategories.map((category) => sumUnitsCategories(category)).reduce((a,b)=> a+b, 0))

    }
  })


  const CourseName = [
    "Temas Transversais",
    "Cursos Tecnológicos",
    "Capacitação de Professores",
  ]

  const CourseNumber = [
    "500",
    "556",
    "748",
  ]


    return (
      <ContainerVideos>
      <HeaderVideos>
              <TitleIndex>
              Total de vídeos por categoria
              </TitleIndex>
              <div style={{display:"flex", alignItems:"center"}}>
              <Total style={{marginRight:"10px"}}>
              Total
              </Total>
              <NumberIndex>
              {totalUnits}
              </NumberIndex>
            </div>
          </HeaderVideos>
      <ContentInfoVideos>
        <TableContainer>
        <TableComponent>
            <thead>
                <ColumnTitle>

                    <th></th>
                    <th></th>

                </ColumnTitle>
            </thead>

            <tbody>

        <TableData>
        {!loading ? (
            <>
        <td> <ol>
        {dataCategorias?.getTotalCoursesCategories?.map((item)=>(
          <li>{item.name}</li>
        ))}
      </ol>
      </td>
        <td>{dataCategorias?.getTotalCoursesCategories?.map((item)=>(
          <li style={{listStyleType:"none"}}
          >{sumUnitsCategories(item)}</li>
        ))}</td>
        </>) :(
          <div style={{justifyContent: 'center', width: '100%', display: 'flex'}}>
            <CircularProgress size={24} color="inherit" />
          </div>
        )}

      </TableData>

            </tbody>
        </TableComponent>
       </TableContainer>

      </ContentInfoVideos>
      </ContainerVideos>
      )}

export default VideosByCategories;
