import styled from "styled-components";

export const DesktopMenu = styled.div`
@media(max-width: 600px) {
  display: none;
   }
`;

export const MobileMenu = styled.div`
display:none;
@media(max-width: 600px) {
  display: block;

   }
`;

export const MobileColumn = styled.div`
@media(max-width: 600px) {
  display:flex;
  flex-direction:column;
  top:0;
  left:0;
};

`

export const User = styled.div`
border-left:2px solid #3D3D3D;
@media(max-width: 600px) {
  border-left:none;
   }`

export const SpanName = styled.span`
@media(max-width: 600px) {
  display:none;
   }`

export const IconesMenu = styled.span`
display:none;
justify-content: space-between;
align-items: center;
@media(max-width: 600px) {
  display:flex;
   }
   `

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: hidden;
  height: 80px;
  overflow: hidden;
  background: #141414;
  @media(max-width: 1300px) {
    height: 140px;
    flex-direction:column;
    width:100vw;
    align-items:center;
  };
  @media(max-width: 600px) {
    display: flex;
    flex-direction:row;
    width: 100vw;
    justify-content: space-between;
    overflow-x: hidden;
    background: transparent;
    opacity: 0.8;
    height: 70px;
    position: absolute;
    top:0;
    @media(max-width: 500px) {
      height: 180px;
      top:-50px;
      position: absolute;
    };
  .appBar {
    background: #141414;
    color: #dcdddf;

    .title {
      flex-grow: 1;
      align-items: center;

      img {
        vertical-align: middle;
        cursor: pointer;
      }
    }

    .userInfo {
      display: flex;
      align-items: center;
      flex-direction: row;

      .name {
        font-size: 14px;
        line-height: 14px;
        margin-right: 8px;
      }
    }
  }

  .content {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    background-color: #080808;
    color: #dcdddf;
  }

  .appBarSpacer {
    min-height: 64px;
  }
`;

export const ContainerMenu = styled.div`
  display: flex;
  width: 100vw;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
  height: 80px;
  padding: 10px;
  background: linear-gradient(179.43deg, #080808 0.49%, rgba(8, 8, 8, 0) 99.51%);
  opacity: 0.8;
  height: 140px;
  position: absolute;
  top:0;
  @media(max-width: 500px) {
    height: 240px;
  };
  .appBar {
    background: #141414;
    color: #dcdddf;

    .title {
      flex-grow: 1;
      align-items: center;

      img {
        vertical-align: middle;
        cursor: pointer;
      }
    }

    .userInfo {
      display: flex;
      align-items: center;
      flex-direction: row;

      .name {
        font-size: 14px;
        line-height: 14px;
        margin-right: 8px;
      }
    }
  }

  .content {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    background-color: #080808;
    color: #dcdddf;
  }

  .appBarSpacer {
    min-height: 64px;
  }
`;

export const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration:none;
  color: #ffffff;
  a{
    &:hover {
      color: #ff4c00;
      cursor: pointer;
    }
  }
  @media(max-width: 600px) {
    display:none
     }
     `;

     export const NavLinksMobile = styled.div`
     display: none;
     justify-content: space-between;
     align-items: center;
     text-decoration:none;
     color: #ffffff;
     overflow-x: hidden;
     padding: 10px;
     background: transparent;
     opacity: 0.8;
     height: 10px;
     width:100vw;
     position: absolute;
     margin-top:80px;
     top:0;
     a{
       &:hover {
         color: #ff4c00;
         cursor: pointer;
       }
     }
     @media(max-width: 600px) {
       display:flex;
        }
        `;

export const NavSocial = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 20px;
  align-items: center;
  @media(max-width: 600px) {
    display:none;
     }
`;




export const SearchbarContainer = styled.div`
  background-color: #312F30;
  color: #DCDDDF;
  border: #312F30;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  margin: 0 10px;
  width: 115px;

  @media(max-width: 600px) {
    border-radius: 12px;
    padding: 0px 0px;
     },
`;

export const Input = styled.input`
  background-color: transparent;
  outline: none;
  color: #EDEDED
  border: none;
  margin-left: 15px;
  font-size: 0.9rem;
  width: 115px;
  @media(max-width: 600px) {
    background-color:transparent;
    margin-left: 0px;
    width: 120px;
    }
     `

export const IconDesktop = styled.span`
@media(max-width: 600px) {
  display:none;
   }`

export const IconMobile = styled.span`
display:none;
@media(max-width: 600px) {
  display:block;
  }`

  export const MobileMenuu = styled.span`
display:none;
@media(max-width: 920px) {
  display:block;
  }`

  export const MenuListDesktop = styled.span`
@media(max-width: 920px) {
  display:none;
   }`
export const ButtonAdmin = styled.button`
background-color: #312F30;
border:none;
color: #DCDDDF;
padding: 10px 15px;
display: flex;
align-items: center;
border-radius: 12px;
margin: 0 10px;
display: flex;
font-weight: 700;
color: #5C93FF;
cursor: pointer;
justify-content: space-around;
@media (max-width: 768px) {
 display:none;
}
`
