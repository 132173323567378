import styled from 'styled-components';
export const TableContainer = styled.table`
width: 808px;
margin-right:60px;
border:none;
border-collapse:collapse;
margin-bottom:120px;
@media(max-width: 920px) {
  width: 90vw;
  overflow:scroll;
  margin-right:0px;
}
`

export const Tr = styled.tr`
height: 68px;
background: rgba(255, 183, 153, 0.2);
boder:none;
`

export const Th = styled.th`
font-size: 14px;
color: #FFFFFF;
text-align:start;
padding:23px;
boder:none;
@media(max-width: 720px) {
  padding:7px;
}
`
export const TrList = styled.tr`
font-size: 14px;
color: #FFFFFF;
text-align:start;
padding:23px;
border:none;
:nth-child(even) {
  background-color:  #141414;;
};
@media(max-width: 720px) {
  padding:7px;
}
`

export const TdTitle = styled.td`
font-size: 14px;
color: #FFFFFF;
text-align:start;
padding:23px;
border:none;
@media(max-width: 720px) {
  padding:7px;
}
`
export const Td = styled.td`
align-items:center;
justify-content: center;
font-size: 14px;
color: #FFFFFF;
padding:23px;
border:none;
@media(max-width: 720px) {
  padding:7px;
}
`

export const Button= styled.button`
background:none;
border:none;
border-style:none;
border: 1px solid #A6A6A6;
border-radius:7px;
color: #FFFFFF;
width: 168px;
height: 45px;
display:flex;
justify-content: space-evenly;
align-items:center;
cursor:pointer;
margin:15px;
transition: filter 0.2;
&:hover{
filter: brightness(0.9)
}
`
