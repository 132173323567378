import React from 'react';
import { Dot, DotContainer, LoadingContainer, LoadingText } from './style';

const Loading = () => {

  return (
    <LoadingContainer>
      <Dot delay="1s" />
      <Dot delay="0.2s" />
      <Dot delay="0.3s" />
    </LoadingContainer>
  );
};


export default Loading;
