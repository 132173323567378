import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import Doc from '../pdf';
import {
  Grid,
  FormControl,
  Typography,
  Link,
  Tooltip,
  Icon,
  Button,
} from '@material-ui/core';

import {
  Main,
  TitlePaper,
} from './../styles';

import TabsBar from '../../../components/TabsBar';
import AppBarWrapper from '../../../components/AppBarWrapper';
import { getStudentsReportTable } from './service';
import { GET_STUDENTS_REPORT } from '../../../services/queries/reports';
import Footer from '../../../components/Footer';


const reportTitle = "Relatório de Estudantes";
const course = JSON.parse(localStorage.getItem('course'));

function buildTitle () {
  return (
    <Grid container>
      <Grid xs={6}>
        <Typography variant="h6">
          <Tooltip title="Voltar" aria-label="add">
            <Link href="/reports">
              <Icon style={{marginRight: "1em", color:"#044C6C", marginLeft: "1em", verticalAlign: "middle"}}>keyboard_backspace</Icon>
            </Link>
          </Tooltip>
            {reportTitle}
        </Typography>
      </Grid>
      <Grid xs={4}>      
        <Button onClick={exportPdf} variant="contained" color="primary" style={{float: "right"}}>
          <Icon>file_download</Icon>
          Baixar Relatório
        </Button>
      </Grid>
    </Grid>
  );
}

function exportPdf () {
  const input = document.getElementById('report');
  Doc.createPdf(input, reportTitle);
}

function buildDescription () {
  return (
    <TitlePaper square={true}>
      <Typography variant="h6" gutterBottom style={{textTransform: "uppercase"}}>
        {reportTitle} - {course.name}
      </Typography>
    </TitlePaper>
  );
}

const StudentsReport = () => {
  const [studentReport, setStudentReport] = useState(null);

  const client = useApolloClient();
  useEffect( () => {
    async function fetchData() {
      const { loading, data, error } = await  client.query({
        query: GET_STUDENTS_REPORT,
        variables: {
          CourseId: course.id,
        },
        forceFetch: true,
      });
      setStudentReport(data.getReportStudent);
    }
    fetchData();
  }, [studentReport]);

  return (
    <AppBarWrapper user={{ avatar: null, name: 'Alfred' }}>
    <Main maxWidth="md">
      <Grid container>
        <TabsBar title={buildTitle()}>
          <div id="report">
            <FormControl style={{ width: "80%", display: 'flex' }}>
              {buildDescription()}
              {getStudentsReportTable(studentReport)}
            </FormControl>
          </div>
        </TabsBar>
      </Grid>
    </Main>
    <Footer />
    </AppBarWrapper>
  );
};
export default withRouter(StudentsReport);