export const formatDateToUTC = (date) => {
  return new Date(new Date(date).toUTCString()).toISOString().slice(0, 4);
}

export const formatDateToUTCWithBars = (date) => {
  return new Date(new Date(date).toUTCString()).toLocaleDateString("pt-BR", { timeZone: 'UTC' });
};


export const formatDateToUTCWithBar = (date) => {
  return new Date(new Date(date).toUTCString()).toLocaleDateString("ko-KR'", { timeZone: 'UTC' });
};

