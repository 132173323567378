import gql from 'graphql-tag';

export const CREATE_COURSE = gql`
  mutation  ($name: String!, $description: String!, $tags: [ID],
      $year: Int, $startDate: Date, $endDate: Date, $nameModule: String,
      $folder: String, $thumbnail: String, $trailer: String, $nameUnit: [String],
      $durationUnit: [Int], $videos: [String], $material: [String], $nameMaterial: [String],
      $subtitles: [[String]], $idiomSubtitles: [[String]], $descriptionUnit: [String], $age: String) {
    createCourse(name: $name, description: $description, tags: $tags, year: $year,
      startDate: $startDate, endDate: $endDate, folder: $folder, nameModule: $nameModule,
        thumbnail: $thumbnail, trailer: $trailer, nameUnit: $nameUnit, durationUnit: $durationUnit, videos: $videos, material: $material, nameMaterial: $nameMaterial,
        subtitles: $subtitles, idiomSubtitles: $idiomSubtitles, descriptionUnit: $descriptionUnit, age: $age) {
      id
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation updateCourse($id: ID!, $name: String!, $description: String!,
     $startDate: Date, $endDate: Date, $tags: [ID], $year: Int, $idModule: ID, $nameModule: String, 
     $folder: String, $thumbnail: String, $trailer: String, $units: [String], $unitsName: [String], $unitsDuration: [Int], $material: [String], $materialName: [String],
     $subtitles: [[String]], $idiomSubtitles: [[String]], $unitsDescription: [String]) {
    updateCourse(id: $id, name: $name, description: $description, tags: $tags, year: $year,
      startDate: $startDate, endDate: $endDate, folder: $folder, idModule: $idModule, nameModule: $nameModule,
        thumbnail: $thumbnail, trailer: $trailer, units: $units, unitsName: $unitsName, unitsDuration: $unitsDuration, material: $material, materialName: $materialName,
        subtitles: $subtitles, idiomSubtitles: $idiomSubtitles, unitsDescription: $unitsDescription) {
      id
    }
  }
`;

export const UPDATE_COURSE_FIELDS = gql`
  mutation updateFieldCourse($id: ID!, $name: String!, $description: String!,
     $startDate: Date, $endDate: Date, $tags: [ID], $year: Int, $age: String, $folder: String, $thumbnail: String, $trailer: String,
     $material: [String], $materialName: [String],) {
      updateFieldCourse(id: $id, name: $name, description: $description, tags: $tags, year: $year,
      startDate: $startDate, endDate: $endDate, age: $age, folder: $folder, thumbnail: $thumbnail, trailer: $trailer,  material: $material, materialName: $materialName) {
      id
    }
  }
`;

export const SET_PUBLIC = gql`
  mutation ($id: ID!) {
    setPublicCourse(id: $id) {
      id
      public      
    }
  }
`;

export const REMOVE_COURSE = gql`
  mutation ($id: ID!) {
    removeCourse(id: $id)
  }
`;

export const REMOVE_COURSES = courses => gql`
  mutation {
    ${courses.map((course, i) => `
      remove${i}: removeCourse(id: "${course.id}")
    `).join('')}
  }
`;

export const CREATE_ALGORITHM_BANK = gql`
  mutation ($course: ID!) {
    createAlgorithmBank(course: $course) {
      id
    }
  }
`;

export const ADD_COURSE_SUBSCRIPTION = gql`
  mutation addCourseSubscription($id: ID!) {
    addCourseSubscription(id: $id) {
      id
    }
  }
`;

export const UPDATE_COURSE_SUBSCRIPTION = gql`
  mutation updateCourseSubscription($id: ID!, $userId: ID!, $confirmed: Boolean!) {
    updateCourseSubscription(id: $id, userId: $userId, confirmed: $confirmed)
  }
`;

export const ADD_COURSE_USER = gql`
  mutation($id: ID!, $userId: ID!, $roleId: ID!) {
    addCourseUser(id: $id, userId: $userId, roleId: $roleId) {
      id
    }
  }
`;

export const REMOVE_COURSE_USERS = list => gql`
  mutation {
    ${list.map((item, i) => `
      remove${i}: removeCourseUser(id: "${item.courseId}", userId: "${item.user && item.user.id}")
    `).join('')}
  }
`;
