import styled from 'styled-components';

const StyledGrid = styled.div`
  .uppy-Dashboard-inner {
    min-height: initial;
    height: 73px !important;
    border: 1px solid #c0c0c0;
  }
  .uppy-Dashboard-files {
    padding: 0;
  }
  .uppy-DashboardContent-bar {
    display: none;
  }
  .uppy-DashboardTabs {
    margin-top: 25px;
  }
  .uppy-Dashboard-dropFilesHereHint {
    padding: initial;
    color: transparent;
  }
  .uppy-Informer {
    bottom: 22px;
  }
`;

export default StyledGrid;
