import styled from 'styled-components';

import { Grid } from '@material-ui/core';

export const StyledGrid = styled(Grid)`
  height: 100vh;
  color: #fff;
  background-color: #080808;

  .image {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url('assets/images/cientik_background.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;

    .paper {
      margin: 30px 0;
      box-sizing: border-box;
      padding: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: rgba(20, 20, 20, 1);
      border-radius: 16px;
      box-shadow: 3px 3px 11px 2px rgba(0,0,0,0.68);
      max-width: 500px;

      .logo {
        width: 210px;
        margin-bottom: 8px;

        @media (max-width: 768px) {
          width: 150px;
          margin: 0;
        }
      }
      .error {
        margin-top: 5px;
        color: #ff3131;
      }

      .form {
        width: 85%;
        margin-bottom: 20px;

        .Mui-focused {
          color: #fff;
        }

        .MuiFormLabel-root {
          color: #fff;
        }

        .MuiOutlinedInput-root {
          color: #fff;

          fieldset {
            border-color: #ccc;
          }

          &:hover fieldset {
            border-color: #fff;
          }
        }

        .MuiCheckbox-root {
          color: rgba(255, 255, 255, 0.54);
        }

        .submit {
          margin: 24px 0px 16px;
          padding: 1rem 0;
        }

        a {
          color: #fff;
          text-align: center;
        }
      }
    }
  }
`;
