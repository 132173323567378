import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import useManualQuery from '../../useManualQuery';
import {
  GET_COURSE_EXERCISES,
} from '../../../services/queries/exercise';
import useNewTestDialog from './useNewTestDialog';
import useNewAlgorithmTestDialog from './useNewAlgorithmTestDialog';
import useQuestions from './questions';

import { GET_COURSE_MODULES } from '../../../services/queries/courses';
import { UPDATE_EXERCISE, REMOVE_EXERCISE, CREATE_EXERCISE } from '../../../services/mutations/exercise';

const useExercise = () => {
  const getExercises = useManualQuery(GET_COURSE_EXERCISES);
  const newTest = useNewTestDialog();
  const newAlgorithmTest = useNewAlgorithmTestDialog();
  const [createExercise] = useMutation(CREATE_EXERCISE);
  const getCourseModules = useManualQuery(GET_COURSE_MODULES);
  const [newTestDialogOpen, setNewTestDialogOpen] = useState(false);
  const [newAlgorithmTestOpen, setNewAlgorithmTestOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [removedExercise, setRemovedExercise] = useState();
  const [removeExercise] = useMutation(REMOVE_EXERCISE);
  const [modalEdit, setModalEdit] = useState();
  const [updateExercise] = useMutation(UPDATE_EXERCISE);
  const [selectedExercise, setSelectedExercise] = useState();
  const [anchorEl, setAnchorEl] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showIntelligentTests, setShowIntelligentTests] = useState(false);
  const questions = useQuestions();

  return {
    anchorEl,
    setAnchorEl,
    getExercises,
    getCourseModules,
    newTest,
    createExercise,
    newTestDialogOpen,
    setNewTestDialogOpen,
    deleteDialogOpen,
    setDeleteDialogOpen,
    removedExercise,
    setRemovedExercise,
    removeExercise,
    modalEdit,
    setModalEdit,
    updateExercise,
    selectedExercise,
    setSelectedExercise,
    questions,
    newAlgorithmTestOpen,
    setNewAlgorithmTestOpen,
    newAlgorithmTest,
    isMenuOpen,
    setIsMenuOpen,
    showIntelligentTests,
    setShowIntelligentTests,
  };
};

export default useExercise;
