import React, {useEffect} from "react";
import {useHistory} from 'react-router-dom';
import {
  Typography, Grid
} from '@material-ui/core';
import TrailerCard from '../../../components/TrailerCard';
import GlobalContext from '../../../context';
import { Title, Linha2 } from "./styles";
import CardTrailers from "../../../components/CardsCourse/CardTrailers";


const VideoExtra = () => {
  const {
    selectedCourse: {
      course
    },
    selectedTrailer: { trailer, setTrailer},
    visualization: { visualization },
  } = GlobalContext();

  const history = useHistory();

  const selectLesson = (Trailer) => {
    setTrailer(Trailer);
    history.push("/trailer");
  };


  const Trailer = course.trailer
  console.log('course', course)

  return (
    <div>
    { Trailer !== null &&(
    <>
      <Title>
        <h3 style={{ paddingRight: "10px" }}>Trailers e mais</h3>
        <Linha2></Linha2>
      </Title>
      <div style={{marginLeft: '30px'}}>

        <CardTrailers
              key={course.id}
              folder={course.folder}
              course={course.trailer}
              enterFunction={selectLesson}
            />

            {/* <TrailerCard
              key={course.id}
              name={'Trailer 1'}
              trailer={course.trailer}
              folder={course.folder}
              enterFunction={() => console.log('Selected a trailer!')}
              checkFunction={null}
              editFunction={null}
            /> */}
     </div>
     </>
   ) }
    </div>
  );
};

export default VideoExtra;
