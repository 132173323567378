import styled from 'styled-components';

export const ContainerList = styled.ol`
display:flex;
flex-direction:column;
color: #fffff;
list-style-type: lower-roman;
`

export const LikesVideosList = styled.li`
display:flex;
justify-content: space-between;
align-items:center;
width: 868px;
height: 172px;
@media(max-width: 920px) {
  width:90vw;
}
`
export const Image= styled.div`
width: 153px;
height: 86px;
background:url("${(props) => props.backgroundUrl}");
background-size: cover;
cursor:pointer;
`
export const Info= styled.div`
display:flex;
`
export const InfoTexts= styled.div`
padding-left:16px;
`
export const TitleVideo= styled.p`
font-weight: 500;
font-size: 16px;
color: #FFFFFF;
`
export const NameCourse= styled.p`
font-style: normal;
font-weight: 500;
font-size: 12px;
color: #A6A6A6;
padding-top:10px;

`
export const Button= styled.button`
background:none;
border:none;
border-style:none;
border: 1px solid #A6A6A6;
border-radius:7px;
color: #FFFFFF;
width: 216px;
height: 25px;
display:flex;
justify-content: space-evenly;
align-items:center;
cursor:pointer;
transition: filter 0.2;
&:hover{
filter: brightness(0.9)
}
`
