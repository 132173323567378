import React from 'react';
import { string, object as obj } from 'prop-types';
import Dashboard from '@uppy/react/lib/Dashboard';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import StyledGrid from './styles';

const Upload = ({ id, upload }) => (
  <StyledGrid>
    <Dashboard
      id={id}
      uppy={upload}
      showLinkToFileUploadResult={false}
      showProgressDetails
      hideUploadButton
      hideRetryButton
      hidePauseResumeButton
      hideCancelButton
      hideProgressAfterFinish
      proudlyDisplayPoweredByUppy={false}
      locale={{
        strings: {
          dropPaste: 'Arraste até aqui ou %{browse}',
          browse: 'escolha um arquivo',
        },
      }}
    />
  </StyledGrid>
);

Upload.propTypes = {
  id: string,
  upload: obj.isRequired,
};
Upload.defaultProps = { id: '1' };

export default Upload;
