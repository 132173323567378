import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { func, shape } from 'prop-types';
import {
  CssBaseline,
  TextField,
  Button,
  Grid,
  Typography,
  CircularProgress,
  Select,
  MenuItem
} from '@material-ui/core';
import { Link, withRouter } from 'react-router-dom';
import { SIGNUP } from '../../../services/mutations/user';
import logo from '../../../assets/images/cientik_logo.svg';
import { StyledGrid } from './styles';
import GlobalContext from '../../../context';
import { GET_INSTITUTIONS_SELECT } from '../../../services/queries/institution';

const SignUp = ({ history }) => {
  const [user, setUser] = useState({});
  const [error, setError] = useState('');
  const [signUp, { loading }] = useMutation(SIGNUP);
  const {data: {getInstitutions} } = useQuery(GET_INSTITUTIONS_SELECT);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    console.log('E: ', e.target)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!user.fullName || !user.birthDate || !user.email
        || !user.password || !user.confirmPassword) {
      setError('Todos os campos são obrigatórios!');
      return;
    }
    if (user.password !== user.confirmPassword) {
      setError('As senhas não correspondem!');
      return;
    }

    try {
      await signUp({ variables: user });
      history.push('/');
    } catch (err) {
      setError('Tivemos um problema, por favor tente novamente!');
    }
  };

  return (
    <StyledGrid container component="main" className="root">
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} className="image">
        <div className="paper">
          <img className="logo" src={logo} alt="logo" />
          {error && (
            <Typography className="error" component="p">
              {error}
            </Typography>
          )}
          <form onSubmit={handleSubmit} className="form" noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              type="text"
              required
              value={user.fullName || ''}
              onChange={handleChange}
              fullWidth
              label="Digite seu nome completo"
              name="fullName"
            />
            <TextField
              variant="outlined"
              margin="normal"
              type="date"
              required
              value={user.birthDate || ''}
              onChange={handleChange}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label="Digite sua data de nascimento"
              name="birthDate"
            />
            <TextField
              variant="outlined"
              margin="normal"
              type="email"
              required
              value={user.email || ''}
              onChange={handleChange}
              fullWidth
              label="Digite seu email"
              name="email"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              value={user.password || ''}
              onChange={handleChange}
              fullWidth
              name="password"
              label="Digite uma senha"
              type="password"
              autoComplete="current-password"
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              value={user.confirmPassword || ''}
              onChange={handleChange}
              fullWidth
              name="confirmPassword"
              label="Repita a senha"
              type="password"
            />
            <Select
              variant="outlined"
              margin="normal"
              required
              value={user.institution || ''}
              onChange={handleChange}
              fullWidth
              name="institution"
              label="Instituição"
            >
              {getInstitutions?.map((institution) =>
                <MenuItem value={institution?.id}>{institution?.name}</MenuItem>
              )}
            </Select>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Entrar'}
            </Button>
            <Grid container>
              <Link to="/login">Voltar ao login</Link>
            </Grid>
          </form>
        </div>
      </Grid>
    </StyledGrid>
  );
};

SignUp.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(SignUp);
