import gql from 'graphql-tag';

export const GET_COURSE_EXERCISES = gql`
  query($id: ID!) {
    getCourseById(id: $id) {
      modules {
        exercises {
          id
          startDate
          endDate
          score
          description
          title
          isIntelligentTo {
            id
            fullName
          }
          module {
            id
          }
        }
      }
    }
  }
`;

export const GET_EXERCISE_QUESTIONS = gql`
  query($id: ID!) {
    getExerciseById(id: $id) {
      exerciseQuestions {
        id
        question {
          __typename
          ... on ObjectiveQuestion {
            id
            stem
            alternatives {
              id
              text
              isCorrect
            }
          }
          ... on TrueFalseQuestion {
            id
            stem
            alternatives {
              id
              text
              isCorrect
            }
          }
          ... on SubjectiveQuestion {
            id
            stem            
          }
        }
      }
    }
  }
`;
