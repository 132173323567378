import styled from 'styled-components';
import { Link } from 'react-router-dom';


export const MenuContainer = styled.section`
    height: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    width: 15vw;
`


export const MenuItem = styled.div`
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    box-sizing: border-box;
    padding: 23px 0;
    align-items: center;
    font-weight: normal;
    color:#A6A6A6;
}
`
export const NavLink = styled(Link)`
    text-decoration: none;
    margin-left: 10px;
    color: #3D3D3D;
    font-size: 14px;
    :focus {
      color:  #080808;
      font-weight: bold;
    }
    .icon{
      color: #A6A6A6;
    }`

export const Icon = styled.span`
    :focus {
      color:  #080808;
      font-weight: bold;
    }
    `
export const Container = styled.div`
    display: flex;
    overflow-x:hidden
}
`

