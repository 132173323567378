import { useState } from 'react';

const useNewTestDialog = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [score, setScore] = useState('');
  const [selectedModule, setSelectedModule] = useState('');

  return {
    selectedModule,
    setSelectedModule,
    title,
    setTitle,
    description,
    setDescription,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    score,
    setScore,
  };
};

export default useNewTestDialog;
