import React from 'react';
import PropTypes from 'prop-types';
import SpeedDialUI from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import GlobalContext from '../../context';
import { SpeedDialStyled } from './styles';

const SpeedDial = ({ actions }) => {
  const {
    speedDial: {
      open,
      setOpen,
    },
  } = GlobalContext();

  return (
    <SpeedDialStyled>
      <SpeedDialUI
        ariaLabel="SpeedDial"
        className="speedDial"
        icon={<SpeedDialIcon />}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        open={open}
      >
        {actions.map(action => (
          <SpeedDialAction
            key={action.title}
            icon={action.icon}
            disabled={action.disabled}
            tooltipTitle={action.title}
            onClick={action.onClick}
          />
        ))}
      </SpeedDialUI>
    </SpeedDialStyled>
  );
};

SpeedDial.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape({
    icon: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  })).isRequired,
};

export default SpeedDial;
