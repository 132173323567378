import { keyframes } from "styled-components";
import styled from 'styled-components';

export const BounceAnimation = keyframes`
  0% {
    margin-bottom: 0;
  }

  50% {
    margin-bottom: 1rem;
  }

  100% {
    margin-bottom: 0;
  }
`

export const LoadingContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
width: 100vw;
height: 100vh;
`

export const LoadingContainerAdmin = styled.div`
display: flex;
align-items: center;
justify-content: center;
height: 100vh;
width: 80vw;
`


export const Dot = styled.div`
background-color: #FF4C00;
border-radius: 50%;
width: 0.75rem;
height: 0.75rem;
margin: 0 0.25rem;
/*Animation*/
animation: ${BounceAnimation} 0.5s linear infinite;
animation-delay: ${(props) => props.delay};
`




