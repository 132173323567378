import React, { useEffect, useState } from "react";
import { shape, func } from "prop-types";
import { withRouter } from "react-router-dom";
import { Grid} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert'
import CourseCard from "../../components/CourseCard";
import GlobalContext from "../../context";
import { Linha, Title , ContainerList, TitlePage, Container} from "./styles";
import AppBarWrapper from "../../components/AppBarWrapper";
import Footer from "../../components/Footer";

const MyListStudent = ({ history }) => {
  const {
    user: { id, institution },
    selectedCourse: {
      setCourse,
      getMyCoursesByUser,
      setCourseAccess,
      getCoursesByInstitution,
    },
    visualization: { visualization },
  } = GlobalContext();

  useEffect(() => {
    let role;
    switch (visualization) {
      case "Estudante":
        role = "STUDENT";
        break;
      case "Professor":
        role = "TEACHER";
        break;
      default:
        break;
    }
    console.log('aq2 ', id)
    getMyCoursesByUser.fetch({ variables: { id } });
  }, []);

  useEffect(() => {
    getCoursesByInstitution.fetch({ variables: { id: institution.id } });
  }, []);

  if (!getMyCoursesByUser.data || !getCoursesByInstitution.data) {
    return "Carregando...";
  }

  const {
    data: {
      getUserById: { courses },
    },
  } = getMyCoursesByUser;


  const enterCourse = (course) => {
    setCourse(course);
    setCourseAccess(false);

    console.log('AGORA')
    console.log(course, course.id)
    for (let i = 0; i < courses.length; i += 1) {
      if (courses[i].id === course.id) {
        setCourseAccess(true);
        break;
      }
    }

    history.push("/course");
  };



  return (
    <div>
    <AppBarWrapper/>
      <Container maxWidth="md" style={{height:"100%", backgroundColor: "#080808"}}>

      <ContainerList>
      <TitlePage>
        <strong>
          Minha lista
        </strong>
        </TitlePage>
      {courses.length > 0 && (
        <Grid container>
        {courses.map((course) => (
        <Grid item key={course.id}lg={3} xs={12} sm={4} md={4}
        style={{marginBottom:"35px"}}>
        <CourseCard course={course} enterFunction={enterCourse} />
        </Grid>
        ))}

        </Grid>
      )}
       </ContainerList>
       </Container>
       { courses.length === 0 && (
       <Container style={{height:"100vh"}}>
        <ContainerList>
        <Alert severity="warning">Nenhum curso no momento. Faça sua inscrição para ter acesso a algum curso.</Alert>
        </ContainerList>
       </Container>
       ) }
    <Footer/>
    </div>
  );
};

MyListStudent.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(MyListStudent );
