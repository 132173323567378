import React, { useEffect } from 'react';
import { shape, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';

import { Grid, Typography } from '@material-ui/core';
import { Add, Delete } from '@material-ui/icons';

import CourseCard from '../../../components/CourseCard';
import SpeedDial from '../../../components/Helpers/SpeedDial';
import Dialog from '../../../components/Helpers/Dialog';

import { StyledContainer } from '../../../styles/global';
import GlobalContext from '../../../context';
import CustomSlider from '../../../components/Sliders/CustomSlider';

const CourseList = ({ history }) => {
  const {
    user: { institution: { id } },
    selectedCourse: {
      setCourse,
      openDialog,
      setOpenDialog,
      selecteds,
      setSelecteds,
      removeCourses,
      getCoursesByInstitution,
      setCourseAccess,
    },
    visualization: { visualization },
  } = GlobalContext();

  const isManager = ['Administrador', 'Gestor'].includes(visualization);

  useEffect(() => {
    getCoursesByInstitution.fetch({ variables: { id } });
  }, []);

  if (!getCoursesByInstitution.data) {
    return 'Carregando...';
  }

  const { data: { getInstitutionById: { courses } } } = getCoursesByInstitution;

  const enterCourse = (course) => {
    console.log('OXE', course)
    setCourse(course);
    setCourseAccess(true);
    history.push('/course');
  };

  const createCourse = () => {
    setCourse({});
    history.push('/manageCourse');
  };

  const editCourse = (course) => {
    setCourse(course);
    history.push('/manageCourse');
  };

  const handleSelectCheck = (course) => {
    if (selecteds.indexOf(course) >= 0) {
      setSelecteds(selecteds.filter(item => item !== course));
    } else {
      setSelecteds([...selecteds, course]);
    }
  };

  const removeCourse = async () => {
    await removeCourses();
    getCoursesByInstitution.refetch();
    setOpenDialog(false);
    setSelecteds([]);
    NotificationManager.success('Operação realizada com sucesso');
  };

  const courseList = courses.map((course) => (<Grid item key={course.id} xs={12} sm={6} md={3}>
    <CourseCard
      course={course}
      enterFunction={enterCourse}
      checkFunction={isManager ? handleSelectCheck : null}
      editFunction={isManager ? editCourse : null}
    />
  </Grid>));
  
  return (
    <StyledContainer>
      <Grid container  xs={12} sm={12} md={12} lg={12}>
        <CustomSlider itemWidth={290} courses={courseList} />
        {courses.length === 0 && (
          <Typography variant="h6">
            Nenhum curso adicionado
          </Typography>
        )}
      </Grid>
      {isManager && (
        <>
          <SpeedDial actions={[
            {
              icon: <Add />,
              title: 'Adicionar curso',
              onClick: createCourse,
            },
            {
              icon: <Delete />,
              title: 'Remover curso',
              onClick: () => setOpenDialog(true),
              disabled: selecteds.length === 0,
            },
          ]}
          />
          <Dialog
            open={openDialog}
            onCancel={() => setOpenDialog(false)}
            colorConfirm="secondary"
            textConfirm="Remover"
            onConfirm={removeCourse}
            title="Remover curso"
          >
            Você tem certeza que deseja remover os seguintes cursos?
            <ul>
              {selecteds.map(course => (
                <li key={course.id}>{course.name}</li>
              ))}
            </ul>
          </Dialog>
        </>
      )}
    </StyledContainer>
  );
};

CourseList.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(CourseList);
