import styled from 'styled-components';

const Container = styled.main`
  .lesson {
    margin: 30px 0;
  }

  h2 {
    margin: 20px 0;
    color: #5A5A5A;
  }

  .answer {
    margin-top: 20px;

    .MuiTextField-root {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 140px);
    }
    
    .MuiInputBase-root {
      width: 100%;
      color: #A6A6A6;
    }

    .MuiButton-root {
      display: inline-block;
      vertical-align: middle;
      margin-left: 15px;
    }
  }
`;

export default Container;
