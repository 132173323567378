import React from 'react';
import GlobalContext from '../../../context';
import { HeaderContainer, Prefeitura, Title } from "./styles";

const Header = () =>  {
  const {
    user,
    user: { fullName, roles, birthDate },
    visualization: {
      setVisualization,
      setVisualizationModalOpen,
      visualization,
      visualizationModalOpen,
    },
  } = GlobalContext();

  console.log(user)

  return (
  <HeaderContainer>
  <Title>{`Área do ${visualization}`}</Title>
  <Prefeitura>{user.institution.name}</Prefeitura>
</HeaderContainer>
 )
};
export default Header;
