import React, { useEffect, useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { Redirect, useHistory } from "react-router-dom";
import GlobalContext from "../../../context";
import { Container } from "../../overview/styles";
import AppBarWrapper from "../../../components/AppBarWrapper";
import Footer from "../../../components/Footer";
import TableMaterial from "./TableMaterial";
import { StyledContainer } from "../ExercisesList/styles";
import { Button, Grid, Typography } from "@material-ui/core";

const ComplementaryMaterial = () => {
  const {
    selectedCourse: {
      course,
      courseAccess,
      getModulesByCourse,
      getMyCoursesByUser,
      getCoursesAvailable,
    },
    selectedLesson: { lesson, setLesson },
    visualization: { visualization },
  } = GlobalContext();

  const isManage = visualization !== "Estudante";
  const history = useHistory()

  return (
    <div style={{backgroundColor: "#080808"}}>
   <AppBarWrapper/>
      {courseAccess ? (
        <Container maxWidth="md">
        <StyledContainer maxWidth="lg">

        <Grid container spacing={2} style={{marginTop: "40px"}}>

        <Grid container justify="space-between">
        <Typography
        className="test-title"
        variant="h6"
        component="span"
        style={{color: '#DCDDDF'}}
      >
       Material Complementar
      </Typography>
      <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              history.goBack();
          }}
          >

            Voltar
          </Button>
          </Grid>

          <TableMaterial course={course}/>
          </Grid>
          </StyledContainer>
        </Container>

        ) : null}
      <Footer/>
  </div>
  );
};

export default ComplementaryMaterial ;
