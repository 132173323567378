import { useQuery } from '@apollo/react-hooks';
import { useEffect } from 'react';
import { GET_USER_ME } from '../../services/queries/user';
export const TOKEN_KEY = '@Galileo:Token';

const useUser = (visualization) => {
  const query = useQuery(GET_USER_ME,{
    onCompleted: e=>{
      console.log('OXEE ', e.getUserMe?.id)
    },
    onError: e=> {
      if(localStorage.getItem(TOKEN_KEY)){
        localStorage.removeItem(TOKEN_KEY);
        window.location.reload(false)
      }

      console.log(e)

    },
    fetchPolicy: 'no-cache'
  });

  const { setVisualization, setIsAdmin, setIsStudent } = visualization;

  useEffect(() => {
    if (query.data && query.data.getUserMe) {
      const roles = query.data.getUserMe.roles.map(i => i.name);
      const order = {
        Administrador: 0,
        Gestor: 1,
        Professor: 2,
        Estudante: 3,
      };
      roles.sort((a, b) => order[a] - order[b]);

      setVisualization(roles[0]);
      setIsAdmin(roles.some(() => 'Administrador'));
      setIsStudent(roles.some(() => 'Estudante'));
    }
  }, [
    query,
    setVisualization,
    setIsAdmin,
    setIsStudent,
  ]);

  return query;
};

export default useUser;
