import React, { useState } from "react";
import SearchBar from "../components/searchbar";
import Add from '@material-ui/icons/Add';
import {MdOutlineVideoCall} from 'react-icons/md';
import { ButtonModal, ColumnRight, ContainerSearchBar, TitleSearch } from "../components/searchbar/styles";
import NotificationsTable from "./NotificationsTable";
import { ContainerDashboard } from "../dashboard/styles";

const PublisherNotifications = () => {
  const [searchedString, setSearchedString] = useState('');

  const onChangeHandler = (e) => {
    setSearchedString(e.target.value);
}

  return (
            <>
              <ContainerSearchBar>
              <TitleSearch>
              Notificações
              </TitleSearch>
              <ColumnRight>
              <SearchBar customPlaceholder='Buscar notificação'
                        onChangeHandler={onChangeHandler}
                        searchedString={searchedString}
                        setSearchedString={setSearchedString}/>
              <ButtonModal>
                <Add  style={{color:"#FF4C00", paddingRight:"5px"}}size={25}/>
              Adicionar
              </ButtonModal>
              </ColumnRight>
              </ContainerSearchBar>
              <NotificationsTable/>
              </>
)}
export default PublisherNotifications;
