import { AccordionTitle, CardVideo, CardLegendas, Count, FormEpisodes, Legendas, Icon, Info, Input, Prevew, Small, StyledFormControlLabel, TextArea, TextAreaContainer, TextAreaContainerDescription, TextAreaDescription, TitleCard, TitleSeasons } from "../styles"
import React, { useState, useContext } from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import { MdAdd } from "react-icons/md";
import { FaClosedCaptioning } from 'react-icons/fa'
import{VscChromeClose}  from 'react-icons/vsc';
import { useDropzone } from "react-dropzone";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import { UploadContext } from '../index'
import { useForm, Controller } from 'react-hook-form';

  export function Episodes({errors, control}) {
    const [data, setData] = useContext(UploadContext);
    const [files, setFiles] = useState([]);
    const [episode, setEpisode] = useState(0);
    const [count, setCount] = useState(0);
    const [countContent, setCountContent] = useState(0);


  console.log(files)

  // const {getRootProps, getInputProps} = useDropzone({noKeyboard: true,
  //   accept: {
  //     'video/mp4': ['.mp4'],
  //     'video/webm': ['.webm'],
  //     'video/ogg': ['.ogg']
  //   },
  //   onDrop: acceptedFiles => {
  //     setFiles(acceptedFiles.map(file => Object.assign(file, {
  //       preview: URL.createObjectURL(file)
  //     })));
  //   }
  // });

  const removeVideo = (index) => {
    const tempVideo = [...data.videos];
    const tempName = [...data.nameUnit];
    const tempDescription = [...data.descriptionUnit];
    const legendaFile = [...data?.subtitles]
    const legendaIdiom = [...data?.idiomSubtitles]
    tempVideo.splice(index, 1);
    tempName.splice(index, 1);
    legendaFile.splice(index, 1)
    legendaIdiom.splice(index, 1)
    tempDescription.splice(index, 1)
    setData({
      ...data,
      videos: tempVideo,
      nameUnit: tempName,
      subtitles: legendaFile,
      idiomSubtitles: legendaIdiom,
      descriptionUnit: tempDescription
      
    })
  }

  const removeLegenda = (epIndex, legIndex) => {
    const legendaFile = [...data?.subtitles]
    const legendaIdiom = [...data?.idiomSubtitles]
    legendaFile[epIndex].splice(legIndex, 1)
    legendaIdiom[epIndex].splice(legIndex, 1)
    setData({
      ...data,
      subtitles: legendaFile,
      idiomSubtitles: legendaIdiom,
    })
  }


   const addVideo = async (e, onChange) => {
    if(data?.videos){
      setData({ ...data,
        videos: [...data?.videos, e.target.files[0]],
        nameUnit: [...data?.nameUnit, ''],
        subtitles: [...data?.subtitles, []],
        idiomSubtitles: [...data.idiomSubtitles, []],
        descriptionUnit: [...data?.descriptionUnit, '']
      })
      onChange([...data?.videos, e.target.files[0]])
    }else{
      setData({
        ...data,
        videos: [e.target.files[0]],
        nameUnit: [''],
        subtitles: [[]],
        idiomSubtitles: [[]],
        descriptionUnit: ['']
      })
      onChange([e.target.files[0]])
    }
   }

   const addLegenda = (e, index) => {
    console.log(episode)
  
      const legendaFile = [...data?.subtitles]
      console.log(index)
      console.log(legendaFile[episode])
      legendaFile[episode].push(e.target.files[0])

      const legendaIdiom = [...data?.idiomSubtitles]
      legendaIdiom[episode].push("")
      setData({ ...data,
        subtitles: legendaFile,
        idiomSubtitles: legendaIdiom,
      })
    // }else{
    //   setData({
    //     ...data,
    //     videos: [e.target.files[0]],
    //     nameUnit: [''],
    //     durationUnit: [null]
    //   })
    //   onChange([e.target.files[0]])
    // }
   }

   const handleName = (value, index) =>{
    const temp = [...data.nameUnit]
    temp[index] = value
    setData({...data, nameUnit: temp})
   }


   const handleIdiomSubtitle = (value, episodio, index) =>{
    const temp = [...data?.idiomSubtitles]
    temp[episodio][index] = value
    setData({...data, idiomSubtitles: temp})
   }

   const handleDescription = (value, index) =>{
    const temp = [...data.descriptionUnit]
    temp[index] = value
    setData({...data, descriptionUnit: temp})
   }


   const languages =
    ['Português', 'Inglês', 'Mandarim', 'Hindi', 'Espanhol', 'Francês',
    'Árabe', 'Bengali', 'Russo', 'Indonésio', 'Japonês', 'Alemão', 'Punjabi', 'Marata',
    'Catalão', 'Coreano', 'Italiano', 'Urdo', 'Turco', 'Vietnamita', 'Tailandês']

  return (
   <>

        <TitleSeasons>
        Episódios
        </TitleSeasons>
        <Info>
        A ordem dos episódios será a ordem de adição.
        </Info>

        {/* {files ? */}
        <div>
      {data?.videos?.map((file, index) => {
       return(
       <div
        key={file.name}
       style={{background: "#F5F5F5", marginBottom:"25px", borderRadius: "16px", marginTop:"25px",
       display:"flex", flexDirection: "column", alignItems:"center", justifyContent:"center"}}
       >
        <AccordionTitle style={{padding:"40px", textAlign:"center"}}>
            <strong>{file.name}</strong>
          </AccordionTitle>

          <FormEpisodes style={{margin:"0", padding:"40px 40px"}}>
          <label>
            Título do episódio <Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainer>
          <Controller
                name="nameUnit"
                control={control}
                defaultValue={data?.nameUnit && data?.nameUnit[index]}
                render={props => (
                    <Input type="text" name="nameUnit" defaultValue={data?.nameUnit && data?.nameUnit[index]} onChange={e => handleName(e.target.value, index)} placeholder='Insira o título do episódio'/>
                )}
                />
              <Count>
                <span>{count}</span>/100
              </Count>
            </TextAreaContainer>

            <label>
            Descrição do episódio<Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainerDescription>
               <Controller
                    name="descriptionUnit"
                    control={control}
                    defaultValue={data?.descriptionUnit && data?.descriptionUnit[index]}
                    render={props => (
                      <TextAreaDescription
                        type="text"
                        maxLength="500"
                        defaultValue={data?.descriptionUnit && data?.descriptionUnit[index]}
                        placeholder='Fale sobre o episódio'
                        name="descriptionUnit"
                        onChange={(e)=>handleDescription(e.target.value, index)}
                      />
                  )}
                  />
              <Count>
                <small>{countContent}</small>/500
              </Count>
              <FormHelperText error={Boolean(errors?.description)}>
              {errors?.description?.message}
            </FormHelperText>
            </TextAreaContainerDescription>

          <label>
            Legendas
          </label>  

          {data?.subtitles[index].map((legenda, i) => (

          

          <Legendas key={legenda.name}>
             <VscChromeClose style={{float: 'right', cursor: 'pointer'}} size={24} onClick={()=> removeLegenda(index, i)}/>
            <div style={{display: 'flex', alignItems: 'center', margin: '10px 0 20px 0'}}>
              
              <FaClosedCaptioning size={20}/>
              <TitleCard style={{marginLeft: 10}}>
                {legenda.name}
              </TitleCard>
             
            </div>

         
              <label>
                Idioma
              </label>
              <TextAreaContainer>
              <Controller
                    name="idiomSubtitles"
                    control={control}
                    defaultValue={data?.idiomSubtitles[index] && data?.idiomSubtitles[index][i]}
                    render={props => (
                        <Input type="text" name="idiomSubtitles" defaultValue={data?.idiomSubtitles[index] && data?.idiomSubtitles[index][i]} onChange={e => handleIdiomSubtitle(e.target.value, index, i)} placeholder='Insira o idioma da legenda'/>
                    )}
                    />
                </TextAreaContainer>

          </Legendas>
      ))}

          <CardLegendas style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
                Faça upload da legenda se necessário
              </TitleCard>

              <Controller
                name="subtitles"
                control={control}
                defaultValue={data?.subtitles}
                render={props => (
                    <input
                    type="file"
                    accept=".txt"
                    id="subtitles"
                    name="subtitles"
                    style={{ display: "none" }}
                    onChange={(e) => {console.log(index);addLegenda(e, index)}}/>
                )}
               />
               <FormHelperText error={Boolean(errors?.videos)}>
            {errors?.videos?.message}
            </FormHelperText>

            <label htmlFor="subtitles"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u onClick={()=>setEpisode(index)}>Clique aqui</u> para adicionar uma nova legenda
            </label>

              <Info>
              Formato: .srt, .sub, .ssa ou .txt
              </Info>
            </CardLegendas>

          </FormEpisodes>


                <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                 <video width="730" controls>
                 <source src={URL.createObjectURL(file)} />
                 </video>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none",  color: 'black', margin: 0 }}
                onClick={() => removeVideo(index)}
                 ><u>remover</u></button>
                </div>

       </div>
       )
       })}
                {/* )}
              )} */}
        </div>

              {/* <CircularProgressbar
              styles={{
                root: { width: 24 },
                path: { stroke: "#7159c1" }
              }}
              strokeWidth={10}
              percentage={files.progress}
            /> */}
            {/* } */}


        <CardVideo style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
              Carregar episódios
              </TitleCard>

              <Controller
                name="videos"
                control={control}
                defaultValue={data?.videos}
                render={props => (
                    <input
                    type="file"
                    accept=".mp4"
                    id="video"
                    name="videos"
                    style={{ display: "none" }}
                    onChange={(e) => {addVideo(e, props.field.onChange)}}/>
                )}
               />
               <FormHelperText error={Boolean(errors?.videos)}>
            {errors?.videos?.message}
            </FormHelperText>

            <label htmlFor="video"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u>Clique aqui</u> para adicionar um novo episódio
            </label>
              <Info>
              Você pode selecionar quantos episódios quiser.
              </Info>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
            </CardVideo>
   </>
  )
}

export default Episodes
