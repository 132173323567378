import styled from 'styled-components';
import { Card } from '@material-ui/core';

const Container = styled(Card)`
  .avatar {
    margin: 14px;
    width: 50px;
    height: 50px;
    background: #ffffff;
    border-radius: 0px;
    @media (max-width: 600px)
      {
        display:none;
      }
  }

  .infoUser {
    text-align: center;
    width: 100px;
    overflow: hidden;
    display: inline-block;
    vertical-align: top;
    color: #ffffff;
    @media (max-width: 600px)
      {
        display:none;
      }
  }

  .infoPost {
    margin-left: 15px;
    display: flex;
    flex-direction:column;
    justify-content: space-between;
    width: calc(100% - 160px);
    padding:15px;
    @media (max-width: 600px)
      {
        margin-left: 0px;
      }

    .userName {
      font-weight: 600;
      color:#ffffff;
      margin-bottom: 10px;
    }

    .text {
      text-align: justify;
      font-style: normal;
      font-size: 16px;
      color: #c6c6c6;
    }

    .date {
      font-size: 12px;
      color: #757575;
      margin-top: 5px;}

      @media (max-width: 600px)
      {
        font-size: 10px;
        width: 100%;
      }
  }

  .menu {
    display: inline-block;
    vertical-align: top;
    margin-left: 6px;
    color:#ffffff;

    > * {
      cursor: pointer;
      margin: 6px;
      display: block;
    }

    .button.disable {
      cursor: initial;
      opacity: 0.6;
    }
    @media (max-width: 600px)
    {
      margin-left: 1px;
    }

  }
`;

export default Container;
