import styled from 'styled-components';

export const Columns = styled.div`
display:flex;
flex-direction:column;

`

export const Container = styled.div`
overflow-x:hidden;
`

export const Column1= styled.div`
display:flex;
`

export const Column2 =styled.div`
display:flex;
`

export const Column3 =styled.div`
display:flex;
flex-direction:column;
`
export const ContainerDashboard = styled.div`
border-left: 1px solid #DCDDDF;
height: 100%;
`
