import React, { useEffect, useState } from "react";
import PlayerVideo from '../../components/PlayerVideo/Player'
import { Redirect } from "react-router-dom";
import "video-react/dist/video-react.css";
import TabsBar from "../../components/TabsBar";
import Post from "./Post";
import GlobalContext from "../../context";
import {useHistory} from "react-router-dom";
import NotificationManager from "react-notifications/lib/NotificationManager";


const Lesson = () => {

  const history = useHistory();
  const {
    users: {favoriteUnit},
    user: { id },
    selectedLesson: {
      lesson,
      setLesson,
      start,
      answer,
      setAnswer,
      createPost,
      removePost,
      fixedPost,
      getPostsByTopic,
    },
    selectedCourse: {
      course,
      courseAccess,
      getModulesByCourse,
    },
    visualization: { visualization },
  } = GlobalContext();


  useEffect(() => {
    getModulesByCourse.fetch({
      variables: { id, filter: visualization === "Estudante" },
    });
  }, []);



  useEffect(() => {
    getPostsByTopic.fetch({ variables: { id: lesson ? lesson.topic.id : 0 } });
  }, []);

  if (!lesson) {
    return <Redirect to="/" />;
  }
  if (!getPostsByTopic.data) {
    return "";
  }

  const isManager = visualization !== "Estudante";
  const {
    data: {
      getTopicById: { posts },
    },
  } = getPostsByTopic;
  const {
    data: {
      getCourseById: { modules },
    },
  } = getModulesByCourse;

  const createAnswer = async () => {
    if (!answer) {
      return;
    }
    await createPost({
      variables: {
        id: lesson.topic.id,
        text: answer,
      },
    });
    getPostsByTopic.refetch();
    setAnswer("");
  };

  const removeAnswer = async (post) => {
    await removePost({
      variables: {
        id: post.id,
      },
    });
    getPostsByTopic.refetch();
  };

  const fixedAnswer = async (post) => {
    await fixedPost({
      variables: {
        id: post.id,
        fixed: !post.fixed,
      },
    });
    getPostsByTopic.refetch();
  };

//mudar video

const changeLesson = (isNext) => {
  let lastLesson;
  let continuing = true;
  modules.every((module) => {
    module.units.every((unit) => {
      if (isNext && lastLesson === lesson) {
        lastLesson = unit;
        continuing = false;
      } else if (!isNext && unit === lesson) {
        continuing = false;
      } else {
        lastLesson = unit;
      }
      return continuing;
    });
    return continuing;
  });
  setLesson(lastLesson || lesson);
};



  const getContent = () => {
    console.log(lesson)
    if (lesson.type === "TOPIC") {
      return null;
    }
    if (lesson.type === "VIDEO") {
      return (

        <PlayerVideo
        // Vídeo
        src={lesson.hlsContent ? /*'https://media.cientik.tv'*/'https://ead3.secure.footprint.net' + lesson.hlsContent : lesson.content} //'https://ead3.secure.footprint.net'
        title={course.name}
        subTitle={lesson.name}
        titleMedia={course.name}
        extraInfoMedia={lesson.name}
        playerLanguage="pt"
        backButton= {history.goBack}
        fullPlayer
        autoPlay
        startPosition={lesson.unitUserPlayHistoric && !start ? lesson.unitUserPlayHistoric?.ElapsedTime : 0}
        lessonId={lesson.id}
        onNextClick={() =>changeLesson(true)}
        onPrevClick ={() =>changeLesson(false)}
        onTimeUpdate={() => { }}
        overlayEnabled
        autoControllCloseEnabled
        subtitleList={lesson.subtitles}
        // Style
        primaryColor="#e84810"
        secundaryColor="#ffffff"
        fontFamily='Montserrat'
        // Velocidade do Video
        playbackRateStart={1}
        playbackRateOptions={['0.25', '0.5', '0.75', '1', '1.25', '1.5', '2', '3']}
      />
      );
    }
    return (
      <div className="ql-snow">
        <div
          className="ql-editor"
          dangerouslySetInnerHTML={{ __html: lesson.content }}
        />
      </div>
    );
  };

  return (

    <div>{getContent()}</div>

    //  <AppBarWrapper>
    //    <Container>
    //     <div className="navigationButtons">
    //     <Tooltip title="Aula anterior">
    //     <div onClick={() => changeLesson(false)} style={{ color: "#e84810" }}>
    //           <AiOutlineDoubleLeft
    //             style={{ color: "#FF4C00", fontSize: "40px" }}
    //           />
    //         </div>

    //         </Tooltip>
    //         <Tooltip title="Próxima aula">
    //         <div onClick={() => changeLesson(true)}style={{ color: "#e84810" }}>
    //           <AiOutlineDoubleRight
    //             style={{ color: "#FF4C00", fontSize: "40px" }}
    //           />
    //         </div>
    //      </Tooltip>
    //     </div>
    //      <Grid container>
    //       <TabsBar
    //       // title={lesson.name}
    //       >
    //       <ContainerStyle maxWidth="md">
    //         <Grid container spacing={2}>
    //           <Grid item key={lesson.id} xs={12}>
    //            <div className="lesson">{getContent()}</div>
    //               <h2>Discussões</h2>
    //               {[true, false].(fixed => (
    //                 posts.map(post => fixed === post.fixed && (
    //                   <Post
    //                     key={post.id}
    //                     post={post}
    //                     removeFunction={isManager || post.creator.id === id ? removeAnswer : null}
    //                     fixedFunction={isManager ? fixedAnswer : null}
    //                   />
    //                 ))
    //               ))}
    //               <div className="answer">
    //                 <TextField
    //                   multiline
    //                   variant="outlined"
    //                   rows="2"
    //                   rowsMax="5"
    //                   label="Digite sua resposta..."
    //                   value={answer}
    //                   onChange={e => setAnswer(e.target.value)}
    //                 />
    //                 <Button variant="contained" color="primary" onClick={createAnswer}>
    //                   Responder
    //                 </Button>
    //               </div>
    //            </Grid>
    //         </Grid>
    //       </ContainerStyle>
    //        </TabsBar>
    //     </Grid>
    //     <Footer />
    //    </Container>
    //  </AppBarWrapper>

    )

  };

  export default Lesson;
