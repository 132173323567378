import React, { useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import client from './services/apollo';
import Routes from './routes';
import GlobalStyle from './styles/global';
import GlobalContext from './context';
import PublisherHome from './pages/publisher';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF4C00',
    },
    secondary: {
      main: '#FF4C00',
    },
    terciary: {
      main: '#080808',
    }
  },
  typography: {
    fontFamily: [
      'Montserrat'
    ]
  }
});

const App = () => {


return (
  <BrowserRouter>
    <ApolloProvider client={client}>
      <GlobalContext.Provider>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
             <Routes />
            <NotificationContainer />
            <GlobalStyle />
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </GlobalContext.Provider>
    </ApolloProvider>
  </BrowserRouter>
);
}

export default App;
