import React from "react";
import { ColumnRight, ContainerSearchBar, TitleSearch } from "../components/searchbar/styles";

const PublisherCertificates = () => {

  return (
            <>
              <ContainerSearchBar>
              <TitleSearch>
              Certificados
              </TitleSearch>
              <ColumnRight>
              </ColumnRight>
              </ContainerSearchBar>
              </>
)}
export default PublisherCertificates;
