import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import 'video-react/dist/video-react.css';
import {
  Grid, TextField, Button, Container as ContainerStyle,
} from '@material-ui/core';
import AppBarWrapper from '../../components/AppBarWrapper';
import TabsBar from '../../components/TabsBar';
import Container from './styles';
import Post from './Post';
import GlobalContext from '../../context';
import Footer from '../../components/Footer';

const Topic = () => {
  const {
    user: { id },
    forum: {
      topic,
      getPostsByTopic,
      createPost,
      fixedPost,
      removePost,
    },
    visualization: { visualization },
  } = GlobalContext();

  const [answer, setAnswer] = useState('');

  useEffect(() => {
    if (topic.id) {
      getPostsByTopic.fetch({ variables: { id: topic.id } });
    }
  }, []);

  if (!topic.id) {
    return <Redirect to="/" />;
  }

  const isManager = visualization !== 'Estudante';

  if (!getPostsByTopic.data) {
    return 'Carregando...';
  }

  const { data: { getTopicById: { posts } } } = getPostsByTopic;

  const createAnswer = async () => {
    if (!answer) {
      return;
    }
    await createPost({
      variables: {
        id: topic.id,
        text: answer,
      },
    });
    getPostsByTopic.refetch();
    setAnswer('');
  };

  const removeAnswer = async (post) => {
    await removePost({
      variables: {
        id: post.id,
      },
    });
    getPostsByTopic.refetch();
  };

  const fixedAnswer = async (post) => {
    await fixedPost({
      variables: {
        id: post.id,
        fixed: !post.fixed,
      },
    });
    getPostsByTopic.refetch();
  };

  return (
    <>
    <AppBarWrapper/>
      <Container>
        <Grid container>
          <TabsBar title={topic.name}>
            <ContainerStyle maxWidth="md">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <h2>Discussões</h2>
                  {[true, false].map(fixed => (
                    posts.map(post => fixed === post.fixed && (
                      <Post
                        key={post.id}
                        post={post}
                        removeFunction={isManager || post.creator.id === id ? removeAnswer : null}
                        fixedFunction={isManager ? fixedAnswer : null}
                      />
                    ))
                  ))}
                  <div className="answer">
                    <TextField
                      multiline
                      variant="outlined"
                      rows="2"
                      rowsMax="5"
                      label="Digite sua resposta..."
                      value={answer}
                      onChange={e => setAnswer(e.target.value)}
                    />
                    <Button variant="contained" color="primary" onClick={createAnswer}>
                      Responder
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </ContainerStyle>
          </TabsBar>
        </Grid>
        <Footer />
      </Container>
    </>
  );
};

export default Topic;
