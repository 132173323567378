import React, { useEffect, useState, useRef } from 'react';
import i18n from 'i18next';
import { useTranslation, initReactI18next } from 'react-i18next';
import gql from 'graphql-tag';

import {
  FaCog,
  FaClone,
} from 'react-icons/fa';
import {MdOutlineFileDownloadDone, MdOutlineSubtitles} from 'react-icons/md';
import{VscChromeClose}  from 'react-icons/vsc';
import {AiOutlineRedo, AiOutlineUndo, AiOutlineFullscreen, AiOutlineAppstoreAdd, AiOutlineStepBackward, AiOutlineFullscreenExit, AiOutlineStepForward,
  AiTwotoneLike, AiOutlineLike, AiFillHeart, AiOutlineHeart}  from 'react-icons/ai';
import {IoMdPlay, IoMdPause, IoMdVolumeHigh, IoMdVolumeLow, IoMdVolumeMute, IoMdVolumeOff} from 'react-icons/io';
import { FiCheck, FiX } from 'react-icons/fi';
import {
  Loading,
  StandyByInfo,
  VideoPreLoading,
  Container,
  Controlls,
  VolumeControll,
  ItemPlaybackRate,
  IconPlayBackRate,
  ItemNext,
  ItemListReproduction,
  ItemListQuality,
} from './styles.js';
import { useBeforeunload } from 'react-beforeunload';
import ReactPlayer from 'react-player';
import translations from './translations';
import { Tooltip } from '@material-ui/core';
import GlobalContext from '../../context/index.js';
import { useQuery, useMutation } from "@apollo/react-hooks";
import ReactHlsPlayer from 'react-hls-player';


export function handelRightClick(event) {
  event.preventDefault();
}

export const UPDATE_UNIT_PLAY_HISTORIC = gql`
  mutation updateUnitVideoPlayHistoric($UnitId: ID!, $ElapsedTime: Float!, $Ended: Boolean) {
    updateUnitVideoPlayHistoric(UnitId: $UnitId, ElapsedTime: $ElapsedTime, Ended: $Ended) 
  }
`;

i18n.use(initReactI18next).init({
  resources: translations,
  lng: 'pt',
  fallbackLng: 'pt',

  interpolation: {
    escapeValue: false,
  },
});

export default function PlayerVideo({
  title = false,
  subTitle = false,
  titleMedia = false,
  extraInfoMedia = false,
  playerLanguage = 'pt',
  fullPlayer = true,
  backButton = false,
  src,
  lessonId = false,
  autoPlay = false,
  onClickLikedRemove = false,
  onCanPlay = false,
  onTimeUpdate = false,
  onEnded = false,
  onErrorVideo = false,
  onNextClick = false,
  onCLickLiked = false,
  onPrevClick =false,
  onClickItemListReproduction = false,
  onClickItemListSubtitle = false,
  onCrossClick = () => {},
  startPosition = 0,
  dataNext = {},
  reprodutionList = [],
  subtitleList = [],
  qualities = [],
  onChangeQuality = [],
  playbackRateEnable = true,
  overlayEnabled = true,
  autoControllCloseEnabled = true,
  // Styles
  primaryColor = '#e84810',
  secundaryColor = '#ffffff',
  fontFamily = 'Montserrat',

  // subtitleMedia,
}) {
  // Referências
  const videoComponent = useRef(null);
  const timerRef = useRef(null);
  const timerBuffer = useRef(null);
  const playerElement = useRef(null);
  const listReproduction = useRef(null);
  const listSubtitle = useRef(null);

  // Estados
  const [videoReady, setVideoReady] = useState(false);
  const [playing, setPlaying] = useState(true);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [end, setEnd] = useState(false);
  const [controlBackEnd, setControlBackEnd] = useState(false);
  const [fullScreen, setFullScreen] = useState(true);
  const [volume, setVolume] = useState(100);
  const [muted, setMuted] = useState(false);
  const [error, setError] = useState(false);
  const [waitingBuffer, setWaitingBuffer] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [started, setStarted] = useState(false);

  const [showControlVolume, setShowControlVolume] = useState(false);
  const [showQuality, setShowQuality] = useState(false);
  const [showDataNext, setShowDataNext] = useState(false);
  const [favoriteVideo, setFavoriteVideo] = useState(false);
  const [likedVideo, setLikedVideo] = useState(false);
  const [showPlaybackRate, setShowPlaybackRate] = useState(false);
  const [showReproductionList, setShowReproductionList] = useState(false);
  const [showSubtitleList, setShowSubtitleList] = useState(false);
  const [subtitleSelected, setSubtitleSelected] = useState(null)

  const [legenda, setLegenda] = useState(null)

  const {
    users: {favoriteUnit, likeUnit},
    selectedLesson: {
      getPostsByTopic,
    },
  } = GlobalContext();

  const [updateVideoPlayHistoric] = useMutation(UPDATE_UNIT_PLAY_HISTORIC)

  const playbackRateOptions = ['0.25', '0.5', '0.75', 'Normal', '1.25', '1.5', '2'];

  const { t } = useTranslation();

  const [, setActualBuffer] = useState({
    index: 0,
    start: 0,
    end: 0,
  });


  const handleClickFavorite = async (favStatus) => {
    setFavoriteVideo(favStatus);
    console.log(lessonId, favoriteVideo )
    await favoriteUnit({
    variables: {
      id: lessonId,
      favorite: favStatus
    },
  });
  getPostsByTopic.refetch();
};

const handleClickLike = async (likeStatus) => {
  setLikedVideo(likeStatus);
  console.log(lessonId, likedVideo )
  await likeUnit({
  variables: {
    id: lessonId,
    like: likeStatus
  },
});
getPostsByTopic.refetch();
};


  useEffect(()=>{
    document.addEventListener('contextmenu', handelRightClick);

    document.onkeydown = function(e) {
      if (e.ctrlKey && (e.keyCode === 67 || e.keyCode === 86 || e.keyCode === 85 || e.keyCode === 117 || e.which == '65' || e.which == '97'|| e.which == '83' || e.which == '115' || e.which == '88' || e.which == '120' || e.which == '74' || e.which == '106')) {
      }
    };
  },[])

  const secondsToHms = d => {
    d = Number(d);
    const h = Math.floor(d / 3600);
    const m = Math.floor((d % 3600) / 60);
    let s = Math.floor((d % 3600) % 60);

    if (s < 10) {
      s = `0${s}`;
    }

    if (h) {
      return `${h}:${m}:${s}`;
    }

    return `${m}:${s}`;
  };

  const timeUpdate = e => {
    setShowInfo(false);
    setEnd(false);
    if (playing) {
      setPlaying(true);
    }

    if (waitingBuffer) {
      setWaitingBuffer(false);
    }

    if (timerBuffer.current) {
      clearTimeout(timerBuffer.current);
    }

    timerBuffer.current = setTimeout(() => setWaitingBuffer(true), 1000);

    if (onTimeUpdate) {
      onTimeUpdate(e);
    }

    let choseBuffer = 0;
    const lenghtBuffer = e.target.buffered.length;
    let start = 0;
    let endBuffer = 0;
    const atualTime = e.target.currentTime;

    for (let i = 1; i <= lenghtBuffer; i++) {
      const startCheck = e.target.buffered.start(i - 1);
      const endCheck = e.target.buffered.end(i - 1);

      if (endCheck > atualTime && atualTime > startCheck) {
        choseBuffer = i;

        if (endCheck > endBuffer) {
          endBuffer = endCheck;
        }

        if (startCheck < start) {
          start = startCheck;
        }
      }
    }

    setActualBuffer({
      index: choseBuffer,
      start,
      endBuffer,
    });

    setProgress(e.target.currentTime);
  };

  const goToPosition = position => {
    videoComponent.current.currentTime = position;
    setProgress(position);
  };

  const play = () => {
    setPlaying(!playing);

    if (videoComponent.current.paused) {
      videoComponent.current.play();
      return;
    }

    videoComponent.current.pause();
  };

  const onEndedFunction = () => {
    if (+startPosition === +videoComponent.current.duration && !controlBackEnd) {
      setControlBackEnd(true);
      videoComponent.current.currentTime = videoComponent.current.duration - 30;
      if (autoPlay) {
        setPlaying(true);
        videoComponent.current.play();
      } else {
        setPlaying(false);
      }
    } else {
      setEnd(true);
      setPlaying(false);

      if (onEnded) {
        onEnded();
      }
    }
  };

  const nextSeconds = seconds => {
    const current = videoComponent.current.currentTime;
    const total = videoComponent.current.duration;

    if (current + seconds >= total - 2) {
      videoComponent.current.currentTime = videoComponent.current.duration - 1;
      setProgress(videoComponent.current.duration - 1);
      return;
    }

    videoComponent.current.currentTime += seconds;
    setProgress(videoComponent.current.currentTime + seconds);
  };

  const previousSeconds = seconds => {
    const current = videoComponent.current.currentTime;

    if (current - seconds <= 0) {
      videoComponent.current.currentTime = 0;
      setProgress(0);
      return;
    }

    videoComponent.current.currentTime -= seconds;
    setProgress(videoComponent.current.currentTime - seconds);
  };

  const startVideo = async () => {
    try {
      setDuration(videoComponent.current.duration);
      setVideoReady(true);

      if (!started) {
        setStarted(true);
        setPlaying(false);

        if (autoPlay) {
          try {
            await videoComponent.current.play();
          } catch {
            setShowControls(true);
          }
          setPlaying(!videoComponent.current.paused);
        }
      }

      if (onCanPlay) {
        onCanPlay();
      }
    } catch (err) {
      setPlaying(false);
    }
  };

  const erroVideo = () => {
    if (onErrorVideo) {
      onErrorVideo();
    }
    setError(t('playError', { lng: playerLanguage }));
  };

  const setMuttedAction = value => {
    setMuted(value);
    setShowControlVolume(false);
    videoComponent.current.muted = value;
  };

  const setVolumeAction = value => {
    setVolume(value);
    videoComponent.current.volume = value / 100;
  };

  const exitFullScreen = () => {
    if (
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else {
        document.webkitExitFullscreen();
      }

      setFullScreen(false);
    }
  };

  const enterFullScreen = () => {
    setShowInfo(true);
    if (playerElement.current.requestFullscreen) {
      playerElement.current.requestFullscreen();
      setFullScreen(true);
    } else if (playerElement.current.webkitRequestFullscreen) {
      playerElement.current.webkitRequestFullscreen();
      setFullScreen(true);
    }
  };

  const chooseFullScreen = () => {
    if (
      document.webkitIsFullScreen ||
      document.mozFullScreen ||
      document.msFullscreenElement ||
      document.fullscreenElement
    ) {
      document.exitFullscreen();
      return;
    }

    setShowInfo(true);

    if (playerElement.current.requestFullscreen) {
      playerElement.current.requestFullscreen();
    } else if (playerElement.current.webkitRequestFullscreen) {
      playerElement.current.webkitRequestFullscreen();
    } else if (playerElement.current.mozRequestFullScreen) {
      playerElement.current.mozRequestFullScreen();
    } else if (playerElement.current.msRequestFullscreen) {
      playerElement.current.msRequestFullscreen();
    }

    setFullScreen(true);
  };

  const setStateFullScreen = () => {
    if (
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement &&
      !document.fullscreenElement
    ) {
      setFullScreen(false);
      return;
    }

    setFullScreen(true);
  };

  const controllScreenTimeOut = () => {
    if (!autoControllCloseEnabled) {
      setShowInfo(true);
      return;
    }

    setShowControls(false);
    if (!playing) {
      setShowInfo(true);
    }
  };

  const hoverScreen = () => {
    setShowControls(true);
    setShowInfo(false);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = setTimeout(controllScreenTimeOut, 5000);
  };

  const getKeyBoardInteration = e => {
    if (e.keyCode === 32 && videoComponent.current) {
      if (videoComponent.current.paused) {
        videoComponent.current.play();
        setPlaying(true);
        hoverScreen();
      } else {
        videoComponent.current.pause();
        setPlaying(false);
        hoverScreen();
      }
    }
  };

  const scrollToSelected = () => {
    const element = listReproduction.current;
    const selected = element.getElementsByClassName('selected')[0];
    const position = selected.offsetTop;
    const height = selected.offsetHeight;
    element.scrollTop = position - height * 2;
  };

  const onChangePlayBackRate = speed => {
    speed = speed === 'Normal' ? 1 : speed;
    videoComponent.current.playbackRate = speed;
    setPlaybackRate(speed);
  };

  useEffect(() => {
    if (showReproductionList) {
      scrollToSelected();
    }
  }, [showReproductionList]);

  const updateElapsedTime = async () => {
    const playHistoricVariables = {
      UnitId: lessonId,
      ElapsedTime: progress,
      Ended: progress >= duration * 0.97 ? true : false,
    };


    await updateVideoPlayHistoric({ variables: playHistoricVariables });

  }


  window.onpopstate = () => {
      updateElapsedTime();
  };

  useBeforeunload(() => {
      updateElapsedTime();
  });


  useEffect(() => {
    if (src) {
      videoComponent.current.currentTime = startPosition;
      videoComponent.current.addEventListener('loadedmetadata', startVideo);
      setProgress(0);
      setDuration(0);
      setVideoReady(false);
      setError(false);
      setShowReproductionList(false);
      setShowDataNext(false);
      setActualBuffer({
        index: 0,
        start: 0,
        end: 0,
      });
      setPlaying(false);

      return () => {
        videoComponent.current.removeEventListener('loadedmetadata', startVideo);
      };
    }
  }, [src]);

  useEffect(() => {
    document.addEventListener('keydown', getKeyBoardInteration, false);
    playerElement.current.addEventListener('fullscreenchange', setStateFullScreen, false);
  }, []);

  // When changes happen in fullscreen document, teh state of fullscreen is changed
  useEffect(() => {
    setStateFullScreen();
  }, [document.fullscreenElement, document.webkitIsFullScreen, document.mozFullScreen, document.msFullscreenElement]);


  function renderLoading() {
    return (
      <Loading color={primaryColor}>
        <div>
          <div />
          <div />
          <div />
        </div>
      </Loading>
    );
  }

  function renderInfoVideo() {
    return (
      <StandyByInfo
        primaryColor={primaryColor}
        secundaryColor={secundaryColor}
        show={showInfo === true && videoReady === true && playing === false}
      >
        {(title || subTitle) && (
          <section className="center">
            <h3 className="text">{t('youAreWatching', { lng: playerLanguage })}</h3>
            <h1 className="title">{title}</h1>
            <h2 className="sub-title">{subTitle}</h2>
          </section>
        )}
        <footer>{t('paused', { lng: playerLanguage })}</footer>
      </StandyByInfo>
    );
  }

  function renderCloseVideo() {
    return (
      <VideoPreLoading
        backgroundColorHoverButtonError="#f78b28"
        colorHoverButtonError="#ddd"
        colorButtonError="#ddd"
        backgroundColorButtonError="#333"
        colorTitle="ffffff"
        colorSubTitle="ffffff"
        colorIcon="ffffff"
        show={videoReady === false || (videoReady === true && error)}
        showError={!!error}
      >
        {(title || subTitle) && (
          <header>
            <div>
              <h1>{title}</h1>
              <h2>{subTitle}</h2>
            </div>
            {/* <FiX onClick={onCrossClick} /> */}
          </header>
        )}

        <section>
          {error && (
            <div>
              <h1>{error}</h1>
              {qualities.length > 1 && (
                <div>
                  <p>{t('tryAccessingOtherQuality', { lng: playerLanguage })}</p>
                  <div className="links-error">
                    {qualities.map(item => (
                      <div onClick={() => onChangeQuality(item.id)}>
                        {item.prefix && <span>HD</span>}
                        <span>{item.nome}</span>
                        {item.playing && <FiX />}
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
        </section>
      </VideoPreLoading>
    );
  }

  return (
    <Container
      onMouseMove={hoverScreen}
      ref={playerElement}
      onDoubleClick={chooseFullScreen}
      fullPlayer={fullPlayer}
      hideVideo={!!error}
      fontFamily={fontFamily}
      oncontextmenu="return false"
    >
      {(videoReady === false || (waitingBuffer === true && playing === true)) && !error && !end && renderLoading()}

      {!!overlayEnabled && renderInfoVideo()}

      {renderCloseVideo()}

      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      {src?.endsWith(".m3u8") ? 
      <ReactHlsPlayer
        playerRef={videoComponent}
        controls={false}
        src={src}
        onCanPlay={() => startVideo()}
        onTimeUpdate={timeUpdate}
        onError={erroVideo}
        onEnded={onEndedFunction}
        onContextMenu={('contextmenu',(e)=>e.preventDefault())} 
      /> : 
      <video
        ref={videoComponent}
        controls={false}
        src={src}
        onCanPlay={() => startVideo()}
        onTimeUpdate={timeUpdate}
        onError={erroVideo}
        onEnded={onEndedFunction}
        onContextMenu={('contextmenu',(e)=>e.preventDefault())} 
      />}

      <Controlls
        show={showControls === true && videoReady === true && error === false}
        primaryColor={primaryColor}
        progressVideo={(progress * 100) / duration}
        onClick={() => {
          if (!showControls) {
            setShowControls(true);
          }
        }}
      >
        {backButton && (
          <div className="back">
            <div onClick={backButton} style={{ cursor: 'pointer' }}>
              <VscChromeClose />
              <span>{t('goBack', { lng: playerLanguage })}</span>
            </div>
          </div>
        )}

        {showControlVolume !== true && showQuality !== true && !showDataNext && !showReproductionList && (
          <div className="line-reproduction">
            <input
              type="range"
              value={progress}
              className="progress-bar"
              max={duration}
              onChange={e => goToPosition(e.target.value)}
              title=""
              style={
                {
                  // background: `linear-gradient(93deg, rgba(247,139,40,1) ${
                  //   (progress * 100) / duration
                  // }%, blue ${
                  //   (progress * 100) / duration
                  // }%, blue ${
                  //   (atualBuffer.end * 100) / duration
                  // }%, red ${
                  //   (atualBuffer.end * 100) / duration
                  // }%)`,
                }
              }
            />
            <span>{secondsToHms(duration - progress)}</span>
          </div>
        )}

        {videoReady === true && (
          <div className="controlls">
            <div className="start">
              <div className="item-control">
                {!playing && <IoMdPlay onClick={play} />}
                {playing && <IoMdPause onClick={play} />}
              </div>

              <div className="seconds">
              <div className="item-control">
                <AiOutlineUndo onClick={() => previousSeconds(10)} />
              </div>

              <div className="item-control">
                <AiOutlineRedo onClick={() => nextSeconds(10)} />
              </div>
              </div>

              {muted === false && (
                <VolumeControll
                  onMouseLeave={() => setShowControlVolume(false)}
                  className="item-control"
                  primaryColor={primaryColor}
                  percentVolume={volume}
                >
                  {showControlVolume === true && (
                    <div className="volumn-controll">
                      <div className="box-connector" />
                      <div className="box">
                        <input
                          type="range"
                          min={0}
                          max={100}
                          value={volume}
                          onChange={e => setVolumeAction(e.target.value)}
                          title=""
                        />
                      </div>
                    </div>
                  )}

                  {volume >= 60 && (
                    <IoMdVolumeHigh onMouseEnter={() => setShowControlVolume(true)} onClick={() => setMuttedAction(true)} />
                  )}

                  {volume < 60 && volume >= 10 && (
                    <IoMdVolumeLow
                      onMouseEnter={() => setShowControlVolume(true)}
                      onClick={() => setMuttedAction(true)}
                    />
                  )}

                  {volume < 10 && volume > 0 && (
                    <IoMdVolumeMute
                      onMouseEnter={() => setShowControlVolume(true)}
                      onClick={() => setMuttedAction(true)}
                    />
                  )}

                  {volume <= 0 && (
                    <IoMdVolumeOff  onMouseEnter={() => setShowControlVolume(true)} onClick={() => setVolumeAction(0)} />
                  )}
                </VolumeControll>
              )}

              {muted === true && (
                <div className="item-control">
                  <IoMdVolumeOff  onClick={() => setMuttedAction(false)} />
                </div>
              )}
            <hideMobile>
              <div className="item-control info-video">
                <span className="info-first">{titleMedia}</span>
                <span className="info-secund">{extraInfoMedia}</span>
              </div>
              </hideMobile>
            </div>

            <div className="end">

              {/* favorita vídeo */}



              <div className="item-control">
                {favoriteVideo === false ? <AiOutlineAppstoreAdd
                onClick={() => handleClickFavorite(true)}/>:
                 <MdOutlineFileDownloadDone onClick={() => handleClickFavorite(false)}/> }
              </div>


              {/* curte vídeo */}

                 <div className="item-control">

                {likedVideo === false ?

                <AiOutlineHeart
                onClick={() => handleClickLike(true)}/>

                :
                 <AiFillHeart
                 onClick={() => handleClickLike(false)}/> }
              </div>


              {/* Função de próximo vídeo */}
              {onPrevClick && (
                <div className="item-control" onMouseLeave={() => setShowDataNext(false)}>
                    {showDataNext === true && dataNext.title && (
                    <ItemNext>
                      <div>
                        <div className="title">{t('episódio anterior', { lng: playerLanguage })}</div>
                        <div className="item" onClick={onPrevClick}>
                          <div className="bold">{dataNext.title}</div>
                          {dataNext.description && <div>{dataNext.description}</div>}
                        </div>
                      </div>
                      <div className="box-connector" />
                    </ItemNext>
                  )}
                  <AiOutlineStepBackward onClick={onPrevClick} onMouseEnter={() => setShowDataNext(true)} />
                </div>
              )}

              {onNextClick && (
                <div className="item-control" onMouseLeave={() => setShowDataNext(false)}>
                    {showDataNext === true && dataNext.title && (
                    <ItemNext>
                      <div>
                        <div className="title">{t('próximo episódio', { lng: playerLanguage })}</div>
                        <div className="item" onClick={onNextClick}>
                          <div className="bold">{dataNext.title}</div>
                          {dataNext.description && <div>{dataNext.description}</div>}
                        </div>
                      </div>
                      <div className="box-connector" />
                    </ItemNext>
                  )}
                  <AiOutlineStepForward onClick={onNextClick} onMouseEnter={() => setShowDataNext(true)} />
                </div>
              )}

              {/* Legenda */}

              <div className="item-control" onMouseLeave={() => setShowSubtitleList(false)}>
                {showSubtitleList && (
                  <ItemListReproduction>
                    <div>
                      <div className="title">{t('Legenda', { lng: playerLanguage })}</div>
                      <div ref={listSubtitle} className="list-list-reproduction scroll-clean-player">
                        {subtitleList.map((item, index) => (
                          <div
                            className={`item-list-reproduction ${item.id === subtitleSelected ? 'selected' : null}`}
                            onClick={() =>
                               console.log(item)
                            }
                          >
                            <div className="bold">
                              <span style={{ marginRight: 15 }}>{index + 1}</span>
                              {item.idiom}
                            </div>

                            {item.percent && <div className="percent" />}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="box-connector" />
                  </ItemListReproduction>
                )}
                {subtitleList && subtitleList.length > 1 && (
                  <MdOutlineSubtitles onMouseEnter={() => setShowSubtitleList(true)} />
                )}
              </div>

              {/* Fim de Legenda */}

              {qualities && qualities.length > 1 && (
                <div className="item-control" onMouseLeave={() => setShowQuality(false)}>
                  {showQuality === true && (
                    <ItemListQuality>
                      <div>
                        {qualities &&
                          qualities.map(item => (
                            <div
                              onClick={() => {
                                setShowQuality(false);
                                onChangeQuality(item.id);
                              }}
                            >
                              {item.prefix && <span>HD</span>}

                              <span>{item.nome}</span>
                              {item.playing && <FiCheck />}
                            </div>
                          ))}
                      </div>
                      <div className="box-connector" />
                    </ItemListQuality>
                  )}

                  <FaCog onMouseEnter={() => setShowQuality(true)} />
                </div>
              )}

              <div className="item-control">

                {fullScreen === false && <AiOutlineFullscreen onClick={enterFullScreen} />}
                {fullScreen === true && <AiOutlineFullscreenExit onClick={exitFullScreen} />}
              </div>
            </div>
          </div>
        )}
      </Controlls>
    </Container>
  );
}
