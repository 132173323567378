import styled from 'styled-components';
import { Typography, Container, TableRow } from '@material-ui/core';

export const QuestionTitle = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 20px;

    &.clickable {
      padding: 5px;
      border-radius: 4px;
      cursor: pointer;
      border: 2px solid rgba(0, 0, 0, 0);
      &.selected {
        border: 2px solid rgba(0, 0, 0, 0.2);
      }
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
`;

export const StyledContainer = styled(Container)`
color: '#DCDDDF'
  && {
    padding-top: 16px;
  }
`;


export const StyledDiv = styled.div`
  margin: 0;
  padding: 12px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
  background: #FFFFFF;
  color: #141414;
  border: 2px solid #e6e6e6;
  &:hover {
    border: 2px solid rgba(0, 0, 0, 0.1);
  }
`;

export const StyledTableRow = styled(TableRow)`
  &&.subjective {
    cursor: pointer;
    color:#FFFFFF;
    &:hover {
      background-color: #e6e6e6;
    }
  }
`;

export const Alternative = styled.div`
 display:flex;
 margin-bottom: 10px;
`;

export const RadioInput = styled.input`
margin-right: 16px;
`;
