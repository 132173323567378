import styled from 'styled-components';
import { Container } from '@material-ui/core';

export const StyledContainer = styled(Container)`
  padding: 20px;
  height: 100%;
  margin-top: 80px;
  overflow-x:hidden;
  background: #312f30;
  @media (max-width: 600px)
  {
   margin-top:200px;
   margin-bottom:200px;
   max-width: 400px;
  }
`;
