import React, { useState } from 'react';
import { Button, TableContainer, Td, TdTitle, Th, Tr, TrList } from './styles';
import { BsDownload} from 'react-icons/bs';
import GlobalContext from '../../../../context';
import { GET_MY_USER_ROLE_COURSES } from '../../../../services/queries/courses';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../components/Loading/Loading';
import { Alert } from '@material-ui/lab';

const Table = () => {

  const {
    user: { id },
    visualization: { visualization },
  }  =  GlobalContext();

  const [myCourses, setMyCourses] = useState();

  const {refetch} = useQuery(GET_MY_USER_ROLE_COURSES, {
    variables: { id, role: visualization === "Professor" ? "TEACHER" : "STUDENT" },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const courses = data?.getUserById?.userRolesCourses ?? [];
      console.log('USER ROLE  COURSES:', courses)
      console.log('DATA: ', data)
      setMyCourses([...courses]);
    }
  })

  const formatDateToUTCWithBars = (date) => {
    return new Date(new Date(date).toUTCString()).toLocaleDateString("pt-BR", { timeZone: 'UTC' });
};

  if (!myCourses) {
    return <p></p>;
  }

 return (
  <TableContainer>

  {myCourses.length > 0 && <Tr>
    <Th>Curso</Th>
    <Th>Inscrição</Th>
    <Th>Finalizou</Th>
    <Th></Th>
  </Tr>}
  {myCourses.length > 0 ?
  myCourses.map((course) =>
    <TrList>
      <TdTitle>{course.Course.name}</TdTitle>
      <Td>{formatDateToUTCWithBars(course.createdAt)}</Td>
      <Td>-</Td>
      <Td></Td>
    </TrList>) :
    <Alert severity="warning">Nenhum curso ingressado até o momento. Inscreva-se em algum para que apareça aqui!</Alert>
  }
  {/* <TrList>
    <TdTitle>Nome do curso gigantesco bem grande mesmo</TdTitle>
    <Td>15/06/2022</Td>
    <Td>-</Td>
    <Td></Td>
  </TrList>
  <TrList>
    <TdTitle>Multiletramentos</TdTitle>
    <Td>15/06/2022</Td>
    <Td>20/06/2022</Td>
    <Button><BsDownload/> Baixar certificado</Button>
  </TrList>
  <TrList>
    <Td>Robótica em Ação</Td>
    <Td>15/06/2022</Td>
    <Td>20/06/2022</Td>
    <Button><BsDownload/> Baixar certificado</Button>
</TrList> */}
</TableContainer>
 )
};

export default Table;
