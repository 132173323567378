import React from 'react';
import { Input } from '@material-ui/core';
import * as Dialog from '@radix-ui/react-dialog'
import { useState } from 'react';
import GlobalContext from '../../../../context';
import { Close} from '@material-ui/icons';
import { FormItem, Label, Small } from '../styles';
import { NotificationManager } from "react-notifications";
import { CloseButton, Content, Overlay, TransactionType, TransactionTypeButton } from './styles'

export function DialogPassword() {
  const {
    user,
    users: {
      getUsers,
      updateUserPassword,
    },

  } = GlobalContext();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");


  const handleSubmitPassword = async (e) => {
    e.preventDefault();
    if (!oldPassword && !newPassword && !confirmPassword) {
     return NotificationManager.error('Todos os campos são obrigatórios!');
    }
    if (confirmPassword !== newPassword) {
     return NotificationManager.error('A nova senha e a confirmação estão divergentes.');
    }
    if ( oldPassword!== newPassword && newPassword ===confirmPassword) {
      try {
        await updateUserPassword(
          { variables: {
          oldPassword: oldPassword,
          newPassword: newPassword }}
        );
      } catch {
        return NotificationManager.error('Não foi possível atualizar sua senha. Tente novamente.');
      }
    }
    NotificationManager.success('Senha alterada com sucesso');
    getUsers.refetch()
  };


  return (
    <Dialog.Portal>
      <Overlay />

      <Content>
        <Dialog.Title>Mudar Senha</Dialog.Title>
        <CloseButton>
          <Close/>
        </CloseButton>

        <form action="">
        <FormItem>
        <Label>
          Senha antiga
          <Small>(obrigatório)</Small>
        </Label>
        <Input
        required
        type="password"
        name="user.password"
        value={oldPassword}
        onChange={(e) => setOldPassword(e.target.value)}
        style={{color:"#ffffff"}}/>
        </FormItem>
        <FormItem>
        <Label>
          Nova senha
        </Label>
        <Input
        required
        type="password"
        name="user.password"
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        style={{color:"#ffffff"}}/>
        </FormItem>
        <FormItem>
        <Label>
          Confirmar nova senha
        </Label>
        <Input
        required
        type="password"
        name="user.password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        style={{color:"#ffffff"}}/>
        </FormItem>


          <button type='submit' onClick={handleSubmitPassword}>
          Salvar alterações
          </button>
        </form>
      </Content>

    </Dialog.Portal>
  )
}

