import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Grid,
  DialogActions,
  Button,
  Select,
  MenuItem,
  OutlinedInput,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import GlobalContext from '../../../context';

const ModalNewTest = () => {
  const {
    selectedCourse: {
      exercises: {
        getExercises,
        createExercise,
        getCourseModules,
        newTest: {
          selectedModule,
          setSelectedModule,
          description,
          endDate,
          score,
          setDescription,
          setEndDate,
          setScore,
          setStartDate,
          setTitle,
          startDate,
          title,
        },
        newTestDialogOpen,
        setNewTestDialogOpen,
        modalEdit,
        setModalEdit,
        updateExercise,
      },
      course: { id },
    },
  } = GlobalContext();

  useEffect(() => {
    getCourseModules.fetch({
      variables: { id },
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (modalEdit) {
      setTitle(modalEdit.title);
      setDescription(modalEdit.description);
      setStartDate(modalEdit.startDate);
      setEndDate(modalEdit.endDate);
      setScore(modalEdit.score);
      setSelectedModule(modalEdit.module.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalEdit]);

  const clean = () => {
    setTitle('');
    setDescription('');
    setScore('');
    setModalEdit();
  };

  const cleanAndClose = () => {
    clean();
    setNewTestDialogOpen(false);
  };

  const cleanAndSubmit = async () => {
    if (modalEdit) {
      await updateExercise({
        variables: {
          id: modalEdit.id,
          title,
          description,
          score: Number(score),
          startDate,
          endDate,
        },
      });
    } else {
      await createExercise({
        variables: {
          title,
          description,
          score: Number(score),
          startDate,
          endDate,
          module: selectedModule,
        },
      });
    }
    getExercises.refetch();
    clean();
    setNewTestDialogOpen(false);
  };

  const renderGetCourseModules = () => {
    if (!getCourseModules.data) {
      return <MenuItem>Carregando...</MenuItem>;
    }

    return getCourseModules.data.getCourseById.modules.map(i => (
      <MenuItem key={i.id} value={i.id}>
        {i.name}
      </MenuItem>
    ));
  };

  return (
    <Dialog
      open={newTestDialogOpen}
      onClose={() => cleanAndClose()}
      maxWidth="sm"
    >
      <DialogTitle>
        Cadastrar novo teste
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="outlined"
              label="Título"
              fullWidth
              value={title}
              onChange={e => setTitle(e.target.value)}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              label="Descrição"
              fullWidth
              value={description}
              onChange={e => setDescription(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="number"
              variant="outlined"
              label="Quantidade de pontos"
              fullWidth
              value={score}
              onChange={e => setScore(e.target.value)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              ampm={false}
              variant="inline"
              inputVariant="outlined"
              label="Começa em"
              value={startDate}
              disablePast
              onChange={e => setStartDate(e)}
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePicker
              ampm={false}
              variant="inline"
              inputVariant="outlined"
              label="Termina em"
              value={endDate}
              disablePast
              onChange={e => setEndDate(e)}
              fullWidth
              required
            />
          </Grid>
          {!modalEdit && (
            <Grid item xs={12}>
              <Select
                value={selectedModule}
                onChange={e => setSelectedModule(e.target.value)}
                input={<OutlinedInput fullWidth name="institution" id="institution" />}
              >
                {renderGetCourseModules()}
              </Select>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => cleanAndClose()}
            >
            Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => cleanAndSubmit()}
            >
            Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalNewTest;
