import gql from 'graphql-tag';

export const GET_STUDENTS_REPORT = gql`
  query ($CourseId: ID!) {
    getReportStudent (CourseId: $CourseId) {
      fullName
      status
      generalGrade
      lessons {
        name
        lastAccess
        grade
        partialGrade
      }
    }
  }
`;

export const GET_ACTIVITY_REPORT = gql`
  query ($CourseId: ID!) {
    getReportExercise (CourseId: $CourseId) {
      name
      description
      totalAnswered
      quantity
      module {
        id
        name
      }
    }
  }
`;

export const GET_TRAJECTORY_REPORT = gql`
  query ($CourseId: ID!) {
    getReportTrajectory (CourseId: $CourseId) {
      type
      quantity
      students {
        id
        fullName
        status
        generalGrade
        lessons {
          id
          name
          grade
          partialGrade
          lastAccess
        }
      }
    }
  }
`;