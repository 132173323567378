import styled from 'styled-components';
// import { Container } from '@material-ui/core';

export const Link = styled.div`
display: flex;
flex-direction:column;
cursor: pointer;
font-weight: 500;
font-size: 14px;
line-height: 24px;
color: #A6A6A6;
margin-bottom: 30px;
:hover{
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
}
`

export const SideBar = styled.div`
width: 296px;
padding: 40px;
@media(max-width: 920px) {
  display:none;
}
`

export const List = styled.div`
`

export const SideBarContainer = styled.div`
display:flex;
justify-content: space-between;
margin-right:60px;
@media(max-width: 920px) {
  display:none;
`

