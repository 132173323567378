import React from 'react';
import { TableContainer, Td, TdTitle, Th, Tr, TrList } from '../../Certification/Table/styles';

const Table = () => {


 return (
  <TableContainer>

  <Tr>
    <Th>Curso</Th>
    <Th>Título do Teste</Th>
    <Th>Quantidade de pontos </Th>
  </Tr>

    <TrList>
      <TdTitle>Course Name</TdTitle>
      <Td>JavaScript Iniciante</Td>
      <Td>7.0 </Td>
    </TrList>

</TableContainer>
 )
};

export default Table;
