import React, { useState } from 'react';
import { AiOutlineDownload } from 'react-icons/ai';
import { Alert } from '@material-ui/lab';
import {  ContainerTable, Link} from '../styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { StyledTableCell, StyledTableRow } from '../../ExercisesList/styles';

const TableMaterial = ({course}) => {


  const [myMaterials, setMyMaterials] = useState(course.material);
  console.log("data", myMaterials)

  return (

   <TableContainer component={Paper} style={{marginTop:"30px", marginBottom:"30px"}}>

{myMaterials.length > 0 && (

  <Table sx={{ minWidth: 650 }} size="small">
    <TableHead>
      <TableRow style={{background:"#080808"}}>
 <TableCell style={{color: '#DCDDDF'}}> Nome</TableCell>
 <TableCell style={{color: '#DCDDDF'}}> Download </TableCell>
   </TableRow>
    </TableHead>

    <TableBody>
    <StyledTableRow>
    {myMaterials.map((material) => (
      <>
     <StyledTableCell>
     <div className="title">{material.name}</div>
     </StyledTableCell>
     <StyledTableCell>
     <Link href={material.url} download={material.url} target="_blank">
      Click Aqui<AiOutlineDownload size={28}/>
      </Link>
     </StyledTableCell>
     </>
    )
      )}
   </StyledTableRow>
    </TableBody>
  </Table>

  )}

{myMaterials.length === 0 && (<Alert severity="warning">Nenhum material até o momento. </Alert>)}

  </TableContainer>

  )
}

export default TableMaterial;
