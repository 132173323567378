import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import AppBarWrapper from "../../components/AppBarWrapper";
import TabsBar from "../../components/TabsBar";
import { Container, ContainerList } from "./styles";
import MyCourseList from "./MyCourseList";
import CoursesAvailableList from "./CoursesAvailableList";
import MemberList from "./MemberList";
import CourseList from "./CourseList";
import GlobalContext from "../../context";
import Footer from "../../components/Footer";
import Hero from "./Hero";

const Overview = () => {
  const {
    users: {  favoriteUnit, },
    visualization: { visualization },
  } = GlobalContext();


  const isManage = ["Administrador", "Gestor"].includes(visualization);


  return (
    <div style={{backgroundColor: "#080808"}}>
    <AppBarWrapper/>
      <Container maxWidth="md">
        <Hero />
        <ContainerList>
          {/* {!isManage && <MyCourseList />} */}
          <MyCourseList />
          {/* !isManage && visualization === "Estudante" && (
              <CoursesAvailableList tabName="Cursos disponíveis" />
            )*/}

          {/* {isManage && <CourseList tabName="Cursos" />}
          {isManage && <MemberList tabName="Membros" />} */}
        </ContainerList>
        <Footer />
      </Container>
      </div>
  );
};

export default Overview;
