import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  CREATE_OBJECTIVE_QUESTION_ALTERNATIVE,
  UPDATE_OBJECTIVE_QUESTION_ALTERNATIVE,
  REMOVE_OBJECTIVE_QUESTION_ALTERNATIVE,
  CREATE_TRUEFALSE_QUESTION_ALTERNATIVE,
  UPDATE_TRUEFALSE_QUESTION_ALTERNATIVE,
  REMOVE_TRUEFALSE_QUESTION_ALTERNATIVE,
  SET_CORRECT_OBJECTIVE_QUESTION_ALTERNATIVE,
} from '../../../services/mutations/question';

const useModalQuestionAlternatives = () => {
  const [modalQuestionAlternativesOpen, setModalQuestionAlternativesOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState({});
  const [createObjectiveQuestionAlternative] = useMutation(CREATE_OBJECTIVE_QUESTION_ALTERNATIVE);
  const [updateObjectiveQuestionAlternative] = useMutation(UPDATE_OBJECTIVE_QUESTION_ALTERNATIVE);
  const [removeObjectiveQuestionAlternative] = useMutation(REMOVE_OBJECTIVE_QUESTION_ALTERNATIVE);
  const [setCorrectObectiveQuestionAlternative] = useMutation(
    SET_CORRECT_OBJECTIVE_QUESTION_ALTERNATIVE,
  );
  const [createTrueFalseQuestionAlternative] = useMutation(CREATE_TRUEFALSE_QUESTION_ALTERNATIVE);
  const [updateTrueFalseQuestionAlternative] = useMutation(UPDATE_TRUEFALSE_QUESTION_ALTERNATIVE);
  const [removeTrueFalseQuestionAlternative] = useMutation(REMOVE_TRUEFALSE_QUESTION_ALTERNATIVE);

  return {
    modalQuestionAlternativesOpen,
    setModalQuestionAlternativesOpen,
    selectedQuestion,
    setSelectedQuestion,
    createObjectiveQuestionAlternative,
    updateObjectiveQuestionAlternative,
    removeObjectiveQuestionAlternative,
    setCorrectObectiveQuestionAlternative,
    createTrueFalseQuestionAlternative,
    updateTrueFalseQuestionAlternative,
    removeTrueFalseQuestionAlternative,
  };
};

export default useModalQuestionAlternatives;
