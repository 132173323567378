import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { NotificationManager } from 'react-notifications';
import { Add, Delete } from '@material-ui/icons';
import {
  Grid, Typography, TextField, FormControl,
  InputLabel, Select, MenuItem, OutlinedInput,
  Checkbox, FormControlLabel, FormLabel,
} from '@material-ui/core';
import { REMOVE_USERS } from '../../services/mutations/user';
import SpeedDial from '../../components/Helpers/SpeedDial';
import Dialog from '../../components/Helpers/Dialog';
import { StyledContainer } from '../../styles/global';
import AppBarWrapper from '../../components/AppBarWrapper';
import TabsBar from '../../components/TabsBar';
import PersonCard from '../../components/PersonCard';
import GlobalContext from '../../context';
import Footer from '../../components/Footer';

const Users = () => {
  const {
    users: {
      getUsers,
      getRoles,
      getInstitutions,
      createUser,
      updateUser,
    },
  } = GlobalContext();

  const [showModal, setShowModal] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [roles, setRoles] = useState([]);
  const [editing, setEditing] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [removeUsers] = useMutation(REMOVE_USERS(selecteds.length > 0 ? selecteds : ['']));

  useEffect(() => {
    getRoles.fetch();
    getUsers.fetch();
    getInstitutions.fetch();
  }, []);

  const openModalAdd = () => {
    setEditing(false);
    setShowModal(true);
    setSelectedUser({});
    setRoles([]);
  };

  const openModalEdit = (_user) => {
    setEditing(true);
    setShowModal(true);
    setSelectedUser(_user);
    setRoles(_user.roles.map(r => r.id));
  };

  const handleSelectCheckbox = (_user) => {
    if (selecteds.indexOf(_user) >= 0) {
      setSelecteds(selecteds.filter(item => item !== _user));
    } else {
      setSelecteds([...selecteds, _user]);
    }
  };

  const onRemoveUsers = async () => {
    await removeUsers();
    setSelecteds([]);
    setOpenDelete(false);
    getUsers.refetch();
    NotificationManager.success('Operação realizada com sucesso');
  };

  const setValue = (e) => {
    setSelectedUser({ ...selectedUser, [e.target.name]: e.target.value });
  };

  console.log("selectedUser", selectedUser)

  const handleRoleChange = (e, checked) => {
    if (checked) {
      setRoles([...roles, e.target.value]);
    } else {
      setRoles(roles.filter(r => r !== e.target.value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser.email || !roles[0] || !selectedUser.institution
      || (editing && !(selectedUser.fullName && selectedUser.birthDate))) {
      return NotificationManager.error('Todos as informações são obrigatórias');
    }

    if (selectedUser.id) {
      await updateUser({ variables: { ...selectedUser, roles } });
    } else {
      try {
        await createUser({ variables: { ...selectedUser, roles } });
      } catch {
        return NotificationManager.error('Já existe um usuário com esse e-mail');
      }
    }
    setShowModal(false);
    getUsers.refetch();
    return NotificationManager.success('Operação realizada com sucesso');
  };

  const getName = (_user) => {
    if (_user.fullName != null) {
      return _user.fullName;
    }
    return _user.email;
  };

  const renderRoles = () => {
    if (!getRoles.data) {
      return (
        <Typography className="label">
          Carregando...
        </Typography>
      );
    }

    const { data: { getRoles: allRoles } } = getRoles;

    return (
      <FormControl>
        <FormLabel>Selecione os perfis do usuário</FormLabel>
        {allRoles.map(item => (
          <FormControlLabel
            key={item.id}
            label={item.name}
            control={(
              <Checkbox
                key={item.id}
                value={item.id}
                checked={roles.filter(r => r === item.id).length > 0}
                onChange={handleRoleChange}
                color="primary"
              />
            )}
          />
        ))}
      </FormControl>
    );
  };

  const renderInstitutions = () => {
    if (!getInstitutions.data) {
      return (
        <Typography className="label">
          Carregando...
        </Typography>
      );
    }

    const { data: { getInstitutions: institutions } } = getInstitutions;

    if (getInstitutions.error || institutions.length === 0) {
      return (
        <Typography className="label">
          Nenhuma instituição cadastrada
        </Typography>
      );
    }
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="institution">
          Selecione a instituição do usuário
        </InputLabel>
        <Select
          value={(selectedUser.institution && selectedUser.institution.id) || ''}
          onChange={setValue}
          input={<OutlinedInput labelWidth={243} name="institution" id="institution" />}
        >
          {institutions.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderModal = () => (
    <Dialog
      onCancel={() => setShowModal(false)}
      onConfirm={handleSubmit}
      open={showModal}
      title={editing ? 'Editar Usuário' : 'Adicionar Usuário'}
    >
      <Grid container spacing={1} className="padding-children">
        { editing && (
          <Grid item xs={12} sm={12}>
            <TextField
              label="Digite o nome do usuário"
              value={selectedUser.fullName || ''}
              fullWidth
              name="fullName"
              onChange={setValue}
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <TextField
            label="Digite o e-mail do usuário"
            value={selectedUser.email || ''}
            type="email"
            fullWidth
            disabled={editing}
            name="email"
            onChange={setValue}
            variant="outlined"
          />
        </Grid>
        { editing && (
          <Grid item xs={12} sm={12}>
            <TextField
              label="Informe a data de nascimento do usuário"
              value={selectedUser.birthDate || ''}
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="birthDate"
              onChange={setValue}
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {renderRoles()}
        </Grid>
        <Grid item xs={12} sm={12}>
          {renderInstitutions()}
        </Grid>
      </Grid>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog
      onCancel={() => setOpenDelete(false)}
      onConfirm={() => onRemoveUsers()}
      colorConfirm="secondary"
      textConfirm="Remover"
      open={openDelete}
      title="Remover Usuários"
    >
      Você tem certeza que deseja remover os seguintes usuários?
      <ul>
        {selecteds.map(i => (
          <li key={i.id}>{getName(i)}</li>
        ))}
      </ul>
    </Dialog>
  );

  const renderUsers = () => {
    if (!getUsers.data) {
      return (
        <Grid xs={12} item>
          <Typography className="label" component="h2">
            Carregando...
          </Typography>
        </Grid>
      );
    }
    if (getUsers.error) {
      return (
        <Grid xs={12} item>
          <Typography className="label" component="h2">
            {getUsers.error.message}
          </Typography>
        </Grid>
      );
    }
    if (getUsers.length === 0) {
      return (
        <Grid xs={12} item>
          <Typography className="label" component="h2">
            Nenhum usuário adicionado
          </Typography>
        </Grid>
      );
    }
    return (
      <>
        {getUsers.data.getUsers.map(item => (
          <Grid item key={item.id} xs={12} sm={6} md={3}>
            <PersonCard
              user={item}
              checkFunction={handleSelectCheckbox}
              editFunction={openModalEdit}
            />
          </Grid>
        ))}
      </>
    );
  };

  console.log("getUsers",getUsers() )
  console.log('allRoles', getRoles())
  return (
    <>
    <AppBarWrapper/>
      <Grid container>
        <TabsBar title="Usuários">
          <StyledContainer>
            <Grid container spacing={2}>
              {renderUsers()}
              <SpeedDial actions={[
                {
                  icon: <Add />,
                  title: 'Adicionar usuário',
                  onClick: () => openModalAdd(),
                },
                {
                  icon: <Delete />,
                  title: 'Remover usuários',
                  onClick: () => setOpenDelete(true),
                  disabled: selecteds.length === 0,
                },
              ]}
              />
              {renderModal()}
              {renderDeleteDialog()}
            </Grid>
          </StyledContainer>
        </TabsBar>
      </Grid>
      <Footer />
    </>
  );
};

export default Users;
