import React, { useEffect, useState } from "react";
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { Page, PageButton, PageContainer, PaginationContainer, SubSection, Ellipsis } from "./styles";

const Pagination = ({page, setPage, limit, totalNumberOfPages}) => {
    const linesPerPage = [30, 90, 120];
    const [current, setCurrent] = useState(1);
    const [previousDisable, setPreviousDisable] = useState(false);
    const [nextDisable, setNextDisable] = useState(false);
    const [firstPages, setFirstPages] = useState([]);
    const [middlePages, setMiddlePages] = useState([]);
    const [lastPages, setLastPages] = useState([]);

    const generatePagesArray = (totalPages) => {
        let pagesArray = []
        for(let i = 1; i <= totalPages; i++) {
            pagesArray.push(i)
        }
        return pagesArray;
    }

    useEffect(() => {
        setPreviousDisable(true);
        setPage(0);
        if(totalNumberOfPages <= 1){
            setNextDisable(true);
        } else {
            setNextDisable(false);
        }
        setCurrent(1);
    }, [totalNumberOfPages]);

    const goToPreviousPage = () => {
        setPage(page - 1);
        setCurrent(current - 1);
        setNextDisable(false);
        if(page <= 1) {
            setPreviousDisable(true);
        }
    }

    const goToNextPage = () => {
        setPage(page + 1);
        setCurrent(current + 1);
        setPreviousDisable(false);
        if(page + 1 === totalNumberOfPages - 1) {
            setNextDisable(true);
        }
    }

    const goToFirstPage = () => {
        setPage(0);
        setCurrent(1);
        setPreviousDisable(true);
        setNextDisable(false);

    }

    const goToLastPage = () => {
        setPage(totalNumberOfPages-1);
        setCurrent(totalNumberOfPages);
        setPreviousDisable(false);
        setNextDisable(true);
    }

    const setPageByNumber = (pageNumber) => {
        setPage(pageNumber-1);
        setCurrent(pageNumber);
        if(pageNumber === totalNumberOfPages) {
            setPreviousDisable(false);
            setNextDisable(true);
        } else if(pageNumber === 1){
            setPreviousDisable(true);
            setNextDisable(false);
        } else {
            setPreviousDisable(false);
            setNextDisable(false);
        }
    }

    const jumpPagesBackwards = () => {
        setCurrent(current - 3);
        setPage(page - 3);
    }

    const jumpPagesForward = () => {
        setCurrent(current + 3);
        setPage(page + 3);
    }

    const firstEllipsisClicked = () => {
        if(current - 3 >= 1){
            jumpPagesBackwards();
        }
    }

    const secondEllipsisClicked = () => {
        if(current + 3 <= lastPages.at(lastPages.length - 1)){
            jumpPagesForward();
        }
    }

    const onlyEllipsisClicked = () => {
        if (firstPages.includes(current) && current + 3 <= lastPages.at(lastPages.length - 1)) {
            jumpPagesForward();
        } else if (lastPages.includes(current) && current - 3 >= 1) {
            jumpPagesBackwards();
        }
    }

    const controlIndexPages = (numbersPages) =>{

    }

    useEffect(() => {
        if(totalNumberOfPages > 13){
            setFirstPages([1, 2, 3, 4, 5]);
            const initialLastPages = [];
            for(let i = 4; i >= 0; --i){
                initialLastPages.push(totalNumberOfPages - i);
            }
            setMiddlePages([]);
            setLastPages([...initialLastPages]);

            if(current > 4 && current < 8){
                const newFirstPages = [1, 2, 3, 4, 5];
                newFirstPages.push(6);
                if(current >= 6){
                    newFirstPages.push(7);
                }
                if(current >= 7){
                    newFirstPages.push(8);
                }

                setFirstPages([...newFirstPages])
                setMiddlePages([]);
            } else if(current < totalNumberOfPages - 3 && current > totalNumberOfPages - 7){
                const newLastPages = [];

                if(current <= totalNumberOfPages - 6){
                    newLastPages.push(totalNumberOfPages - 7);
                }
                if(current <= totalNumberOfPages - 5){
                    newLastPages.push(totalNumberOfPages - 6);
                }
                newLastPages.push(totalNumberOfPages - 5);

                for(let i = 4; i >= 0; --i){
                    newLastPages.push(totalNumberOfPages - i);
                }

                setLastPages([...newLastPages])
                setMiddlePages([]);
            } else if (current >= 8 && current <= totalNumberOfPages - 7) {
                setMiddlePages([current - 1, current, current + 1]);
            }
        } else {
            const pages = []
            for(let i = 1; i <= totalNumberOfPages; ++i){
                pages.push(i);
            }
            setFirstPages([...pages]);
        }
    }, [current, totalNumberOfPages]);

    return (
        <PaginationContainer>
            <SubSection>
                <p>Linhas por página</p>
                <p style={{fontWeight: 'bold', color: '#1D1D1D'}}>{limit}</p>
            </SubSection>

            <SubSection>
                <p>{current} de {totalNumberOfPages}</p>
            </SubSection>

            <SubSection>
                <PageButton style={{cursor: 'pointer'}} onClick={goToFirstPage} disabled={previousDisable}>
                    <FirstPage />
                </PageButton>

                <PageButton style={{cursor: 'pointer'}} disabled={previousDisable} onClick={goToPreviousPage}>
                    <ChevronLeft />
                </PageButton>

                    <PageContainer>
                        { totalNumberOfPages ?
                        generatePagesArray(totalNumberOfPages).map((page, index) => {
                            const activePage = (current === page);

                            if (firstPages.includes(page) || middlePages.includes(page) || lastPages.includes(page)){
                                return <Page onClick={ () => setPageByNumber(page)}
                                    active={activePage} key={index}>
                                        {page}
                                    </Page>
                            } else if (middlePages.length > 0 && (page === firstPages.at(firstPages.length - 1) + 1)) {
                                return <Ellipsis onClick={() => firstEllipsisClicked()}>...</Ellipsis>;
                            } else if (middlePages.length > 0 && (page === lastPages.at(0) - 1)) {
                                return <Ellipsis  onClick={() => secondEllipsisClicked()}>...</Ellipsis>;
                            } else if (middlePages.length === 0 && page === firstPages.at(firstPages.length - 1) + 1) {
                                return <Ellipsis onClick={() => onlyEllipsisClicked()}>...</Ellipsis>;
                            }
                        }) : null }
                    </PageContainer>

                <PageButton style={{cursor: 'pointer'}} onClick={goToNextPage} disabled={nextDisable}>
                    <ChevronRight />
                </PageButton>

                <PageButton style={{cursor: 'pointer'}} onClick={goToLastPage} disabled={nextDisable}>
                    <LastPage />
                </PageButton>
            </SubSection>
        </PaginationContainer>
    )

}

export default Pagination;

