import React from 'react';
import { PropTypes, func } from 'prop-types';
import {
  IconButton,
  Tooltip,
  Checkbox,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { Edit, Close } from '@material-ui/icons';
import { Container, StyledDialog } from './styles';
import GlobalContext from '../../context';

const TagCard = ({ tag, handleTagChecked }) => {
  const {
    tags: {
      tagCard: {
        description,
        id,
        name,
        setDescription,
        setId,
        setName,
        setShowModal,
        showModal,
        updateTag,
      },
    },
  } = GlobalContext();

  const handleClickModal = () => {
    if (!showModal) {
      setId(tag.id);
      setName(tag.name);
      setDescription(tag.description);
    }
    setShowModal(prev => !prev);
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateTag({
      variables: {
        id,
        name,
        description,
      },
    });
    handleClickModal();
  };

  const renderModal = () => (
    <StyledDialog
      onClose={() => false}
      aria-labelledby="customized-dialog-title"
      open={showModal}
    >
      <form onSubmit={handleSubmit} noValidate autoComplete="off">
        <DialogTitle
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          disableTypography
          id="edit-tag-dialog-title"
          className="titleModal"
          onClose={handleClickModal}
        >
          <Typography variant="h5">Editar TAG</Typography>
          <IconButton aria-label="Close" className="closeButton" onClick={handleClickModal}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-name"
                label="Digite o nome do Tag"
                InputLabelProps={{ shrink: true }}
                value={name}
                fullWidth
                onChange={handleNameChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-description"
                label="Digite a descrição do Tag"
                InputLabelProps={{ shrink: true }}
                value={description}
                fullWidth
                onChange={handleDescriptionChange}
                margin="normal"
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickModal} variant="contained" color="default">
            Cancelar
          </Button>
          <Button
            type="submit"
            onClick={e => handleSubmit(e, updateTag)}
            variant="contained"
            color="primary"
          >
              Salvar
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );

  return (
    <Container>
      <div className="wrapper">
        <Checkbox
          className="checkbox"
          onChange={(event) => {
            const selected = event.target.checked;
            handleTagChecked(tag.id, tag.name, selected);
          }}
          value="tagCheckbox"
          color="secondary"
          inputProps={{
            'aria-label': 'secondary checkbox',
          }}
        />

        <div className="infoContainer">
          <span className="name">{tag.name}</span>
          <span className="description">{tag.description}</span>
        </div>
      </div>

      <Tooltip title="Editar">
        <IconButton className="editButton" onClick={handleClickModal}>
          <Edit />
        </IconButton>
      </Tooltip>
      {renderModal()}
    </Container>
  );
}; //  END

TagCard.propTypes = {
  tag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  handleTagChecked: func.isRequired,
};

export default TagCard;
