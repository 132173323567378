import React from 'react';
import ProfileData from './ProfileData/ProfileData';

const Profile = () => {

    return (
      <>
    <ProfileData/>
      </>
);
}

    export default Profile;
