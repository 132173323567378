import gql from 'graphql-tag';

export const GET_COURSE_FORUM = gql`
  query($id: ID!) {
    getCourseById(id: $id) {
      forum {
        id,
        name,
        description,
        disabled,
        topics {
          id,
          name,
          description,
          creator {
            id,
            fullName,
          }
          unit {
            id
          }
          fixed,
          createdAt,
          updatedAt,
        }
        createdAt,
        updatedAt,
      }
    }
  }
`;
