import styled from 'styled-components';

export const Container = styled.div`
  flex-grow: 1;
  width: 100%;

  .MuiTab-root {
    min-height: 0;
    color: #EDEDED;
  }

  .MuiTabs-root {
    min-height: 0;
  }

  .tabsBar {
    box-shadow: none;
    margin: 18px 0;
    background-color: #080808;
    color: #FFF;
  }

  .title {
    flex-grow: 1;
    font-size: 2rem;

    @media (max-width: 500px) {
      font-size: 1.3rem;
    }
  }

  .header {
    align-items: center;
  }

  .button {
    margin-left: 5px;
    transition: filter 0.2;
    &:hover{
    filter: brightness(0.9)
    }
  }

  .sectionDesktop {
    display: none;
    @media (min-width: 800px) {
      display: flex;
    }
  }
  .sectionMobile {
    display: flex;
    @media (min-width: 800px) {
      display: none;
    }
  }
`;
