import { useMutation } from '@apollo/react-hooks';
import { useState } from 'react';
import { GET_ALGORITHM_BANK } from '../../../services/queries/courses';
import useManualQuery from '../../useManualQuery';
import { CREATE_ALGORITHM_BANK } from '../../../services/mutations/course';
import {
  CREATE_OBJECTIVE_QUESTION,
  UPDATE_OBJECTIVE_QUESTION,
  REMOVE_OBJECTIVE_QUESTION,
  CREATE_TRUEFALSE_QUESTION,
  UPDATE_TRUEFALSE_QUESTION,
  REMOVE_TRUEFALSE_QUESTION,
  CREATE_SUBJECTIVE_QUESTION,
  UPDATE_SUBJECTIVE_QUESTION,
  REMOVE_SUBJECTIVE_QUESTION,
} from '../../../services/mutations/question';
import {
  GET_OBJECTIVE_QUESTION_BY_ID,
  GET_TRUEFALSE_QUESTION_BY_ID,
  GET_SUBJECTIVE_QUESTION_BY_ID,
} from '../../../services/queries/questions';
import useModalQuestionAlternatives from './useModalQuestionAlternatives';
import useModalAddTagToQuestion from './useModalAddTagToQuestion';

const useQuestionBank = () => {
  const getAlgorithmBank = useManualQuery(GET_ALGORITHM_BANK);
  const getObjectiveQuestionById = useManualQuery(GET_OBJECTIVE_QUESTION_BY_ID);
  const getTrueFalseQuestionById = useManualQuery(GET_TRUEFALSE_QUESTION_BY_ID);
  const getSubjectiveQuestionById = useManualQuery(GET_SUBJECTIVE_QUESTION_BY_ID);
  const [createAlgorithmBank] = useMutation(CREATE_ALGORITHM_BANK);
  const [modalQuestionOpen, setModalQuestionOpen] = useState(false);
  const [modalRemoveQuestionOpen, setModalRemoveQuestionOpen] = useState(false);
  const [questionType, setQuestionType] = useState('');
  const [question, setQuestion] = useState('');
  const [selectedQuestionBank, setSelectedQuestionBank] = useState();
  const [createObjectiveQuestion] = useMutation(CREATE_OBJECTIVE_QUESTION);
  const [updateObjectiveQuestion] = useMutation(UPDATE_OBJECTIVE_QUESTION);
  const [removeObjectiveQuestion] = useMutation(REMOVE_OBJECTIVE_QUESTION);
  const [createTrueFalseQuestion] = useMutation(CREATE_TRUEFALSE_QUESTION);
  const [updateTrueFalseQuestion] = useMutation(UPDATE_TRUEFALSE_QUESTION);
  const [removeTrueFalseQuestion] = useMutation(REMOVE_TRUEFALSE_QUESTION);
  const [createSubjectiveQuestion] = useMutation(CREATE_SUBJECTIVE_QUESTION);
  const [updateSubjectiveQuestion] = useMutation(UPDATE_SUBJECTIVE_QUESTION);
  const [removeSubjectiveQuestion] = useMutation(REMOVE_SUBJECTIVE_QUESTION);
  const modalQuestionAlternatives = useModalQuestionAlternatives();
  const modalAddTagToQuestion = useModalAddTagToQuestion();

  return {
    getAlgorithmBank,
    getObjectiveQuestionById,
    getTrueFalseQuestionById,
    getSubjectiveQuestionById,
    createAlgorithmBank,
    modalQuestionOpen,
    setModalQuestionOpen,
    modalRemoveQuestionOpen,
    setModalRemoveQuestionOpen,
    questionType,
    setQuestionType,
    selectedQuestionBank,
    setSelectedQuestionBank,
    question,
    setQuestion,
    createObjectiveQuestion,
    updateObjectiveQuestion,
    removeObjectiveQuestion,
    createTrueFalseQuestion,
    updateTrueFalseQuestion,
    removeTrueFalseQuestion,
    createSubjectiveQuestion,
    updateSubjectiveQuestion,
    removeSubjectiveQuestion,
    modalQuestionAlternatives,
    modalAddTagToQuestion,
  };
};

export default useQuestionBank;
