import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  @media(max-width: 600px) {
    padding: 30px 15px;
  }
  @media(max-width: 375px) {
    padding: 50px 50px;
  }


`;

export const Linha = styled.hr`
  width: 22vw;
  border: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    rgb(232, 72, 16, 0.5),
    rgb(20, 20, 20, 0.6)
  );
  border-image-slice: 1;
`;

export const Linha2 = styled.hr`
  width: 19vw;
  border: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    rgb(232, 72, 16, 0.5),
    rgb(20, 20, 20, 0.6)
  );
  border-image-slice: 1;
`;

export const CardFlexEpisodes = styled.div`
  width: 250px;
  height: 400px;
  padding: 20px 15px;
  margin-right:0px;
  @media(max-width: 600px) {
    padding: 20px 15px;
    margin-right:0px;
    margin-left:0px;
  }

`;
export const GridVideo = styled.div`
  height: 170px;
  border: 1px solid #f4f4f4;
  width: 250px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 14px;
  background: url("${(props) => props.folder}");
  background-size: cover;
  @media(max-width: 440px) {
  }
`;

export const ButtonPlay = styled.div``;
export const TitleEpisode = styled.h3`
  padding: 20px 0px;
`;
export const DescriptionEpisode = styled.div`
  text-align: justify;
  padding-bottom: 20px;
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  @media(max-width: 440px) {
    flex-direction: column;
  }

`;

export const Watch = styled.div`
  display: flex;
  align-items: center;
`;
export const TimeEpisode = styled.div`
  display: flex;
  align-items: center;
`;
export const ProgressVideo = styled.div``;

export const ContainerSubscribed = styled.div`
padding-right: 40px;
padding-left: 0px;
@media(max-width: 940px) {
  padding-right: 70px;
  padding-left: 10px;
  margin-right:0px;
}
@media(max-width: 600px) {
  padding-right: 40px;
  padding-left: 40px;
  margin-right:0px;
}
`


export const ContainerList = styled.div`
padding-left: 0px;
padding-bottom: 0px;
padding-right: 120px;
padding-top:30px;
margin: 20px;
margin-top:30px;
margin-bottom:30px;
  @media(max-width: 1300px) {
    margin:0px;
  };
  @media(max-width: 900px) {
    padding-right: 0px;

  }`
  export const LessonDesktop = styled.div`
  display: block;
  @media(max-width: 600px) {
    display: none;
  }

`;

export const LessonMobile = styled.div`
  display: none;
  @media(max-width: 600px) {
    display:block;
  }

`;
