import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import { Redirect, useHistory } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import GlobalContext from "../../context";
import AppBarWrapper from "../../components/AppBarWrapper";
import { ReactComponent as Livre } from '../../assets/icons/faixa_livre.svg';
import { ReactComponent as DezAnos } from '../../assets/icons/faixa_10.svg';
import { ReactComponent as DozeAnos } from '../../assets/icons/faixa_12.svg';
import { ReactComponent as QuatorzeAnos } from '../../assets/icons/faixa_14.svg';
import { ReactComponent as DeseseisAnos } from '../../assets/icons/faixa_16.svg';
import { ReactComponent as DesoitoAnos } from '../../assets/icons/faixa_18.svg';
import { Container } from "../overview/styles";
import Footer from "../../components/Footer";
import SubscribedEpisodes from "./EpisodesAndExtras/SubscribedEpisodes";
import VideoExtra from "./EpisodesAndExtras/VideoExtra";
import {
  BackgroundCourses,
  ContainerFlexComponents,
} from "./styles";
import { ButtonMobile } from "../overview/Hero/styles";
import { Button, ButtonClose, ButtonCloseMobile, ButtonGroup, ButtonGroupDesktop, ButtonGroupMobile, ContainerHomeCourse, ContainerInfo, CourseInfo, CourseInfoMobile } from "./styles";
import Dialog from "../../components/Helpers/Dialog";
import { ADD_COURSE_SUBSCRIPTION } from "../../services/mutations/course";
import MaterialCourse from "./MaterialCourse";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import FormatAlignLeftIcon from "@material-ui/icons/FormatAlignLeft";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { GET_MY_COURSES_BY_USER, GET_COURSE_CURRENT_UNIT } from "../../services/queries/courses";

const Course = () => {
  const {
    selectedCourse: {
      course,
      courseAccess,
      setCourseAccess,
      getModulesByCourse,
      getMyCoursesByUser,
      getCoursesAvailable,
    },
    selectedLesson: { lesson, setLesson, start, setStart },
    selectedTrailer: { trailer, setTrailer},
    visualization: { visualization },
  } = GlobalContext();

  const history = useHistory();

  console.log(courseAccess)

  const [getMyCourses] = useLazyQuery(GET_MY_COURSES_BY_USER);

  const {data: dataCurrentUnit, loading: loadingCurrentUnit} = useQuery(GET_COURSE_CURRENT_UNIT, {
    variables: {
      CourseId: course?.id
    },
    onCompleted: e => console.log(e),
    onError: e => console.log(e)
  });

  console.log("course",course)

  useEffect(() => {
    if (course) {
      getModulesByCourse.fetch({
        variables: { id: course.id, filter: visualization === "Estudante" },
      });
    }
  }, [course]);

  console.log("trailer", course.trailer)

  const [addCourseSubscription] = useMutation(ADD_COURSE_SUBSCRIPTION);
  const [openAddCourseSubscription, setOpenAddCourseSubscription] =useState(false);

    const confirmAddCourseSubscription = async () => {
      try {
        const response = await addCourseSubscription({ variables: { id: course.id } });
        if(response.data.addCourseSubscription.id){
          setCourseAccess(true);
          getMyCoursesByUser.refetch();
          getCoursesAvailable.refetch();
          NotificationManager.success("Inscrição realizada com sucesso");
        } else {
          NotificationManager.error(
            "Não foi possível realizar a solicitação, aparentemente você já teve uma solicitação recusada."
          );
        }

      } catch (e) {
        NotificationManager.error(
          "Não foi possível realizar a solicitação, aparentemente você já teve uma solicitação recusada."
        );
      } finally {
        setOpenAddCourseSubscription(false);
      }
    };


  if (!course) {
    return <Redirect to="/" />;
  }

  if (!getModulesByCourse.data) {
    return "Carregando...";
  }

  const {
    data: {
      getCourseById: { modules },
    },
  } = getModulesByCourse;

  if (!course) {
    return <Redirect to="/" />;
  }

  const isManage = visualization !== "Estudante";

  const selectLesson = (_lesson) => {
    setLesson(_lesson);
    history.push("/lesson");
  };

  const watchFroThemBeginning = () => {
    console.log(modules[0].units[0])
    if (modules && modules[0] && modules[0].units && modules[0].units[0]) {
      setStart(true)
      selectLesson(modules[0].units[0]);
    }
  };

  const watchTrailer = () => {
    console.log(course.trailer)
    if (course.trailer) {
      setTrailer(course.trailer);
      history.push("/trailer");
    }
  };

  const continueWatching = () => {
    setStart(false)
    selectLesson(dataCurrentUnit?.getCourseCurrentUnitHistoric?.unit)
  }

  return (
    <>
   <AppBarWrapper/>
   <div>

      <BackgroundCourses backgroundUrl={course.thumbnail} backgroundUrlMobile={course.folder}>
        <Container maxWidth="md">
          <ContainerFlexComponents>
            {/* Home */}
          <ContainerHomeCourse>
            <ContainerInfo>
              <CourseInfo>
                <h1 style={{ color: "#f4f4f4", paddingBottom: "15px", textAlign:"start" }}>
                  {course.name}
                </h1>
                {course.age == "L" || course.age == null ? <Livre style={{display: 'flex'}}/> : null}
                {course.age == "10" ? <DezAnos style={{display: 'flex'}}/> : null}
                {course.age == "12" ? <DozeAnos style={{display: 'flex'}}/> : null}
                {course.age == "14" ? <QuatorzeAnos style={{display: 'flex'}}/> : null}
                {course.age == "16" ? <DeseseisAnos style={{display: 'flex'}}/> : null}
                {course.age == "18" ? <DesoitoAnos style={{display: 'flex'}}/> : null}
                <br></br>
                <strong style={{ color: "#FF9466" }}>{course.year}</strong>
                <br></br>
                <p style={{ color: "#f4f4f4", padding: "23px 0px" }}>
                  {course.description}
                </p>
                <br></br>
              </CourseInfo>
              <ButtonCloseMobile
              style={{
                backgroundColor: "rgb(0, 0, 0,0.7)",
                color: "#f4f4f4",
              }}
            >

            </ButtonCloseMobile>


                <ButtonGroupDesktop>


              {courseAccess ? dataCurrentUnit?.getCourseCurrentUnitHistoric?.unit ? (
                <ButtonGroup>
                  <Button
                    onClick={continueWatching}
                    style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
                  >
                    <PlayArrowOutlinedIcon style={{ paddingRight: "5px" }} />
                    <strong>Continuar Assistindo</strong>
                  </Button>
                  <Button
                    style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                    onClick={watchFroThemBeginning}
                  >
                    <SlideshowIcon style={{ paddingRight: "5px" }} />{" "}
                    <p>Assistir do Início</p>
                  </Button>
                  <Button
                    style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                  >
                    <FormatAlignLeftIcon style={{ paddingRight: "5px" }} />{" "}
                    <p>Mais informações</p>
                  </Button>
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  <Button
                    onClick={watchFroThemBeginning}
                    style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
                  >
                    <PlayArrowOutlinedIcon style={{ paddingRight: "5px" }} />
                    <strong>Assistir</strong>
                  </Button>
                  <Button
                    style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                  >
                    <FormatAlignLeftIcon style={{ paddingRight: "5px" }} />{" "}
                    <p>Mais informações</p>
                  </Button>
                </ButtonGroup>
              ) : (
                <ButtonGroup>
                  <Button
                    onClick={() => setOpenAddCourseSubscription(true)}
                    style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
                  >
                    <strong>Inscrever-se</strong>
                  </Button>
                  {course.trailer ? (
                  <Button
                style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                onClick={watchTrailer}
              >
                <SlideshowIcon style={{ paddingRight: "5px" }} />
                <p>Ver trailer</p>
              </Button>
                  ): null}
                </ButtonGroup>
              )}




              </ButtonGroupDesktop>
              <ButtonGroupMobile>



               {courseAccess ? (
            <ButtonGroup>

              <ButtonMobile
                style={{ color: "#A6A6A6" }}
              >
                <SlideshowIcon />
                <p>Continuar Assistindo</p>
              </ButtonMobile>

              <Button
                onClick={watchFroThemBeginning}
                style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
              >
                <PlayArrowOutlinedIcon />
                <strong>Assistir do inicio</strong>
              </Button>

              <ButtonMobile
                style={{ color: "#A6A6A6" }}>
                <AddIcon/>
                <p>Saiba mais</p>
              </ButtonMobile>
            </ButtonGroup>
           ) : (
            <ButtonGroup>
               <ButtonMobile
                style={{ color: "#A6A6A6" }}
              >
                <SlideshowIcon />
                <p>Ver trailer</p>
              </ButtonMobile>
             <Button
                onClick={() => setOpenAddCourseSubscription(true)}
                style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
              >
                <PlayArrowOutlinedIcon />
                <strong>Inscrever-se</strong>
              </Button>

              <ButtonMobile
                style={{ color: "#A6A6A6" }}>
                <AddIcon/>
                <p>Saiba mais</p>
              </ButtonMobile>
            </ButtonGroup>
          )}
              </ButtonGroupMobile>


            </ContainerInfo>

            <ButtonClose
              style={{
                backgroundColor: "rgb(0, 0, 0,0.7)",
                color: "#f4f4f4",
              }}
            >
              <CloseIcon
                style={{ color: "red", cursor: "pointer" }}
                onClick={() => {
                  history.push("/");
                }}
              />{" "}
              <p>Fechar</p>
            </ButtonClose>

          </ContainerHomeCourse>

          <CourseInfoMobile>
                <h2 style={{ color: "#f4f4f4",
                 paddingBottom: "15px",
                 paddingTop: "20px",
                 textAlign:"start" }}>
                  {course.name}
                </h2>
                <br></br>
                <strong style={{ color: "#e84810" }}>{course.year}</strong>
                <br></br>
                <p style={{ color: "#f4f4f4", padding: "23px 0px" }}>
                  {course.description}
                </p>
                <br></br>
              </CourseInfoMobile>

              {courseAccess ? (
                <>
            <SubscribedEpisodes  />
            </> ) : null}

            <VideoExtra/>

            {courseAccess ? (
                <>
            <MaterialCourse/>
            </> ) : null}

          </ContainerFlexComponents>
          <Dialog
            onCancel={() => setOpenAddCourseSubscription(false)}
            onConfirm={confirmAddCourseSubscription}
            colorConfirm="secondary"
            textConfirm="Ingressar"
            open={openAddCourseSubscription}
            title={"Inscrever-se"}
          >
            {"Deseja ingressar neste curso?"}
          </Dialog>
        </Container>
      </BackgroundCourses>
      <Footer />
  </div>
  </>
  );
};

export default Course;
