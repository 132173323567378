import React, { useEffect, useState } from "react";
import SearchBar from "../components/searchbar";
import Add from '@material-ui/icons/Add';
import {MdOutlineVideoCall} from 'react-icons/md';
import { ButtonModal, ColumnRight, ContainerSearchBar, TitleSearch } from "../components/searchbar/styles";
import MembersTable from "./MembersTable";
import GlobalContext from "../../../context";
import { Container } from "./styles";

const PublisherMembers = () => {




    return (
              <Container>
                <MembersTable/>
                </Container>
)}
export default PublisherMembers;
