import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { CategoryInputData, TextArea, DataTitle, FormEpisodes,StyledFormControlLabel, FlexData, FlexDataInput, Input, InputContainer, Small, TextAreaContainer, Count } from '../styles';
import { ReactComponent as Livre } from '../../../../../assets/icons/faixa_livre.svg';
import { ReactComponent as DezAnos } from '../../../../../assets/icons/faixa_10.svg';
import { ReactComponent as DozeAnos } from '../../../../../assets/icons/faixa_12.svg';
import { ReactComponent as QuatorzeAnos } from '../../../../../assets/icons/faixa_14.svg';
import { ReactComponent as DeseseisAnos } from '../../../../../assets/icons/faixa_16.svg';
import { ReactComponent as DesoitoAnos } from '../../../../../assets/icons/faixa_18.svg';
import { FormHelperText } from "@material-ui/core";
import { UploadContext } from '../index'
import { useForm, Controller } from 'react-hook-form';
import { MdToday } from 'react-icons/md';


const FormDetails = ({errors, control}) =>{
  const [data, setData] = useContext(UploadContext);
  const [count, setCount] = useState(0);

  const handleName = (e) => {
    e.preventDefault();
     setCount(e.target.value.length);
   };

  return(
    <>
        <FormEpisodes style={{marginLeft: "0%"}}>
          <label>
            Nome da temporada <Small>(Obrigatório)</Small>
          </label>
           <TextAreaContainer>
          <Controller
            name="nameModule"
            control={control}
            defaultValue={data?.nameModule}
            render={props => (
             <TextArea
                type="text"
                defaultValue={data?.nameModule}
                maxLength="100"                
                placeholder='Insira aqui o título do conteúdo'
                name="nameModule"
                onChange={(e)=>{setData({...data, nameModule: e.target.value});props.field.onChange(e.target.value)}}
              />
            )}
          />
             <FormHelperText error={Boolean(errors?.nameModule)}>
            {errors?.nameModule?.message}
            </FormHelperText> 
              <Count>
                <span>{count}</span>/500
              </Count>
            </TextAreaContainer>
             {/* <label>
            Diretor(es) <Small>(Obrigatório)</Small>
          </label>
          <select>
            <option></option>
          </select>
          <label>
            Elenco <Small>(Obrigatório)</Small>
          </label>
          <select  >
            <option></option>
          </select>
          <label>Faixa etária
          <Small>(Obrigatório)</Small></label>

                <FormControl style={{alignSelf:"space-between"}}>
                    <RadioGroup
                        
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                    >
                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="L" control={<Radio />} label=""/>
                            <Livre style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="10" control={<Radio />} label=""/>
                            <DezAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="12" control={<Radio />} label=""/>
                            <DozeAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="14" control={<Radio />} label=""/>
                            <QuatorzeAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="16" control={<Radio />} label=""/>
                            <DeseseisAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="18" control={<Radio />} label=""/>
                            <DesoitoAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>
                    </RadioGroup>
                </FormControl>
          <label>
          Seção | Categoria <Small>(Obrigatório)</Small>
          </label>
          <FormGroup required name="tags" >
          <FormControlLabel control={<Checkbox  />} label="Cursos Tecnológicos" />
          <FormControlLabel control={<Checkbox  />} label="Temas Transversais" />
          <FormControlLabel control={<Checkbox />} label="Capacitação de Professores" />
          </FormGroup> 
           <label>
            Ano de lançamento <Small>(Obrigatório)</Small>
          </label>
          <Input type="text"  name="year" placeholder='Ano de lançamento. Ex.: 2020'/>
          <label>
            Data da publicação <Small>(Obrigatório)</Small>
          </label>
          <Input type="date"  name="createdAt"/>

          <label>
            Período de destaque <Small>(Obrigatório)</Small>
          </label>
          <FlexData>
              <FlexDataInput>
                <DataTitle>De</DataTitle>
                <InputContainer>
                  <CategoryInputData
                    type="date"
                    name="startDate"
                    
                  />
                </InputContainer>
              </FlexDataInput>

              <FlexDataInput>
                <DataTitle>Até</DataTitle>
                <InputContainer>
                  <CategoryInputData
                    type="date"
                    name="endDate"
                    
                  />
                </InputContainer>
              </FlexDataInput>
            </FlexData> */}

        </FormEpisodes>

    </>
  )

}
export default FormDetails;
