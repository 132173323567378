import { Link } from "react-router-dom";
import styled from "styled-components";

export const FooterContainer = styled.footer`
  width: 100%;
  background-color:  #312F30;
  color: #c6c6c6;
  display: flex;
  justify-content: space-between;
  padding: 30px 60px;
  margin-top:30px;
  box-sizing: border-box;
  bottom: 0;
  overflow-x: hidden;
  @media(max-width: 700px) {
    flex-direction: column;
    align-items:start;
  }
`;

export const UpperRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
`;
export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media(max-width: 820px) {
    flex-direction: column;

  }
  @media(max-width: 664px) {
    flex-direction: column;
    padding-top:30px;
  }
`;
export const Column = styled.div`
display: flex;
flex-direction: column;
`
export const FooterLink = styled(Link)`
  text-decoration: none;
  color: #c6c6c6;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  padding-bottom:15px;
  &:hover {
    color: #ff4c00;
  }
`;
export const SocialNetworksContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  min-width: fit-content;
  @media(max-width: 820px) {
    padding-top:30px;
    flex-direction: column;
  }
`;

export const SocialNetworksIconsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 820px) {
    padding-top:30px;
    flex-direction: row;
  }
`;

export const SocialNetworksTitle = styled.div`
@media(max-width: 1028px) {
  display:none;
}
`

export const LowerRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  h3 {
    color: #fff;
  }
  p {
    font-size: 0.75rem;
  }
  @media(max-width: 700px) {
    flex-direction: column;
    text-align: start;
  }
`;
