import styled from "styled-components";

export const ContainerTable = styled.div`
display:flex;
justify-content: center;
margin-top:40px;
@media (max-width: 600px)
{
 margin-top:200px;
 margin-bottom:200px;
 margin-left:16px;
}
`

export const TableContainer = styled.table`
margin-right:60px;
border:none;
border-collapse:collapse;
margin-bottom:120px;
`

export const Link = styled.a`
text-decoration:none;
display: flex;
align-items: center;
justify-content:start;
color: #080808;
`
