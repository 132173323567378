import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useManualQuery from '../useManualQuery';
import useExercise from './exercise';
import useMembers from './members';
import { CREATE_COURSE, UPDATE_COURSE, REMOVE_COURSES, SET_PUBLIC, REMOVE_COURSE } from '../../services/mutations/course';
import useQuestionBank from './questionBank';
import {
  GET_COURSES_BY_INSTITUTION,
  GET_MY_COURSES_BY_USER,
  GET_COURSES_AVAILABLE,
  GET_COURSE_SUBSCRIPTIONS,
  GET_COURSES,
} from '../../services/queries/courses';
import { GET_MODULES_BY_COURSE } from '../../services/queries/module';

const useCourse = () => {
  const [course, setCourse] = useState();
  const exercises = useExercise();
  const members = useMembers();
  const [openDialog, setOpenDialog] = useState(false);
  const [selecteds, setSelecteds] = useState([]);
  const [createCourse] = useMutation(CREATE_COURSE);
  const [updateCourse] = useMutation(UPDATE_COURSE);
  const [setPublic] = useMutation(SET_PUBLIC);
  const [removeCourses] = useMutation(REMOVE_COURSES(selecteds.length > 0 ? selecteds : ['']));
  const [removeCourse] = useMutation(REMOVE_COURSE);
  const questionBank = useQuestionBank();
  const getCoursesByInstitution = useManualQuery(GET_COURSES_BY_INSTITUTION);
  const getCourseSubscriptions = useManualQuery(GET_COURSE_SUBSCRIPTIONS);
  const getModulesByCourse = useManualQuery(GET_MODULES_BY_COURSE);
  const getCourses = useManualQuery(GET_COURSES);
  const getMyCoursesByUser = useManualQuery(GET_MY_COURSES_BY_USER, "no-cache");
  const getCoursesAvailable = useManualQuery(GET_COURSES_AVAILABLE);
  const [courseAccess, setCourseAccess] = useState(false);

  return {
    course,
    setCourse,
    exercises,
    members,
    openDialog,
    setOpenDialog,
    selecteds,
    setSelecteds,
    createCourse,
    updateCourse,
    setPublic,
    removeCourses,
    removeCourse,
    questionBank,
    getCoursesByInstitution,
    getModulesByCourse,
    getMyCoursesByUser,
    getCoursesAvailable,
    getCourseSubscriptions,
    courseAccess,
    setCourseAccess,
    getCourses,
  };
};

export default useCourse;
