import { useMutation, useQuery } from "@apollo/react-hooks";
import {
  Grid, Typography, TextField, FormControl,
  InputLabel, Select, MenuItem, OutlinedInput,
  Checkbox, FormControlLabel, FormLabel, Tooltip, IconButton,
} from '@material-ui/core';
import { Delete, Edit } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import Dialog from "../../../../components/Helpers/Dialog";
import SpeedDial from "../../../../components/Helpers/SpeedDial";
import PersonCard from "../../../../components/PersonCard";
import GlobalContext from "../../../../context";
import { REMOVE_USERS } from "../../../../services/mutations/user";
import Pagination from "../../components/pagination";
import {
  bool, func, shape, string,
} from 'prop-types';
import { ColumnTitle, TableComponent, TableContainer, TableData } from "../../contents/ContentTable/styles";
import { ButtonModal, ColumnRight, ContainerSearchBar, TitleSearch } from "../../components/searchbar/styles";
import SearchBar from "../../components/searchbar";
import Add from '@material-ui/icons/Add';
import {MdOutlineVideoCall} from 'react-icons/md';
import { GET_USERS } from "../../../../services/queries/user";
import { ContainerDashboard } from "../../dashboard/styles";
import LoadingAdmin from "../../../../components/Loading/LoadingAdmin";


const MembersTable = () => {


  const {
    user,
    users: {
      getUsers,
      getRoles,
      getInstitutions,
      createUser,
      updateUser,
    },
  } = GlobalContext();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const columnTitles = ['Nome', 'Email', 'Perfil', ''];
    const [totalNumberOfPages, setTotalNumberOfPages] = useState(0);
    const [currentContent, setCurrentContent] = useState();
    const [showModal, setShowModal] = useState(false);
    const [selecteds, setSelecteds] = useState([]);
    const [roles, setRoles] = useState([]);
    const [editing, setEditing] = useState(true);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedUser, setSelectedUser] = useState('');
    const [searchedString, setSearchedString] = useState('');
    const [currentMembers, setCurrentMembers] = useState();
    const [showRowOptions, setShowRowOptions] = useState([]);
    const [removeUsers] = useMutation(REMOVE_USERS(selecteds.length > 0 ? selecteds : ['']));


    useEffect(() => {
    getRoles.fetch();
    getUsers.fetch();
    getInstitutions.fetch();
  }, []);


  useEffect(()=> {
    getUsers.fetch({variables: { fullName: searchedString }})
  }, [searchedString])

  const openModalAdd = () => {
    setEditing(false);
    setShowModal(true);
    setSelectedUser({});
    setRoles([]);
    getUsers.refetch();
  };

  const openModalEdit = (_user) => {
    setEditing(true);
    setShowModal(true);
    setSelectedUser(_user);
    setRoles(_user.roles.map(r => r.id));
  };

  const openModalRemove = (member) => {
    console.log(member)
    setSelecteds([...selecteds, member])
    setOpenDelete(true)
  };

  const handleSelectCheckbox = (_user) => {
    if (selecteds.indexOf(_user) >= 0) {
      setSelecteds(selecteds.filter(item => item !== _user));
    } else {
      setSelecteds([...selecteds, _user]);
    }
  };


  const onRemoveUsers = async () => {
    console.log(selecteds)
    await removeUsers();
    setSelecteds([]);
    setOpenDelete(false);
    getUsers.refetch();
    NotificationManager.success('Operação realizada com sucesso');
  };

  const setValue = (e) => {
    setSelectedUser({ ...selectedUser, [e.target.name]: e.target.value });
  };

  console.log("selectedUser", selectedUser)

  const handleRoleChange = (e, checked) => {
    if (checked) {
      setRoles([...roles, e.target.value]);
    } else {
      setRoles(roles.filter(r => r !== e.target.value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser.email || !roles[0] || !selectedUser.institution
      || (editing && !(selectedUser.fullName && selectedUser.birthDate))) {
      return NotificationManager.error('Todos as informações são obrigatórias');
    }

    if (selectedUser.id) {
      await updateUser({ variables: { ...selectedUser, roles ,
         institution: selectedUser.institution.id} });
    } else {
      try {
        await createUser({ variables: { ...selectedUser, roles} });
      } catch {
        return NotificationManager.error('Já existe um usuário com esse e-mail');
      }
    }
    setShowModal(false);
    getUsers.refetch();
    return NotificationManager.success('Operação realizada com sucesso');
  };

  const getName = (_user) => {
    if (_user.fullName != null) {
      return _user.fullName;
    }
    return _user.email;
  };

  const onChangeHandler = (e) => {
    setSearchedString(e.target.value);
}

useEffect(()=> {
  getUsers.fetch({variables: { fullName: searchedString }})
}, [searchedString])

console.log("searchedString", searchedString)

const { loading, data, error, refetch} = useQuery(GET_USERS, {
  variables: {
      fullName: searchedString,
      offset: page,
      limit: limit
  },
  fetchPolicy: 'cache-and-network',
  onCompleted: (data) => {
      setCurrentMembers(data.getUsers);
  }
});



  const renderRoles = () => {
    if (!getRoles.data) {
      return (
        <LoadingAdmin/>
      );
    }

    const { data: { getRoles: allRoles } } = getRoles;

    return (
      <FormControl>
        <FormLabel>Selecione os perfis do usuário</FormLabel>
        {allRoles.map(item => (
          <FormControlLabel
            key={item.id}
            label={item.name}
            control={(
              <Checkbox
                key={item.id}
                value={item.id}
                checked={roles.filter(r => r === item.id).length > 0}
                onChange={handleRoleChange}
                color="primary"
              />
            )}
          />
        ))}
      </FormControl>
    );
  };

  const renderInstitutions = () => {
    if (!getInstitutions.data) {
      return (
        <LoadingAdmin/>
      );
    }

    const { data: { getInstitutions: institutions } } = getInstitutions;

    if (getInstitutions.error || institutions.length === 0) {
      return (
        <Typography className="label">
          Nenhuma instituição cadastrada
        </Typography>
      );
    }
    return (
      <FormControl variant="outlined" fullWidth>
        <InputLabel htmlFor="institution">
          Selecione a instituição do usuário
        </InputLabel>
        <Select
          value={(selectedUser.institution && selectedUser.institution.id) || ''}
          onChange={setValue}
          input={<OutlinedInput labelWidth={243} name="institution" id="institution" />}
        >
          {institutions.map(item => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  };

  const renderModal = () => (
    <Dialog
      onCancel={() => setShowModal(false)}
      onConfirm={handleSubmit}
      open={showModal}
      title={editing ? 'Editar Usuário' : 'Adicionar Usuário'}
    >
      <Grid container spacing={1} className="padding-children">
        { editing && (
          <Grid item xs={12} sm={12}>
            <TextField
              label="Digite o nome do usuário"
              value={selectedUser.fullName || ''}
              fullWidth
              name="fullName"
              onChange={setValue}
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <TextField
            label="Digite o e-mail do usuário"
            value={selectedUser.email || ''}
            type="email"
            fullWidth
            disabled={editing}
            name="email"
            onChange={setValue}
            variant="outlined"
          />
        </Grid>
        { editing && (
          <Grid item xs={12} sm={12}>
            <TextField
              label="Informe a data de nascimento do usuário"
              value={selectedUser.birthDate || ''}
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              name="birthDate"
              onChange={setValue}
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          {renderRoles()}
        </Grid>
        <Grid item xs={12} sm={12}>
          {renderInstitutions()}
        </Grid>
      </Grid>
    </Dialog>
  );

  const renderDeleteDialog = () => (
    <Dialog
      onCancel={() => setOpenDelete(false)}
      onConfirm={() => onRemoveUsers()}
      colorConfirm="secondary"
      textConfirm="Remover"
      open={openDelete}
      title="Remover Usuários"
    >
      Você tem certeza que deseja remover o usuário?
      <ul>
        {selecteds.map(i => (
          <li key={i.id}>{getName(i)}</li>
        ))}
      </ul>
    </Dialog>
  );

  const renderUsers = () => {
    if (!getUsers.data) {
      return (
        <LoadingAdmin/>
      );
    }
    if (getUsers.error) {
      return (
        <Grid xs={12} item>
          <Typography className="label" component="h2">
            {getUsers.error.message}
          </Typography>
        </Grid>
      );
    }
    if (getUsers.length === 0) {
      return (
        <Grid xs={12} item>
          <Typography className="label" component="h2">
            Nenhum usuário adicionado
          </Typography>
        </Grid>
      );
    }
    return (
      <>
      {(data && currentMembers) ?
        currentMembers.map((members, index) => {
        return <TableData key={members.id}>
        <PersonCard
        user={members}
        checkFunction={handleSelectCheckbox}
        editFunction={openModalEdit}
        onClickRemove={()=>openModalRemove(members)}/>

        </TableData>})
        : null}

      </>
    );
  };




    return (
        <>
         <ContainerSearchBar>
                <TitleSearch>
                Membros
                </TitleSearch>
                <ColumnRight>
                <SearchBar customPlaceholder='Buscar membro'
                          onChangeHandler={onChangeHandler}
                          searchedString={searchedString}
                          setSearchedString={setSearchedString}
                          />
                <ButtonModal onClick={()=>openModalAdd()}>
                  <Add
                   style={{color:"#FF4C00", paddingRight:"5px"}}size={25}/>
                Adicionar
                </ButtonModal>
                </ColumnRight>
                </ContainerSearchBar>
            <TableContainer>
                <TableComponent>
                    <thead>
                        <ColumnTitle>
                            {columnTitles.map((title, index) => {
                                return <th key={index}>{title}</th>
                            })}
                        </ColumnTitle>
                    </thead>

                    <tbody>

                    {renderUsers()}
                    </tbody>
                </TableComponent>
            </TableContainer>
            {renderModal()}
            {renderDeleteDialog()}

        <Pagination page={page} setPage={setPage} limit={limit} totalNumberOfPages={totalNumberOfPages} />
        </>
    )
}


MembersTable.propTypes = {

    editing:bool,
    showModal: bool,
    selectedUser:string,
    roles:string
};

MembersTable.defaultProps = {
    editing:true,
    showModal: false,
    selectedUser:null,
    roles:null
};
export default MembersTable;
