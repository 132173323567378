import styled from "styled-components";

export const CarouselContainer = styled.div`
  overflow: hidden;
`;

export const Inner = styled.div`
    white-space:nowrap;
    transition:transform 0.3s;
    @media(max-width: 600px) {
      display:flex;
      margin:30px;
      white-space:rap;
    }
`;

export const InnerMobile = styled.div`
    white-space:nowrap;
    display:none;
    transition:transform 0.3s;
    @media(max-width: 500px) {
     display:block;
    }
`;


export const Indicadores = styled.div`
display: inline-flex;
  padding: 14px 0px;
  @media(max-width: 500px) {
    padding: 0px 0px;
  }
`;

export const Prev = styled.button`
  position: absolute;
  z-index: 4;
  left: 30px;
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2.5em;
&:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
  };

`;

export const Next = styled.button`
  display:flex;
  position: absolute;
  z-index: 4;
  right: 30px;
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2.5em;
&:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
  };

`;


export const CardFlexEpisodes = styled.div`
  display:inline-flex;
  flex-direction:column;
  justify-content:center;
`;

export const Card = styled.div`
  display:flex;
  flex-direction:column;
  justify-content: space-between;
  width: 250px;
  height: 100%;
  margin:30px;
  @media(max-width: 500px) {
    margin:0px;
    margin-right:60px;
    width: 230px;
  }

`;
export const GridVideo = styled.div`
  height: 170px;
  border: 1px solid #f4f4f4;
  width: 250px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 14px;
  background: url("${(props) => props.folder}");
  background-size: cover;
  @media(max-width: 440px) {
    width: 230px;
  }
`;

export const ButtonPlay = styled.div`
`;
export const TitleEpisode = styled.h3`
  padding: 20px 0px;
`;
export const DescriptionEpisode = styled.div`
  text-align: justify;
  padding-bottom: 20px;
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  @media(max-width: 440px) {
    flex-direction: column;
  }

`;

export const Watch = styled.div`
  display: flex;
  align-items: center
`;
export const TimeEpisode = styled.div`
  display: flex;
  align-items: center;
`;
export const ProgressVideo = styled.div`
  background-color: #FF4C00;
  width: ${(props) => props.value}%;
  height: 5px;
`;

export const ProgressBar = styled.div`
  background-color: gray;
  width: 100%;
  height: 5px;
`;
