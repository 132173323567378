import React, { useState, useEffect } from 'react';
import { Button, RadioGroup, FormControlLabel, Radio, Checkbox, FormGroup, FormControl,} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GlobalContext from '../../../../context';
import { GET_ANSWERS_FROM_QUESTION } from '../../../../services/queries/questions';
import { CREATE_TRUEFALSE_ANSWER } from '../../../../services/mutations/question';
import { Controller, useForm } from 'react-hook-form';
import { createAnswerTrueFalse } from './valitation';
import { yupResolver } from '@hookform/resolvers/yup';

const TrueFalseQuestion = ({ exerciseQuestion}) => {
  const {
    user,
  } = GlobalContext();
  const getAnswers = useQuery(GET_ANSWERS_FROM_QUESTION, {
    variables: {
      id: exerciseQuestion.id,
    },
    fetchPolicy: "no-cache"
  });

  const { handleSubmit, register, control, watch, formState: { errors, isValid }  } = useForm({resolver: yupResolver(createAnswerTrueFalse)});



  const [createAnswer] = useMutation(CREATE_TRUEFALSE_ANSWER);
  const [selected, setSelected] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [valueAlternative, setValueAlternative] = useState([])

  const { data: { getAnswersFromExerciseQuestion: answers } } = getAnswers;
  const [filteredAnswers, setFilteredAnswers] = useState([]);

  useEffect(() => {
    if(answers){
      setFilteredAnswers([...answers.filter(i => i.user.id === user.id)]);
    } 
  }, [answers]);

  console.log("filteredAnswers", filteredAnswers)

  useEffect(() => {
    console.log(filteredAnswers)
    if (filteredAnswers && filteredAnswers.length > 0) {
      const alternativesIds = [];
      for(let i = 0; i < filteredAnswers.length; i++){
        if(filteredAnswers[i].value === true && !alternativesIds.includes(filteredAnswers[i].trueFalseQuestionAlternative.id)){
          alternativesIds.push(filteredAnswers[i].trueFalseQuestionAlternative.id);
        }
      }


      const newSelected = [];
      newSelected.push(...alternativesIds);
      setSelected([...alternativesIds]);
      setDisabled(true);
    }
  }, [filteredAnswers]);

  if (getAnswers.loading) {
    return 'Carregando...';
  }


  const submit = () => {
    createAnswer({
      variables: {
        exercisesQuestion: exerciseQuestion.id,
        trueFalseQuestionAlternatives: selected,
        values: valueAlternative,
      },
    })
    setDisabled(true)
    getAnswers.refetch();
  };


  const handleChange = (alternative, onChange)=>{
    let selectedAlternatives = [...selected, alternative.id.toString()]
    let valuesAlternatives = [...valueAlternative, true]
    setSelected(selectedAlternatives)
    setValueAlternative(valuesAlternatives)
    onChange(selectedAlternatives)
    console.log(selectedAlternatives)
    console.log(valuesAlternatives)
  }

  return (
    <>
 {!disabled && (
  <>
          <Controller
              name="alternatives"
              control={control}
              defaultValue={exerciseQuestion?.question?.alternatives}
              render={props => (
              <FormControl  name="alternatives">
                {exerciseQuestion.question.alternatives.map(alternative => (
                  <FormControlLabel disabled={disabled}
                  key={alternative.id} control={<Checkbox />}
                  onChange={(_,selected) => { handleChange(alternative, props.field.onChange)}}
                  label={alternative.text} />
                ))}
              </FormControl>
               )}
             />
             <br></br>
            <Button
           variant="outlined"
           color="primary"
           style={{marginTop:"10px", marginBottom:"10px"}}
           onClick={handleSubmit(submit)} >
          Enviar resposta
          </Button>
          </>
        )}

            {disabled && (
             <Controller
             name="alternatives"
             control={control}
             defaultValue={exerciseQuestion?.question?.alternatives}
             render={props => (
             <FormControl  name="alternatives">
               {exerciseQuestion.question.alternatives.map(alternative => (
                <>
                 <FormControlLabel
                 disabled={disabled} key={alternative.id} control={<Checkbox />}
                 checked={selected.includes(alternative?.id)}
                 label={alternative.text +  '   ' + (alternative?.isCorrect === true ? '✅': '❌') } />
                </>
               ))}
             </FormControl>
              )}
            />
        )}


    </>
  );
};

export default TrueFalseQuestion;
