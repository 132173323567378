import styled from "styled-components";

export const ContainerFlexComponents = styled.div`
  background: linear-gradient(to bottom, rgb(20, 20, 20, 0.6), rgb(8, 8, 8));
  overflow-x: hidden;
  padding-left: 140px;
  padding-right: 60px;
  padding-bottom: 120px;
  height: 100%;
  @media(max-width: 900px) {
    padding-left: 80px;
  }
  @media(max-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
    height: 100%;
  }
`;

export const ContainerInfo = styled.div`
  width: 80vw;
  height: 100%;
  margin-bottom: 30px;
  @media(max-width: 600px) {
    width: 100vw;
  },


`;
export const CourseInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
  width: 40vw;
  line-height: 1.5;
  padding-top: 80px;
  @media(max-width: 600px) {
    display: none;
  }

`;

export const CourseInfoMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: justify;
  line-height: 1.5;
  padding: 25px;
  margin-top: 80px;
  padding-top:250px;

  display: none;
  @media(max-width: 600px) {
    display: flex;
  }


`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  @media(max-width: 600px) {
    justify-content: center;
    align-items: center;
    padding: 350px 10px;
     padding-top: 180px;
     margin-bottom: 100px;
     margin-right:10px;
    },
    @media(max-width: 400px) {
      padding-top: 180px;

     }


`;
export const Button = styled.button`
  margin-y: 15px;
  padding: 18px 18px;
  margin-right: 10px;
  height: 48px;
  border-radius: 7px;
  border-style: none;
  align-items: center;
  display: flex;
  flex-direction: row;
  cursor: pointer;
  @media(max-width: 500px) {
    margin-y: 5px;
    padding: 8px 8px;
    margin-right: 10px;
    height: 60px;
   }
   @media(max-width: 375px) {
    height: 60px;
   }
   transition: filter 0.2;
   &:hover{
   filter: brightness(0.9)
   }
`;

export const ButtonClose = styled.button`
  padding: 10px 10px;
  margin-right: 10px;
  border-radius: 7px;
  border-style: none;
  align-items: center;
  height: 50px;
  display: flex;
  margin-top: 80px;
  flex-direction: row;
  transition: filter 0.2;
  &:hover{
  filter: brightness(0.9)
  }
  @media(max-width: 1200px) {
    margin-right: 200px;
  };
  @media(max-width: 900px) {
    margin-right: 120px;
  }
  @media(max-width: 600px) {
   display:none;
  }
`;

export const ButtonCloseMobile = styled.button`
  padding: 10px 10px;
  margin-right: 5px;
  border-radius: 7px;
  border-style: none;
  align-items: center;
  height: 50px;
  margin-top: 15px;
  right:0;
  display: none;
  transition: filter 0.2;
  &:hover{
  filter: brightness(0.9)
  }
  @media(max-width: 600px) {
   display:flex;
  }
`;
export const BackgroundCourses = styled.div`
  background-image:
  linear-gradient(
    to bottom,
    rgb(20, 20, 20, 0.2),
    rgb(8, 8, 8)
  ), url("${(props) => props.backgroundUrl}");
  background-size: 100%;
  background-repeat: no-repeat;
  overflow-x: hidden;
  @media(max-width: 600px) {
    background-image:
    linear-gradient(
      to bottom,
      rgb(20, 20, 20, 0.2),
      rgb(8, 8, 8)
    ), url("${(props) => props.backgroundUrlMobile}");
   },
`;

export const ContainerHomeCourse = styled.div`
  display: flex;
  flex-direction: row;
  width: 100vw;
  @media(max-width: 600px) {
    height: 40vh;
    flex-direction: column;
   },

`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
`;


export const ButtonGroupMobile = styled.div`
display:none;
@media(max-width: 600px) {
  display: flex;
  flex-direction: row;
  justify-content:center;
  padding: 250px 0px;
  align-items: center;
  margin-bottom:150px;
};
`
export const ButtonGroupDesktop = styled.div`
@media(max-width: 600px) {
  display:none;
};
`
