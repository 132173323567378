import gql from 'graphql-tag';

export const CREATE_INSTITUTION = gql`
  mutation createInstitution($name: String!) {
    createInstitution(name: $name) {
      id
      name
      managers {
        id
        fullName
        email
      }
      creator {
        id
        fullName
      }
      users {
        id
        fullName
      }
      courses {
        id
        name
      }
    }
  }
`;

export const REMOVE_INSTITUTION = gql`
  mutation removeInstitution($id: ID!) {
    removeInstitution(id: $id)
  }
`;

export const REMOVE_INSTITUTIONS = (ids = []) => `
mutation {
  ${ids
    .map(
      ({ id }, index) => `
    remove${index}: removeInstitution(id: "${id}")
  `,
    )
    .join('')}
}
`;

export const UPDATE_INSTITUTION = gql`
  mutation updateInstitution($id: ID!, $name: String) {
    updateInstitution(id: $id, name: $name) {
      id
      name
      managers {
        id
        fullName
      }
      creator {
        id
        fullName
      }
      users {
        id
        fullName
      }
      courses {
        id
        name
      }
    }
  }

`;
