import gql from 'graphql-tag';

export const CREATE_LESSON = gql`
  mutation createUnit($ModuleId: ID!, $name: String!, $content: String!,
      $type: UnitTypes!, $order: Int, $folder: String, $tags: [ID]) {
    createUnit(ModuleId: $ModuleId, name: $name, content: $content,
        type: $type, order: $order, folder: $folder, tags: $tags) {
      id
    }
  }
`;

export const VIEW_UNIT = gql`
  mutation viewUnitVideoPlayHistoric($UnitId: String!, $ElapsedTime: Int, $Ended: Boolean) {
    viewUnitVideoPlayHistoric(UnitId: $UnitId, ElapsedTime: $ElapsedTime, Ended: $Ended) 
  }
`;

export const UPDATE_LESSON = gql`
  mutation updateUnit($id: ID!, $name: String!, $content: String!,
      $type: UnitTypes!, $order: Int, $folder: String, $tags: [ID]) {
    updateUnit(id: $id, name: $name, content: $content,
        type: $type, order: $order, folder: $folder, tags: $tags) {
      id
    }
  }
`;


export const REMOVE_LESSONS = lessons => gql`
  mutation {
    ${lessons.map((lesson, i) => `
      remove${i}: removeUnit(id: "${lesson.id}")
    `).join('')}
  }
`;
