import { useMutation } from '@apollo/react-hooks';
import useManualQuery from '../useManualQuery';
import { GET_USERS,  FAVORITE_UNITS, GET_USERS_BY_INSTITUTION } from '../../services/queries/user';
import { GET_ROLES_SELECT } from '../../services/queries/role';
import { GET_INSTITUTIONS_SELECT } from '../../services/queries/institution';
import { CREATE_USER, FAVORITE_UNIT,LIKE_UNIT, UPDATE_USER, UPDATE_USER_PROFILE_INFO, UPDATE_USER_PASSWORD } from '../../services/mutations/user';

const useUsers = () => {
  const getUsers = useManualQuery(GET_USERS);
  const getRoles = useManualQuery(GET_ROLES_SELECT);
  const getInstitutions = useManualQuery(GET_INSTITUTIONS_SELECT);
  const getUsersByInstitution = useManualQuery(GET_USERS_BY_INSTITUTION);
  const favoriteUnits = useManualQuery(FAVORITE_UNITS);
  const [createUser] = useMutation(CREATE_USER);
  const [updateUser] = useMutation(UPDATE_USER, {
    onError: (e) => {
      console.log(e)
    }
  });
  const [updateUserProfileInfo] = useMutation(UPDATE_USER_PROFILE_INFO);
  const [updateUserPassword] = useMutation(UPDATE_USER_PASSWORD);
  const [favoriteUnit] = useMutation(FAVORITE_UNIT);
  const [likeUnit] = useMutation(LIKE_UNIT);

  return {
    getUsers,
    getRoles,
    getInstitutions,
    getUsersByInstitution,
    createUser,
    updateUser,
    updateUserProfileInfo,
    updateUserPassword,
    favoriteUnit,
    likeUnit,
    favoriteUnits,

  };
};

export default useUsers;
