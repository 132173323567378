import styled from 'styled-components';

export const StyledContainer = styled.div`
  .avatar {
    background: url(Montreal-corporate-headshot-BFL-group-on-location-white-background-02-1024x799.jpg);
    border: 1px solid #DADADA;
    box-sizing: border-box;
    color: #FFF;

  }

  .nameInitials {
    font-weight: bold;
  }
`;

