import gql from 'graphql-tag';

export const GET_USER_ME = gql`
  query {
    getUserMe {
      id
      fullName
      email
      avatar
      birthDate
      institution {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;

export const GET_USERS = gql`
  query($fullName: String, $orderByFullName: OrderTypes, $offset: Int, $limit: Int) {
    getUsers(fullName: $fullName, orderByFullName: $orderByFullName, offset: $offset, limit: $limit) {
      id
      fullName
      email
      birthDate
      avatar
      institution {
        id
        name
      }
      roles {
        id
        name
      }
    }
  }
`;


export const GET_USERS_BY_INSTITUTION = gql`
  query($id: ID!) {
    getInstitutionById(id: $id) {
      users {
        id
        fullName
        email
        birthDate
        avatar
        institution {
          id
          name
        }
        roles {
          id
          name
        }
      }
    }
  }
`;

export const FAVORITE_UNITS = gql`
  query ($id: ID!) {
    getUserById (id: $id) {
    favoriteUnits{
      id
      folder
      name
      module{
        id
        course{
          id
          name
        }
      }
    }
  }
  }
`;
