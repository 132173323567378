import { useMutation } from '@apollo/react-hooks';
import useManualQuery from '../../useManualQuery';
import { GET_MEMBERS_BY_COURSE } from '../../../services/queries/courses';
import { ADD_COURSE_USER } from '../../../services/mutations/course';

const useMembers = () => {
  const getMembersByCourse = useManualQuery(GET_MEMBERS_BY_COURSE);
  const [addCourseUser] = useMutation(ADD_COURSE_USER);

  return {
    getMembersByCourse,
    addCourseUser,
  };
};

export default useMembers;
