import React, { useEffect } from 'react';
import { RiFileDownloadLine } from 'react-icons/ri';
import {BsPencil} from 'react-icons/bs';
import { FlexCard, FlexContent, FlexItem, IconContent, Text, TextTitle } from './styles';
import { Linha, Title } from '../../overview/styles';
import { NavLink } from "react-router-dom";
import GlobalContext from "../../../context";
import { MdOutlineForum } from 'react-icons/md';
import { Grid } from '@material-ui/core';


const MaterialCourse = () => {
  const {
    selectedCourse: {
      course,
      getMyCoursesByUser,
      courseAccess,
      exercises: {
        getExercises,
      },
    },
    visualization: { visualization },
  } = GlobalContext();

  useEffect(() => {
    let role;
    switch (visualization) {
      case "Estudante":
        role = "STUDENT";
        break;
      case "Professor":
        role = "TEACHER";
        break;
      default:
        break;
    }
    getMyCoursesByUser.fetch();
  }, []);


  console.log('coursedados', getExercises?.data)

  return (

  <div style={{marginTop:"30px"}}>
    <Title>
        <h3 style={{
        paddingLeft: "20px",
        color:"#ffffff"}}>
          Conteúdo Complementar
          </h3>
        <Linha></Linha>
      </Title>

      <Grid container spacing={2}>
  {course.material.length > 0  ?
  <NavLink to="/complementaryMaterial" style={{textDecoration:"none"}}>
  <FlexItem>
      <IconContent>
      <RiFileDownloadLine size={40}/>
      </IconContent>
      <FlexContent>
          <TextTitle>
            <strong> Material complementar</strong>
          </TextTitle>
          <Text>
          Tenha acesso a apostilas e materiais complementares.
          </Text>
      </FlexContent>
  </FlexItem>
  </NavLink>
 : null
}
{ visualization === 'Estudante'?
  <NavLink to="/course/exercises" style={{textDecoration:"none"}}>
  <FlexItem>
      <IconContent>
      <BsPencil size={40}/>
      </IconContent>
      <FlexContent>
          <TextTitle>
            <strong> Testes</strong>
          </TextTitle>
          <Text>
          Acesse e realize os testes para obter seu certificado.
          </Text>
      </FlexContent>
  </FlexItem>
</NavLink>
:null}

{ visualization !== 'Estudante' ?
  <NavLink to="/course/exercises" style={{textDecoration:"none"}}>
  <FlexItem>
      <IconContent>
      <BsPencil size={40}/>
      </IconContent>
      <FlexContent>
          <TextTitle>
            <strong> Testes</strong>
          </TextTitle>
          <Text>
          Tenha acesso aos testes e notas dos estudantes.
          </Text>
      </FlexContent>
  </FlexItem>
</NavLink>
:null}

  <NavLink to="/course/foruns" style={{textDecoration:"none"}}>
  <FlexItem>
      <IconContent>
      <MdOutlineForum size={40}/>
      </IconContent>
      <FlexContent>
          <TextTitle>
            <strong>Fórum</strong>
          </TextTitle>
          <Text>
          Comente e tire suas dúvidas nos tópicos do curso.
          </Text>
      </FlexContent>
  </FlexItem>
</NavLink>




</Grid>
  </div>
  )
        };



export default MaterialCourse;
