import styled from "styled-components";

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
`;
export const TitlePage = styled.div`
color:#ffffff;
font-size:38px;
@media(max-width: 500px) {
 display:none;
}
`;
export const Linha = styled.hr`
  width: 22vw;
  border: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    rgb(232, 72, 16, 0.5),
    rgb(20, 20, 20, 0.6)
  );
  border-image-slice: 1;
`;
export const ContainerList = styled.div`
  padding-left: 120px;
  padding-bottom: 120px;
  padding-right: 120px;
  padding-top:30px;
  margin: 20px;
  margin-top:30px;
  margin-bottom:30px;
  @media(max-width: 1300px) {

  };
  @media(max-width: 900px) {
    padding-top:0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  },

  @media(max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-top:550px;
    padding-top:300px;
    display: fex;
    justify-content:center;
    align-items:center;
  }

`;
export const Container = styled.main`
  flex-grow: 1;
  padding-top:-10px;
  .container {
    padding-top: 0px;
    padding-bottom: 32px;
  }

  .test {
    margin-bottom: 10px;
  }

  .pageTitle {
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    color: #1c1c1c;
    margin-top: 16px;
  }

  .membersContainer {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 24px;
    padding-left: 130px;
    padding-right: 130px;
    justify-content: space-between;

  }

  @media(max-width: 1300px) {
    width:90vw;
  };
  @media(max-width: 600px) {
    padding-top:120px;
    padding-left:30px;
  };
`;
