import React from "react";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import {
  CardFlexEpisodes,
  GridVideo,
  TitleEpisode,
  DescriptionEpisode,
  Watch,
  ButtonGroup,
  TimeEpisode,
  ProgressVideo,
  ProgressBar,
  ButtonPlay,
  Card,
} from "./styles";
import { string, number, func, shape, arrayOf, object } from "prop-types";

const CardTrailers = ({ course, folder, enterFunction }) => {

  return (
    <CardFlexEpisodes>
      <Card>
      <GridVideo
        style={enterFunction ? { cursor: "pointer" } : null}
        folder={folder}
        onClick={enterFunction ? () => enterFunction(course) : null}
      >
        <ButtonPlay>
          <PlayCircleOutlineIcon
            style={{ color: "#f4f4f4", fontSize: "40px" }}
          />
        </ButtonPlay>

      </GridVideo>

      <ButtonGroup>
        <Watch style={{ color: "#e84810" }}>
          <PlayArrowOutlinedIcon style={{ paddingRight: "5px" }} />
          <strong
            style={enterFunction ? { cursor: "pointer" } : null}
            onClick={enterFunction ? () => enterFunction(course) : null}
          >
            Assistir Trailer
          </strong>
        </Watch>
      </ButtonGroup>

      </Card>
    </CardFlexEpisodes>


  );
};

CardTrailers.propTypes = {
  course: shape({
    id: string.isRequired,
    name: string.isRequired,
    description: string,
    image: string,
    price: number,
    timeOfDedication: number,
    percent: number,
    teachers: arrayOf(object),
  }).isRequired,
  checkFunction: func,
  editFunction: func,
  enterFunction: func,
};

CardTrailers.defaultProps = {
  checkFunction: null,
  editFunction: null,
  enterFunction: null,
};

export default CardTrailers;
