import styled from "styled-components";

export const ContainerList = styled.div`
  padding-left: 120px;
  position:relative;
  padding-bottom: 120px;
  padding-right: 120px;
  margin-top: -860px;
  margin: 20px;
  @media(max-width: 2000px) {
    padding-left: 120px;
    padding-bottom: 120px;
    padding-right: 120px;
    margin-top: -560px;
    margin: 20px;
  };
  @media(max-width: 1300px) {

  };
  @media(max-width: 900px) {
    margin-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  },
  @media(max-width: 800px) {
    margin-top: 0px;
    padding: 20px;

  },

  @media(max-width: 600px) {
    margin-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
   padding:20px;
  }

`;
export const Container = styled.div`
  flex-grow: 1;
  padding-top:-10px;
  height:100%;
  overflow-x:hidden;

  .container {
    padding-top: 0px;
    padding-bottom: 32px;
  }

  .test {
    margin-bottom: 10px;
  }

  .pageTitle {
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    color: #1c1c1c;
    margin-top: 16px;
  }

  .membersContainer {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 24px;
    padding-left: 130px;
    padding-right: 130px;
    justify-content: space-between;

  }

  @media(max-width: 1300px) {

  };
  @media(max-width: 700px) {

  };
`;
export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
`;

export const Linha = styled.hr`
  width: 22vw;
  border: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    rgb(232, 72, 16, 0.5),
    rgb(20, 20, 20, 0.6)
  );
  border-image-slice: 1;
`;

export const CourseDesktop = styled.div`
  display: block;
  @media(max-width: 600px) {
    display: none;
  }

`;

export const CourseMobile = styled.div`
  display: none;
  @media(max-width: 600px) {
    display:block;
  }

`;
