import Footer from "../../../components/Footer";
import React, { useEffect, useState } from 'react';
import { AiOutlineHome , AiOutlineUnorderedList} from 'react-icons/ai';
import { oneOfType, arrayOf, node, shape, func } from "prop-types";
import {MdOutlineCategory } from 'react-icons/md';
import { AppBar, Toolbar, IconButton, CssBaseline, Grid } from "@material-ui/core";
import { Menu, SearchOutlined } from "@material-ui/icons";
import CientikIcon from "../../../assets/images/cientik_logo.svg";
import logomobile from '../../../assets/images/logomobile.png'
import { Container, NavLinks, NavSocial, IconesMenu, IconDesktop, IconMobile, NavLinksMobile, MobileColumn, SearchbarContainer, Input, ButtonAdmin } from "../../../components/AppBarWrapper/styles";
import GlobalContext from "../../../context";
import UserDropdown from "../../../components/AppBarWrapper/UserDropdown";
import {AiOutlineSearch} from 'react-icons/ai';
import {TbScreenShare} from 'react-icons/tb';
import {MdOutlineAdminPanelSettings} from 'react-icons/md';
import { NavLink, useHistory, withRouter } from "react-router-dom";
import Drawer from "../../../components/Drawer";
import CourseCard from "../../../components/CourseCard";
import { ContainerList } from "../../MyListStudent/styles";
import {TbSchool}   from 'react-icons/tb';

const SearchPage = () => {
  const {
    user: {id, institution },
    selectedCourse: {
      setCourse,
      getMyCoursesByUser,
      setCourseAccess,
      getCoursesByInstitution,
    },
    drawer: { toggle },
    visualization: { visualization },
  } = GlobalContext();


  const [text, setText] = useState('');


  const isManager = ["Administrador", "Gestor"].includes(visualization);

  const dataCourse = getCoursesByInstitution.data.getInstitutionById.courses;
  const history = useHistory();

  useEffect(() => {
    let role;
    switch (visualization) {
      case "Estudante":
        role = "STUDENT";
        break;
      case "Professor":
        role = "TEACHER";
        break;
      default:
        break;
    }
    getMyCoursesByUser.fetch({ variables: { id } });
  }, []);

  useEffect(() => {
    getCoursesByInstitution.fetch({ variables: { id: institution.id } });
  }, []);

  if (!getMyCoursesByUser.data || !getCoursesByInstitution.data) {
    return "Carregando...";
  }

  const {
    data: {
      getUserById: { courses },
    },
  } = getMyCoursesByUser;

  const filtered =  dataCourse.filter((dataCourse) =>
    dataCourse.name.toLowerCase().includes(text.toLowerCase())
      );




  const enterCourse = (course) => {
    setCourse(course);
    setCourseAccess(false);

    for (let i = 0; i < courses.length; i += 1) {
      if (courses[i].id === course.id) {
        setCourseAccess(true);
        break;
      }
    }

    history.push("/course");
  };



  return (


    <div style={{backgroundColor: "#080808"}}>
      <div className="menu">
          <MobileColumn>
        <Container>
       <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}}>
        <div>

          <IconDesktop className="title">
            <img
              src={CientikIcon}
              onClickCapture={() => history.push("/")}
              alt="logo"
            />
          </IconDesktop>
          <IconMobile className="title">
            <img
              src={logomobile}
              onClickCapture={() => history.push("/")}
              alt="logo" />
          </IconMobile>

          </div>

          <div>
          <NavLinks>
          <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/"
            >
	          <a style={{display:"flex", paddingLeft:"80px"}}
            >   <AiOutlineHome size={18}/>
             Inicio
             </a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/mylist"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>
              <AiOutlineUnorderedList size={18}/>
              Minha lista</a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/categories"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>
              <MdOutlineCategory size={18}/>
              Categorias</a>
          </NavLink>
          {visualization !== "Estudante" && (

          <NavLink
          style={{color: "#ffffff", textDecoration:"none"}}
          to="/"
          >
          <a style={{display:"flex", paddingLeft:"50px"}}>
          <TbSchool size={18}/>
           Capacitação de professores</a>
          </NavLink>
          )}
          </NavLinks>
          </div>
          </div>

          <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}}>
          <NavSocial>

          <SearchbarContainer>

          <SearchOutlined style={{cursor: 'pointer'}}/>
          <Input placeholder='Buscar'
          type='text'
          value={text}
          onChange={ (e) => setText(e.target.value)}
          />
          </SearchbarContainer>


          </NavSocial>

          <IconesMenu>
          <SearchbarContainer>
          <Input placeholder='Buscar'
          type='text'
          value={text}
          onChange={ (e) => setText(e.target.value)}
          />
          <AiOutlineSearch size={24} style={{marginRight:"20px"}}/>
          </SearchbarContainer>

            <TbScreenShare size={24}/>
          </IconesMenu>
          <UserDropdown/>
          {isManager &&
          <ButtonAdmin
          edge="start"
          className="menuButton"
          onClick={() =>history.push("/publishermain")}
          color="inherit"
          aria-label="Menu">
            <MdOutlineAdminPanelSettings size={18}/> <p style={{paddingLeft:"5px"}}>Acessar Admin</p>
        </ButtonAdmin>}

      {isManager && <Drawer />}
      {/* <main className="content">
        <div className="appBarSpacer" />
        {children}
      </main> */}
      </div>
    </Container>
    <NavLinksMobile>
          <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/"
            >
	          <a>
             Inicio
             </a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/mylist"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>

              Minha lista</a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/categories"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>

              Categorias</a>
          </NavLink>
          </NavLinksMobile>
</MobileColumn>
    </div>


      <div>
        <ContainerList
        style={{paddingTop:"120px"}}>
        {filtered.length === 0 && (
        <Grid container>
        {courses.map((course) => (
        <Grid item key={course.id}lg={3} xs={12} sm={4} md={4}
        style={{marginBottom:"35px"}}>
        <CourseCard course={course} enterFunction={enterCourse} />
        </Grid>
        ))}
           </Grid>
        )}
        {filtered.length >= 1 && (
        <Grid container>
         {filtered.map((course) => {
          return (
            <Grid item key={course.id}lg={3} xs={12} sm={4} md={4}
            style={{marginBottom:"35px"}}>
            <CourseCard course={course} enterFunction={enterCourse} />
            </Grid>
    );})}
           </Grid>
        )}

        </ContainerList>
        <Footer/>
      </div>
      </div>
  );
};
SearchPage.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(SearchPage);

