import styled from 'styled-components';

export const Container = styled.div`
min-height: 100vh;
color: #080808;
font-family: 'Montserrat';
padding: 40px;
form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content:center;
  alignItems:center;
  width: 40rem;
  float:center;
  gap: 1rem;
  label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  textarea {
    background: #ffffff;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    padding: 1rem;
    color:  #6D6D6D;
    border: 1px solid #C6C6C6;
    border-radius: 8px;
  }
  button {
    padding: 12px 32px;
    gap: 8px;
    height: 48px;
    background: #FF4C00;
    border-radius: 8px;
    border:none;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    color: #FFFFFF;
    margin-top:30px;
    margin-bottom: 80px;
    cursor: pointer;
    transition: filter 0.2;
      &:hover{
      filter: brightness(0.9)
      }
  }
`
export const EditorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const ToolBar = styled.div`
  width: 100%;
  background-color:#282c34;
  height: 10vmin;
  display: flex;
  padding: 1rem;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-evenly;
`

export const TextEditorr = styled.div`
  max-width: 900px;
`

export const SaveButton = styled.button`
  background-color: yellow;
  border-radius: 25px;
  width: 12rem;
  height: 3rem;
  border: none;
&:hover {
  cursor: pointer;
  background-color: rgb(255, 255, 163);
}
&:active {
  -moz-box-shadow:    inset 0 0 1px #000000;
  -webkit-box-shadow: inset 0 0 1px #000000;
  box-shadow:         inset 0 0 1px #000000;
}`

export const DocumentTitle = styled.div`
  /* background-color: red; */
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-bottom: 2rem;
`

export const TitleField = styled.p`
  width: 50%;
  height: 2rem;
  text-align: center;
`

export const DocDropDown = styled.div`
  height: 3rem;
  width: 12rem;
  border-radius: 25px;
  text-align: center;
  border: none;
`
