import React, { useState, useEffect } from 'react';
import { Button, RadioGroup, FormControlLabel, Radio,} from '@material-ui/core';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GlobalContext from '../../../../context';
import { GET_ANSWERS_FROM_QUESTION } from '../../../../services/queries/questions';
import { CREATE_OBJECTIVE_ANSWER } from '../../../../services/mutations/question';

const ObjectiveQuestion = ({ exerciseQuestion}) => {
  const {
    user,
  } = GlobalContext();

  const getAnswers = useQuery(GET_ANSWERS_FROM_QUESTION, {
    variables: {
      id: exerciseQuestion.id,
    },
  });
  const [createAnswer] = useMutation(CREATE_OBJECTIVE_ANSWER);
  const [selected, setSelected] = useState();
  const [disabled, setDisabled] = useState(false);

  const { data: { getAnswersFromExerciseQuestion: answers } } = getAnswers;
  const filteredAnswers = answers && answers.filter(i => i.user.id === user.id);

  useEffect(() => {
    console.log(filteredAnswers)
    if (filteredAnswers && filteredAnswers.length > 0) {
      setSelected(filteredAnswers[0].alternative.id);
      setDisabled(true);
    }
  }, [filteredAnswers]);

  if (getAnswers.loading) {
    return 'Carregando...';
  }

  const submit = async () => {
    await createAnswer({
      variables: {
        exercisesQuestion: exerciseQuestion.id,
        objectiveQuestionAlternative: selected.toString(),
      },
    });
    getAnswers.refetch();
  };

  return (
    <>
    {!disabled && (
      <>
      <RadioGroup
        aria-label="gender"
        name="gender1"
        // className={classes.group}
        value={selected}
        onChange={e => setSelected(e.target.value)}
      >
        {exerciseQuestion.question.alternatives.map(i => (
          <FormControlLabel disabled={disabled} key={i.id} value={i.id} control={<Radio />} label={i.text} />
        ))}

        </RadioGroup>

        <Button
           variant="outlined"
           color="primary"
           style={{marginTop:"10px", marginBottom:"10px"}}
           onClick={submit}>
          Enviar resposta
          </Button>
           </>
            )}
         {disabled && (
            <RadioGroup
            aria-label="gender"
            name="gender1"
            defaultValue={exerciseQuestion?.question?.alternatives}
            value={selected}

          >
            {exerciseQuestion.question.alternatives.map(i => (
              <FormControlLabel disabled={disabled} key={i.id}
              value={i.id} control={<Radio />}
              label={i.text +  '   ' + (i?.isCorrect === true ? '✅': '❌') } />
            ))}

            </RadioGroup>


        )}


    </>
  );
};

export default ObjectiveQuestion;
