import Uppy from '@uppy/core';
import AwsS3Multipart from '@uppy/aws-s3-multipart';
import { getToken } from './auth';

const createUpload = (
  types = ['image/*', 'video/*'],
  folder = process.env.REACT_APP_UPLOAD_FOLDER_DEFAULT,
  maxNumberOfFiles = 1,
) => {
  const uppy = Uppy({
    debug: false,
    autoProceed: false,
    restrictions: {
      maxNumberOfFiles,
      allowedFileTypes: types,
    },
  }).use(AwsS3Multipart, {
    companionUrl: process.env.REACT_APP_UPLOAD_HOST,
    serverHeaders: {
      'uppy-auth-token': `Bearer ${getToken()}`,
    },
    createMultipartUpload(file) {
      const { name: filename, type, path } = file;
      return this.client.post('s3/multipart', { filename, type, path });
    },
  });
  uppy.on('file-added', file => Object.assign(file, { path: folder }));

  return uppy;
};

export default createUpload;
