import React, { useEffect, useRef, useState } from "react";
import ContentTable from "./ContentTable";


const PublisherContents = () => {


  return (
            <>
              <ContentTable/>
              </>
)}
export default PublisherContents;
