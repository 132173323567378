import React, {useState, useContext, useEffect} from 'react';
import {Card, ButtonNewSeasons, CardExtras, CardVideo, ContainerSeasons, FlexImg, FlexVideo, Icon, Info,
   Prevew, PrevewImg, PrevewVideo, PreviewVideo, Submit, SubTitleSeasons, Tam, TitleCard, TitleSeasons } from '../styles';
import {MdOutlineUpload, MdAdd, MdOutlineDone} from 'react-icons/md'
import { Close } from '@material-ui/icons';
import FormDetails from './FormDetails';
import { useMutation } from '@apollo/react-hooks';
import { UploadContext } from '../index'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import {createCourseTwo} from "../validation";
import {useDropzone} from 'react-dropzone';
import GlobalContext from '../../../../../context'
import Episodes from './Episodes';
import Material from './Material';
import { FormHelperText } from "@material-ui/core";
import {
  Typography,
  CircularProgress,
  Box,
  LinearProgress
} from '@material-ui/core';
import AWS from 'aws-sdk'


AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_USER_KEY,
  secretAccessKey: process.env.REACT_APP_S3_USER_SECRET
})

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME},
  region: process.env.REACT_APP_S3_REGION,
})

const params = {
  ACL: 'public-read',
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
};


export const Seasons=({refetch})=> {
  const {
    selectedCourse: {
      createCourse, updateCourse
    },
    visualization: { visualization },
  } = GlobalContext();
  const [data, setData] = useContext(UploadContext);
  const [thumbnail, setThumbnail] = useState('')
  const [background, setBackground] = useState('')
  const [trailer, setTrailer] = useState('')
  const [material, setMaterial] = useState([]);
  const [files, setFiles] = useState([]);
  const [percentState, setPercent] = useState(0)
  const [uploading, setUploading] = useState(false)

  let loaded = 0;
  let actualLoaded = 0
  let totalSize = 0;


  const { handleSubmit, register, control, watch, formState: { errors, isValid }  } = useForm({resolver: yupResolver(createCourseTwo)});

  useEffect(()=> {
    console.log('errors')
    console.log(errors)
  }, [errors])

  const {getRootProps, getInputProps} = useDropzone({noKeyboard: true,
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/ogg': ['.ogg']
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });

  const delImage =()=>{setThumbnail('')}

  const delBackground =()=>{setBackground('')}

  const delTrailer =()=>{setTrailer('')}

  const handleRemoveFile = name => {
    setFiles(files.filter(file => file.name !== name))
    }


  const onError = (errors, e) => {
    console.log(errors)
  }

  const loadVideo = file => new Promise((resolve, reject) => {
    try {
        let video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
            resolve(this)
        }

        video.onerror = function () {
            reject("Invalid video. Please select a video file.")
        }

        video.src = window.URL.createObjectURL(file)
    } catch (e) {
        reject(e)
    }
})



  const handleSubmitForm = async (props) => {

    console.log({...data, tags: data?.tags?.map(e => e.id)})

    console.log(data)
    totalSize += data?.thumbnail ? data?.thumbnail?.size : 0
    totalSize += data?.folder ? data?.folder?.size : 0
    totalSize += data?.trailer ? data?.trailer?.size : 0
    totalSize += data?.videos ? data?.videos?.reduce((a, b) => a + b.size, 0) : 0
    totalSize += data?.material ? data?.material?.reduce((a, b) => a + b.size, 0) : 0
    totalSize += data?.subtitles ? data?.subtitles?.reduce((a,b)=> a + b.reduce((c,d)=>c + d.size, 0), 0): 0

    setUploading(true)


    var uploadFolder = myBucket.upload({...params, Body: data?.folder, Key: `cientik/${data?.name}/Folder/${data?.folder.name}`})
      .on('httpUploadProgress', function(evt) {
        loaded += evt.loaded - actualLoaded
        setPercent(Math.round((loaded / totalSize)*100))
        actualLoaded = evt.loaded
      })
      .promise();

    const folderUrl = await uploadFolder.then(function(el, err) {
      actualLoaded = 0
      return el.Location
    });

    var uploadThumb = myBucket.upload({...params, Body: data?.thumbnail, Key: `cientik/${data?.name}/Thumbnail/${data?.thumbnail.name}`})
    .on('httpUploadProgress', function(evt) {
      loaded += evt.loaded - actualLoaded
      setPercent(Math.round((loaded / totalSize)*100))
      actualLoaded = evt.loaded
    })
    .promise();

    const thumbUrl = await uploadThumb.then(function(el, err) {
      actualLoaded = 0
      return el.Location
    });



    const videosUrls = [];
    const videosDurations = [];

    for (const video of data?.videos) {
      var uploadVideos = myBucket.upload({...params, Body: video, Key: `cientik/${data?.name}/Videos/${video.name}`})
        .on('httpUploadProgress', function(evt) {
          loaded += evt.loaded - actualLoaded
          setPercent(Math.round((loaded / totalSize)*100))
          actualLoaded = evt.loaded
        })
        .promise();

        const url = await uploadVideos.then(function(el, err) {
          actualLoaded = 0
          return el.Location
        });

        console.log(video)
        const videoDuration = await loadVideo(video)


        videosDurations.push(parseInt(videoDuration.duration))
        videosUrls.push(url)
        continue;
    }

    const materialUrls = [];

    if(data?.material) {
      for (const material of data?.material) {
        var uploadMaterials = myBucket.upload({...params, Body: material, Key: `cientik/${data?.name}/Material/${material.name}`})
          .on('httpUploadProgress', function(evt) {
            loaded += evt.loaded - actualLoaded
            setPercent(Math.round((loaded / totalSize)*100))
            actualLoaded = evt.loaded
          })
          .promise();

          const url = await uploadMaterials.then(function(el, err) {
            actualLoaded = 0
            return el.Location
          });

          console.log(url)

          materialUrls.push(url)
          continue;
      }
    }


    const trailerUrl = [];

    if(data?.trailer) {
      var uploadTrailer = myBucket.upload({...params, Body: data?.trailer, Key: `cientik/${data?.name}/Trailer/${data?.trailer?.name}`})
      .on('httpUploadProgress', function(evt) {
        loaded += evt.loaded - actualLoaded
        setPercent(Math.round((loaded / totalSize)*100))
        actualLoaded = evt.loaded
      })
      .promise();

    const url = await uploadTrailer.then(function(el, err) {
      actualLoaded = 0
      return el.Location
    });
      console.log(url)

      trailerUrl.push(url)
}

  let trailerCourse = trailerUrl.toString()

    const legendasUrls = [];
    var i = 0
    var y = 0
    for (const episodio of data?.subtitles) {
      const legendasEp = []
      for (const legenda of episodio) {
        var uploadLegendas = myBucket.upload({...params, Body: legenda, Key: `cientik/${data?.name}/Legendas/Ep. ${i}/${data?.idiomSubtitles[i][y]}`})
          .on('httpUploadProgress', function(evt) {
            loaded += evt.loaded - actualLoaded
            setPercent(Math.round((loaded / totalSize)*100))
            actualLoaded = evt.loaded
          })
          .promise();

          const url = await uploadLegendas.then(function(el, err) {
            actualLoaded = 0
            return el.Location
          });


          console.log(url)

          legendasEp.push(url)

          y++
          continue;
      }
      legendasUrls.push(legendasEp)
      i++
      continue;
    }

console.log({
  ...data,
  tags: data?.tags.map(e => e.id),
  folder: folderUrl,
  thumbnail: thumbUrl,
  videos: videosUrls,
  durationUnit: videosDurations,
  material: materialUrls,
  subtitles: legendasUrls,
  year: parseInt(data?.year),
  trailer: trailerCourse
})

    createCourse({
      variables: {
        ...data,
        tags: data?.tags.map(e => e.id),
        folder: folderUrl,
        thumbnail: thumbUrl,
        videos: videosUrls,
        durationUnit: videosDurations,
        material: materialUrls,
        subtitles: legendasUrls,
        year: parseInt(data?.year),
        trailer: trailerUrl.toString()
      }
    }).then(e => {
      console.log(e)
      setUploading(false)
      NotificationManager.success("Conteúdo criado com sucesso!");
      refetch()
    })
    .catch(err => {
      console.log(err)
      NotificationManager.error(
        "Não foi possível adicionar o conteúdo, veja se todos os dados foram informados corretamente e tente novamente."
      );
      setUploading(false)
    })



  }

//   const uploadFile = (file) => {

//     const params = {
//         ACL: 'public-read',
//         Body: file,
//         Bucket: S3_BUCKET,
//         Key: file.name
//     };

//     myBucket.putObject(params)
//         .on('httpUploadProgress', (evt) => {
//             setProgress(Math.round((evt.loaded / evt.total) * 100))
//         })
//         .send((err) => {
//             if (err) console.log(err)
//         })
// }

//   const upload = () => {
//     console.log(data)
//     S3FileUpload
//     .uploadFile(thumbnail, config)
//     .then(data => console.log(data))
//     .catch(err => console.error(err))
//   }

  const renderProgressBar = () => {
    return(
        <>
            <Box  sx={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '-20px' }}>
                <Box sx={{ width: '94%', mr: 1 }}>
                    <div>
                        <LinearProgress variant="determinate" value={percentState} />
                    </div>

                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${percentState}%`}</Typography>
                </Box>
            </Box>
            {percentState >= 100 ? (
                <Typography style={{marginTop: '20px'}} variant="body2" color="text.secondary">
                <MdOutlineDone style={{width: '12px', height: '12px', marginRight: '5px'}} color="inherit" />
                        Upload feito com sucesso!
                </Typography>
                ):(
                  <Typography style={{marginTop: '20px'}} variant="body2" color="text.secondary">
                  <CircularProgress style={{width: '12px', height: '12px', marginRight: '5px'}} color="inherit" />
                          Seu filme está sendo processado aguarde um estante.
                  </Typography>
                )}

        </>
    )
}



    return(
      <div style={{justifyContent: 'center', display: 'flex'}}>
    <form onSubmit={handleSubmit(handleSubmitForm, onError)}>
        <ContainerSeasons>


          {/* <div>
        <SubTitleSeasons>
        Imagens do conteúdo
        </SubTitleSeasons>

          <FlexImg>
            <Card style={{maginRight:"60px"}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Thumbnail
              </TitleCard>
              <Tam>
              1080 x 1920 px
              </Tam>

              <Controller
                name="thumbnail"
                control={control}
                defaultValue={data?.thumbnail}
                render={props => (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="thumbnail"
                    name="thumbnail"
                    // onChange={(e) => setThumbnail(e.target.files[0])}
                    onChange={(e) => {setData({...data, thumbnail: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                  />
                )}
              />
               <FormHelperText error={Boolean(errors?.thumbnail)}>
            {errors?.thumbnail?.message}
            </FormHelperText>

          <label htmlFor="thumbnail"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar a thumbnail do curso
          </label>
              <Info>
              Formato: JPG ou PNG
              </Info>
              {data?.thumbnail ?
              <>
              <PrevewImg src={URL.createObjectURL(data?.thumbnail)}/>
              <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delImage}><u>remover</u></button>
              </>
              :null }
            </Card>

            <Card>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Cartaz
              </TitleCard>
              <Tam>
              1920 x 1080 px
              </Tam>
              <Controller
                name="folder"
                control={control}
                defaultValue={data?.folder}
                render={props => (
                  <input
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="folder"
                    name="folder"
                    onChange={(e) => {setData({...data, folder: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                  )}
                />
                   <FormHelperText error={Boolean(errors?.thumbnail)}>
            {errors?.thumbnail?.message}
            </FormHelperText>
          <label htmlFor="folder"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o cartaz do curso
          </label>
          <Info>
            Formato: JPG ou PNG
              </Info>
          {data?.folder ? <>
          <PrevewImg src={URL.createObjectURL(data?.folder)}/>
          <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delBackground}><u>remover</u></button>
          </>:null }

            </Card>
          </FlexImg>
        </div> */}
        {/* <div>
        <SubTitleSeasons>
        Trailers
        </SubTitleSeasons>
        <FlexVideo>
            <CardVideo style={{maginRight:"60px"}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Trailer
              </TitleCard>
              <Controller
                name="trailer"
                control={control}
                defaultValue={data?.trailer}
                render={props => (
                  <input
                    type="file"
                    multiple
                    style={{ display: 'none'}}
                    name="trailer"
                    id="trailer"
                    accept=".mp4, .webm, .ogg"
                    onChange={(e)=>{setData({...data, trailer: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                )}
                />
                 <FormHelperText error={Boolean(errors?.trailer)}>
            {errors?.trailer?.message}
            </FormHelperText>
          <label htmlFor="trailer"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o trailer da temporada
          </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {data?.trailer ? <>
              <video width="730" controls>
              <source src={URL.createObjectURL(data?.trailer)} />
              </video>
              <button style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delTrailer}><u>remover</u></button>
          </>:null }
            </CardVideo> */}
{/*
            <CardExtras style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
              Adicionar Extras
              </TitleCard>
              <input
            {...getInputProps()}/>

            <label htmlFor="extras"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u {...getRootProps({className: 'dropzone'})}>Clique aqui</u> para adicionar extras da temporada
            </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {files ?
              <div>
              {files.map(file => {
                return(
                <div key={file.name} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                 <video width="730" controls>
                 <source src={file.preview} />
                 </video>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
                 onClick={() =>handleRemoveFile(file.name)}><u>remover</u></button>
                </div>
                )}
              )}
              </div>
              :null }
            </CardExtras>
          </FlexVideo>
        </div>*/}


      {/* <Material errors={errors} control={control}/> */}

      <TitleSeasons>
        Informações da temporada
      </TitleSeasons>

      <FormDetails errors={errors} control={control}/>

      <Episodes errors={errors} control={control}/>

        {/* <ButtonNewSeasons>
        <MdAdd size={28} style={{color:"#FF4C00"}}/>
        Nova Temporada
        </ButtonNewSeasons> */}


        </ContainerSeasons>
        {uploading ?
          renderProgressBar() :
          <button type='submit' onClick={()=> console.log(data?.videos)}>
              Publicar
          </button>
        }
         </form>
         </div>
    )
}

export default Seasons;
