import React from "react";
import { string, number, func, shape, arrayOf, object } from "prop-types";

import {
  CardContent,
  CardMedia,
  LinearProgress,
  Typography,
  Checkbox,
  IconButton,
} from "@material-ui/core";

import { Edit } from "@material-ui/icons";

import { StyledCard } from "./styles";

const renderTeachers = (teachers) => {
  const len = teachers.length;
  let teachersText = "";

  teachers.forEach((e, i) => {
    if (i === len - 1) {
      teachersText += e.fullName;
    } else {
      teachersText = `${e.fullName}, `;
    }
  });
  return teachersText;
};

const CourseCard = ({ course, checkFunction, editFunction, enterFunction }) => {
  const {
    name,
    teachers,
    description,
    price,
    generalInfo,
    folder,
    percent,
    timeOfDedication,
    institution,
  } = course;

  return (
    <StyledCard>
      <CardMedia
        className="cardMedia"
        image={folder}
        alt={name}
        // style={enterFunction && !checkFunction && !editFunction ? { cursor: 'pointer' } : null}
        // onClick={enterFunction && !checkFunction && !editFunction ? (
        //   () => enterFunction(course)
        // ) : null}
        style={enterFunction ? { cursor: "pointer" } : null}
        onClick={enterFunction ? () => enterFunction(course) : null}
      >
        {teachers && (
          <Typography className="subtitle" variant="subtitle1" component="p">
            {renderTeachers(teachers)}
          </Typography>
        )}

        {generalInfo && (
          <Typography className="footer" variant="subtitle1" component="p">
            {`${generalInfo.lessions} aulas | ${generalInfo.modules} módulos | ${generalInfo.users} membros`}
          </Typography>
        )}
        <div className="actions">
          {checkFunction && (
            <Checkbox
              className="action"
              onClick={() => checkFunction(course)}
              value="checkedB"
              color="primary"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          )}
          {editFunction && (
            <IconButton
              className="action"
              color="default"
              onClick={() => editFunction(course)}
            >
              <Edit />
            </IconButton>
          )}
        </div>
        {percent && percent !== 0 && (
          <LinearProgress
            className="progressBar"
            variant="determinate"
            color="primary"
            value={percent}
          />
        )}
      </CardMedia>
    </StyledCard>
  );
};

CourseCard.propTypes = {
  course: shape({
    id: string.isRequired,
    name: string.isRequired,
    description: string,
    image: string,
    price: number,
    timeOfDedication: number,
    percent: number,
    teachers: arrayOf(object),
  }).isRequired,
  checkFunction: func,
  editFunction: func,
  enterFunction: func,
};

CourseCard.defaultProps = {
  checkFunction: null,
  editFunction: null,
  enterFunction: null,
};

export default CourseCard;
