import React from 'react';
import Button from '@material-ui/core/Button';
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { DialogStyled } from './styles';

const Dialog = ({
  open, title, fullScreen, children, onConfirm, onCancel,
  textConfirm, colorConfirm, textCancel, colorCancel,
}) => (
  <DialogStyled
    open={open}
    onClose={onCancel}
    fullScreen={fullScreen}
    aria-labelledby="customized-dialog-title"
  >
    {title && (
      <DialogTitle
        disableTypography
        id="customized-dialog-title"
        className="titleModal"
        onClose={onCancel}
      >
        <Typography variant="h6">{title}</Typography>
        <IconButton aria-label="Close" className="closeButton" onClick={onCancel}>
          <Close />
        </IconButton>
      </DialogTitle>
    )}
    <DialogContent dividers>
      {children}
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancel} variant="contained" color={colorCancel}>
        {textCancel}
      </Button>
      {onConfirm && (
        <Button onClick={onConfirm} variant="contained" color={colorConfirm}>
          {textConfirm}
        </Button>
      )}
    </DialogActions>
  </DialogStyled>
);

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  textConfirm: PropTypes.string,
  colorConfirm: PropTypes.string,
  textCancel: PropTypes.string,
  colorCancel: PropTypes.string,
  fullScreen: PropTypes.bool,
};

Dialog.defaultProps = {
  title: null,
  children: null,
  onConfirm: null,
  textConfirm: 'Salvar',
  colorConfirm: 'primary',
  textCancel: 'Cancelar',
  colorCancel: 'default',
  fullScreen: false,
};

export default Dialog;
