import React from 'react';
import AppBarWrapper from '../../../components/AppBarWrapper';
import Header from '../Header/Header';
import { FormContainer } from '../ProfileData/styles';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import { Container } from '../styles';
import Table from './Table';

const Certification = () => {

 return (
  <>
<AppBarWrapper/>
  <Header/>
  <Container>
  <SideBarMenu/>
  <FormContainer>
<Table/>
  </FormContainer>
  </Container>
  </>
 )
};

export default Certification;
