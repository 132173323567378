
import { useMutation } from '@apollo/react-hooks';
import useManualQuery from '../useManualQuery';
import { GET_MODULES_BY_COURSE } from '../../services/queries/module';
import { CREATE_MODULE, REMOVE_MODULE, UPDATE_MODULE } from '../../services/mutations/module';

const useModule = () => {
  const [createModule] = useMutation(CREATE_MODULE);
  const [updateModule] = useMutation(UPDATE_MODULE);
  const getModulesByCourse = useManualQuery(GET_MODULES_BY_COURSE);


  return {
    createModule,
    updateModule,
    getModulesByCourse,

  };
};

export default useModule;
