import React from 'react';
import AppBarWrapper from '../../../components/AppBarWrapper';
import GlobalContext from '../../../context';
import Header from '../Header/Header';
import { FormContainer } from '../ProfileData/styles';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import { Container } from '../styles';
import ListHistory from './ListHistory';


const HistoryVideos = () => {
  const {
    user,
    user: { fullName, roles, birthDate },
    visualization: {
      setVisualization,
      setVisualizationModalOpen,
      visualization,
      visualizationModalOpen,
    },
  } = GlobalContext();

 return (
  <>
<AppBarWrapper/>
  <Header/>
  <Container>
  <SideBarMenu/>
  <FormContainer>
<ListHistory/>
  </FormContainer>
  </Container>
  </>
 )
};

export default HistoryVideos;
