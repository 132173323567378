import React, { useEffect, useState } from "react";
import "video-react/dist/video-react.css";
import {useHistory} from "react-router-dom";
import PlayerVideo from "../../../components/PlayerVideo/Player";
import GlobalContext from "../../../context";


const Trailer = () => {

  const history = useHistory();

  const {
    users: {favoriteUnit},
    user: { id },
    selectedTrailer: {
      trailer,
    },
    selectedCourse: {
      course,
    },
    visualization: { visualization },
  } = GlobalContext();

  console.log("trailer", trailer)
  return (
  <PlayerVideo
  // Vídeo
  src={trailer}
  title={course.name}
  titleMedia={course.name}
  playerLanguage="pt"
  backButton= {history.goBack}
  fullPlayer
  autoPlay
  onTimeUpdate={() => { }}
  overlayEnabled
  lessonId={course.id}
  autoControllCloseEnabled
  // Style
  primaryColor="#e84810"
  secundaryColor="#ffffff"
  fontFamily='Montserrat'
  // Velocidade do Video
  playbackRateStart={1}
  playbackRateOptions={['0.25', '0.5', '0.75', '1', '1.25', '1.5', '2', '3']}
/>
  )};

  export default Trailer;
