
import React, { useRef, useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog'
import GlobalContext from '../../../../context';
import { NotificationManager } from "react-notifications";
import { AiOutlineArrowLeft} from 'react-icons/ai'
import {BiCircle} from 'react-icons/bi'
import { ButtonNewSeasons, CloseButton, Content, Overlay } from './styles';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import  AccordeonSeasons  from './Seasons/Accordeon';
import IndexDialogForm from './IndexDialogForm';
import Seasons from './Seasons';

export const UploadContext = React.createContext(null);
  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

 function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

    export function DialogContent({ refetch, marginTop }) {

    const [data, setData] = useState();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      // newValue != 2 && 
      setValue(newValue);
    };

  return (
    <UploadContext.Provider value={[data, setData]}>
      <Dialog.Portal >
        <Overlay/>
        <Content style={{marginTop: marginTop}}>
          <Box sx={{ width: '100%' }} style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{display:"flex", justifyContent:"center"}}>
          <CloseButton style={{left:"2%"}}>
          <AiOutlineArrowLeft/> Voltar
          </CloseButton>

          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="inherit"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label={<div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <BiCircle size={14}/>
              <p style={{textTransform:"none"}}>Detalhes</p>
            </div>}  {...a11yProps(0)} />
            <p style={{marginLeft:"-75px", marginRight:"-75px", color:"#DEDEDE"}}>————————————————</p>
            <Tab label={<div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
              <BiCircle size={14}/>
              <p style={{textTransform:"none"}}>Conteúdo</p>
            </div>} {...a11yProps(1)} />
          </Tabs>

        </Box>
        <TabPanel value={value} index={0}>
          <IndexDialogForm nextStep={()=>setValue(2)}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Seasons nextStep={()=>setValue(0)} refetch={refetch}/>
        </TabPanel>
      </Box>
        </Content>


      </Dialog.Portal>
    </UploadContext.Provider>
  )
}

export default DialogContent;
