import React, { useEffect, useRef, useState } from "react";
import { array } from "prop-types";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import {
  MovieRow,
  MovieRowList,
  MovieRowListArea,
  LeftArrow,
  RightArrow,
  Shadow,
} from "./styles";

const CustomSliderLesson = ({ modules = [], itemWidth = 10 }) => {
  const wrapperRef = useRef();
  const [scrollX, setScrollX] = useState(0);
  const [isLeftArrowEnabled, setIsLeftArrowEnabled] = useState(false);
  const [hasMoreCourses, setHasMoreCourses] = useState(
    Boolean(modules.length * itemWidth > window.innerWidth)
  );

  const getCoursesOnScreen = Math.floor(
    (wrapperRef.current?.getBoundingClientRect().width ?? 0) / itemWidth
  );
  const [isSize, setIsSize] = useState(true);

  const handleLeftArrow = () => {
    let x = scrollX + getCoursesOnScreen * itemWidth;
    // Chegou ao inicio
    if (x >= 0) {
      x = 0;
      setIsLeftArrowEnabled(false);
    }
    setHasMoreCourses(Boolean(modules.length * itemWidth > window.innerWidth));
    setScrollX(x);
  };
  const handleRightArrow = () => {
    let x = scrollX - getCoursesOnScreen * itemWidth;
    if (getCoursesOnScreen === 0) {
      x =
        scrollX -
        Math.floor(
          (wrapperRef.current?.getBoundingClientRect().width ?? 0) / itemWidth
        ) *
          itemWidth;
    }
    const listW = modules.length * itemWidth;

    // Chegou ao fim
    if (window.innerWidth - listW > x) {
      setHasMoreCourses(false);

    }

    if (listW > x) {
      setScrollX(x);
      setIsLeftArrowEnabled(true);
    }
  };

  const autoResize = () => {
    if (modules.length * itemWidth < window.innerWidth) {
      setScrollX(0);
      setIsLeftArrowEnabled(false);
    } else if (modules.length * itemWidth > window.innerWidth && scrollX <= 0) {
      setHasMoreCourses(true);
    }
    setIsSize(Boolean(modules.length * itemWidth > window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", autoResize);
    autoResize();
  }, []);

  return (
    <MovieRow ref={wrapperRef}>
      <MovieRowListArea>
        {isLeftArrowEnabled && isSize && (
          <LeftArrow onClick={handleLeftArrow}>
            <AiOutlineDoubleLeft
              style={{ color: "#FF4C00", fontSize: "40px" }}
            />
          </LeftArrow>
        )}
        {hasMoreCourses && isSize && (
          <>

            <RightArrow onClick={handleRightArrow}>
              <AiOutlineDoubleRight
                style={{ color: "#FF4C00", fontSize: "40px" }}
              />
            </RightArrow>
          </>
        )}

        <MovieRowList
          width={modules.length * itemWidth}
          marginLeft={scrollX}
          onClick={() => console.log("clicked")}
        >
          {modules.map((unit) => (
            <div>{unit}</div>
          ))}
        </MovieRowList>
      </MovieRowListArea>
    </MovieRow>
  );
};

CustomSliderLesson.propTypes = {
  courses: array.isRequired,
};

export default CustomSliderLesson;
