import * as Dialog from '@radix-ui/react-dialog'
import styled, { css } from "styled-components";
import TextField from "@material-ui/core/TextField";

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background-color: transparent;
  `

  export const CardLegendas = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px 48px;
  font-size: 14px;
  gap: 16px;
  height: 100%;
  width: 100%;
  background: #EDEDED;
  border: 2px dashed #3D3D3D;
  border-radius: 8px;
  label{
    text-align: center;
  }
`

export const Legendas = styled.div`
  display: block;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  padding: 16px 48px;
  font-size: 14px;
  gap: 16px;
  height: 100%;
  width: 100%;
  background: #EDEDED;
  border-radius: 8px;
  label{
    text-align: center;
  }
`


export const Content = styled(Dialog.Content)`
  height:100vh;
  width:100vw;
  overflow:scroll;
  border-radius: 6px;
  padding: 2.5rem 3rem;
  background: #FFFFFF;
  position: fixed;
  top: 50%;
  left: 50%;
  color:#080808;
  transform: translate(-50%, -50%);
  form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content:center;
    alignItems:center;
    width: 40rem;
    float:center;
    gap: 1rem;
    label{
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
    }

    textarea {
      background: #ffffff;
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
      padding: 1rem;
      color:  #6D6D6D;
      border: 1px solid #C6C6C6;
      border-radius: 8px;
    }
    button {
      padding: 12px 32px;
      gap: 8px;
      height: 48px;
      background: #FF4C00;
      border-radius: 8px;
      border:none;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      margin-top:30px;
      margin-bottom: 80px;
      cursor: pointer;
      transition: filter 0.2;
        &:hover{
        filter: brightness(0.9)
        }
    }
  }
`

export const FormEpisodes = styled.form`
margin-top: 2rem;
display: flex;
flex-direction: column;
justify-content:center;
width: 40rem;
gap: 1rem;
label{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
}

textarea {
  background: #ffffff;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  padding: 1rem;
  color:  #6D6D6D;
  border: 1px solid #C6C6C6;
  border-radius: 8px;
}
select {
  background: #ffffff;
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  padding: 1rem;
  color:  #6D6D6D;
  border: 1px solid #C6C6C6;
  border-radius: 8px;
}
button {
  padding: 12px 32px;
  gap: 8px;
  height: 48px;
  background: #FF4C00;
  border-radius: 8px;
  border:none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top:30px;
  margin-bottom: 80px;
  cursor: pointer;
  transition: filter 0.2;
    &:hover{
    filter: brightness(0.9)
    }
  }
`

export const CloseButton = styled(Dialog.Close)`
  position: absolute;
  color: #6D6D6D;
  background-color: transparent;
  font-size:14px;
  font-weight: 500;
  border: 0;
  top: 1.5rem;
  right: 1.5rem;
  line-height: 0;
  cursor: pointer;
  display:flex;
  align-items:center;

;
`


export const Small = styled.span`
font-style: normal;
font-weight: 400;
font-size: 14px;
 `

 export const FlexData= styled.div`
display: flex;
justify-content: space-between;
width:100%;
@media screen and (max-width:1054px) {
    flex-direction: column;
}

`
export const DataTitle = styled.p`
font-family: 'Montserrat';
font-style: normal;
font-weight: 500;
font-size: 16px;
    padding-right:10px;
`
export const FlexDataInput= styled.div`
display: flex;
align-items: center;
margin-bottom:16px;
@media screen and (max-width:520px) {
    flex-direction: column;
}
`
export const TextAreaContainer = styled.div`
display: flex;
flex-direction: column;

`

export const TextArea = styled.input`
background: #ffffff;
box-shadow: 0 0 0 0;
border: 0 none;
outline: 0;
padding: 1rem;
color:  #6D6D6D;
border: 1px solid #C6C6C6;
border-radius: 8px;
padding: 16px;
p {
color: #6d6d6d;
font-size: 12px;
text-align: right;
margin-right: 10px;

}

`

export const TextAutocomplete = styled(TextField)`
background: #ffffff;
box-shadow: 0 0 0 0;
padding: 1rem;
color:  #6D6D6D;
border: 1px solid #C6C6C6;
border-radius: 8px;
padding: 16px;
p {
color: #6d6d6d;
font-size: 12px;
text-align: right;
margin-right: 10px;

}

`

export const TextAreaContainerDescription = styled.div`
   display: flex;
   flex-direction: column;
`

export const Count = styled.p`
   align-self:flex-end;
   font-size:12px;
`

export const TextAreaDescription = styled.textarea`
    background: #ffffff;
    box-shadow: 0 0 0 0;
    border: 0 none;
    outline: 0;
    padding: 1rem;
    color:  #6D6D6D;
    border: 1px solid #C6C6C6;
    border-radius: 8px;
    padding: 16px;
    height:100%;
    p {
    color: #6d6d6d;
    font-size: 12px;
    text-align: right;
    margin-right: 10px;

}
`

export const InputContainer = styled.div`
    background-color: #DCDDDF;
    border-radius: 16px;
    border-color: transparent;
    padding: 16px;
    width: 100%;
    outline: none;
    display:flex;
    justify-content:space-between;
`
export const CategoryInputData = styled.input`
    background-color: transparent;
    outline: none;
    border: transparent;
    @media screen and (min-width:768px) {
    width:  14rem;
}
`
export const Input =styled.input`
      background: #ffffff;
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
      padding: 1rem;
      border: 1px solid #C6C6C6;
      border-radius: 8px;

`
export const AccordionTitle = styled.p`
font-family: 'Montserrat';
font-weight: 400;
font-size: 16px;
line-height: 20px;
color: #080808;`

export const Container = styled.div`
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    alignItems:center;
    width: 55rem;
    margin-left: 15%;
    gap: 1rem;
`

export const ContainerSeasons = styled.div`
display:flex;
flex-direction:column;
align-items:center;
width:100%;
`

export const TitleSeasons = styled.p`
font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 26px;
line-height: 24px;
text-align: center;
color: #080808;
padding: 40px 10px;
`
export const SubTitleSeasons = styled.p`
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 20px;
text-align: center;
color: #080808;
padding: 20px 10px;
padding-top: 40px;

`
export const FlexImg = styled.div`
display: flex;
width:100%;
justify-content: space-between;
`

export const FlexVideo = styled.div`
display: flex;
flex-direction:column;
width:100%;
justify-content: space-between;
`
export const Card = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 16px 48px;
font-size: 14px;
gap: 16px;
width: 384px;
height: 100%;
margin:30px;
background: #EDEDED;
border-radius: 8px;
label{
  text-align: center;
}
`

export const CardVideo = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 16px 48px;
font-size: 14px;
gap: 16px;
width: 800px;
height: 100%;
margin:30px;
background: #EDEDED;
border-radius: 8px;
label{
  text-align: center;
}
`

export const CardExtras = styled.div`
display: flex;
flex-direction: column;
align-items: center;
padding: 16px 48px;
font-size: 14px;
gap: 16px;
width: 800px;
height: 100%;
margin:30px;
background: #EDEDED;
border: 2px dashed #3D3D3D;
border-radius: 8px;
label{
  text-align: center;
}
`
export const Icon = styled.div`
color:#FF4C00
`
export const TitleCard = styled.div`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 10px;
text-align: center;
color: #080808;

`
export const Tam = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 12px;
text-align: center;
color: #6D6D6D;
`
export const Info = styled.div`
font-style: normal;
font-weight: 500;
font-size: 14px;
text-align: center;
color: #6D6D6D;
`
export const Prevew = styled.div`
display:flex;
flex-direction:column;
justify-content:start;
align-items:center;
text-align:center;
width:100%;
`

export const PrevewImg = styled.img`
	margin-right: 15px;
	width: 320px;
	margin-bottom: 8px;
`


export const ButtonNewSeasons = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
margin-top:40px;
padding: 16px 48px;
gap: 8px;
width: 100%;
height: 84px;
cursor: pointer;
border:none;
border-radius: 8px;
font-style: normal;
font-weight: 700;
font-size: 14px;
text-align: center;
transition: filter 0.2;
    &:hover{
    filter: brightness(0.9)
    }
`

export const Submit = styled.button`
padding: 12px 32px;
  gap: 8px;
  height: 48px;
  background: #FF4C00;
  border-radius: 8px;
  border:none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #FFFFFF;
  margin-top:30px;
  width: 50rem;
  margin-bottom: 80px;
  cursor: pointer;
  transition: filter 0.2;
    &:hover{
    filter: brightness(0.9)
    }
`

export const StyledFormControlLabel = styled.div`
   background: #DCDDDF;
   padding-right: 10px;
   border-radius: 8px;
   margin-right: 10px;
   height: 45px;
`
