import React, { Component } from 'react';
import { string, node, bool } from 'prop-types';
import {
  AppBar,
  Tabs,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Tab,
  Toolbar,
} from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { Container } from './styles';

class TabsBar extends Component {
  static propTypes = {
    title: string,
    children: node.isRequired,
    goBack: bool,
  };

  static defaultProps = {
    title: null,
    goBack: false,
  };

  state = {
    value: 0,
    mobileMoreAnchorEl: null,
  };

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleMobileMenu = (e) => {
    const { mobileMoreAnchorEl } = this.state;

    if (mobileMoreAnchorEl) {
      this.setState({ mobileMoreAnchorEl: null });
    } else {
      this.setState({ mobileMoreAnchorEl: e.currentTarget });
    }
  };

  renderMobileMenu = () => (
    <Menu
      anchorEl={this.state.mobileMoreAnchorEl}
      keepMounted
      open={Boolean(this.state.mobileMoreAnchorEl)}
      onClose={this.handleMobileMenu}
    >
      <MenuItem>
        <p>Novo Módulo</p>
      </MenuItem>
      <MenuItem>
        <p>Preview</p>
      </MenuItem>
      <MenuItem onClick={() => {}}>
        <p>Remover</p>
      </MenuItem>
    </Menu>
  );

  render() {
    const { value } = this.state;
    const { title, children, goBack } = this.props;
    const tabs = React.Children.toArray(children).filter(e => e);

    return (
      <Container>
        <AppBar position="static" color="default" className="tabsBar">
          {title && (
            <Toolbar variant="regular" className="header">
              {goBack && (
                <IconButton color="primary">
                  <ArrowBack />
                </IconButton>
              )}
              <Typography component="h2" variant="h5" className="title">
                {title}
              </Typography>
            </Toolbar>
          )}
          {!title && <br />}
          {tabs.length > 1 && (
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
              value={value}
              onChange={this.handleChange}
              backgroundColor="#141414"
            >
              {tabs.map(child => <Tab key={child.props.tabName} label={child.props.tabName} />)}
            </Tabs>
          )}
        </AppBar>
        {tabs.filter((child, i) => i === value && child)[0] || tabs[0]}
      </Container>
    );
  }
}

export default TabsBar;
