import React from 'react';
import Dialog from '../../../components/Helpers/Dialog';
import GlobalContext from '../../../context';

const ModalRemoveQuestion = () => {
  const {
    selectedCourse: {
      questionBank: {
        modalRemoveQuestionOpen,
        setModalRemoveQuestionOpen,
        removeObjectiveQuestion,
        removeTrueFalseQuestion,
        removeSubjectiveQuestion,
        getAlgorithmBank,
        question,
        setQuestion,
      },
    },
  } = GlobalContext();

  const clean = () => {
    setQuestion({});
  };

  const cleanAndClose = () => {
    clean();
    setModalRemoveQuestionOpen(false);
  };

  const confirm = async () => {
    let removeQuestion;

    switch (question.__typename) {
      case 'ObjectiveQuestion':
        removeQuestion = removeObjectiveQuestion;
        break;
      case 'TrueFalseQuestion':
        removeQuestion = removeTrueFalseQuestion;
        break;
      case 'SubjectiveQuestion':
        removeQuestion = removeSubjectiveQuestion;
        break;
      default:
        return;
    }

    await removeQuestion({
      variables: {
        id: question.id,
      },
    });
    cleanAndClose();
    getAlgorithmBank.refetch();
  };

  return (
    <Dialog
      open={modalRemoveQuestionOpen}
      colorConfirm="secondary"
      textConfirm="Remover"
      onConfirm={confirm}
      onCancel={cleanAndClose}
      title="Remover questão"
    >
            Você tem certeza que deseja esta questão?
    </Dialog>
  );
};

export default ModalRemoveQuestion;
