import styled, { keyframes } from "styled-components";

const fade = keyframes`
from {opacity: .4}
to {opacity: 1}
`;

export const SlideshowContainer = styled.div`
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
  overflow: hidden;
  @media(max-width: 1300px) {
    height: 100%;
  }

`;

export const SlideshowContainerMobile = styled.div`
  display:flex;
  flex-direction: column;
  justify-content:start;
  align-items:end;
  height: 100%;
  width: 100vw;
  overflow-x: hidden;
`;

export const SliderInfo = styled.div`
    background-image: linear-gradient(
      to bottom,
      rgb(20, 20, 20, 0.5),
      rgb(8, 8, 8)
    ),
    url("${(props) => props.backgroundUrl}");
    background-size: 100%;
    background-repeat: no-repeat;
    transition-duration: 2s ease;
    overflow-x: hidden;
`;
export const SlideInfo = styled.div`
  width: 80vw;
  padding-left: 140px;
  margin-bottom:30px;
  @media(max-width: 900px) {
    width: 100vw;
    padding-left: 80px;
  }
  @media(max-width: 600px) {
    width: 100vw;
    padding-left: 25px;
    padding-right: 3px;

  }
`;
export const CourseInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  text-align: justify;
  width: 40vw;
  height: 400px;
  line-height: 1.5;
  padding-top: 80px;
  animation-name: ${fade};
  animation-duration: 8s;
  @media(max-width: 900px) {
    height: 380px;
    width: 80vw;
  }
  @media(max-width: 600px) {
    width: 60vw;
    height:100%;
  }

`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  @media(max-width: 600px) {
  justify-content: space-between;
   padding-top: 250px;
   align-items: center;
   margin-top:180px;

  }
  @media(max-width: 375px) {
    margin-top:100px;
   }
   @media(max-width: 360px) {
    margin-top:80px;
   }
`;
export const Button = styled.button`
  padding: 18px 18px;
  margin-right: 10px;
  border-radius: 16px;
  border-style: none;
  align-items: center;
  display: flex;
  height: 48px;
  flex-direction: row;
  cursor: pointer;
  transition: filter 0.2;
  &:hover{
  filter: brightness(0.9)
  }
`;

export const ButtonMobile = styled.button`

  padding: 18px 18px;
  margin-right: 10px;
  border-style: none;
  align-items: center;
  display: flex;
  flex-direction:column;
  background: transparent;
  height: 48px;
  cursor: pointer;
  transition: filter 0.2;
  &:hover{
  filter: brightness(0.9)
  }
`

export const ProgressSlider = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px 140px;
  width: 100vw;
  @media(max-width: 900px) {
  padding: 5px 80px;
  }

`;

export const ProgressVideo = styled.div``;

export const Prev = styled.button`
  cursor: pointer;
  display:none;
  background: none;
  border: none;
  &:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }
  @media(max-width: 700px) {
    display: none;
    }
  @media(max-width: 500px) {
  display: none;
  }

`;

export const Next = styled.button`
  display:none;
  cursor: pointer;
  background: none;
  border: none;
  &:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }
  @media(max-width: 700px) {
    display: none;
     }
`;


export const HeroDesktop = styled.div`
@media(max-width: 600px) {
  display: none;
   }
`;

export const HeroMobile = styled.div`
display:none;
@media(max-width: 600px) {
  display: block;
   }
`;
