import styled from 'styled-components';

const Container = styled.main`
background: #312f30;
border-radius: 6px;
padding: 40px;
padding:
  .lesson {
    margin: 30px 0;
  }

  h2 {
    margin: 20px 0;
    color: #5A5A5A;
  }

  .answer {
    margin-top: 20px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;

    .MuiTextField-root {
      display: inline-block;
      vertical-align: middle;
      width: calc(100% - 140px);
    }

    .MuiInputBase-root {
      width: 100%;
      color: #A6A6A6;
    }

    .MuiButton-root {
      display: inline-block;
      vertical-align: middle;
    }
    .Description{
      text-align:center;
      color:#DCDDDF;
      margin-bottom:60px;
      margin-top:30px;
    }
    @media (max-width: 600px)
    {
    display:flex;
    flex-direction:column;
    }
  }
  .input {
    position: relative;
    margin-bottom:16px;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(.5rem * 0.75) calc(.5rem * .5);
      margin: calc(.5rem * 0.75 + 3px) calc(.5rem * .5);
      background: none;
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }
    &__field {
      box-sizing: border-box;
      display: block;
      width: 100%;
      border: 2px solid #e84810;
      padding: calc(.5rem * 1.5) .5rem;
      color: currentColor;
      background: transparent;
      border-radius: 4px;
      }
    }
  }
`;

export default Container;
