import React, { useEffect, useState } from "react";
import { shape, func } from "prop-types";
import { withRouter } from "react-router-dom";
import { Grid} from "@material-ui/core";
import { useMutation, useQuery } from '@apollo/react-hooks';
import Alert from '@material-ui/lab/Alert'
import CourseCard from "../../components/CourseCard";
import gql from 'graphql-tag';
import GlobalContext from "../../context";
import { Linha, Title , ContainerList, TitlePage, Container} from "./styles";
import ReactHtmlParser from 'react-html-parser';
import AppBarWrapper from "../../components/AppBarWrapper";
import Footer from "../../components/Footer";

export const GET_TERMS = gql`
  query {
    getTermsConditions {
      id
      text
    }
  }
`;


const Terms = ({ history }) => {

  const {data: dataTermos, loading} = useQuery(GET_TERMS, {
    onCompleted: e => {
      console.log(dataTermos.getTermsConditions)
    }
  })

  return (
    <div>
    <AppBarWrapper/>
      <Container maxWidth="md" style={{height:"100%", backgroundColor: "#080808"}}>

      <ContainerList>
      <TitlePage>
        <strong>
          Termos de Uso
        </strong>
        </TitlePage>
       </ContainerList>
       <Container style={{height:"100%", padding: '0 10% 0 10%',}}>
       <div>
          { ReactHtmlParser (dataTermos.getTermsConditions ? dataTermos?.getTermsConditions[0]?.text : null)}
        </div>

       </Container>

       </Container>
    <Footer/>
    </div>
  );
};

Terms.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(Terms);
