import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { shape, func } from 'prop-types';
import Icon from '@material-ui/core/Icon';
import {
  Button,
  Grid,
  FormControl,
  Typography,
  InputLabel,
  MenuItem,
  Paper,
  Link,
  Select,
} from '@material-ui/core';

import GlobalContext from '../../context';

import {
  Main,
} from './styles';

import TabsBar from '../../components/TabsBar';
import AppBarWrapper from '../../components/AppBarWrapper';

import { getReportTypes } from './service';
import { ReportsProvider } from '../../context/reports';
import Footer from '../../components/Footer';

const Reports = ({ history }) => {
  const reportTypes = getReportTypes();
  const [course, setCourse] = useState({
    id: 0,
    name: '',
  });
  const {
    user: { institution: { id } },
    selectedCourse: {
      getCoursesByInstitution,
    },
    visualization: { visualization },
  } = GlobalContext();

  const isManager = ['Administrador', 'Gestor'].includes(visualization);

  useEffect(() => {
    getCoursesByInstitution.fetch({ variables: { id } });
  }, []);

  if (!getCoursesByInstitution.data) {
    return 'Carregando...';
  }
  const { data: { getInstitutionById: { courses } } } = getCoursesByInstitution;

  const handleChange = (e) => {
    const {
      value,
    } = e.target;
    const parsed = JSON.stringify(value);
    localStorage.setItem('course', parsed);
    setCourse(value);
  };

  const renderOptions = () => courses.map((dt, i) =>
  // console.log(dt);
    (
      <MenuItem
        label="Selecione um curso"
        value={dt}
        key={i}
        name={dt.name}
      >
        {dt.name}

      </MenuItem>
    ));

  const isCourseSelected = (e) => {
    if (course == null) {
      e.stopPropagation();
    }
  };

  return (
    <ReportsProvider value={course}>
      <AppBarWrapper user={{ avatar: null, name: 'Alfred' }}>
        <Main maxWidth="md">
          <Grid container>
            <TabsBar title="Relatórios">
              <form autoComplete="off">
                <FormControl
                  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', color: '#DCDDDF' }}
                >
                  <br />
                  <Typography variant="h6" gutterBottom>
                  Informe o curso que deseja emitir os relatórios:
                  </Typography>
                  <br />
                  <FormControl required>
                    <InputLabel htmlFor="course-select"
                    style={{color: '#FF4C00'}}>Selecione o curso</InputLabel>
                    <Select
                      value={course}
                      onChange={handleChange}
                      style={{ width: 400, fullWidth: true, display: 'flex', color: '#DCDDDF'}}
                    >
                      {renderOptions()}
                    </Select>
                  </FormControl>
                  <br />
                  <Typography variant="h6" gutterBottom>
                  Escolha o tipo de relatório que deseja emitir:
                  </Typography>
                  <br />
                  <Grid item xs={12}>
                    <Grid container justify="center" spacing="2">
                      {Object.keys(reportTypes).map(key => (
                        <Link href={reportTypes[key].path}>
                          <Button xs={4}>
                            <Grid style={{ width: 184, height: 191 }} key={reportTypes[key].value} item>
                              <Paper style={{ width: 184, height: 191 }} rounded>
                                <Icon style={{ color: '#044C6C', fontSize: 80, marginTop: 20 }}>{reportTypes[key].icon}</Icon>
                                <Typography component="h5">
                                  {reportTypes[key].value}
                                </Typography>
                              </Paper>
                            </Grid>
                          </Button>
                        </Link>
                      ))}
                    </Grid>
                  </Grid>
                </FormControl>
              </form>
            </TabsBar>
          </Grid>
        </Main>
        <Footer />
      </AppBarWrapper>
    </ReportsProvider>
  );
};

Reports.propTypes = {
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
};

export default withRouter(Reports);
