import styled from 'styled-components';

export const StylePublisher = styled.div`
background-color: #FFFFFF;
margin:0;
padding:0;
header: 100vh;
width:100vw;
overflow-x:hidden;
`

