import React, { useEffect } from 'react';
import {
  IconButton,
  Button,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
} from '@material-ui/core';
import {
  Add, Close, Save, Cancel, Delete,
} from '@material-ui/icons';
// SpeeDial imports
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import {
  StyledDialog, Main, StyledContainer, NewTagDialog,
} from './styles';

import { GET_TAGS } from '../../services/queries/tag';

import TagList from './TagList';
import TabsBar from '../../components/TabsBar';
import AppBarWrapper from '../../components/AppBarWrapper';
import ListComponent from './listComponent';
import GlobalContext from '../../context';
import Footer from '../../components/Footer';

const Tags = () => {
  const {
    tags: {
      doDelete,
      newTagDescription,
      newTagName,
      open,
      selectedTags,
      setDoDelete,
      setNewTagDescription,
      setNewTagName,
      setOpen,
      setSelectedTags,
      setShowModal,
      showModal,
      getTags,
      createTag,
      removeTag,
    },
  } = GlobalContext();

  useEffect(() => {
    getTags.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addTag = () => setShowModal(prev => !prev);

  const showDeleteDialog = () => {
    setDoDelete(true);
  };

  const actions = [
    {
      id: 'ADD',
      icon: <Add />,
      name: 'Adicionar TAG',
      handleClick: () => addTag(),
    },
    {
      id: 'REMOVE',
      icon: <Delete />,
      name: 'Remover TAG(s)',
      handleClick: () => showDeleteDialog(),
    },
  ];

  const handleClickModal = () => {
    setNewTagName('');
    setNewTagDescription('');
    setShowModal(false);
    setDoDelete(false);
  };

  const handleClickCancel = () => {
    setNewTagName('');
    setNewTagDescription('');
    setShowModal(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const name = newTagName;
    const description = newTagDescription;
    await createTag({
      variables: {
        name,
        description,
      },
      refetchQueries: [{ query: GET_TAGS }],
    });
    handleClickModal();
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    const promises = [];
    for (let i = 0; i < selectedTags.length; i += 1) {
      const { id } = selectedTags[i];
      promises.push(
        removeTag({
          variables: {
            id,
          },
          refetchQueries: [{ query: GET_TAGS }],
        }),
      );
    }
    Promise.all(promises).then(() => {
      setSelectedTags([]);
    });

    handleClickModal();
    setOpen(false);
  };

  const handleNameChange = (e) => {
    setNewTagName(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setNewTagDescription(e.target.value);
  };

  const handleTagChecked = (id, name, selected) => {
    if (selected) {
      setSelectedTags(prev => [...prev, { id, name }]);
    } else {
      const arr = [...selectedTags];
      const ids = arr.map(element => element.id);
      const index = ids.indexOf(id);
      if (index !== -1) {
        arr.splice(index, 1);
        setSelectedTags(arr);
      }
    }
  };

  const getText = () => 'Voce tem certeza que deseja remover os seguintes TAGs?';

  /**
   * Renders the Adicionar Tag dialog
   */
  const renderNewTagDialog = () => (
    <NewTagDialog
      onClose={() => false}
      aria-labelledby="customized-dialog-title"
      open={showModal}
    >
      <form onSubmit={addTag} noValidate autoComplete="off">
        <DialogTitle
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          disableTypography
          id="edit-tag-dialog-title"
          className="titleModal"
          onClose={handleClickModal}
        >
          <Typography variant="h5">Adicionar TAG</Typography>
          <IconButton aria-label="Save" className="saveButton" onClick={() => {}}>
            <Save />
          </IconButton>
          <IconButton aria-label="Cancel" className="CancelButton" onClick={handleClickCancel}>
            <Cancel />
          </IconButton>
          <IconButton aria-label="Close" className="closeButton" onClick={handleClickModal}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-name"
                label="Nome"
                value={newTagName}
                fullWidth
                onChange={handleNameChange}
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-description"
                label="Descrição"
                value={newTagDescription}
                fullWidth
                onChange={handleDescriptionChange}
                margin="normal"
                variant="outlined"
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickModal} variant="contained" color="default">
            Cancelar
          </Button>
          <Button
            type="submit"
            onClick={handleSubmit}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </DialogActions>
      </form>
    </NewTagDialog>
  );

  /**
   * Renders the delete tag confirmation dialog
   */
  const renderDeleteDialog = () => (
    <StyledDialog
      onClose={() => false}
      aria-labelledby="customized-dialog-title"
      open={doDelete}
    >
      <DialogTitle
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
        disableTypography
        id="edit-tag-dialog-title"
        className="titleModal"
        onClose={() => handleClickModal()}
      >
        <Typography variant="h5">Remover TAG</Typography>
        <IconButton aria-label="Close" className="closeButton" onClick={handleClickModal}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <span className="content">{getText()}</span>
      </DialogContent>
      <ListComponent tags={selectedTags} />
      <DialogActions>
        <Button onClick={handleClickModal} variant="contained" color="default">
          Cancelar
        </Button>
        <Button
          type="submit"
          onClick={handleDelete}
          variant="contained"
          color="primary"
        >
          Remover
        </Button>
      </DialogActions>
    </StyledDialog>
  );

  const renderTags = () => {
    if (!getTags.data) {
      return (
        <Grid xs={12} item>
          <Typography className="label" component="h2">
            Carregando...
          </Typography>
        </Grid>
      );
    }

    const { getTags: tags } = getTags.data;

    if (tags.length === 0) {
      return (
        <Grid xs={12} item>
          <Typography className="label" component="h2">
            Nenhuma Tag cadastrada
          </Typography>
        </Grid>
      );
    }

    return <TagList tags={getTags} handleTagChecked={handleTagChecked} />;
  };

  return (
    <AppBarWrapper user={{ avatar: null, name: 'Alfred' }}>
      <Main maxWidth="md">
        <Grid container>
          <TabsBar title="Tags">
            <StyledContainer>
              <Grid container spacing={2}>
                {renderTags()}
                <SpeedDial
                  ariaLabel="Criar TAG"
                  className="speedDial"
                  hidden={false}
                  icon={<SpeedDialIcon />}
                  onBlur={() => setOpen(false)}
                  onClick={() => setOpen(true)}
                  onClose={() => setOpen(false)}
                  onFocus={() => setOpen(true)}
                  onMouseEnter={() => setOpen(true)}
                  onMouseLeave={() => setOpen(false)}
                  open={open}
                >
                  {actions.map(action => (
                    <SpeedDialAction
                      disabled={action.id === 'REMOVE' && selectedTags.length < 1}
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={action.handleClick}
                    />
                  ))}
                </SpeedDial>
              </Grid>
            </StyledContainer>
          </TabsBar>
        </Grid>
      </Main>
      {renderNewTagDialog()}
      {renderDeleteDialog()}
      <Footer />
    </AppBarWrapper>
  );
};

export default Tags;
