import React, { useEffect } from "react";
import gql from 'graphql-tag';
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Typography, Grid, Container } from "@material-ui/core";
import GlobalContext from "../../../context";
import { Title, Linha, ContainerSubscribed, LessonMobile, LessonDesktop} from "./styles";
import CardEpisodes from "../../../components/CardsCourse/CardEpisodes";
import CustomSliderLesson from "../../../components/Sliders/CustomSliderLesson";
import Slider from "react-slick";

export const VIEW_UNIT = gql`
  mutation viewUnitVideoPlayHistoric($UnitId: String!, $ElapsedTime: Int, $Ended: Boolean) {
    viewUnitVideoPlayHistoric(UnitId: $UnitId, ElapsedTime: $ElapsedTime, Ended: $Ended)
  }
`;


const SubscribedEpisodes = () => {

  const [viewUnit] = useMutation(VIEW_UNIT, {
    onCompleted: e => console.log(e),
    onError: e=> console.log(e)
  });

  const {
    selectedCourse: {
      course: { id },
      courseAccess,
      getModulesByCourse,
    },
    selectedLesson: { lesson, setLesson },
    visualization: { visualization },
  } = GlobalContext();

  const history = useHistory();

  useEffect(() => {
    getModulesByCourse.fetch({
      variables: { id, filter: visualization === "Estudante" },
    });
  }, []);

  if (!getModulesByCourse.data) {
    return "Carregando...";
  }



  const {
    data: {
      getCourseById: { modules},
    },
  } = getModulesByCourse;

  const selectLesson = (_lesson) => {
    viewUnit({
      variables: {
        UnitId: _lesson.id
      }
    })
    setLesson(_lesson);
    history.push("/lesson");
  };

  console.log("selectLesson", selectLesson)

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "6px",
    slidesToShow: 1,
    speed: 500,
    arrows: false
  };
  return modules[0]?.units?.length > 1 && courseAccess ? (
    <div>
      <Container>
      <Title>
        <h3 style={{
        paddingRight: "10px",
        color:"#ffffff"}}>
          Episódios
          </h3>
        <Linha></Linha>
      </Title>

    {/* Inicio do card */}
    <ContainerSubscribed>
          <LessonDesktop>
      <CustomSliderLesson
        itemWidth={400}
        modules={modules[0]?.units?.map((unit) => (
            <CardEpisodes
              key={unit.id}
              course={unit}
              enterFunction={selectLesson}
              checkFunction={null}
              editFunction={null}
            >
            </CardEpisodes>
        ))} />
</LessonDesktop>

<LessonMobile>
<Slider {...settings}>
        {modules[0]?.units?.map((unit) => (
            <CardEpisodes
              key={unit.id}
              course={unit}
              enterFunction={selectLesson}
              checkFunction={null}
              editFunction={null}
            >
            </CardEpisodes>
             ))}
</Slider>
</LessonMobile>



        {modules[0]?.units.length === 0 && (
          <Typography variant="h6" style={{ margin: 20 }}>
            Nenhuma aula adicionada
          </Typography>
        )}
 </ContainerSubscribed>
      {/* fim do card */}
      </Container>
    </div>
  ) : null;
};

export default SubscribedEpisodes;
