import styled from 'styled-components';

export const NavBar = styled.div`
width: 100vw;
height: 80px;
display: flex;
background-color: #FFFFFF;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
border-bottom: 1px solid #DCDDDF;
padding: 0 30px;
color: #080808;
overflow:hidden;
`

export const Visualization = styled.div`
display:flex;
align-items:center;
`

export const VisualizationButton = styled.button`
display: flex;
border:none;
cursor:pointer;
align-items: center;
padding: 8px 16px;
height: 28px;
background: #EDEDED;
border-radius: 16px;
margin-left: 16px;
`

export const Logout = styled.div`
display:flex;

`

export const LinkLogout = styled.a`
cursor:pointer;
text-decoration:none;
`
