import styled from 'styled-components';
import {
  Typography, Container, Chip, Dialog,
} from '@material-ui/core';

export const StyledContainer = styled(Container)`
padding: 20px;
margin-top:60px;
background: #312f30;
color: #c6c6c6;
.button-grid {
  text-align: left;
}
.pr {
  float: inline-end;
  > * {
    margin-left: 16px;
  }
}
`;

export const QuestionTitle = styled(Typography)`
  && {
    font-weight: normal;
    font-size: 20px;
  }
`;

export const StyledChip = styled(Chip)`
  && {
    margin-right: 8px;
  }
`;

export const StyledDialog = styled(Dialog)`
  .paper {
    width: 100%;
  }
`;
