import React from 'react';
import {Bar} from 'react-chartjs-2';
import {
  Grid,
  Typography,
} from '@material-ui/core';

import {
  DescriptionPaper,
  TypographyReport,
} from './../styles';

export function getEvaluativeActivitiesReport(report) {
  if(!report)
    return ("Carregando...");

  function getChartProps () {
    return ({
      maintainAspectRatio: false, 
      scales: {
        yAxes: [{
          ticks: {
            beginAtZero: true,
            min: 0
          }    
        }]
      },
    });
  }

  function mountGraphics (array) {
      return (
        {
          labels: ['Fraco', 'Médio', 'Bom', 'Excelente'],
          datasets: [{
            label: 'Historograma de conceitos',
            backgroundColor: '#036B9A',
            borderColor: '#036B9A',
            data: [array],
          }]
        }
      );
  }

  return (
    report.map(activity => (
      <Grid style={{marginLeft: "10%", marginRight: "10%"}} container>
        <DescriptionPaper square={true}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TypographyReport gutterBottom variant="p">
              Atividade: <b>{activity.name}</b>
              </TypographyReport>
            </Grid>
            <Grid item xs={4}>
              <TypographyReport gutterBottom variant="p">
              Módulo: <b>{activity.module.name}</b>
              </TypographyReport>
            </Grid>
            <Grid item xs={4}>
              <TypographyReport gutterBottom variant="p">
              Total de respostas: <b>{activity.totalAnswered}</b>
              </TypographyReport>
            </Grid>
          </Grid>
        </DescriptionPaper>  
        <Grid container >
          <Grid xs={8}>
            <Typography variant="p">
              <b>Enunciado</b>
            </Typography>
            <br/>
            <Typography variant="p" style={{textAlign: "justify" }}>
              {activity.description}
            </Typography>
          </Grid>
          <Grid xs={4}>
            <Bar 
              data={mountGraphics(activity.quantity)}
              options={getChartProps()}
              height={'50%'}
              width={'50%'}
              />
          </Grid>
        </Grid>
      </Grid>
      ))
  );
}
