import React, { useState } from "react";
import {
  CircularProgress,
} from '@material-ui/core';
import { MdCircle } from "react-icons/md";
import { ColumnTitle, GridIndexes, GridIndexesItem, HeaderIndex, NumberIndex, TableComponent, TableContainer, TableData, TitleFlex, TitleIndex, Total } from "./styles";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import gql from 'graphql-tag';

export const GET_USERS = gql`
  query {
    getUsersByRole{
        usersActive {
            id
            name
            users {
                fullName
            }
        }
        usersInactive {
            id
            name
            users {
                fullName
            }
        }
    }
  }
`;

const Indexes = () => {
  const [alunos, setAlunos] = useState([])
  const [professores, setProfessores] = useState([])
  const [coordenadores, setCoordenadores] = useState([])
  const [admins, setAdmins] = useState([])

  const { data: dataUsers, loading } = useQuery(GET_USERS, {
    onCompleted: (e) => {
     console.log(e)
    },
    fetchPolicy: 'no-cache',
  })

  const countActivesAdmins = () => dataUsers.getUsersByRole && dataUsers.getUsersByRole?.usersActive.find(x => x.name === 'Administrador').users.length

  const countInactivesAdmins = () => dataUsers.getUsersByRole && dataUsers.getUsersByRole?.usersInactive.find(x => x.name === 'Administrador').users.length

  const countActivesProfessores = () => dataUsers.getUsersByRole && dataUsers.getUsersByRole?.usersActive.find(x => x.name === 'Professor').users.length

  const countInactivesProfessores = () => dataUsers.getUsersByRole && dataUsers.getUsersByRole?.usersInactive.find(x => x.name === 'Professor').users.length

  const countActivesAlunos = () => dataUsers.getUsersByRole && dataUsers.getUsersByRole?.usersActive.find(x => x.name === 'Estudante').users?.length

  const countInactivesAlunos = () => dataUsers.getUsersByRole &&  dataUsers.getUsersByRole?.usersInactive.find(x => x.name === 'Estudante').users?.length

  const countActivesCoordenadores = () => dataUsers.getUsersByRole && dataUsers.getUsersByRole?.usersActive.find(x => x.name === 'Gestor').users.length

  const countInactivesCoordenadores = () => dataUsers.getUsersByRole && dataUsers.getUsersByRole?.usersInactive.find(x => x.name === 'Gestor').users.length



    return (
      <GridIndexes>

        {/* Alunos */}
        <GridIndexesItem>
          <HeaderIndex>
            <TitleFlex>
              <Total>
              Total
              </Total>
              <TitleIndex>
              Alunos
              </TitleIndex>
            </TitleFlex>
            {!loading ? (
            <NumberIndex>
              {countActivesAlunos() + countInactivesAlunos()}
            </NumberIndex>
            ): (
              <div style={{justifyContent: 'end', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
            )}
            
          </HeaderIndex>

          <TableContainer style={{height:"50"}}>
        <TableComponent>
          <tbody>

        <TableData>
        <td>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#12805C"}}/>
          Ativos</li>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#C62828"}}/>
          Inativos</li>
        </td>
        <td>
          <li style={{listStyleType:"none"}}>{countActivesAlunos()}</li>
          <li style={{listStyleType:"none"}}>{countInactivesAlunos()}</li>
        </td>
      </TableData>
      </tbody>
        </TableComponent>
       </TableContainer>

        </GridIndexesItem>

        {/* Professores */}
        <GridIndexesItem>
          <HeaderIndex>
            <TitleFlex>
              <Total>
              Total
              </Total>
              <TitleIndex>
              Professores
              </TitleIndex>
            </TitleFlex>

            {!loading ? (
            <NumberIndex>
            {countActivesProfessores() + countInactivesProfessores()}
            </NumberIndex>
            ): (
              <div style={{justifyContent: 'end', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
            )}
            
          </HeaderIndex>

          <TableContainer style={{height:"50"}}>
        <TableComponent>
          <tbody>

        <TableData>
        <td>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#12805C"}}/>
          Ativos</li>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#C62828"}}/>
          Inativos</li>
        </td>
        <td>
          <li>{countActivesProfessores()}</li>
          <li>{countInactivesProfessores()}</li>
        </td>
      </TableData>
      </tbody>
        </TableComponent>
       </TableContainer>

        </GridIndexesItem>

        {/* Coordenadores */}

        <GridIndexesItem>
          <HeaderIndex>
            <TitleFlex>
              <Total>
              Total
              </Total>
              <TitleIndex>
              Coordenadores
              </TitleIndex>
            </TitleFlex>
            {!loading ? (
            <NumberIndex>
            {countActivesCoordenadores() + countInactivesCoordenadores()}
            </NumberIndex>
            ): (
              <div style={{justifyContent: 'end', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
            )}
            
          </HeaderIndex>

          <TableContainer style={{height:"50"}}>
        <TableComponent>
          <tbody>

        <TableData>
        <td>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#12805C"}}/>
          Ativos</li>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#C62828"}}/>
          Inativos</li>
        </td>
        <td>
          <li>{countActivesCoordenadores()}</li>
          <li>{countInactivesCoordenadores()}</li>
        </td>
      </TableData>
      </tbody>
        </TableComponent>
       </TableContainer>

        </GridIndexesItem>

        {/* Administradores */}
        <GridIndexesItem>
          <HeaderIndex>
            <TitleFlex>
              <Total>
              Total
              </Total>
              <TitleIndex>
              Administradores
              </TitleIndex>
            </TitleFlex>
            {!loading ? (
            <NumberIndex>
            {countActivesAdmins() + countInactivesAdmins()}
            </NumberIndex>
            ): (
              <div style={{justifyContent: 'end', width: '100%', display: 'flex'}}>
              <CircularProgress size={24} color="inherit" />
            </div>
            )}
            
          </HeaderIndex>

          <TableContainer style={{height:"50"}}>
        <TableComponent>
          <tbody>

        <TableData>
        <td>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#12805C"}}/>
          Ativos</li>
          <li style={{display:"flex", alignItems:"center"}}
          ><MdCircle size={18} style={{paddingRight:"10px", color:"#C62828"}}/>
          Inativos</li>
        </td>
        <td>
          <li>{countActivesAdmins()}</li>
          <li>{countInactivesAdmins()}</li>
        </td>
      </TableData>
      </tbody>
        </TableComponent>
       </TableContainer>

        </GridIndexesItem>
      </GridIndexes>
      )}
export default Indexes;
