import React, { useEffect } from 'react';
import {
  Grid, Button, Table, TableHead, TableRow, TableCell, TableBody, Icon, Menu, MenuItem, Typography, Paper, TableContainer, Divider,
} from '@material-ui/core';
import CheckCircleOutline from '@material-ui/icons/CheckCircleOutline';
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import GlobalContext from '../../../context';
import {
  StyledContainer, StyledTableCell, StyledButton, StyledTableRow,
} from './styles';
import ModalNewTest from './ModalNewTest';
import ModalDelete from './ModalDelete';
import ModalNewAlgorithmTest from './ModalNewAlgorithmTest';
import AppBarWrapper from '../../../components/AppBarWrapper';
import Footer from '../../../components/Footer';

const ExercisesList = ({ history, CourseId }) => {
  const {
    selectedCourse: {
      course,
      exercises: {
        getExercises,
        setNewTestDialogOpen,
        setDeleteDialogOpen,
        setRemovedExercise,
        setModalEdit,
        setSelectedExercise,
        setNewAlgorithmTestOpen,
        anchorEl,
        setAnchorEl,
        isMenuOpen,
        setIsMenuOpen,
        showIntelligentTests,
        setShowIntelligentTests,
      },
    },
    visualization: {
      visualization,
    },
    user,
  } = GlobalContext();

  useEffect(() => {
    getExercises.fetch({
      variables: {
          id: CourseId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!getExercises.data) {
    return 'Carregando...';
  }

  const { getCourseById: { modules } } = getExercises.data;

  const convertDate = (dateString) => {
    const date = new Date(dateString);

    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().slice(2)} às ${date.getHours()}:${date.getMinutes()}`;
  };

  const handleEdit = (e, i) => {
    e.stopPropagation();
    setModalEdit(i);
    setNewTestDialogOpen(true);
  };

  const renderExercises = () => {
    const exercises = [];

    modules.forEach((i) => {
      if (i.exercises) {
        i.exercises.forEach((j) => {
          if (showIntelligentTests === !!j.isIntelligentTo) {
            if (
              (visualization === 'Estudante' && showIntelligentTests === true && j.isIntelligentTo && j.isIntelligentTo.id === user.id)
              || (visualization === 'Estudante' && showIntelligentTests === false)
              || visualization !== 'Estudante'
            ) {
              exercises.push(j);
            }
          }
        });
      }
    });

    return exercises.map(i => (
      <StyledTableRow
        key={i.id}
        onClick={() => {
          setSelectedExercise(i);
          history.push('/exercise');
        }}
      >
        <StyledTableCell>
          <Icon component={CheckCircleOutline} fontSize="large" />
        </StyledTableCell>
        <StyledTableCell>
          <div className="title">{i.title}</div>
          <div>{i.description}</div>
        </StyledTableCell>
        <StyledTableCell>{convertDate(i.startDate)}</StyledTableCell>
        <StyledTableCell>{convertDate(i.endDate)}</StyledTableCell>
        <StyledTableCell>{i.score}</StyledTableCell>
        {visualization === 'Administrador' && (
          <StyledTableCell>
            <StyledButton
              onClick={e => handleEdit(e, i)}
            >
              <Icon component={Edit} />
            </StyledButton>
            {' '}
            <StyledButton
              onClick={(e) => {
                e.stopPropagation();
                setRemovedExercise(i);
                setDeleteDialogOpen(true);
              }}
            >
              <Icon component={Delete} />
            </StyledButton>
          </StyledTableCell>
        )}
      </StyledTableRow>
    ));
  };

  const buttonAction = () => {
    if (visualization === 'Estudante') {
      setNewAlgorithmTestOpen(true);
    } else {
      setNewTestDialogOpen(true);
    }
  };

  const renderTable = () => (
    <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} size="small" >
        <TableHead>
          <TableRow style={{background:"#080808"}}>
            <TableCell colSpan={2} style={{color: 'transparent'}}> Testes </TableCell>
            <TableCell style={{color: '#DCDDDF'}}> Inicia em </TableCell>
            <TableCell style={{color: '#DCDDDF'}}> Termina em </TableCell>
            <TableCell style={{color: '#DCDDDF'}}> Quantidade de pontos </TableCell>
            {visualization === 'Administrador' && <TableCell style={{color: '#DCDDDF'}}> Ações </TableCell> }
          </TableRow>
        </TableHead>
        <TableBody>
          {renderExercises()}
        </TableBody>
      </Table>
      </TableContainer>
  );

  return (
    <>
    <AppBarWrapper/>
    <StyledContainer maxWidth="lg">

    <Grid container spacing={2}>

        <Grid item xs={12} style={{marginTop: "40px"}}>
          <Grid container justify="space-between">
          <Typography
            className="test-title"
            variant="h6"
            component="span"
            style={{color: '#DCDDDF'}}
          >
            {showIntelligentTests ? 'Atividades inteligentes' : 'Atividades'}
          </Typography>
          <div className="pr">
          <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  history.goBack();
              }}
              >

                Voltar
              </Button>
            {/* <Button
              variant="outlined"
              color="primary"
              onClick={() => setIsMenuOpen(true)}
              buttonRef={ref => setAnchorEl(ref)}
            >
            ...
            </Button> */}
            <Menu
              anchorEl={anchorEl}
              open={isMenuOpen}
              onClose={() => setIsMenuOpen(false)}
            >
              <MenuItem
                onClick={() => {
                  setShowIntelligentTests(p => !p);
                  setIsMenuOpen(false);
                }}
              >
                {showIntelligentTests ? 'Ver atividades' : 'Ver atividades inteligentes'}
              </MenuItem>
            </Menu>
            {(
              (visualization === 'Estudante' && showIntelligentTests === true)
              || (visualization === 'Administrador' && showIntelligentTests === false)
            ) && (
            <Button
              variant="outlined"
              color="primary"
              onClick={buttonAction}
            >
              {visualization === 'Administrador'
                ? 'Novo teste'
                : null}
            </Button>
            )}
          </div>

          </Grid>
          </Grid>
          <Grid item xs={12}>
           {renderTable()}
          </Grid>
        </Grid>

      <ModalNewTest />
      <ModalDelete />
      <ModalNewAlgorithmTest />
    </StyledContainer>
    <Footer/>
    </>
  );
};

ExercisesList.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(ExercisesList);
