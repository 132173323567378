import styled from 'styled-components';

export const TableContainer = styled.section`
    width: 100%;
    padding-bottom:60px;
    overflow: scroll;
    overflow-x:hidden;
    `
export const Container = styled.div`
overflow-x:hidden;
`
export const TableComponent = styled.table`
    width: 100%;
    box-sizing: border-box;
    overflow: scroll;
    overflow-x:hidden;

`
export const ColumnTitle = styled.tr`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    border-bottom: 1px solid #DCDDDF;
    padding: 5px 0;
    box-sizing: border-box;

    th {
        color: #6D6D6D;
        font-size: 14px;
        font-weight: normal;
        margin: 0 15px;
        text-align: center;

    }

`

export const TitleColumn = styled(ColumnTitle)`
   justify-content: space-between
   th {
     padding: 0;
     color: #6D6D6D;
     font-size: 14px;
     font-weight: normal;
     text-align: center;
     column-width: 100px;

    }
    .first{

        text-align: center;


    }
    .firstSerie{

      text-align: center;


}
    .others{
      text-align: center;
      &:last-child{
        text-align:right;
      }

    }
    .othersSeries{
    text-align: right;

    }


`


export const TableData = styled.tr`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
    color:#080808;
    td {
        box-sizing: border-box;
        margin: 0 0px;
        font-size: 14px;
        min-width: 9%;
        max-width: 9%;
        text-align: start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-child{
          text-align: left;
          margin-left:15px;

        }
    }
    &:last-child {
        border-bottom: 1px solid #DCDDDF;
    }
    `

export const TitleData = styled(TableData)`
justify-content: space-between;
td {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space:normal;
  width: 100px;
  column-width: 50px;
  height:150px;
  margin: 0 0 0 0;
  flex-wrap: wrap;
  .datePub{
    display: flex;
  align-items: center;
  white-space:normal;
  width: 100px;
  column-width: 100px;
  height:150px;
  margin: 0 0 0 0;
  flex-wrap: wrap;
  }
    }
`

export const TitleDiv = styled.div`
display: Flex;
flex-direction: row;
.detailsButton{
  button{
    text-transform:none;
    text-decoration: none;
    background-color:transparent;
    border:none;
    cursor:pointer;
    img{
      opacity:0.5;
      &:hover{
        opacity:1
      }
  }
  }
}
.titleThumb{
  position:relative;
  height:180px;
  width:255px;
  object-fit: content;
  border-radius:4px
}

.texts{
  text-overflow: ellipsis;

  h4{

    margin-top:5px;
    margin-bottom: 5px;
    span{
      position:absolute
    }
  }
  margin-left:5px;
  display:flex;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow-y: auto;
  max-height:80%;
  width: 280px;
  font-size: 14px;
.parentalRating{
  p{margin-bottom:15px
  }

}
}
.visibility{

  background-color:rgba(230,230,230,0.8);
  border-radius:4px;
  padding:2px;
  display:flex;
  flex-direction:row;
  width: max-content;
  margin-bottom: 4px;
  /* position:absolute;
  left: 35rem;
  transform: translateY(-20%) */

}
.time{
position: absolute;
background-color: rgba(230,230,230,0.8);
color: #6D6D6D;
padding: 2px;
border-radius:4px;
left: 24.2rem;


}


`
export const ContentData = styled.div`
position:absolute;
left:48.5%;
display:flex;
flex-direction:row;
justify-content:space-between;
td{
  width: 100px;
  column-width: 4px;
  margin: 0;
  flex-wrap: wrap;
  white-space: normal;
  &:last-child{

  }
}


`


export const MoreDiv = styled.div `
/* background-color:transparent;
text-decoration: none;
text-transform: none;
border: none; */
`


export const MoreOptions = styled.div`
    display: flex;
    position: absolute;
    top: 100%;
    left: -200%;
    flex-direction: column;
    background-color: #fff;
    border: 1px transparent solid;
    box-shadow: 5px 5px 12px -2px rgba(0,0,0,0.4);
    border-radius: 8px;
    padding: 12px 12px;
    min-width: 100px;
    width: fit-content;
    justify-content: space-between;
    box-sizing: border-box;
    z-index: 10;
`

export const MoreOptionsRow = styled.div`
    display: flex;
    box-sizing: border-box;
    align-items: center;
    cursor: pointer;
    p {
        line-height: 20px;
        font-size: 14px;
        margin-left: 6px;
    }
`
export const CardContent = styled.table`
    width: 100px;
    height: 55px;
    background: url("${(props) => props.folder}");
    background-size: cover;
`
