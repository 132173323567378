import React, {useState, useContext, useEffect} from 'react';
import { ContainerSeasons, TitleSeasons, Count, FormEpisodes,
Small, TextAreaContainer, Input } from '../../DialogAdd/styles';
import { MdOutlineDone} from 'react-icons/md'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup";
import { NotificationManager } from "react-notifications";
import {useDropzone} from 'react-dropzone';
import Episodes from './Episodes';
import { FormHelperText } from "@material-ui/core";
import { UploadContext } from '../index'
import {
  Typography,
  CircularProgress,
  Box,
  LinearProgress
} from '@material-ui/core';
import AWS from 'aws-sdk'
import GlobalContext from '../../../../../context';
import { createModuleTwo } from './validation';


AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_USER_KEY,
  secretAccessKey: process.env.REACT_APP_S3_USER_SECRET
})

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME},
  region: process.env.REACT_APP_S3_REGION,
})

const params = {
  ACL: 'public-read',
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
};


export const AddSeasons=({course})=> {
  const {
    selectedModule: {
      createModule
    },
    visualization: { visualization },
  } = GlobalContext();
  const {dataModule, setDataModule, data} = useContext(UploadContext);
  const {material, setMaterial} = useState([]);
  const [files, setFiles] = useState([]);
  const [percentState, setPercent] = useState(0)
  const [uploading, setUploading] = useState(false)
  const [count, setCount] = useState(0);

  const handleName = (e) =>{
    const temp = e.target.value;
    setDataModule({...dataModule, name: temp})
    setCount(e.target.value.length);
   }

  console.log("data", dataModule, data)

  let loaded = 0;
  let actualLoaded = 0
  let totalSize = 0;


  const { handleSubmit, register, control, watch, formState: { errors, isValid }  } = useForm({resolver: yupResolver(createModuleTwo)});

  useEffect(()=> {
    console.log('errors')
    console.log(errors)
  }, [errors])

  const {getRootProps, getInputProps} = useDropzone({noKeyboard: true,
    accept: {
      'video/mp4': ['.mp4'],
      'video/webm': ['.webm'],
      'video/ogg': ['.ogg']
    },
    onDrop: acceptedFiles => {
      setFiles(acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
    }
  });


  const handleRemoveFile = name => {
    setFiles(files.filter(file => file.name !== name))
    }

  const delMaterial = name => {
    setMaterial(material.filter(material => material.name !== name))
  }


  const onError = (errors, e) => {
    console.log(errors)
  }

  const CourseId = '' + course.id;
  console.log("CourseId", CourseId)

  const loadVideo = file => new Promise((resolve, reject) => {
    try {
        let video = document.createElement('video')
        video.preload = 'metadata'

        video.onloadedmetadata = function () {
            resolve(this)
        }

        video.onerror = function () {
            reject("Invalid video. Please select a video file.")
        }

        video.src = window.URL.createObjectURL(file)
    } catch (e) {
        reject(e)
    }
})



  const handleSubmitForm = async (props) => {

    console.log({...dataModule})

    totalSize += dataModule?.videos ? dataModule?.videos.reduce((a, b) => a + b.contentFile ? b.contentFile?.size : 0, 0) : 0
    setUploading(true)

    let videosUrls = [];
    let videosDurations = [];

    for (const video of dataModule?.videos) {
      var uploadVideos = myBucket.upload({...params, Body: video, Key: `cientik/${data?.name}/Videos/${video.name}`})
        .on('httpUploadProgress', function(evt) {
          loaded += evt.loaded - actualLoaded
          setPercent(Math.round((loaded / totalSize)*100))
          actualLoaded = evt.loaded
        })
        .promise();

        const url = await uploadVideos.then(function(el, err) {
          actualLoaded = 0
          return el.Location
        });


        const videoDuration = await loadVideo(video)
        videosDurations.push(parseInt(videoDuration.duration))

        videosUrls.push(url)
        continue;
    }

    const legendasUrls = [];
    var i = 0 
    var y = 0
    for (const episodio of dataModule?.subtitles) {
      const legendasEp = []
      for (const legenda of episodio) {
        var uploadLegendas = myBucket.upload({...params, Body: legenda, Key: `cientik/${dataModule?.name}/Legendas/Ep. ${i}/${dataModule?.idiomSubtitles[i][y]}`})
          .on('httpUploadProgress', function(evt) {
            loaded += evt.loaded - actualLoaded
            setPercent(Math.round((loaded / totalSize)*100))
            actualLoaded = evt.loaded
          })
          .promise();

          const url = await uploadLegendas.then(function(el, err) {
            actualLoaded = 0
            return el.Location
          });


          console.log(url)

          legendasEp.push(url)
          
          y++
          continue;
      }
      legendasUrls.push(legendasEp)
      i++
      continue;
    }

    createModule({
      variables: {
        ...dataModule,
        id: '' + course.id,
        videos: videosUrls,
        durationUnit: videosDurations,
        subtitles: legendasUrls
      }
    }).then(e => {
      console.log(e)
      setUploading(false)
      NotificationManager.success("Módulo adicionado com sucesso!");
    })
    .catch(err => {
      console.log(err)
      setUploading(false)
      NotificationManager.error(
        "Não foi possível finalizar a solicitação."
      );
    })
  }

  const renderProgressBar = () => {
    return(
        <>

            <Box  sx={{ display: 'flex', alignItems: 'center', marginTop: '30px', marginBottom: '-20px' }}>
                <Box sx={{ width: '94%', mr: 1 }}>
                    <div>
                        <LinearProgress variant="determinate" value={percentState} />
                    </div>

                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${percentState}%`}</Typography>
                </Box>
            </Box>
            {percentState >= 100 && !uploading ? (
                <Typography style={{marginTop: '20px'}} variant="body2" color="text.secondary">
                <MdOutlineDone style={{width: '12px', height: '12px', marginRight: '5px'}} color="inherit" />
                        Upload feito com sucesso!
                </Typography>
                ):(
                  <Typography style={{marginTop: '20px'}} variant="body2" color="text.secondary">
                  <CircularProgress style={{width: '12px', height: '12px', marginRight: '5px'}} color="inherit" />
                          Seu filme está sendo processado aguarde um estante.
                  </Typography>
                )}

        </>
    )
}

    return(
    <div style={{justifyContent: 'center', display: 'flex'}}>
    <form onSubmit={handleSubmit(handleSubmitForm, onError)}>

    <ContainerSeasons>

<TitleSeasons>
Informações da temporada
</TitleSeasons>

<FormEpisodes style={{marginLeft: "0%"}}>
  <label>
    Nome da temporada <Small>(Obrigatórsio)</Small>
  </label>
   <TextAreaContainer>

   <Controller
    name="nameModule"
    control={control}
    defaultValue={dataModule?.name}
    render={props => (
      <Input type="text"
      name="name"
      required
      defaultValue={dataModule?.name}
      onChange={(e)=>
        { setDataModule({...dataModule, name: e.target.value});
        props.field.onChange(e.target.value);
        setCount(e.target.value.length)}
      }
      placeholder='Insira o título da temporada'
      />
      )}
      />
       <FormHelperText error={Boolean(errors?.name)}>
    {errors?.name?.message}
    </FormHelperText>
      <Count>
      <span>{count}</span>/100
      </Count>
    </TextAreaContainer>
    </FormEpisodes>

<Episodes errors={errors} control={control}/>

</ContainerSeasons>
        {uploading ?
          renderProgressBar() :
          <button type='submit' onClick={handleSubmitForm}>
              Publicar Temporada
          </button>
        }
         </form>
         </div>
    )
}

export default AddSeasons;
