
import { Editor } from '@tinymce/tinymce-react';
import React, { useEffect, useRef, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from 'react-hook-form';
import { GET_TERMS, UPDATE_TERMS } from './terms'
import { NotificationManager } from "react-notifications";
import { FormHelperText } from "@material-ui/core";
import {updateTermsValidation} from './validation'
import { Container, EditorContainer, TextEditorr } from './styles';

export default function TextEditor() {

  const [updateTerms] = useMutation(UPDATE_TERMS, {
    onCompleted: e => {
      NotificationManager.success("Termos de privacidade editado com sucesso!");
    },
    onError: e => {
      NotificationManager.error("Não foi possível editar os termos de privacidade.");
    }
  });

  const {data: dataTermos, loading} = useQuery(GET_TERMS, {
    onCompleted: e => {
      console.log(dataTermos.getTermsConditions)
    }
  })

    const editorRef = useRef(null);
    const [currentDoc, setCurrentDoc] = useState([]);
    console.log("content", currentDoc)


    const { handleSubmit, register, control, watch, formState: { errors, isValid }  } = useForm({resolver: yupResolver(updateTermsValidation)});


    const config={
      "api_key": "dgetbionxhfbz04rlt9bznbdsj3d7ujx7d7x0rc73dulrt0c",
      //criar api_key
  }

    const log = () => {
      if (editorRef.current) {
        const content = editorRef.current.getContent();
    };
  }


    async function handleKeyUp(event) {
        setCurrentDoc({
          content: editorRef.current.getContent()
        });
    };

    const update = (props)=>{
      console.log(props)
      updateTerms({
        variables: {
          text: props.text
        }
      })
    }



    return (
      <>
      <Container>
      <EditorContainer>
        <form onSubmit={handleSubmit(update)}>
        <TextEditorr>
        <Controller
            name="text"
            control={control}
            defaultValue={dataTermos.getTermsPrivacy ?  dataTermos?.getTermsPrivacy[0]?.text : null}
            render={props => (
          <Editor
            apiKey={config.api_key}
            initialValue={dataTermos.getTermsPrivacy ? dataTermos?.getTermsPrivacy[0]?.text : null}
            // onInit={(evt, editor) => editorRef.current = editor}
            // value={currentDoc['content']}
            {...props}
            onChange={e => {props.field.onChange(e.target.getContent())}}
            init={{
              height: 400,
              menubar: false,
              plugins: [
                'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount'
              ],
              toolbar: 'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
          />
            )}
            />
        </TextEditorr>
        <FormHelperText error={Boolean(errors?.text)}>
            {errors?.text?.message}
            </FormHelperText>

         <button type='submit'>
              Editar Termos de Privacidade
          </button>
          </form>
      </EditorContainer>
      </Container>
      </>
    );
  }
