import React, { useState, useEffect } from 'react';
import { NavLink, useHistory, withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { shape, func } from 'prop-types';

import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';

import TopicList from '../../../components/TopicList';
import Dialog from '../../../components/Helpers/Dialog';
import { StyledContainer } from './styles';

import GlobalContext from '../../../context';
import AppBarWrapper from '../../../components/AppBarWrapper';
import Footer from '../../../components/Footer';

export const CourseForum = () => {
  const {
    selectedCourse: {
      course: {
        id,
      },
    },
    forum: {
      topic,
      setTopic,
      getCourseForum,
      createTopic,
      updateTopic,
      removeTopic,
    },
    visualization: { visualization },
  } = GlobalContext();
  const isManager = visualization !== 'Estudante';
  const history = useHistory();

  const setPropByEvent = (e, state, setState) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const [openUpsertTopic, setOpenUpsertTopic] = useState(false);
  const [openRemoveTopic, setOpenRemoveTopic] = useState(false);

  const confirmUpsertTopic = async (t) => {
    if (!t || !t.name || !t.description) {
      NotificationManager.error('Informações obrigatórias: Nome, Descrição');
      return;
    }
    try {
      if (t.id) {
        await updateTopic({ variables: t });
        NotificationManager.success('Tópico atualizado');
      } else {
        await createTopic({ variables: t });
        NotificationManager.success('Tópico criado');
      }
    } catch (e) {
      NotificationManager.error('Ocorreu um erro ao salvar este tópico');
    } finally {
      getCourseForum.refetch();
      setOpenUpsertTopic(false);
    }
  };

  const confirmRemoveTopic = async (t) => {
    try {
      await removeTopic({ variables: t });
      NotificationManager.success('Tópico removido');
    } catch (e) {
      NotificationManager.error('Ocorreu um erro ao remover este tópico');
    } finally {
      getCourseForum.refetch();
      setOpenRemoveTopic(false);
    }
  };

  const selectTopic = (t) => {
    setTopic(t);
    console.log("aqui foi")
    history.push('/course/forum/Answer');
  };

  useEffect(() => {
    getCourseForum.fetch({ variables: { id } });
  }, []);

  if (!getCourseForum.data) {
    return 'Carregando...';
  }
  const { data: { getCourseById: { forum } } } = getCourseForum;
  const noLessonTopics = forum.topics.filter(t => !t.unit);

  return (
    <>
     <AppBarWrapper/>
    <StyledContainer>
        <CardContent style={{  background: "#312f30"}}>


        <Grid container justify="space-between" alignItems="center" style={{ margin: '14px 16px' }}>
            <Grid item>
            <Typography variant="h5"
            style={{textAlign:"start", color:"#DCDDDF"}}>{forum.name}</Typography>
            </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    style={{marginRight:'16px'}}
                    onClick={() => {
                      history.goBack();
                  }}
                  >
                    Voltar
                  </Button>
                  {
              isManager && (
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    onClick={() => {
                      setTopic({ forumId: forum.id });
                      setOpenUpsertTopic(true);
                    }}
                  >
                    Novo Tópico
                  </Button>
              )
            }
          </Grid>
          </Grid>
          {
            noLessonTopics.length > 0 ? (
              <>
                <TopicList
                style={{marginTop:"40px", marginBottom:"30px"}}
                  topics={noLessonTopics.filter(t => t.fixed)}
                  onPin={t => confirmUpsertTopic({ ...t, fixed: true })}
                  onUnpin={t => confirmUpsertTopic({ ...t, fixed: false })}
                  onEdit={(t) => {
                    setTopic(t);
                    setOpenUpsertTopic(true);
                  }}
                  onRemove={(t) => {
                    setTopic(t);
                    setOpenRemoveTopic(true);
                  }}
                  onEnter={(t)=>selectTopic(t)}
                />
                <TopicList
                style={{marginTop:"40px", marginBottom:"30px"}}
                  topics={noLessonTopics.filter(t => !t.fixed)}
                  onPin={t => confirmUpsertTopic({ ...t, fixed: true })}
                  onUnpin={t => confirmUpsertTopic({ ...t, fixed: false })}
                  onEdit={(t) => {
                    setTopic(t);
                    setOpenUpsertTopic(true);
                  }}
                  onRemove={(t) => {
                    setTopic(t);
                    setOpenRemoveTopic(true);
                  }}
                  onEnter={selectTopic}
                />
              </>
            ) : (
              <Grid container justify="center">
                <Grid item>
                  <Typography variant="body1" style={{ padding: '20px' }}>Nenhum tópico criado</Typography>
                </Grid>
              </Grid>
            )
          }
        </CardContent>

      <Dialog
        open={openUpsertTopic}
        title={topic.id ? 'Editar Tópico' : 'Novo Tópico'}
        onConfirm={() => confirmUpsertTopic(topic)}
        onCancel={() => setOpenUpsertTopic(false)}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              label="Nome"
              fullWidth
              name="name"
              value={topic.name || ''}
              onChange={e => setPropByEvent(e, topic, setTopic)}
              variant="outlined"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Descrição"
              fullWidth
              name="description"
              value={topic.description || ''}
              onChange={e => setPropByEvent(e, topic, setTopic)}
              variant="outlined"
            />
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        open={openRemoveTopic}
        onCancel={() => setOpenRemoveTopic(false)}
        colorConfirm="secondary"
        textConfirm="Remover"
        onConfirm={() => confirmRemoveTopic(topic)}
        title="Remover tópico"
      >
            Você tem certeza que deseja este tópico?
      </Dialog>
    </StyledContainer>
     <Footer/>
    </>
  );
};

CourseForum.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(CourseForum);
