import React from 'react';
import {
  func,
} from 'prop-types';

import { Grid } from '@material-ui/core';
import TagCard from '../../../components/TagCard/index';

import { StyledContainer } from '../styles';
import GlobalContext from '../../../context';

const TagList = ({ handleTagChecked }) => {
  const { tags: { getTags: { data: { getTags: tags } } } } = GlobalContext();
  return (
    <StyledContainer maxWidth="md">
      <Grid container spacing={2}>
        {tags.map(tag => (
          <Grid item key={tag.id} xs={12} sm={6} md={6}>
            <TagCard
              tag={tag}
              handleTagChecked={handleTagChecked}
            />
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  );
};

TagList.propTypes = {
  handleTagChecked: func.isRequired,
};

export default TagList;
