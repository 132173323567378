import React from 'react';

import { number, shape, string } from 'prop-types';

import { Avatar } from '@material-ui/core';

import { StyledContainer } from './styles';
import GlobalContext from '../../context';


let UserAvatar = ({ size }) => {
  const {
    user,
  } = GlobalContext();
  let { fullName, email, avatar } = user;
  let getInitials = () => {
    let initials = (!fullName ? email : fullName).match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  };

  let randomInt = () => {
    return Math.floor(Math.random() * 10) + 100;
  };

  return (
    <StyledContainer>
      <Avatar
        src={user.avatar ?? 'https://thumbs.dreamstime.com/b/vetor-de-%C3%ADcone-perfil-do-avatar-padr%C3%A3o-foto-usu%C3%A1rio-m%C3%ADdia-social-183042379.jpg' /* avatar */}
        style={{
          height: `${size}px`,
          width: `${size}px`,
          borderStyle:"none",
          borderRadius: "8px"
        }}
        className="avatar"
      >
        {/* !avatar && (
          <span className="nameInitials">{getInitials()}</span>
        ) */}
      </Avatar>
    </StyledContainer>
  );
};

UserAvatar.propTypes = {
  size: number.isRequired,
  user: shape({
    fullName: string,
    email: string.isRequired,
    avatar: string,
  }).isRequired,
};

export default UserAvatar;
