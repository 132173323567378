import styled from "styled-components";

export const MovieRow = styled.div`
  z-index: 3;
  overflow: hidden;
  width: 100%;
  flex-wrap: nowrap;

`;


export const Movie = styled.div`
  cursor: pointer;
  position: relative;
  transition: all ease 0.2s;
  /* transform: scale(0.95); */
  &&:hover {
    transform: scale(1);
  }
`;

export const Shadow = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 212, 255, 0) 30%
  );
  z-index: 1;
  pointer-events: none;
`;
export const MovieRowListArea = styled.div`
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

`;

export const MovieRowList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  transition: all ease 1s;
  overflow: visible;
  padding: 14px 0px;
  margin-left: ${(marginLeft) => marginLeft}px;
  width: ${(width) => width}px;
`;

export const LeftArrow = styled.button`
  position: absolute;
  z-index: 4;
  left: 0px;
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2.5em;

  &:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }

  @media(max-width: 600px) {
    left: 0px;
    },
`;

export const RightArrow = styled.button`
  position: absolute;
  z-index: 4;
  right: 0px;
  align-self: center;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 2.5em;

  &:hover {
    transform: scale(1.2);
    transition: all 0.3s ease;
  }
  @media(max-width: 600px) {
    right: 0px;
    },
`;


export const ProgressCourse = styled.div`
  background-color: #FF4C00;
  width: ${(props) => props.value}%;
  height: 5px;
`;

export const ProgressBar = styled.div`
  background-color: gray;
  height: 5px;
`;
