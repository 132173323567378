import React, { useEffect, useState } from 'react';
import { TableRow, TableCell } from '@material-ui/core';
import { StyledTableRow } from './styles';
import { useLazyQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import GlobalContext from '../../../../context';
import useManualQuery from '../../../../context/useManualQuery';

const GET_ANSWERS_TRUEFALSE = gql`  query($ExercisesQuestionsId: ID!) {
  getTrueFalseAnswerByQuestionAndUser(ExercisesQuestionsId: $ExercisesQuestionsId) {
      id
      value
      user{
          fullName
      }
  }
}`


const Answer = ({ answer, setSelectedAnswer, setModalScoreOpen }) => {
  //console.log("answer", answer)


  // const [getAnswersTrueFalse, { data, loading}] = useLazyQuery(GET_ANSWERS_TRUEFALSE, {
  //   onCompleted: e=> console.log("getTrueFalse", e),
  //   fetchPolicy: 'no-cache',
  //   variables:{
  //     ExercisesQuestionsId: answer.exerciseQuestions.id
  //   }
  // })

  const getAnswersTrueFalse = useManualQuery(GET_ANSWERS_TRUEFALSE,{
    variables:{
      ExercisesQuestionsId: answer?.exerciseQuestions?.id
    },
    onCompleted: e => console.log(e),
    onError: e=> console.log(e),
    fetchPolicy: 'no-cache',
  });

  console.log(getAnswersTrueFalse)

  const renderAnswer = () => {
    if (answer.__typename === 'SubjectiveAnswer') {
      return (
        <>
          <TableCell>
            {answer.user.fullName}
          </TableCell>
          <TableCell>
            {answer.text}
          </TableCell>
          <TableCell>
            {answer.isCorrect*10 || '-'}/10
          </TableCell>
          <TableCell>
          </TableCell>
        </>
      );
    } if (answer.__typename === 'ObjectiveAnswer') {
      return (
        <>
          <TableCell>
            {answer.user.fullName}
          </TableCell>
          <TableCell>
            {answer.alternative.text}
          </TableCell>
          <TableCell>
           {answer.alternative.isCorrect === true ? 'Correto' : answer.alternative.isCorrect === false ? 'Incorreto' : '-'}
          </TableCell>
          <TableCell>
          </TableCell>
        </>
      );
    }
    if(answer.__typename === 'TrueFalseAnswer'){
      //console.log("ID AQEEEEE: ", answer.exerciseQuestions.id)
      /*getAnswersTrueFalse({
        variables:{
          ExercisesQuestionsId: answer.exerciseQuestions.id
        }
      })*/
      return (
        <>
          <TableCell>
          {answer.user.fullName}
          </TableCell>
          <TableCell>
            {answer.alternatives.map(alternative => alternative.text).reduce((total, current) => total + "," + current )}
          </TableCell>
          <TableCell>
          </TableCell>
          <TableCell>
          </TableCell>
        </>
      )
    }
  };

  return (
    <StyledTableRow
      onClick={() => {
        if (answer.__typename === 'SubjectiveAnswer') {
          setSelectedAnswer(answer);
          setModalScoreOpen(true);
        }
      }}
      className={answer.__typename === 'SubjectiveAnswer' ? 'subjective' : ''}
    >
      {renderAnswer()}
    </StyledTableRow>
  );
};

export default Answer;
