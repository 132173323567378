import React from 'react';
import {
  Grid,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

import {
  DescriptionPaper,
  TypographyReport,
} from './../styles';

export function getStudentsReportTable(report) {
  if(!report)
    return ("Carregando...");

  if(Object.keys(report).length === 0)
    return (
      "Sem resultados :-("
    );
  return (
    report.map(student => (
      <Grid style={{marginLeft: "10%", marginRight: "10%"}}  container alignItems="center">
        <DescriptionPaper square={true}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <TypographyReport variant="p">
              Aluno: <b>{student.fullName}</b>
              </TypographyReport>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="p">
              Pontuação: <b>{student.generalGrade}</b>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography gutterBottom variant="p">
              Status: <b>{student.status}</b>
              </Typography>
            </Grid>
          </Grid>
        </DescriptionPaper>
        <Typography variant="p">
          Trajetória do aluno
        </Typography>
        <Table title="Trajetória do aluno">
          <TableHead>
            <TableRow>
              <TableCell>Aula</TableCell>
              <TableCell align="right">Data de Acesso</TableCell>
              <TableCell align="right">Nota</TableCell>
            </TableRow>
          </TableHead>
          
          <TableBody>
          {student.lessons && student.lessons.map((lesson, index) => (
            <TableRow key={index}>       
              <TableCell component="th" scope="row">
                {lesson.name}
              </TableCell>
              <TableCell align="right">{lesson.lastAccess}</TableCell>
              <TableCell align="right">{lesson.partialGrade}</TableCell>
            </TableRow>
          ))}              
          </TableBody>
        </Table>
      </Grid>
  )));
}