import gql from 'graphql-tag';

export const CREATE_EXERCISE = gql`
  mutation(
    $module: ID!
    $startDate: Date!
    $endDate: Date!
    $score: Float!
    $description: String!
    $title: String!
  ) {
    createExercise(
      module: $module
      startDate: $startDate
      endDate: $endDate
      score: $score
      description: $description
      title: $title
    ) {
      id
    }
  }
`;

export const REMOVE_EXERCISE = gql`
  mutation($id: ID!) {
    removeExercise(id: $id)
  }
`;

export const UPDATE_EXERCISE = gql`
  mutation(
    $id: ID!
    $title: String
    $description: String
    $startDate: Date
    $endDate: Date
    $score: Float
  ) {
    updateExercise(
      id: $id
      title: $title
      description: $description
      startDate: $startDate
      endDate: $endDate
      score: $score
    ) {
      id
    }
  }
`;

export const CREATE_ALGORITHM_EXERCISE = gql`
  mutation(
    $module: ID!
    $title: String!
    $quantity: Int!
  ) {
    mountExerciseWithRecommendedQuestions(
      module: $module
      quantity: $quantity
      title: $title
    ) {
      id
    }
  }
`;
