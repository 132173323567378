import React from 'react';

import {
  CssBaseline,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Grid,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { Link, withRouter } from 'react-router-dom';

import logo from '../../../assets/images/cientik_logo.svg';

import { StyledGrid } from './styles';
import GlobalContext from '../../../context';

const SignIn = () => {
  const {
    signIn: {
      mutation,
      loading,
      email,
      setEmail,
      password,
      setPassword,
      error,
      setError,
    },
  } = GlobalContext();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email && !password) {
      setError('Todos os campos são obrigatórios!');
      return;
    }

    try {
      await mutation();
    } catch (err) {
      setError('Email ou senha incorretos!');
      setPassword('');
    }
  };
  return (
    <StyledGrid container component="main" className="root">
      <CssBaseline />
      <Grid item xs={12} sm={12} md={12} className="image">
        <div className="paper">
          <img className="logo" src={logo} alt="logo" />
          {!!error && (
            <Typography className="error" component="p">
              {error}
            </Typography>
          )}
          <form onSubmit={e => handleSubmit(e)} className="form" noValidate>
            <h1 className="enter">Entrar</h1>
            <TextField
              variant="outlined"
              margin="normal"
              type="email"
              required
              value={email}
              onChange={e => setEmail(e.target.value)}
              fullWidth
              label="Digite seu email"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              value={password}
              onChange={e => setPassword(e.target.value)}
              fullWidth
              name="password"
              label="Digite sua senha"
              type="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Lembre-me"
            />
            <Link to="/esqueci-minha-senha" className="esqueciSenha">
              Esqueci minha senha
            </Link>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Entrar'
              )}
            </Button>
            <Grid container>
              {/* <Grid item xs>
              <Link to="#" variant="body2">
                Esqueceu a senha?
              </Link>
            </Grid> */}
              <Grid item>
                <Link to="/cadastro">
                  Ainda não tem conta?
                  {' '}
                  <span>Cadastre-se</span>
                </Link>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                <Link to="/cadastro-professor" className="professor">
                  É professor?
                  {' '}
                  <span>Faça seu cadastro aqui</span>
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
      </Grid>
    </StyledGrid>
  );
};

export default withRouter(SignIn);
