import * as React from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Drawer,
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import GlobalContext from '../../context';
import UserAvatar from '../UserAvatar';
import { FaRegSmile } from 'react-icons/fa';
import {MdOutlinePublishedWithChanges} from 'react-icons/md';
import {GiBackwardTime} from 'react-icons/gi'
import {FiHelpCircle, FiLogOut} from 'react-icons/fi';
import { withRouter } from 'react-router-dom';
import {AiOutlineLike} from 'react-icons/ai';
import {TbSchool}   from 'react-icons/tb';
import {
  func, shape,
} from 'prop-types';
import { MenuListDesktop, MobileListDesktop, MobileMenuu } from './styles';


const UserDropdown = ({ history }) => {
  const {
    user,
    user: { fullName, roles },
    visualization: {
      setVisualization,
      setVisualizationModalOpen,
      visualization,
      visualizationModalOpen,
    },
    dropdown: {
      open,
      setOpen,
      anchorRef,
    },
    drawer: { isOpen, toggle },
    signIn: { signOut }
  } = GlobalContext();

  React.useEffect(() => {
    let role;
    switch (visualization) {
      case "Estudante":
        role = "STUDENT";
        break;
      case "Professor":
        role = "TEACHER";
        break;
      default:
        break;
    };
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const selectVisualization = (e) => {
    setVisualization(e.target.value);
    setVisualizationModalOpen(false);
  };

  const exit = () => {
    setOpen(false);
    signOut();
  };

  // Drawer

  const [state, setState] = React.useState({

    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={{height: "100%", background:"#141414"}}
    >
      <List
        style={{background:"#141414", color:" #FFFFFF"}}
        >

<ListItem disablePadding onClick={() => signOut()}
              style={{cursor:"pointer", paddingBottom:"30px"}}>

              <ListItemIcon style={{color:"#FFFFFF"}}>
              <UserAvatar size={50} user={user} />
              </ListItemIcon>
              <ListItemText>
              {user.fullName}
              </ListItemText>
              </ListItem>
              </List>
              <Divider />
              <List
        style={{background:"#141414", color:" #FFFFFF"}}
        >


      <MenuListDesktop>
      <ListItem disablePadding onClick={() => history.push('/profile')}
          style={{cursor:"pointer"}}>

              <ListItemIcon style={{color:"#FFFFFF"}}>
              <FaRegSmile size={25}/>
              </ListItemIcon>
              <ListItemText>
              {`Área do ${visualization}`}
              </ListItemText>

              </ListItem>
      </MenuListDesktop>
          <MobileMenuu>
          <ListItem disablePadding onClick={() => history.push('/info')}
          style={{cursor:"pointer"}}>

              <ListItemIcon style={{color:"#FFFFFF"}}>
              <FaRegSmile size={25}/>
              </ListItemIcon>
              <ListItemText>
              Dados do estudante
              </ListItemText>

              </ListItem>

              <ListItem disablePadding onClick={() => history.push('/likes')}
          style={{cursor:"pointer"}}>

              <ListItemIcon style={{color:"#FFFFFF"}}>
              <AiOutlineLike size={25}/>
              </ListItemIcon>
              <ListItemText>
              Favoritos
              </ListItemText>

              </ListItem>


              <ListItem disablePadding onClick={() => history.push('/history')}
              style={{cursor:"pointer"}}>

              <ListItemIcon style={{color:"#FFFFFF"}}>
                <GiBackwardTime   size={25}/>
              </ListItemIcon>
              <ListItemText>
              Histórico de visualizações
              </ListItemText>

              </ListItem>

              <ListItem disablePadding onClick={() => history.push('/certification')}
              style={{cursor:"pointer"}}>

              <ListItemIcon style={{color:"#FFFFFF"}}>
                <TbSchool size={25}/>
              </ListItemIcon>
              <ListItemText>
              Cursos e certificados
              </ListItemText>

              </ListItem>
              </MobileMenuu>



                          {roles.length > 1 && (
                                     <ListItem
                                     style={{cursor:"pointer"}}
                                     disablePadding onClick={(e) => {
                                   handleClose(e);
                                   setVisualizationModalOpen(true)}}>
                                  <ListItemIcon style={{color:"#FFFFFF"}}>
                                  <MdOutlinePublishedWithChanges  size={25}/>
                                  </ListItemIcon>
                                    <ListItemText>
                                    Mudar Visualização
                                   </ListItemText>
                                   </ListItem>

              )}


              <ListItem disablePadding onClick={() => history.push('/help')}
              style={{cursor:"pointer"}}>

              <ListItemIcon style={{color:"#FFFFFF"}}>
                <FiHelpCircle size={25}/>
              </ListItemIcon>
              <ListItemText>
              Central de ajuda
              </ListItemText>
              </ListItem>

              <ListItem disablePadding onClick={() => signOut()}
              style={{cursor:"pointer"}}>
              <ListItemIcon style={{color:"#FFFFFF"}}>
              <FiLogOut size={25}/>
              </ListItemIcon>
              <ListItemText>
              Sair
              </ListItemText>
              </ListItem>

      </List>
    </Box>
  );

  return (

    <div>
    {['right'].map((anchor) => (
      <React.Fragment key={anchor}>
        <Button onClick={toggleDrawer(anchor, true)}>
        <UserAvatar
              size={40}
              user={user}
            />
        </Button>
        <Drawer
          anchor={anchor}
          open={state[anchor]}
          onClose={toggleDrawer(anchor, false)}
        >
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    ))}
     {roles.length > 1 && (
          <Dialog open={visualizationModalOpen} onClose={() => setVisualizationModalOpen(false)}>
            <DialogTitle>
              Qual tipo de visualização você deseja?
              <RadioGroup
                value={visualization}
                onChange={e => selectVisualization(e)}
              >
                {roles.map(i => (
                  <FormControlLabel
                    key={i.name}
                    value={i.name}
                    control={<Radio />}
                    label={i.name}
                  />
                ))}
              </RadioGroup>
            </DialogTitle>
          </Dialog>
        )}
  </div>



    // <div>
    //   <div>
    //     <div className="userInfo" ref={anchorRef}>
    //       {/* <span className="name">{fullName}</span> */}
    //       <Button onClick={openModal}>
    //         <UserAvatar
    //           size={40}
    //           user={user}
    //         />
    //       </Button>
    //     </div>
    //     <Popper open={open} anchorEl={anchorRef.current} transition disablePortal placement="bottom-end">
    //       {({ TransitionProps }) => (
    //         <Grow
    //           {...TransitionProps}
    //           style={{ transformOrigin: 'top' }}
    //         >
    //           <Paper id="menu-list-grow">
    //             <ClickAwayListener onClickAway={handleClose}>
    //               <MenuList style={{
    //               background:"#141414",
    //               color:"#ffffff",
    //               marginTop:"60px",}}>
    //                 {roles.length > 1 && (
    //                   <MenuItem onClick={(e) => {
    //                     handleClose(e);
    //                     setVisualizationModalOpen(true);
    //                   }}
    //                   >
    //                     Mudar visualização
    //                   </MenuItem>
    //                 )}
    //                 <MenuItem onClick={exit}>Sair</MenuItem>
    //               </MenuList>
    //             </ClickAwayListener>
    //           </Paper>
    //         </Grow>
    //       )}
    //     </Popper>
    //     {roles.length > 1 && (
    //       <Dialog open={visualizationModalOpen} onClose={() => setVisualizationModalOpen(false)}>
    //         <DialogTitle>
    //           Qual tipo de visualização você deseja?
    //           <RadioGroup
    //             value={visualization}
    //             onChange={e => selectVisualization(e)}
    //           >
    //             {roles.map(i => (
    //               <FormControlLabel
    //                 key={i.name}
    //                 value={i.name}
    //                 control={<Radio />}
    //                 label={i.name}
    //               />
    //             ))}
    //           </RadioGroup>
    //         </DialogTitle>
    //       </Dialog>
    //     )}
    //   </div>
    // </div>


    );
};


UserDropdown.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};


export default withRouter(UserDropdown);

