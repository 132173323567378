import * as yup from "yup";

export const createCourse = yup.object().shape({
    name: yup.string().required('Campo "Título" é obrigatório'),
    // synopsis: yup.string().required('Campo "Resumo" é obrigatório'),
    tags: yup.array().required('Campo "Categorias" é obrigatório').min(1),
    year: yup.string().required('Campo "Ano de lançamento" é obrigatório').nullable(),
    startDate: yup.date().max(new Date(), 'Data tem que ser menor que a data atual').required('Campo "Período de destaque" é obrigatório'),
    endDate: yup.date().min(new Date(), 'Data tem que ser maior que a data atual').required('Campo "Período de destaque" é obrigatório'),
    description: yup.string().required('Campo "Descrição" é obrigatório'),
    age: yup.string().required('Campo "Faixa Etária" é obrigatório').nullable(),
    thumbnail: yup.mixed().required('Você deve adicionar uma thumbnail.'),
    folder: yup.mixed().required('Você deve adicionar um cartaz.'),

    // actors: yup.array().of(yup.string()).required('Campo "Elenco" é obrigatório'),
    // category: yup.array().of(yup.string()).required('Campo "Gêneros" é obrigatório'),
    // // studios: yup.array().of(yup.object()).required('Campo "Estúdios" é obrigatório'),
    // // distributors: yup.array().of(yup.object()).required('Campo "Distribuidores" é obrigatório'),
    // country: yup.string().required('Campo "País" é obrigatório'),
    // parental_rating: yup.string().required('Campo "Faixa Etária" é obrigatório'),
    // year: yup.string().required('Campo "Ano de lançamento" é obrigatório'),
    // trailers: yup.array().of(yup.mixed()).required('Você deve adicionar ao menos um trailer.'),
    // thumbnail: yup.mixed().when("thumbnail2", {
    //     is: (thumbnail2) => thumbnail2 === undefined || thumbnail2 === null,
    //     then: yup.mixed().required('Você deve adicionar ao menos uma thumbnail.'),
    //     otherwise: yup.mixed()
    //   }),
    // thumbnail2: yup.mixed().when("thumbnail", {
    //     is: (thumbnail) => thumbnail === undefined || thumbnail === null,
    //     then: yup.mixed().required('Você deve adicionar ao menos uma thumbnail.'),
    //     otherwise: yup.mixed()
    //   }),
    },
    // ['thumbnail', 'thumbnail2']
);

export const createCourseTwo = yup.object().shape({
    // trailer: yup.mixed(),
    videos: yup.array().of(yup.mixed()).required('Você deve adicionar ao menos um episódio.').min(1),
    nameUnit: yup.string(),
    nameModule: yup.string().required('Campo "Nome da temporada" é obrigatório'),
    // nameDuration: yup.string(),
    },
);
