import React, { useState } from "react";
import SearchBar from "../components/searchbar";
import Add from '@material-ui/icons/Add';
import {MdOutlineVideoCall} from 'react-icons/md';
import { ButtonModal, ColumnRight, ContainerSearchBar, TitleSearch } from "../components/searchbar/styles";
import { Column1, Column2, Column3, Columns, Container, ContainerDashboard } from "./styles";
import MostAccessedCourses from "./components/MostAccessedCourses";
import MostAccessedLesson from "./components/MostAccessedLesson";
import BestEstimates from "./components/BestEstimates";
import Indexes from "./components/indexes";
import VideosByCategories from "./components/VideosByCategories";


const PublisherDashboard = () => {

    const [searchedString, setSearchedString] = useState('');

    const onChangeHandler = (e) => {
      setSearchedString(e.target.value);
  }

    return (
              <Container>
                <ContainerSearchBar>
                <TitleSearch>
                Dashboard
                </TitleSearch>
                </ContainerSearchBar>

                <Columns>
                <Column1>
                <MostAccessedCourses/>
                <MostAccessedLesson/>
                </Column1>

                <Column2>
                <BestEstimates/>
                <Indexes/>
                </Column2>

                <Column3>
                <VideosByCategories/>
                </Column3>

                </Columns>
                </Container>
)}
export default PublisherDashboard;
