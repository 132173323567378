import React, { useEffect, useState } from 'react';
import GlobalContext from "../../../../context";
import { Redirect, useHistory } from 'react-router-dom';
import 'video-react/dist/video-react.css';
import {
  Grid, TextField, Button, Container as ContainerStyle, Typography, FormControl, InputLabel, OutlinedInput, makeStyles, Divider,
} from '@material-ui/core';
import AppBarWrapper from '../../../../components/AppBarWrapper';
import TabsBar from '../../../../components/TabsBar';
import Footer from '../../../../components/Footer';
import Container from './styles';
import Post from './Post';
import { StyledContainer } from '../styles';


export const ForumAnswer = () => {
  const {
    user: { id },
    forum: {
      topic,
      getPostsByTopic,
      createPost,
      fixedPost,
      removePost,
    },
    visualization: { visualization },
  } = GlobalContext();

  const [answer, setAnswer] = useState('');

  const history = useHistory();

  useEffect(() => {
    if (topic.id) {
      getPostsByTopic.fetch({ variables: { id: topic.id } });
    }
  }, []);

  if (!topic.id) {
    return <Redirect to="/" />;
  }

  const isManager = visualization === 'Professor';

  if (!getPostsByTopic.data) {
    return 'Carregando...';
  }

  const { data: { getTopicById: { posts } } } = getPostsByTopic;

  const createAnswer = async () => {
    if (!answer) {
      return;
    }
    await createPost({
      variables: {
        id: topic.id,
        text: answer,
      },
    });
    getPostsByTopic.refetch();
    setAnswer('');
  };

  const removeAnswer = async (post) => {
    await removePost({
      variables: {
        id: post.id,
      },
    });
    getPostsByTopic.refetch();
  };

  const fixedAnswer = async (post) => {
    await fixedPost({
      variables: {
        id: post.id,
        fixed: !post.fixed,
      },
    });
    getPostsByTopic.refetch();
  };

  const useStyles = makeStyles(theme=>({
    textField:{
      border: "1px solid orange"
    }
  }))


  return (
    <>
    <AppBarWrapper/>
    <StyledContainer>
      <Container>
        <Grid container>

            <Grid container justify="space-between" alignItems="center" style={{ margin: '14px 16px' }}>
            <Grid item>
            <Typography variant="h5"
            style={{textAlign:"start", color:"#DCDDDF"}}>{topic.name}</Typography>
            </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    color="primary"
                    style={{marginRight:'16px'}}
                    onClick={() => {
                      history.goBack();
                  }}
                  >
                    Voltar
                  </Button>
                  </Grid>
                  </Grid>

              <Grid>
              <p style={{
              textAlign:"center",
              color:"#DCDDDF",
              marginBottom:"60px",
              marginTop:"30px"}}
               >{topic.description}</p>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  {[true, false].map(fixed => (
                    posts.map(post => fixed === post.fixed && (
                      <Post
                        key={post.id}
                        post={post}
                        removeFunction={isManager || post.creator.id === id ? removeAnswer : null}
                        fixedFunction={isManager ? fixedAnswer : null}
                      />
                    ))
                  ))}
                  <div className="answer">

                  <FormControl fullWidth>

                  <label className="input">
                  <textarea className="input__field"
                  value={answer}
                  onChange={e => setAnswer(e.target.value)}
                  type="text" placeholder="Digite aqui sua resposta..." />
                  <span className="input__label"></span>
                  </label>
                </FormControl>

                <Button fullWidth variant="contained" color="primary" onClick={createAnswer}>
                      Responder
                </Button>
                  </div>
                </Grid>
              </Grid>
        </Grid>
      </Container>
      </StyledContainer>
      <Footer/>
    </>
  );
};
export default ForumAnswer;
