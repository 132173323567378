import { Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText, FormControl} from "@material-ui/core";
import { GET_CATEGORIES } from '../../../../../services/queries/categories';
import { ReactComponent as Livre } from '../../../../../assets/icons/faixa_livre.svg';
import { ReactComponent as DezAnos } from '../../../../../assets/icons/faixa_10.svg';
import { ReactComponent as DozeAnos } from '../../../../../assets/icons/faixa_12.svg';
import { ReactComponent as QuatorzeAnos } from '../../../../../assets/icons/faixa_14.svg';
import { ReactComponent as DeseseisAnos } from '../../../../../assets/icons/faixa_16.svg';
import { ReactComponent as DesoitoAnos } from '../../../../../assets/icons/faixa_18.svg';
import { useForm, Controller } from 'react-hook-form';
import { CategoryInputData, Count, DataTitle, FlexData, FlexDataInput, Input, InputContainer, Small, TextArea,
   TextAreaContainer, TextAreaContainerDescription, TextAreaDescription, StyledFormControlLabel,
   Card, ButtonNewSeasons, CardExtras, CardVideo, ContainerSeasons, FlexImg, FlexVideo, Icon, Info, Prevew, PrevewImg, PrevewVideo, PreviewVideo, Submit, SubTitleSeasons, Tam, TitleCard, TitleSeasons} from '../../DialogAdd/styles';
import { Material } from '../Seasons/Material'
import { GET_COURSES } from '../../../../../services/queries/courses';
import { UPDATE_COURSE_FIELDS } from '../../../../../services/mutations/course';
import { formatDateToUTC } from '../../../../../components/Helpers/formatDate';
import { NotificationManager } from "react-notifications";
import {MdOutlineUpload, MdAdd, MdOutlineDone} from 'react-icons/md'
import { UploadContext } from '../index'
import { FormatIndentIncreaseTwoTone } from '@material-ui/icons';
import {updateCourse} from "../validation";
import AWS from 'aws-sdk'


AWS.config.update({
  accessKeyId: process.env.REACT_APP_S3_USER_KEY,
  secretAccessKey: process.env.REACT_APP_S3_USER_SECRET
})

const myBucket = new AWS.S3({
  params: { Bucket: process.env.REACT_APP_S3_BUCKET_NAME},
  region: process.env.REACT_APP_S3_REGION,
})

const params = {
  ACL: 'public-read',
  Bucket: process.env.REACT_APP_S3_BUCKET_NAME,
};



export  function ContentDetails({nextStep, course}){
  const {data, setData} = useContext(UploadContext);
  const [count, setCount] = useState(0);
  const [countContent, setCountContent] = useState(0);
  const [categories, setCategories] = useState([]);
  const [percentState, setPercent] = useState(0)
  const [uploading, setUploading] = useState(false)

  let loaded = 0;
  let actualLoaded = 0
  let totalSize = 0;

  const { loading, data: dataCourses, error } = useQuery(GET_COURSES, {
    onCompleted: e=>console.log(e)
  })
  const [updateFields, {data: dataFields, loading: loadingFields}] = useMutation(UPDATE_COURSE_FIELDS, {
    onCompleted: (e) =>{
      NotificationManager.success("Dados editados com sucesso!");
      window.scrollTo({ top: 0 });
      nextStep()
    },
    onError: (e)=>{
      NotificationManager.error("Não foi possível editar os dados.");
    }

  })
  const [edit, setEdit] = useState([]);
  const [name, setName] = useState(course.name)
  const [year, setYear] = useState(formatDateToUTC(course.createdAt))
  const [tags, setTags] = useState("")
  const [initial_date, setInitial_date] = useState(course.initial_date?.toISOString().substring(0,10) || "")
  const [expiration_date, setExpiration_date] = useState(course?.expiration_date?.toISOString().substring(0,10))
  const [description, setDescription] = useState(course.description)


  const { handleSubmit, register, control, setValue,  watch, formState: { errors, isValid }  } = useForm({resolver: yupResolver(updateCourse)});

  const { data: dataCategorias} = useQuery(GET_CATEGORIES, {
    onCompleted: (e) => {
      console.log(dataCategorias)
    }
  })

  const memoizedData = useMemo(() => data, [data]);

  useEffect(()=> {
    setValue('thumbnail', data?.thumbnail);
    setValue('trailer', data?.trailer);
    setValue('folder', data?.folder);
  }, [memoizedData])

  const delThumb =()=>{
    setValue('thumbnail', null)
    setData({...data, thumbnailFile: null, thumbnail: null})
  }

  const delFolder =()=>{
    setValue('folder', null)
    setData({...data, folderFile: null, folder: null})
  }

  const delTrailer =()=>{
    setValue('trailer', null)
    setData({...data, trailerFile: null, trailer: null})
  }


  useEffect(()=>{
    console.log(errors)
  }, [errors])
  const handleName = (e) => {
    e.preventDefault();
     setCount(e.target.value.length);
   };
   const handleContent = (e) => {
    setCountContent(e.target.value.length);
   };

   const handleSubmitForm = async ({name, categories, year, startDate, endDate, description, age})=> {
    console.log({name, categories, year, startDate, endDate, description, age})
    console.log(countContent)
    console.log(data?.categories)
    setData({ ...data, name, categories, year, startDate, endDate, description, age})

    totalSize += data?.thumbnailFile ? data?.thumbnailFile?.size : 0
    totalSize += data?.folderFile ? data?.folderFile?.size : 0
    totalSize += data?.trailerFile ? data?.trailerFile?.size : 0


    let folderUrl = null

    if(data?.folderFile){
      let uploadFolder = myBucket.upload({...params, Body: data?.folderFile, Key: `cientik/${data?.name}/Folder/${data?.folderFile.name}`})
      .on('httpUploadProgress', function(evt) {
        loaded += evt.loaded - actualLoaded
        setPercent(Math.round((loaded / totalSize)*100))
        actualLoaded = evt.loaded
      })
      .promise();

      folderUrl = await uploadFolder.then(function(el, err) {
        actualLoaded = 0
        return el.Location
      });


      setData({...data, folder: folderUrl, folderFile: null})
    }

    let thumbUrl = null

    if(data?.thumbnailFile){

      let uploadThumb = myBucket.upload({...params, Body: data?.thumbnailFile, Key: `cientik/${data?.name}/Thumbnail/${data?.thumbnailFile.name}`})
      .on('httpUploadProgress', function(evt) {
        loaded += evt.loaded - actualLoaded
        setPercent(Math.round((loaded / totalSize)*100))
        actualLoaded = evt.loaded
      })
      .promise();

      thumbUrl = await uploadThumb.then(function(el, err) {
        actualLoaded = 0
        return el.Location
      });

      setData({...data, folder: thumbUrl, thumbnailFile: null})
    }

    let trailerUrl = null

    if(data?.trailerFile){

      let uploadTrailer = myBucket.upload({...params, Body: data?.trailerFile, Key: `cientik/${data?.name}/Trailer/${data?.trailerFile.name}`})
      .on('httpUploadProgress', function(evt) {
        loaded += evt.loaded - actualLoaded
        setPercent(Math.round((loaded / totalSize)*100))
        actualLoaded = evt.loaded
      })
      .promise();

      trailerUrl = await uploadTrailer.then(function(el, err) {
        actualLoaded = 0
        return el.Location
      });

      setData({...data, trailer: trailerUrl, trailerFile: null})
    }

    let materialUrls = [];
    let materialName = [];

    if(data?.material) {
      for (const material of data?.material) {
        if(material?.contentFile){
          let uploadMaterial = myBucket.upload({...params, Body: material?.contentFile, Key: `cientik/${data?.name}/Material/${material.nameMaterial}`})
            .on('httpUploadProgress', function(evt) {
              loaded += evt.loaded - actualLoaded
              setPercent(Math.round((loaded / totalSize)*100))
              actualLoaded = evt.loaded
            })
            .promise();

            const url = await uploadMaterial.then(function(el, err) {
              actualLoaded = 0
              return el.Location
            });

            //videosUrls.push[url]
            console.log(url)

            materialUrls.push(url)
            materialName.push(material.name)
            continue;
        } else {
          materialUrls.push(material.url)
          materialName.push(material.name)
        }
      }
    }


    updateFields({
      variables: {
        id: course.id, name, tags: categories.map(e => e.id), year: parseInt(year), startDate, endDate, description, age,
        folder: folderUrl,
        thumbnail: thumbUrl,
        trailer: trailerUrl,
        material: materialUrls,
        materialName,
      }
    })
    console.log(data)

    // console.log(nextStep)
   }

   const handleCategory = (dataCat, onChange)=>{
    let selectedCategories = [];
    const category = data.categories.find(e => e.id == dataCat.id)
    console.log(category)
    console.log(data.categories)
    console.log(dataCat)
    if(category) {
      selectedCategories = [...data.categories.filter((item) => item.id != dataCat.id)]
    } else {
      selectedCategories = [...data.categories, dataCat]
    }

    onChange(selectedCategories)
    setData({...data, categories: selectedCategories})
    console.log(selectedCategories)
  }


  useEffect(()=>{
    setData({...course, nameBucket: course.name})
  },[])

  console.log("course", course)

  return(
    <>

        <TitleSeasons>
        Dados gerais do curso
        </TitleSeasons>
        <Info>
        Salve as informações antes de prosseguir para temporadas.
        </Info>
        <div style={{justifyContent: 'center', display: 'flex'}}>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div>
        <SubTitleSeasons>
        Imagens do conteúdo
        </SubTitleSeasons>

          <FlexImg>
            <Card style={{marginRight: 20}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Thumbnail
              </TitleCard>
              <Tam>
              1080 x 1920 px
              </Tam>

              <Controller
                name="thumbnail"
                control={control}
                render={props => (
                  <input
                    type="file"
                    defaultValue={data?.thumbnailFile || data?.thumbnail}
                    // accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="thumbnail"
                    name="thumbnail"
                    // onChange={(e) => setThumbnail(e.target.files[0])}
                    onChange={(e) => {setData({...data, thumbnailFile: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                  />
                )}
              />
               <FormHelperText error={Boolean(errors?.thumbnail)}>
            {errors?.thumbnail?.message}
            </FormHelperText>

          <label htmlFor="thumbnail"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar a thumbnail do curso
          </label>
              <Info>
              Formato: JPG ou PNG
              </Info>
              {data?.thumbnail || data?.thumbnailFile ?
              <>
              <PrevewImg src={data?.thumbnail || URL.createObjectURL(data?.thumbnailFile)}/>
              <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={()=>delThumb()}><u>remover</u></button>
              </>
              :null }
            </Card>

            <Card>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Cartaz
              </TitleCard>
              <Tam>
              1920 x 1080 px
              </Tam>
              <Controller
                name="folder"
                control={control}
                render={props => (
                  <input
                    type="file"
                    defaultValue={data?.folderFile || data?.folder}
                    // accept="image/png, image/jpeg"
                    style={{ display: 'none'}}
                    id="folder"
                    name="folder"
                    onChange={(e) => {setData({...data, folderFile: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                  )}
                />
                   <FormHelperText error={Boolean(errors?.folder)}>
            {errors?.folder?.message}
            </FormHelperText>
          <label htmlFor="folder"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o cartaz do curso
          </label>
          <Info>
            Formato: JPG ou PNG
              </Info>
          {data?.folder || data?.folderFile ? <>
          <PrevewImg src={data?.folder || URL.createObjectURL(data?.folderFile)}/>
          <button
              style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={()=> delFolder()}><u>remover</u></button>
          </>:null }

            </Card>
          </FlexImg>
        </div>
          <label>
            Título do conteúdo <Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainer>

          <Controller
            name="name"
            control={control}
            defaultValue={data?.name || course?.name}
            render={props => (
             <TextArea
                type="text"
                defaultValue={data?.name || course?.name}
                maxLength="100"
                placeholder='Insira aqui o título do conteúdo'
                name="name"
                onChange={(e)=>props.field.onChange(e.target.value)}
              />
            )}
          />
            <Count>
              <span>{count}</span>/100
            </Count>
            <FormHelperText error={Boolean(errors?.name)}>
              {errors?.name?.message}
            </FormHelperText>
            </TextAreaContainer>



          <label>
          Seção | Categoria <Small>(Obrigatório)</Small>
          </label>
          <Controller
            name="categories"
            control={control}
            defaultValue={data?.categories || course?.categories}
            render={props => (
            <FormControl  name="categories">
              {dataCategorias?.getCategories?.map(e => (
                <FormControlLabel key={e.id} control={<Checkbox />}
                 checked={data?.categories ? data?.categories?.find(el => el.id === e.id) : course?.categories?.find(el => el.id === e.id)} onChange={(_,data) => { handleCategory(e, props.field.onChange)}} label={e.name} />
              ))}
            </FormControl>
           )}
           />
            <FormHelperText error={Boolean(errors?.categories)}>
              {errors?.categories?.message}
            </FormHelperText>


            <label>Faixa etária
          <Small>(Obrigatório)</Small></label>

          <Controller
                name="age"
                control={control}
                defaultValue={data?.age || course?.age}
                render={props => (

                <FormControl style={{alignSelf:"space-between"}}>
                    <RadioGroup
                         row
                         aria-labelledby="demo-row-radio-buttons-group-label"
                         defaultValue={data?.age || course?.age}
                         onChange={(_,data) => props.field.onChange(data)}
                    >
                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="L" control={<Radio />} label=""/>
                            <Livre style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="10" control={<Radio />} label=""/>
                            <DezAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="12" control={<Radio />} label=""/>
                            <DozeAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="14" control={<Radio />} label=""/>
                            <QuatorzeAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="16" control={<Radio />} label=""/>
                            <DeseseisAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>

                        <StyledFormControlLabel style={{alignItems: 'center', display: 'flex'}}>
                            <FormControlLabel style={{marginLeft:'5px'}} value="18" control={<Radio />} label=""/>
                            <DesoitoAnos style={{marginLeft: '-10px'}}/>
                        </StyledFormControlLabel>
                    </RadioGroup>
                </FormControl>
                 )}
                 />

            <FormHelperText error={Boolean(errors?.age)}>
              {errors?.age?.message}
            </FormHelperText>




          <label>
            Ano de lançamento <Small>(Obrigatório)</Small>
          </label>
          <Controller
            name="year"
            control={control}
            defaultValue={data?.year || course?.year}
            render={props => (
              <Input type="number" defaultValue={data?.year|| course?.year} onChange={(e)=>props.field.onChange(e.target.value)} name="year" placeholder='Ano de lançamento. Ex.: 2020'/>
           )}
           />
            <FormHelperText error={Boolean(errors?.year)}>
              {errors?.year?.message}
            </FormHelperText>
          <label>
            Período de destaque <Small>(Obrigatório)</Small>
          </label>
          <FlexData>
              <FlexDataInput>
                <DataTitle>De</DataTitle>
                <InputContainer>

                <Controller
                    name="startDate"
                    defaultValue={data?.startDate ? new Date(data?.startDate).toISOString().substring(0,10) : new Date(course?.startDate).toISOString().substring(0,10) }
                    control={control}
                    render={props => (
                      <CategoryInputData
                            type="date"
                            name="startDate"
                            defaultValue={data?.startDate ? new Date(data?.startDate).toISOString().substring(0,10) : new Date(course?.startDate).toISOString().substring(0,10) }
                            onChange={(e)=>props.field.onChange(e.target.value)}
                          />
                  )}
                  />
                </InputContainer>
              </FlexDataInput>

              <FlexDataInput>
                <DataTitle>Até</DataTitle>
                <InputContainer>

                <Controller
                    name="endDate"
                    control={control}
                    defaultValue={data?.endDate ? new Date(data?.endDate).toISOString().substring(0,10) : new Date(course?.endDate).toISOString().substring(0,10) }
                    render={props => (
                      <CategoryInputData
                            type="date"
                            name="endDate"
                            defaultValue={data?.endDate ? new Date(data?.endDate).toISOString().substring(0,10) : new Date(course?.endDate).toISOString().substring(0,10) }
                            onChange={(e)=>props.field.onChange(e.target.value)}
                          />
                  )}
                  />
                </InputContainer>
              </FlexDataInput>
            </FlexData>
            <FormHelperText error={Boolean(errors?.startDate || errors?.endDate)}>
              {errors?.startDate ? errors?.startDate.message : errors?.endDate?.message}
            </FormHelperText>
          <label>
            Descrição<Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainerDescription>
          <Controller
                    name="description"
                    control={control}
                    defaultValue={data?.description || course?.description}
                    render={props => (
                      <TextAreaDescription
                        type="text"
                        maxLength="500"
                        defaultValue={data?.description || course?.description}
                        placeholder='Fale sobre o conteúdo'
                        name="description"
                        onChange={(e)=>props.field.onChange(e.target.value)}
                      />
                  )}
                  />

              <Count>
                <small>{countContent}</small>/500
              </Count>
              <FormHelperText error={Boolean(errors?.description)}>
              {errors?.description?.message}
            </FormHelperText>
            </TextAreaContainerDescription>

            <div>
        <SubTitleSeasons>
        Trailers
        </SubTitleSeasons>
        <FlexVideo>
             <CardVideo style={{maginRight:"60px"}}>
              <Icon>
              <MdOutlineUpload size={28}/>
              </Icon>
              <TitleCard>
              Trailer
              </TitleCard>
              <Controller
                name="trailer"
                control={control}
                // defaultValue={data?.trailer}
                render={props => (
                  <input
                    type="file"
                    multiple
                    style={{ display: 'none'}}
                    name="trailer"
                    id="trailer"
                    accept=".mp4, .webm, .ogg"
                    onChange={(e)=>{setData({...data, trailerFile: e.target.files[0]});props.field.onChange(e.target.files[0])}}
                    />
                )}
                />
                 <FormHelperText error={Boolean(errors?.trailer)}>
            {errors?.trailer?.message}
            </FormHelperText>
          <label htmlFor="trailer"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u>Clique aqui</u> para adicionar o trailer da temporada
          </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {data?.trailer || data?.trailerFile? <>
              <video width="730" controls>
              <source src={data?.trailer || URL.createObjectURL(data?.trailerFile)} />
              </video>
              <button style={{ fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
              onClick={delTrailer}><u>remover</u></button>
          </>:null }
            </CardVideo>

            {/* <CardExtras style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
              Adicionar Extras
              </TitleCard>
              <input
            {...getInputProps()}/>
            <label htmlFor="extras"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u {...getRootProps({className: 'dropzone'})}>Clique aqui</u> para adicionar extras da temporada
            </label>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
              {files ?
              <div>
              {files.map(file => {
                return(
                <div key={file.name} style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                 <video width="730" controls>
                 <source src={file.preview} />
                 </video>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none", color: 'black', margin: 0 }}
                 onClick={() =>handleRemoveFile(file.name)}><u>remover</u></button>
                </div>
                )}
              )}
              </div>
              :null }
            </CardExtras>  */}
          </FlexVideo>
         </div>

         <Material errors={errors} control={control}/>


          <button type='submit'>
            Salvar Informações
          </button>
        </form>
        </div>
    </>
  )

}
export default ContentDetails;
