import React, { useState } from 'react';
import { TextField, Button } from '@material-ui/core';
import update from 'immutable-assign';
import {
  shape,
  func,
  string,
  number,
} from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import GlobalContext from '../../../../context';
import { GET_ANSWERS_FROM_QUESTION } from '../../../../services/queries/questions';
import { CREATE_SUBJECTIVE_ANSWER } from '../../../../services/mutations/question';

const SubjectiveQuestion = ({ exerciseQuestion, index }) => {
  const {
    user,
  } = GlobalContext();
  const getAnswers = useQuery(GET_ANSWERS_FROM_QUESTION, {
    variables: {
      id: exerciseQuestion.id,
    },
  });
  const [value, setValue] = useState('');
  const [createAnswer] = useMutation(CREATE_SUBJECTIVE_ANSWER);

  if (getAnswers.loading) {
    return 'Carregando...';
  }

  const { data: { getAnswersFromExerciseQuestion: answers } } = getAnswers;
  const filteredAnswers = answers.filter(i => i.user.id === user.id);

  const submit = async () => {
    await createAnswer({
      variables: {
        exercisesQuestion: exerciseQuestion.id,
        subjectiveQuestion: exerciseQuestion.question.id,
        text: value,
      },
    });
    getAnswers.refetch();
  };

  return (
    <>
      {filteredAnswers.map(i => (
        <div key={i.id}>{i.text}</div>
      ))}
      <br />
      {filteredAnswers.length === 0 && (
        <>
          <TextField
            label="Escreva aqui a sua resposta"
            variant="outlined"
            value={value}
            onChange={e => setValue(e.target.value)}
            fullWidth
            multiline
            rows={3}
          />
          <Button
           variant="outlined"
           color="primary"
           fullwidth
           style={{marginTop:"10px", marginBottom:"10px"}}
           onClick={submit}>
          Enviar resposta
          </Button>
        </>
      )}
    </>
  );
};

export default SubjectiveQuestion;
