import React, { useState } from 'react';

import { IconButton, Tooltip, Checkbox } from '@material-ui/core';
import { Edit, Done, Clear , Delete} from '@material-ui/icons';

import {
  bool, func, shape, string,
} from 'prop-types';
import UserAvatar from '../UserAvatar';

import { Container } from './styles';

const getName = (user) => {
  if (user.fullName != null) {
    return user.fullName;
  }
  if (user.email.length > 11) {
    return user.email.substr(0, 9).concat('...');
  }
  return user.email;
};


const PersonCard = ({
  user, isSubscription, onAccept, onRefuse, checkFunction, editFunction, onClickRemove
}) => {

return(
  <>
    <td>
    <span className="name">{getName(user)}</span>
    </td>
    <td>
    <span>{user.email}</span>
    </td>
    <td>
    {!isSubscription && user.roles && user.roles[0] &&
        <span className="profile">{user.roles[0].name}</span>}
    </td>
    <td>
    {editFunction && !isSubscription && (
    <Tooltip title="Editar">
      <IconButton className="editButton" onClick={() => { editFunction(user); }}>
        <Edit />
      </IconButton>
    </Tooltip>
    )}
    <Tooltip title="Deletar">
              <IconButton className="editButton" onClick={() => {onClickRemove(true)}}>
              <Delete/>
             </IconButton>
             </Tooltip>
             </td>
  </>
)};

PersonCard.propTypes = {
  user: shape({
    id: string,
    fullName: string,
    avatar: string,
  }).isRequired,
  isSubscription: bool,
  onAccept: func,
  onRefuse: func,
  checkFunction: func,
  editFunction: func,
  onClickRemove:func,
};

PersonCard.defaultProps = {
  isSubscription: false,
  onAccept: () => {},
  onRefuse: () => {},
  checkFunction: null,
  editFunction: null,
  onClickRemove: null,
};

export default PersonCard;
