import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { shape, string, oneOf } from 'prop-types';
import {
  Checkbox,
  Container,
  Fab,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import Dialog from '../../../components/Helpers/Dialog';
import GlobalContext from '../../../context';
import {
  GET_OBJECTIVE_QUESTION_BY_ID,
  GET_TRUEFALSE_QUESTION_BY_ID,
} from '../../../services/queries/questions';
import { MdOutlineRadioButtonChecked, MdOutlineRadioButtonUnchecked } from 'react-icons/md';

const ModalQuestionAlternatives = ({ question }) => {
  const {
    id,
    __typename: type,
  } = question;
  const {
    selectedCourse: {
      questionBank: {
        modalQuestionAlternatives: {
          modalQuestionAlternativesOpen,
          setModalQuestionAlternativesOpen,
          selectedQuestion,
          setSelectedQuestion,
          createObjectiveQuestionAlternative,
          updateObjectiveQuestionAlternative,
          removeObjectiveQuestionAlternative,
          setCorrectObectiveQuestionAlternative,
          createTrueFalseQuestionAlternative,
          updateTrueFalseQuestionAlternative,
          removeTrueFalseQuestionAlternative,
        },
      },
    },
  } = GlobalContext();

  const style = {
    width: 'inherit',
    color: '#C6C6C6',
    margin: 10,
    backgroundColor: '#080808'
  };

  const [addMode, setAddMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [alternative, setAlternative] = useState({});

  const [gql, setGql] = useState(GET_OBJECTIVE_QUESTION_BY_ID);
  const [skip, setSkip] = useState(true);
  const getQuestion = useQuery(gql, { skip, variables: { id } });

  useEffect(() => {
    if (modalQuestionAlternativesOpen) {
      switch (type) {
        case 'ObjectiveQuestion':
          setGql(GET_OBJECTIVE_QUESTION_BY_ID);
          break;
        case 'TrueFalseQuestion':
          setGql(GET_TRUEFALSE_QUESTION_BY_ID);
          break;
        default:
          throw new Error(`Tipo de questão ${type} não implementado!`);
      }

      setSkip(false);
    }
  }, [modalQuestionAlternativesOpen]);

  const setPropByEvent = (e, state, setState, targetProp = 'value') => {
    setState({ ...state, [e.target.name]: e.target[targetProp] });
  };

  const clean = () => {
    setSelectedQuestion({});
    setAddMode(false);
    setAlternative({});
  };

  const cleanAndClose = () => {
    clean();
    setModalQuestionAlternativesOpen(false);
  };

  const submitAddAlternative = async () => {
    switch (type) {
      case 'ObjectiveQuestion':
        await createObjectiveQuestionAlternative({
          variables: {
            objectiveQuestion: selectedQuestion.id,
            text: alternative.text,
            isCorrect: false,
          },
        });
        break;
      case 'TrueFalseQuestion':
        await createTrueFalseQuestionAlternative({
          variables: {
            trueFalseQuestion: selectedQuestion.id,
            text: alternative.text,
            isCorrect: alternative.isCorrect,
          },
        });
        break;
      default:
        throw new Error(`Tipo de questão ${type} não implementado!`);
    }
    setAlternative({});
    setAddMode(false);
    getQuestion.refetch();
  };

  const submitEditAlternative = async () => {
    switch (type) {
      case 'ObjectiveQuestion':
        await updateObjectiveQuestionAlternative({
          variables: {
            id: alternative.id,
            text: alternative.text,
          },
        });
        break;
      case 'TrueFalseQuestion':
        await updateTrueFalseQuestionAlternative({
          variables: {
            id: alternative.id,
            text: alternative.text,
            isCorrect: alternative.isCorrect,
          },
        });
        break;
      default:
        throw new Error(`Tipo de questão ${type} não implementado!`);
    }
    setAlternative({});
    setEditMode(false);
    getQuestion.refetch();
  };

  const submitDeleteAlternative = async () => {
    switch (type) {
      case 'ObjectiveQuestion':
        await removeObjectiveQuestionAlternative({
          variables: {
            id: alternative.id,
          },
        });
        break;
      case 'TrueFalseQuestion':
        await removeTrueFalseQuestionAlternative({
          variables: {
            id: alternative.id,
          },
        });
        break;
      default:
        throw new Error(`Tipo de questão ${type} não implementado!`);
    }
    setAlternative({});
    setDeleteMode(false);
    getQuestion.refetch();
  };

  const submitSetCorrectObjectiveQuestionAlternative = async (alternativeItem) => {
    if (type !== 'ObjectiveQuestion') {
      throw new Error(`Tipo de questão ${type} não implementado!`);
    }
    console.log(alternativeItem.id);
    await setCorrectObectiveQuestionAlternative({
      variables: {
        objectiveQuestion: selectedQuestion.id,
        objectiveQuestionAlternative: alternativeItem.id,
      },
    });
    getQuestion.refetch();
  };

  const submitEditAlternativeCheckbox = async (alternativeItem) => {
        await updateTrueFalseQuestionAlternative({
          variables: {
            id: alternativeItem.id,
            text: alternativeItem.text,
            isCorrect: true,
          },
        });
    getQuestion.refetch();
  };


  const renderAddAlternative = () => {
    if (addMode) {
      return (
        <TableRow>
          { type === 'ObjectiveQuestion' && <TableCell /> }
          { type === 'TrueFalseQuestion' && (
          <TableCell align="center">
            <Checkbox
              checked={alternative.isCorrect || false}
              value={alternative.isCorrect || false}
              name="isCorrect"
              color="primary"
              onChange={e => setPropByEvent(e, alternative, setAlternative, 'checked')}
            />
          </TableCell>
          ) }
          <TableCell>
            <TextField
              autoFocus
              fullWidth
              name="text"
              margin="dense"
              value={alternative.text || ''}
              onChange={e => setPropByEvent(e, alternative, setAlternative)}
              variant="outlined"
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  submitAddAlternative();
                  ev.preventDefault();
                }
              }}
            />
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
            <Fab
              size="small"
              color="primary"
              aria-label="cancel"
              onClick={() => { submitAddAlternative(); }}
            >
              <SaveIcon />
            </Fab>
            {' '}
            <Fab
              size="small"
              aria-label="cancel"
              onClick={() => { setAddMode(false); }}
            >
              <ClearIcon />
            </Fab>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        <TableCell>
          <div style={{ backgroundColor: '#dadada', height: '32px' }} />
        </TableCell>
        <TableCell>
          <div style={{ backgroundColor: '#dadada', height: '32px' }} />
        </TableCell>
        <TableCell align="center">
          <Fab
            size="small"
            color="primary"
            aria-label="add alternative"
            onClick={() => {
              setAlternative({
                isCorrect: false,
              });
              setAddMode(true);
              setEditMode(false);
              setDeleteMode(false);
            }}
          >
            <AddIcon />
          </Fab>
        </TableCell>
      </TableRow>
    );
  };

  const renderAlternativeItem = (alternativeItem) => {
    if (editMode && alternative.id === alternativeItem.id) {
      return (
        <TableRow key={alternativeItem.id}>
          { type === 'ObjectiveQuestion' && <TableCell /> }
          { type === 'TrueFalseQuestion' && (
          <TableCell align="center">
            <Checkbox
              checked={alternative.isCorrect}
              name="isCorrect"
              color="primary"
              onChange={e => setPropByEvent(e, alternative, setAlternative, 'checked')}
            />
          </TableCell>
          ) }
          <TableCell>
            <TextField
              autoFocus
              fullWidth
              name="text"
              margin="dense"
              value={alternative.text || ''}
              onChange={e => setPropByEvent(e, alternative, setAlternative)}
              variant="outlined"
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  submitEditAlternative();
                  ev.preventDefault();
                }
              }}
            />
          </TableCell>
          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
            <Fab
              size="small"
              color="primary"
              aria-label="cancel"
              onClick={() => { submitEditAlternative(); }}
            >
              <SaveIcon />
            </Fab>
            {' '}
            <Fab
              size="small"
              aria-label="cancel"
              onClick={() => { setEditMode(false); }}
            >
              <ClearIcon />
            </Fab>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow key={alternativeItem.id}>
        <TableCell align="center">
          { type === 'ObjectiveQuestion' && (
          <Radio
            checked={alternativeItem.isCorrect || false}
            onChange={() => {
              submitSetCorrectObjectiveQuestionAlternative(alternativeItem);
            }}
          />
          )}
          { type === 'TrueFalseQuestion' && (

        <Checkbox
    checked={alternativeItem.isCorrect || false}
    onChange={() => submitEditAlternativeCheckbox(alternativeItem)}
    />
          )}
        </TableCell>
        <TableCell>
          {alternativeItem.text}
        </TableCell>
        { (deleteMode && alternative.id === alternativeItem.id) ? (
          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
            <Fab
              size="small"
              color="primary"
              aria-label="cancel"
              onClick={() => { submitDeleteAlternative(); }}
            >
              <DeleteIcon />
            </Fab>
            {' '}
            <Fab
              size="small"
              aria-label="cancel"
              onClick={() => { setDeleteMode(false); }}
            >
              <ClearIcon />
            </Fab>
          </TableCell>
        ) : (
          <TableCell align="center" style={{ whiteSpace: 'nowrap' }}>
            <IconButton
              size="small"
              aria-label="cancel"
              onClick={() => {
                setAlternative(alternativeItem);
                setAddMode(false);
                setEditMode(true);
                setDeleteMode(false);
              }}
            >
              <EditIcon />
            </IconButton>
            {' '}
            <IconButton
              size="small"
              aria-label="cancel"
              onClick={() => {
                setAlternative(alternativeItem);
                setAddMode(false);
                setEditMode(false);
                setDeleteMode(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  };

  const renderAlternatives = alternatives => (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell style={{ width: '100px' }} align="center">
            { type === 'ObjectiveQuestion' && 'Correto?' }
            { type === 'TrueFalseQuestion' && 'Verdadeiro?' }
          </TableCell>
          <TableCell>Alternativa</TableCell>
          <TableCell align="center" style={{ width: '120px' }}>Ações</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        { !alternatives ? (
          <TableRow>
            <TableCell align="center" colSpan="3">Sem alternativas</TableCell>
          </TableRow>
        ) : alternatives.map(alternativeItem => renderAlternativeItem(alternativeItem)) }
        {renderAddAlternative()}
      </TableBody>
    </Table>
  );

  const renderQuestion = () => {
    if (!getQuestion.data) {
      return 'Carregando...';
    }

    const {
      data: {
        question: {
          stem,
          alternatives,
        },
      },
    } = getQuestion;

    return (
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h4" color="textPrimary" gutterBottom>{stem}</Typography>
        </Grid>
        <Grid item xs={12}>
          {renderAlternatives(alternatives)}
        </Grid>
      </Grid>
    );
  };

  return (
    <Dialog
      fullScreen
      open={modalQuestionAlternativesOpen && selectedQuestion.id === id}
      title="Editar alternativas"
      onCancel={cleanAndClose}
      textCancel="Sair"
      style={style}
    >
      <Container>
        {renderQuestion()}
      </Container>
    </Dialog>
  );
};

ModalQuestionAlternatives.propTypes = {
  question: shape({
    id: string.isRequired,
    __typename: oneOf(['ObjectiveQuestion', 'TrueFalseQuestion', 'SubjectiveQuestion']).isRequired,
  }).isRequired,
};

export default ModalQuestionAlternatives;
