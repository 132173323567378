import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useManualQuery from '../useManualQuery';
import { GET_COURSE_FORUM } from '../../services/queries/forum';
import { GET_POSTS_BY_TOPIC } from '../../services/queries/module';
import {
  CREATE_TOPIC,
  UPDATE_TOPIC,
  REMOVE_TOPIC,
} from '../../services/mutations/forum';
import {
  CREATE_POST,
  REMOVE_POST,
  FIXED_POST,
}
  from '../../services/mutations/post';

const useForum = () => {
  const [topic, setTopic] = useState({});

  const getCourseForum = useManualQuery(GET_COURSE_FORUM);
  const getPostsByTopic = useManualQuery(GET_POSTS_BY_TOPIC);

  const [createTopic] = useMutation(CREATE_TOPIC);
  const [updateTopic] = useMutation(UPDATE_TOPIC);
  const [removeTopic] = useMutation(REMOVE_TOPIC);
  const [createPost] = useMutation(CREATE_POST);
  const [removePost] = useMutation(REMOVE_POST);
  const [fixedPost] = useMutation(FIXED_POST);

  return {
    topic,
    setTopic,
    getCourseForum,
    getPostsByTopic,
    createTopic,
    updateTopic,
    removeTopic,
    createPost,
    removePost,
    fixedPost,
  };
};

export default useForum;
