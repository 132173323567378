import React from 'react';
import { SearchOutlined } from '@material-ui/icons';
import { Input, SearchbarContainer } from './styles';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Searchbar = () => {

  const history = useHistory()

    return (
        <SearchbarContainer>
            <SearchOutlined style={{cursor: 'pointer'}}/>
            <Input placeholder='Buscar'
             onClick={() => history.push("/searchpage")}
            />
        </SearchbarContainer>
    )
}

export default Searchbar;
