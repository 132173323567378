import React, { useEffect } from 'react';
import {
  Typography,
  Grid,
  Divider,
  Button,
  Container,
} from '@material-ui/core';
import { Redirect, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import GlobalContext from '../../../../context';
import { StyledContainer } from '../styles';
import AppBarWrapper from '../../../../components/AppBarWrapper';
import ModalNewQuestion from './ModalNewQuestion';
import { QuestionTitle } from './styles';
import { GET_EXERCISE_QUESTIONS } from '../../../../services/queries/exercise';
import ExerciseQuestion from './ExerciseQuestion';
import Footer from '../../../../components/Footer';

const Exercise = () => {
  const {
    selectedCourse: {
      exercises: {
        selectedExercise,
        questions: {
          setModalNewQuestionOpen,
        },
      },
    },
    visualization: {
      visualization,
    },
  } = GlobalContext();

  const history = useHistory();

  const getQuestions = useQuery(GET_EXERCISE_QUESTIONS, {
    variables: { id: selectedExercise && selectedExercise.id },
  });


  if (!selectedExercise) {
    return <Redirect to="/course" />;
  }

    const renderQuestions = () => {
      if (getQuestions.loading) {
        return 'Carregando...';
      }


    const { data: { getExerciseById: { exerciseQuestions } } } = getQuestions;
    return (
      <Container style={{marginTop:"30px"}}>
        <Grid container spacing={2}>
          {exerciseQuestions.map((i, indexI) => (
            <ExerciseQuestion
              exerciseQuestion={i}
              index={indexI}
            />
          ))}
        </Grid>
      </Container>
    );
  };

  return (
    <>
    <AppBarWrapper/>
      <StyledContainer maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" color="primary"
            style={{textAlign:"center"}}>
              {selectedExercise.title}</Typography>
            <Typography variant="body1"
            style={{marginTop:"10px", marginBottom:"10px", textAlign:"center",
            color: '#DCDDDF'}}
            >{selectedExercise.description}</Typography>

            {visualization === 'Estudante'?
            <Typography variant="body1"
            style={{ marginBottom:"26px", textAlign:"center", color: '#DCDDDF'}}
            >Envie a resposta após ter certeza, uma vez a resposta enviada não há como responder novamente.
            </Typography>
             :null}
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Typography variant="h6" style={{color: '#DCDDDF'}}>Questões</Typography>
              <div>
              <Button
                variant="outlined"
                color="primary"
                style={{marginRight:"10px"}}
                onClick={() => {
                  history.goBack();
              }}
              >
                Voltar
              </Button>
              {visualization === 'Administrador' && (
                <>
                <Button
                variant="outlined"
                color="primary"
                style={{marginRight:"10px"}}
                onClick={() => {
                  history.push('/questionbank');
              }}
              >
                Banco de Questões
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setModalNewQuestionOpen(true);
                }}
              >
                Publicar Questão
              </Button>
              </>
              )}
              </div>
            </Grid>
            <br></br>
          <Divider style={{background: '#DCDDDF'}}/>
          </Grid>
          <Grid item xs={12}>
            {renderQuestions()}
          </Grid>
        </Grid>
        <ModalNewQuestion refetch={getQuestions.refetch} />
      </StyledContainer>
      <Footer />
    </>
  );
};

export default Exercise;
