import styled from 'styled-components';

export const HeaderContainer = styled.div`
padding: 40px;
@media(max-width: 720px) {
  margin-top:100px;
}
`

export const Title = styled.p`
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 16px;
color: #FF9466;
padding-bottom:10px;

`
export const Prefeitura = styled.p`
font-weight: 700;
font-size: 28px;
line-height: 32px;
color: #FFFFFF;
@media(max-width: 720px) {
  font-size: 18px;
}
`
