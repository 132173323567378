import { AccordionTitle, CardVideo, Count, CardLegendas, Legendas, FormEpisodes, Icon, Info, Input, Prevew, Small, StyledFormControlLabel, TextArea, TextAreaContainer, TextAreaContainerDescription, TextAreaDescription, TitleCard, TitleSeasons } from "./styles"
import React, { useState, useContext, useEffect } from "react";
import {CircularProgressbar} from "react-circular-progressbar";
import { MdAdd } from "react-icons/md";
import { useDropzone } from "react-dropzone";
import { FaClosedCaptioning } from 'react-icons/fa'
import{VscChromeClose}  from 'react-icons/vsc';
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import { UploadContext } from '../index'
import { useForm, Controller } from 'react-hook-form';

  export function Episodes({errors, control, watch, setValue, index, setEpsData, epsData}) {
    const {data, setData} = useContext(UploadContext);
    const [files, setFiles] = useState([]);
    const [legend, setLegend] = useState();
    const [episode, setEpisode] = useState(0);
    const [count, setCount] = useState(0);
    const [countContent, setCountContent] = useState(0);
    const [episodiosData, setEpisodiosData] = useState([])


  // const {getRootProps, getInputProps} = useDropzone({noKeyboard: true,
  //   accept: {
  //     'video/mp4': ['.mp4'],
  //     'video/webm': ['.webm'],
  //     'video/ogg': ['.ogg']
  //   },
  //   onDrop: acceptedFiles => {
  //     setFiles(acceptedFiles.map(file => Object.assign(file, {
  //       preview: URL.createObjectURL(file)
  //     })));
  //   }
  // });

  useEffect(()=> {
    setEpsData(data?.modules[index]?.units)
    setValue('videos', data?.modules[index]?.units);
  }, [])

  const removeVideo = (i) => {
    const newArray = [...data?.modules]
    newArray[index].units.splice(i, 1)
    setValue('videos', newArray);
    setData({...data, modules: newArray})
  }

  const removeLegenda = (i, ep) => {
    const newArray = [...data?.modules]
    newArray[index].units[i].subtitles.splice(ep, 1)
    console.log(newArray)
    // setValue('videos', newArray);
    setData({...data, modules: newArray})
  }


   const addVideo = (e, onChange) => {
    const newArray = [...data?.modules]
    newArray[index].units.push({
      contentFile: e.target.files[0],
      name: '',
    })
    setData({...data, modules: newArray})
    onChange(newArray[index].units)

   }

   const addLegenda = (e, ep) => {
    console.log(episode)

      const newArray = [...data?.modules]
      newArray[index].units[episode].subtitles.push({
        fileContent: e.target.files[0],
        idiom: ''
      })
      setData({...data, modules: newArray})

      console.log(data?.modules ? data?.modules?.reduce((e, f)=> e + f.units.reduce((a,b) => a + b.subtitles?.reduce((c,d)=>c + d.fileContent ? d.fileContent.size : 0, 0), 0), 0) : 0)
   }

   const handleName = (value, i) =>{
    var newArray = {...data}

    newArray.modules[index].units[i].name = value
    setData(newArray);
   }

   const handleDescription = (value, i) =>{
    var newArray = {...data}

    newArray.modules[index].units[i].description = value
    setData(newArray);
   }

   const handleIdiomSubtitle = (value, sub, i) =>{
    var newArray = {...data}

    newArray.modules[index].units[i].subtitles[sub].idiom = value
    setData(newArray);
   }


   useEffect(()=>{
    console.log(data)
   },[data])

   const languages =
    ['Português', 'Inglês', 'Mandarim', 'Hindi', 'Espanhol', 'Francês',
    'Árabe', 'Bengali', 'Russo', 'Indonésio', 'Japonês', 'Alemão', 'Punjabi', 'Marata',
    'Catalão', 'Coreano', 'Italiano', 'Urdo', 'Turco', 'Vietnamita', 'Tailandês']

  return (
   <>

        <TitleSeasons>
        Episódios
        </TitleSeasons>
        <Info>
        A ordem dos episódios será a ordem de adição.
        </Info>

        {/* {files ? */}
        <div>
      {data && data?.modules[index]?.units?.map((file, i) => {
       return(
       <div
        key={i}
       style={{background: "#F5F5F5", marginBottom:"25px", borderRadius: "16px", marginTop:"25px",
       display:"flex", flexDirection: "column", alignItems:"center", justifyContent:"center"}}
       >
        <AccordionTitle style={{padding:"40px", textAlign:"center"}}>
            <strong>Episódio {i+1}</strong>
          </AccordionTitle>

          <FormEpisodes style={{margin:"0", padding:"40px 40px"}}>
          <label>
            Título do episódio <Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainer>
          <Controller
                name="name"
                control={control}
                defaultValue={file.name}
                render={props => (
                    <Input type="text" name="name" defaultValue={file.name}
                    onChange={e =>
                      handleName(e.target.value, i)} placeholder='Insira o título do episódio'/>
                )}
                />
              <Count>
                <span>{count}</span>/100
              </Count>
            </TextAreaContainer>


            <label>
            Descrição do episódio<Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainerDescription>
               <Controller
                    name="descriptionUnit"
                    control={control}
                    defaultValue={file.description}
                    render={props => (
                      <TextAreaDescription
                        type="text"
                        maxLength="500"
                        defaultValue={file.description}
                        placeholder='Fale sobre o episódio'
                        name="descriptionUnit"
                        onChange={(e)=>handleDescription(e.target.value, i)}
                      />
                  )}
                  />
              <Count>
                <small>{countContent}</small>/500
              </Count>
              <FormHelperText error={Boolean(errors?.description)}>
              {errors?.description?.message}
            </FormHelperText>
            </TextAreaContainerDescription>


               <label>
                    Legendas
                </label>

      {file.subtitles && file.subtitles.map((legenda, y) => (

        <Legendas key={y}>
          <VscChromeClose style={{float: 'right', cursor: 'pointer'}} size={24} onClick={()=> removeLegenda(i, y)}/>
          <div style={{display: 'flex', alignItems: 'center', margin: '10px 0 20px 0'}}>

            <FaClosedCaptioning size={20}/>
            <TitleCard style={{marginLeft: 10}}>
              {legenda.fileContent?.name || legenda.idiom}
            </TitleCard>

          </div>


            <label>
              Idioma
            </label>
            <TextAreaContainer>
            <Controller
                  name="idiomSubtitles"
                  control={control}
                  defaultValue={legenda.idiom}
                  render={props => (
                      <Input type="text" name="idiomSubtitles" defaultValue={legenda.idiom} onChange={e => handleIdiomSubtitle(e.target.value, y, i)} placeholder='Insira o idioma da legenda'/>
                  )}
                  />
              </TextAreaContainer>

                </Legendas>
        ))}

        <CardLegendas style={{maginRight:"60px"}}>
          <Icon>
            <MdAdd size={28}/>
            </Icon>
            <TitleCard>
              Faça upload da legenda se necessário
            </TitleCard>

            <Controller
              name="subtitles"
              control={control}
              defaultValue={file?.subtitles}
              render={props => (
                  <input
                  type="file"
                  accept=".txt"
                  id="subtitles"
                  name="subtitles"
                  style={{ display: "none" }}
                  onChange={(e) => {console.log(index);addLegenda(e, i)}}/>
              )}
            />
            <FormHelperText error={Boolean(errors?.videos)}>
          {errors?.videos?.message}
          </FormHelperText>

          <label htmlFor="subtitles"
          style={{ fontSize: '12px', cursor:"pointer"}}>
          <u onClick={()=>setEpisode(i)}>Clique aqui</u> para adicionar uma nova legenda
          </label>

            <Info>
            Formato: .srt, .sub, .ssa ou .txt
            </Info>
          </CardLegendas>



          </FormEpisodes>


                <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                 <video width="730" controls>
                 <source src={file?.content || URL.createObjectURL(file?.contentFile)} />
                 </video>
                 <div style={{display:'flex', justifyContent:'center'}}>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none",  color: 'black', margin: 0 }}
                onClick={() => removeVideo(i)}
                 ><u>remover</u></button>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none",  color: 'black', margin: 0 }}
                ><a style={{color: 'black'}} href={file?.content || URL.createObjectURL(file?.contentFile)} download>download</a></button>
                </div>
                </div>

       </div>
       )
       })}
                {/* )}
              )} */}
        </div>

              <CircularProgressbar
              styles={{
                root: { width: 24 },
                path: { stroke: "#7159c1" }
              }}
              strokeWidth={10}
              percentage={files.progress}
            />
            {/* } */}


        <CardVideo style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
              Carregar episódios
              </TitleCard>

              <Controller
                name="videos"
                control={control}
                defaultValue={data?.videos}
                render={props => (
                    <input
                    type="file"
                    accept=".mp4"
                    id="video"
                    name="videos"
                    style={{ display: "none" }}
                    onChange={(e) => {addVideo(e, props.field.onChange)}}/>
                )}
               />
               <FormHelperText error={Boolean(errors?.videos)}>
            {errors?.videos?.message}
            </FormHelperText>

            <label htmlFor="video"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u>Clique aqui</u> para adicionar um novo episódio
            </label>
              <Info>
              Você pode selecionar quantos episódios quiser.
              </Info>
              <Info>
              Formato: .mp4, .webm ou .ogg
              </Info>
            </CardVideo>
   </>
  )
}

export default Episodes
