import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { ADD_TAG_TO_QUESTION } from '../../../services/mutations/tag';

const useModalAddTagToQuestion = () => {
  const [searchText, setSearchText] = useState('');
  const [modalAddTagToQuestionOpen, setModalAddTagToQuestionOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [addTagToQuestion] = useMutation(ADD_TAG_TO_QUESTION);

  return {
    searchText,
    setSearchText,
    modalAddTagToQuestionOpen,
    setModalAddTagToQuestionOpen,
    selectedQuestion,
    setSelectedQuestion,
    addTagToQuestion,
  };
};

export default useModalAddTagToQuestion;
