import styled from 'styled-components';

import { Card } from '@material-ui/core';

export const Container = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  background: #f6f6f6;
  border: 1px solid #dadada;
  border-radius: 4px;

  .wrapper {
    display: flex;
    align-items: center;
    height: 100%;

    .checkbox {
      align-self: center;
      padding: 0;
    }

    .avatar {
      margin: 0 10px 0 5px;
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      height: 100%;

      .name {
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        color: #1c1c1c;
      }

      .profile {
        font-size: 14px;
        color: #757575;
        margin-bottom: 5px;
      }

      .button {
        box-shadow: none;
      }

      .accept {
        margin-right: 5px;
      }
    }
  }

  .editButton {
    background-color: #f6f6f6;
    border: 2px solid #dadada;
    border-radius: 3px;
    padding: 5px;
  }

  .MuiButton-contained {
    box-shadow: none;
  }
`;
