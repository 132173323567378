import {
  Dialog, DialogTitle, DialogContent, DialogActions, TextField, Grid, Button,
} from '@material-ui/core';
import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { EVALUATE_SUBJECTIVE_ANSWER } from '../../../../services/mutations/question';

const ModalScore = ({
  open, setOpen, selectedAnswer, refetch,
}) => {
  const [score, setScore] = useState('');
  const [giveScoreToSubjectiveAnswer] = useMutation(EVALUATE_SUBJECTIVE_ANSWER);

  const cleanAndClose = () => {
    setScore('');
    setOpen(false);
  };

  const submit = async () => {
    await giveScoreToSubjectiveAnswer({
      variables: {
        id: selectedAnswer.id, isCorrect: Number(score),
      },
    });
    refetch();
    cleanAndClose();
  };

  return (
    <Dialog
      open={open}
      onClose={cleanAndClose}
    >
      <DialogTitle>
        Atribuir nota
      </DialogTitle>
      <DialogContent>
        <TextField
          value={score}
          onChange={e => setScore(e.target.value)}
          variant="outlined"
          label="Nota"
        />
        <br />
        (favor inserir decimais entre 0 e 1, EX: 0.25)
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={cleanAndClose}
            >
            Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={submit}
            >
            Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalScore;
