import React, {useState, useEffect} from 'react';
import { Button, ContainerList, Image, Info, InfoTexts, LikesVideosList, NameCourse, TitleVideo } from './styles';
import { BiTrashAlt} from 'react-icons/bi';
import GlobalContext from '../../../../context';
import Loading from '../../../../components/Loading/Loading';
import { useQuery } from '@apollo/react-hooks';
import { FAVORITE_UNITS } from '../../../../services/queries/user';
import { Alert } from '@material-ui/lab';
import NotificationManager from 'react-notifications/lib/NotificationManager';


const ListVideos = () => {

  const {
    user: { id },
    users: {
      favoriteUnit,
    },
  }  =  GlobalContext();

  const [favorites, setFavorites] = useState();

  const {refetch} = useQuery(FAVORITE_UNITS, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setFavorites(data?.getUserById?.favoriteUnits);
    }
  })


  const handleFavoriteRemoval = async (id) => {
    const response = await favoriteUnit({
      variables: {
        id,
        favorite: false
      },
    });

    if(response?.data?.favoriteUnit?.id){
      NotificationManager.success("Favorito removido com sucesso!");
    } else {
      NotificationManager.error("Falha na remoção do favorito! Tente novamente mais tarde.");
    }

    refetch({ variables: { id } });
  }

 return (
  <ContainerList>
    {favorites?.length > 0 ? favorites?.map((favorite) =>
    (
      <LikesVideosList>
        <Info>
          <Image backgroundUrl={favorite.folder}/>
          <InfoTexts>
            <TitleVideo>{favorite.name}</TitleVideo>
            <NameCourse>{favorite.module.course.name}</NameCourse>
          </InfoTexts>
        </Info>
        <Button onClick={() =>handleFavoriteRemoval(favorite.id)} ><BiTrashAlt/> Remover dos favoritos</Button>
      </LikesVideosList>
    )) : <Alert severity="warning">Nenhum conteúdo favoritado até o momento. Favorite algum para que apareceça aqui!</Alert>}
  </ContainerList>
 )
};

export default ListVideos;
