import gql from 'graphql-tag';

export const CREATE_TOPIC = gql`
  mutation (
    $forumId: ID!
    $name: String!
    $description: String!
  ) {
    createTopic (
      ForumId: $forumId
      name: $name
      description: $description
    ) {
      id
    }
  }
`;

export const UPDATE_TOPIC = gql`
  mutation (
    $id: ID!
    $name: String
    $description: String
    $fixed: Boolean
    $notification: Boolean
  ) {
    updateTopic (
      id: $id
      name: $name
      description: $description
      fixed: $fixed
      notification: $notification
    ) {
      id
    }
  }
`;

export const REMOVE_TOPIC = gql`
  mutation (
    $id: ID!
  ) {
    removeTopic (
      id: $id
    )
  }
`;
