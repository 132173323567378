import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Delete, LocalOffer } from '@material-ui/icons';
import {
  bool, func, shape, string,
} from 'prop-types';
import 'moment/min/locales.min';
import moment from 'moment';
import UserAvatar from '../../../components/UserAvatar';
import Container from './styles';

moment.locale('pt-BR');

const Post = ({
  post, fixedFunction, removeFunction,
}) => (
  <Container>
    <div className="infoUser">
      <UserAvatar size={62} className="avatar" user={post.creator} />
      {post.creator.fullName}
    </div>
    <div className="infoPost">
      <div className="text">{post.text}</div>
      <div className="date">{moment(post.createdAt).format('LLLL')}</div>
    </div>
    <div className="menu">
      {post.fixed && (
        <Tooltip placement="left" title="Fixado">
          <LocalOffer className="button disable" />
        </Tooltip>
      )}
      {fixedFunction && (
        <Tooltip placement="left" title={post.fixed ? 'Desafixar' : 'Fixar'}>
          <LocalOffer className="button" onClick={() => fixedFunction(post)} />
        </Tooltip>
      )}
      {removeFunction && (
        <Tooltip placement="left" title="Remover">
          <Delete className="button" onClick={() => removeFunction(post)} />
        </Tooltip>
      )}
    </div>
  </Container>
);

Post.propTypes = {
  post: shape({
    id: string.isRequired,
    text: string.isRequired,
    fixed: bool,
    createdAt: string.isRequired,
    creator: shape({
      id: string.isRequired,
      fullName: string.isRequired,
      avatar: string,
    }).isRequired,
  }).isRequired,
  fixedFunction: func,
  removeFunction: func,
};

Post.defaultProps = {
  fixedFunction: null,
  removeFunction: null,
};

export default Post;
