import { useState } from 'react';

const useTrailer = () => {
  const [trailer, setTrailer] = useState();

  return {
    trailer,
    setTrailer,
  };
};

export default useTrailer;
