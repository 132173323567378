import styled from 'styled-components';
import { Drawer } from '@material-ui/core';

export const StyledDrawer = styled(Drawer)`
  .MuiPaper-root {
    background-color: #141414;
    width: 250px;
  }

  .MuiListItem-root {
    padding-bottom: 15px;
    padding-top: 15px;
    color: #fff;

    .MuiListItemIcon-root {
      color: #fff;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .info-profile {
    display: flex;
    flex-direction: column;
    margin-left: 10px;

    .name {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 5px;
    }

    .page {
      font-size: 12px;
      text-transform: uppercase;
    }
  }
`;
