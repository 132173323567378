import React from 'react';
import {
  string, bool, func, shape, arrayOf,
} from 'prop-types';

import {
  IconButton,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Tooltip,
  Typography,
  TableContainer,
  Paper,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';

import GlobalContext from '../../context';
import { useHistory } from 'react-router-dom';

const TopicList = ({
  topics, onPin, onUnpin, onEdit, onRemove, onEnter,
}) => {
  const {
    visualization: { visualization },
  } = GlobalContext();
  const isManager = visualization !== 'Estudante';
  const history = useHistory();

  return (
    <TableContainer component={Paper}>
    <Table  sx={{ minWidth: 650 }} size="small">
      <TableBody>
        {
        topics.map(t => (
          <TableRow hover key={t.id} style={{background: '#DCDDDF'}}>
            <TableCell style={{background: '#DCDDDF', cursor: 'pointer' }} onClick={()=> onEnter(t)}>
              <Typography variant="body1" noWrap><b>{t.name}</b></Typography>
              <Typography variant="body2"

              Wrap style={{color:"gray"}}>{t.description}</Typography>
            </TableCell>

            {
              isManager ? (
                <TableCell align="right" style={{ width: '180px' }}>
                  {
                    t.fixed ? (
                      <Tooltip title="Desafixar">
                        <IconButton
                          aria-label="unpin"
                          onClick={() => onUnpin(t)}
                        >
                          <TurnedInIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Fixar">
                        <IconButton
                          aria-label="pin"
                          onClick={() => onPin(t)}
                        >
                          <TurnedInNotIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                  <Tooltip title="Editar">
                    <IconButton
                      aria-label="edit"
                      onClick={() => onEdit(t)}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  {
                    !t.unit && (
                      <Tooltip title="Remover">
                        <IconButton
                          aria-label="delete"
                          onClick={() => onRemove(t)}
                        >
                          <DeleteIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </TableCell>
              ) : (
                <TableCell align="right" style={{ width: '180px' }}>
                  {
                    t.fixed && (
                    <TurnedInIcon fontSize="default" color="action" />
                    )
                  }
                </TableCell>
              )
            }
          </TableRow>
        ))
      }
      </TableBody>
    </Table>
    </TableContainer>
  );
};

TopicList.propTypes = {
  topics: arrayOf(shape({
    id: string.isRequired,
    name: string.isRequired,
    description: string,
    fixed: bool,
  })).isRequired,
  onPin: func,
  onUnpin: func,
  onEdit: func,
  onRemove: func,
  onEnter: func,
};

TopicList.defaultProps = {
  onPin: () => null,
  onUnpin: () => null,
  onEdit: () => null,
  onRemove: () => null,
  onEnter: () => null,
};

export default TopicList;
