import React, { useState } from 'react';

import {
  Add, Close, Delete,
} from '@material-ui/icons';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';

import {
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  TextField,
} from '@material-ui/core';

import { Query, Mutation } from 'react-apollo';
import { gql } from 'apollo-boost';
import { shape, string } from 'prop-types';
import client from '../../services/apollo';
import { GET_INSTITUTIONS, GET_INSTITUTION_BY_ID } from '../../services/queries/institution';
import {
  CREATE_INSTITUTION,
  REMOVE_INSTITUTIONS,
  UPDATE_INSTITUTION,
} from '../../services/mutations/institution';

import { Main, StyledContainer, StyledDialog } from './styles';

import AppBarWrapper from '../../components/AppBarWrapper';
import TabsBar from '../../components/TabsBar';
import InstitutionCard from '../../components/InstitutionCard';
import GlobalContext from '../../context';
import Footer from '../../components/Footer';

const Institutions = () => {
  const [state, setState] = useState({
    open: false,
    showModal: false,
    showConfirmation: false,
    name: '',
    id: null,
    selecteds: [],
    remove: false,
    edit: false,
    isDisable: false,
    error: false,
  });

  const { user } = GlobalContext();

  const handleClickModal = () => setState({
    ...state,
    showModal: !state.showModal,
    name: '',
    edit: false,
    isDisable: true,
  });

  const onRemoveInstitutions = () => {
    const REMOVE_INSTITUTIONS_MUT = REMOVE_INSTITUTIONS(state.selecteds);

    client.mutate({
      mutation: gql`
        ${REMOVE_INSTITUTIONS_MUT}
      `,
      refetchQueries: [{ query: GET_INSTITUTIONS }],
      onCompleted: setState({ ...state, selecteds: [] }),
    });
  };

  const confirmDelete = () => {
    setState({ ...state, showConfirmation: true });
    // TODO, change to use the custom component <Dialog>
    // eslint-disable-next-line no-alert
    const confirm = window.confirm(
      `Você tem certeza que deseja remover a instituição "${state.selecteds
        .map((item, index) => `(${index + 1}) ${item.name}`)
        .join(' - ')}"`,
    );

    if (confirm) onRemoveInstitutions();
  };

  const actions = [
    {
      id: 'ADD',
      icon: <Add />,
      name: 'Adicionar instituição',
      handleClick: () => handleClickModal(),
    },
    {
      id: 'REMOVE',
      icon: <Delete />,
      name: 'Remover instituições',
      handleClick: () => confirmDelete(),
    },
  ];

  const handleEditModal = () => setState({
    ...state,
    showModal: !state.showModal,
    error: false,
  });

  const handleSelectCheckbox = (id, name) => {
    const { selecteds } = state;

    let newSelected = [];
    if (selecteds.indexOf(id) >= 0) {
      newSelected = selecteds.filter(item => item.id !== id);
    } else {
      newSelected = [...selecteds, { id, name }];
    }

    setState({ ...state, selecteds: newSelected, remove: newSelected.length > 0 });
  };

  const handleEdit = (id) => {
    client
      .query({
        query: GET_INSTITUTION_BY_ID,
        variables: { id },
      })
      .then(({ data: { getInstitutionById } }) => {
        const { name } = getInstitutionById;
        setState({
          ...state,
          edit: true,
          showModal: true,
          isDisable: false,
          error: false,
          name,
          id,
        });
      })
      .catch(error => console.error(error));
  };

  const handleChange = ({ target: { value } }) => {
    setState({
      ...state,
      name: value,
      isDisable: value === '',
      error: value === '',
    });
  };

  const handleCreateSubmit = async (e, createInstitution) => {
    e.preventDefault();
    const { name } = state;
    if (name.trim() === '') return;

    await createInstitution({
      variables: {
        name,
      },
      // eslint-disable-next-line no-shadow
      update: (proxy, { data: { createInstitution } }) => {
        const data = proxy.readQuery({
          query: GET_INSTITUTIONS,
        });

        data.getInstitutions.unshift(createInstitution);

        proxy.writeQuery({
          query: GET_INSTITUTIONS,
          data,
        });
      },
    });

    setState({
      ...state,
      name: '',
    });
  };

  const handleEditSubmit = async (e, updateInstitution) => {
    e.preventDefault();
    const { name, id } = state;
    if (name.trim() === '') return;

    await updateInstitution({
      variables: {
        id,
        name,
      },
    });

    setState({
      ...state,
      name: '',
      id: null,
      edit: false,
    });
  };

  const renderModal = () => (
    <StyledDialog
      onClose={() => false}
      aria-labelledby="customized-dialog-title"
      open={state.showModal}
    >
      <form onSubmit={() => {}} noValidate autoComplete="off">
        <DialogTitle
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          disableTypography
          id="customized-dialog-title"
          className="titleModal"
          onClose={handleClickModal}
        >
          <Typography variant="h6">Adicionar Instituição</Typography>
          <IconButton aria-label="Close" className="closeButton" onClick={handleClickModal}>
            <Close />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextField
                id="outlined-name"
                label="Digite o nome da instituição"
                value={state.name}
                fullWidth
                required
                name="name"
                onChange={handleChange}
                margin="normal"
                variant="outlined"
              />
              <Typography className="error">{state.error && 'Campo obrigatório'}</Typography>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-uncontrolled"
                label="Digite o email do gestor"
                fullWidth
                type="email"
                margin="normal"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                id="outlined-button-file"
                label="Selecione uma imagem"
                fullWidth
                accept="image/*"
                margin="normal"
                variant="outlined"
                type="file"
                InputLabelProps={{ shrink: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClickModal} variant="contained" color="default">
            Cancelar
          </Button>
          {state.edit ? (
            <Mutation
              mutation={UPDATE_INSTITUTION}
              onCompleted={() => {
                handleEditModal();
                setState({ ...state, open: false });
              }}
            >
              {updateInstitution => (
                <Button
                  type="submit"
                  onClick={e => handleEditSubmit(e, updateInstitution)}
                  variant="contained"
                  color="primary"
                  disabled={state.isDisable}
                >
                  Editar
                </Button>
              )}
            </Mutation>
          ) : (
            <Mutation
              mutation={CREATE_INSTITUTION}
              onCompleted={() => {
                handleClickModal();
                setState({
                  ...state,
                  open: false,
                });
              }}
            >
              {createInstitution => (
                <Button
                  type="submit"
                  onClick={e => handleCreateSubmit(e, createInstitution)}
                  variant="contained"
                  color="primary"
                  disabled={state.isDisable}
                >
                  Salvar
                </Button>
              )}
            </Mutation>
          )}
        </DialogActions>
      </form>
    </StyledDialog>
  );
  const { open, remove } = state;

  if (!user.roles.find(i => i.name === 'Administrador')) {
    return <div>Essa tela é apenas para administradores.</div>;
  }

  return (
    <AppBarWrapper {...{ user }}>
      <Main maxWidth="md">
        <Grid container>
          <TabsBar title="Instituições">
            <StyledContainer>
              <Grid container spacing={2}>
                <Query query={GET_INSTITUTIONS}>
                  {({ loading, error, data: { getInstitutions } }) => {
                    if (loading) {
                      return (
                        <Grid xs={12} item>
                          <Typography className="label" component="h2">
                            Carregando...
                          </Typography>
                        </Grid>
                      );
                    }
                    if (error) {
                      return (
                        <Grid xs={12} item>
                          <Typography className="label" component="h2">
                            {error.message}
                          </Typography>
                        </Grid>
                      );
                    }
                    if (getInstitutions.length === 0) {
                      return (
                        <Grid xs={12} item>
                          <Typography className="label" component="h2">
                            Nenhuma instituição cadastrada
                          </Typography>
                        </Grid>
                      );
                    }
                    return (
                      <>
                        {getInstitutions.map(
                          item => item && (
                            <Grid item key={item.id} xs={12} sm={6} md={3}>
                              <InstitutionCard
                                data={item}
                                checkFunction={handleSelectCheckbox}
                                editFunction={handleEdit}
                              />
                            </Grid>
                          ),
                        )}
                      </>
                    );
                  }}
                </Query>
                <SpeedDial
                  ariaLabel="SpeedDial openIcon example"
                  className="speedDial"
                  hidden={false}
                  icon={<SpeedDialIcon />}
                  onBlur={() => setState({ ...state, open: false })}
                  onClick={() => setState({ ...state, open: true })}
                  onClose={() => setState({ ...state, open: false })}
                  onFocus={() => setState({ ...state, open: true })}
                  onMouseEnter={() => setState({ ...state, open: true })}
                  onMouseLeave={() => setState({ ...state, open: false })}
                  open={open}
                >
                  {actions.map(action => (
                    <SpeedDialAction
                      disabled={action.id === 'REMOVE' && !remove}
                      key={action.name}
                      icon={action.icon}
                      tooltipTitle={action.name}
                      onClick={action.handleClick}
                    />
                  ))}
                </SpeedDial>
                {renderModal()}
              </Grid>
            </StyledContainer>
          </TabsBar>
        </Grid>
        <Footer />
      </Main>
    </AppBarWrapper>
  );
};

Institutions.propTypes = {
  user: shape({
    fullName: string.isRequired,
    avatar: string,
    institution: shape({
      id: string,
    }).isRequired,
  }).isRequired,
};

export default Institutions;
