import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;
  height: 400px;
  width: 250px;
  margin: 0 20px;
  box-sizing: border-box;
  flex-grow: 0;

  .cardMedia {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    align-items: flex-start;

  .title {
    font-weight: bold;
    font-size: 17px;
    line-height: 18px;
    color: #fff;
    margin-top: 370px;
    margin-left: 20px;
    text-wrap: wrap;
  }

    .actions {
      display: flex;
      justify-content: space-between;
    }

    .progressBar {
      height: 10px;
      background-color: #fff;

      .MuiLinearProgress-barColorPrimary {
        border-radius: 20px;
        background-color: #22aaf5;
      }
    }
  }

    .duration {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #333333;
    }


  .subtitle {
    font-size: 14px;
    line-height: 14.06px;
    color: #56CCF2;
  }

  .footer {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #333333;
    margin: 5px 0;
  }

  .info-institution {
    font-size: 14px;
    line-height: 16px;
    color: #DCDDDF;
  }
`;
