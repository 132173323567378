import styled from 'styled-components';

export const FormContainer = styled.div`
margin-top:40px;
@media(max-width: 920px) {
 margin-left:20px;
}
`

export const ImageContainer = styled.div`
display:flex;
justify-content:start;
background: #e84810,
`
export const AvatarImg = styled.img`
width:96px;
height:96px;
borderRadius: "8px"
`
export const ImageDescription = styled.div`
display:flex;
flex-direction: column;
justify-content:start;
max-width: 494px;
padding-left: 15px;
`

export const TextDescription = styled.p`

`

export const Title = styled.p`
font-weight: 700;
font-size: 18px;
color: #FFFFFF;
padding-bottom: 30px;
`

export const Buttons = styled.div`
display:flex;

`

export const FormDate = styled.form`
`

export const FormItem = styled.div`
display:flex;
flex-direction: column;
justifyContent: flex-end;
@media(max-width: 920px) {
  width: 90vw;
}

`
export const Label = styled.label`
display:flex;
align-items:center;
padding:10px;
padding-left:0px;
font-weight: 500;
font-size: 16px;
line-height: 20px;
color: #FFFFFF;
`
export const Small = styled.p`
margin-bottom: 3px;
font-weight: 400;
font-size: 14px;
color: #DCDDDF;
padding-left:3px;
padding-right:3px;
`

export const Input = styled.input`
padding: 16px;
isolation: isolate;
width: 808px;
height: 56px;
border: 1px solid #FFFFFF;
border-radius: 8px;
@media(max-width: 920px) {
  width: 90vw;
}
`

export const Select = styled.select`
padding: 16px;
isolation: isolate;
width: 808px;
height: 56px;
border: 1px solid #FFFFFF;
border-radius: 8px;

`
export const BoxDetails = styled.div`
padding: 16px;
isolation: isolate;
width: 808px;
height: 56px;
border: 1px solid #FFFFFF;
background:#FFFFFF;
border-radius: 8px;
margin-bottom:30px;
display:flex;
justify-content: space-between;
align-items:center;
width: 808px;
@media(max-width: 920px) {
  width: 90vw;
}
`
export const DetailsIcon = styled.div`
`
export const DetailsText = styled.div`
font-size: 16px;
color: #141414;
font-weight: 700;
padding-left:25px;
`
export const SubText = styled.p`
font-size: 14px;
color: #6D6D6D;
font-weight: 400;
`
export const ButtonDetails = styled.button`
color:#6D6D6D;
padding: 4px 8px;
height: 24px;
background: #FFFFFF;
border:none;
cursor:pointer;
transition: filter 0.2;
&:hover{
filter: brightness(0.9)
}
`

export const Submit = styled.button`
padding: 12px 32px;
gap: 8px;
height: 48px;
background: #FF4C00;
border-radius: 8px;
border:none;
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 24px;
color: #FFFFFF;
margin-top:30px;
margin-bottom: 80px;
cursor: pointer;
transition: filter 0.2;
&:hover{
filter: brightness(0.9)
}
`

export const ButtonLogout = styled.button`
background: rgba(255, 76, 0, 0.05);
border: 1px solid #FF4C00;
border-radius: 8px;
width: 808px;
height: 36px;
text-align:center;
color: #FFFFFF;
cursor: pointer;
font-size: 14px;
&:hover{
  background: #FF4C00;
}

@media(max-width: 920px) {
  width: 90vw;
}

`


