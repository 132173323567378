import { AccordionTitle, SubTitleSeasons, CardVideo, Count, FormEpisodes, Icon, Info, Input, Prevew, Small, StyledFormControlLabel, TextArea, TextAreaContainer, TextAreaContainerDescription, TextAreaDescription, TitleCard, TitleSeasons } from "../styles"
import React, { useState, useContext } from "react";
import { MdAdd} from "react-icons/md";
import {CircularProgressbar} from "react-circular-progressbar";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, FormControlLabel, Radio, RadioGroup, FormHelperText } from "@material-ui/core";
import { UploadContext } from '../index'
import { useForm, Controller } from 'react-hook-form';
import { useDropzone } from "react-dropzone";

  export function Material({errors, control}) {
    const [data, setData] = useContext(UploadContext);
    const [files, setFiles] = useState([]);
    const [count, setCount] = useState(0);

    console.log(files)

    const {getRootProps, getInputProps} = useDropzone({noKeyboard: true,
      accept: {
        '.pdf': ['.pdf'],
      },
      onDrop: acceptedFiles => {
        setFiles(acceptedFiles.map(file => Object.assign(file, {
          preview: URL.createObjectURL(file)
        })));
      }
    });

    const addVideo = (e, onChange) => {
      const nameDownload = e.target.files[0].name.replace(/\.pdf$/, '');
      console.log(e.target.files)
      if(data?.material){
        setData({ ...data,
          material: [...data?.material, e.target.files[0]],
          nameMaterial: [...data?.nameMaterial, nameDownload],
        })
        onChange([...data?.material, e.target.files[0]])
      }else{
        setData({
          ...data,
          material: [e.target.files[0]],
          nameMaterial: [nameDownload],
        })
        onChange([e.target.files[0]])
      }
     }


    const removeMaterial = (i) => {
      const tempVideo = [...data.material];
      const tempName = [...data.nameMaterial];

      tempVideo.splice(i, 1)
      tempName.splice(i, 1)
      // setValue('material', newArray);
      // setValue('nameMaterial', newArray);
      setData({
        ...data,
        material: tempVideo,
        nameMaterial: tempName,
      })

    }


    const handleName = (value, index) =>{
      const temp = [...data.nameMaterial]
      temp[index] = value
      setData({...data, nameMaterial: temp})
    }

    const handleRemoveFile = name => {
      setFiles(files.filter(file => file.name !== name))
    }

    return (
    <>

        <div>
          <SubTitleSeasons>
                  Material Complementar
          </SubTitleSeasons>

          {data?.material?.map((file, index) => {
       return(
       <div
        key={index}
       style={{background: "#F5F5F5", marginBottom:"25px", borderRadius: "16px", marginTop:"25px",
       display:"flex", flexDirection: "column", alignItems:"center", justifyContent:"center"}}
       >
        <AccordionTitle style={{padding:"40px", textAlign:"center"}}>
            <strong>{file.name}</strong>
          </AccordionTitle>

          <FormEpisodes style={{margin:"0", padding:"40px 40px"}}>
          <label>
            Título do episódio <Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainer>
          <Controller
                name="nameMaterial"
                control={control}
                defaultValue={data?.nameMaterial && data?.nameMaterial[index]}
                render={props => (
                    <Input type="text" name="nameMaterial" defaultValue={data?.nameMaterial && data?.nameMaterial[index]} onChange={e => handleName(e.target.value, index)} placeholder='Insira o título do episódio'/>
                )}
                />
              <Count>
                <span>{count}</span>/100
              </Count>
            </TextAreaContainer>

          </FormEpisodes>


                <div style={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                <embed src={URL.createObjectURL(file)} width="500" height="375" type="application/pdf"/>
                 <button style={{ paddingBottom:"25px", fontSize:"14px" , cursor:"pointer", background:"transparent", border:"none",  color: 'black', margin: 0 }}
                onClick={() => removeMaterial(index)}
                 ><u>remover</u></button>
                </div>

       </div>
       )
       })}
                {/* )}
              )} */}
        </div>

              <CircularProgressbar
              styles={{
                root: { width: 24 },
                path: { stroke: "#7159c1" }
              }}
              strokeWidth={10}
              percentage={files.progress}
            />
            {/* } */}


        <CardVideo style={{maginRight:"60px"}}>
             <Icon>
              <MdAdd size={28}/>
              </Icon>
              <TitleCard>
              Material Complementar
              </TitleCard>

              <Controller
                name="material"
                control={control}
                defaultValue={data?.material}
                render={props => (
                    <input
                    type="file"
                    accept="application/pdf"
                    id="material"
                    name="material"
                    style={{ display: "none" }}
                    onChange={(e) => {addVideo(e, props.field.onChange)}}/>
                )}
               />
               <FormHelperText error={Boolean(errors?.material)}>
            {errors?.material?.message}
            </FormHelperText>

            <label htmlFor="material"
            style={{ fontSize: '12px', cursor:"pointer"}}>
            <u>Clique aqui</u> para adicionar um novo material complementar
            </label>
              <Info>
              Você pode selecionar quantos materiais complementares quiser.
              </Info>
              <Info>
              Formato: .pdf
              </Info>
            </CardVideo>



    </>
  )
}

export default Material
