import styled from 'styled-components';
// import { Container } from '@material-ui/core';

export const Container = styled.div`
display:flex;
justify-content: space-between;
margin-right:60px;
@media(max-width: 720px) {
  width: 90vw;
  margin-right:0px;
}
`

export const StyledContainer = styled.div`
  .dataWrapper {
    display: flex;
    justify-content: center;
  }

  .header {
    display: flex;
    height: 81px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
  }

  .pageTitle {
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    font-color: #1C1C1C;
    margin-left: 158px;
  }

  .userInfoBox {
    display: flex;
    flex-direction: column;
    width: 645px;
    height: auto;
    margin-top: 31px;
    background: #F6F6F6;
    border: 1px solid #DADADA;
    box-sizing: border-box;
    border-radius: 4px;
    justify-self: center;
    align-items: center;
    margin-bottom: 36px;
  }

  .avatar {
    margin-top: 27px;
  }

  .selectNewAvatar {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    margin-top: 5px;
    color: #575757;
  }

  .selectNewAvatar:hover {
    font-weight: bold;
    cursor: pointer;
    text-decoration: underline;
  }

  .userPosition {
    margin-top: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;

    display: flex;
    align-items: flex-end;

    color: #575757;
  }

  .submitButton {
    margin-top: 10px;
    margin-bottom: 22px;
    transition: filter 0.2;
    &:hover{
    filter: brightness(0.9)
    }
  }

  .uploadInput {
    display: none;
  }
`;

