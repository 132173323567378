import styled from 'styled-components';

import { Container, Dialog } from '@material-ui/core';

export const StyledContainer = styled(Container)`
  padding: 20px;

  .speedDial {
    position: absolute;
    bottom: 24px;
    right: 16px;
  }
`;

export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 10px;

    .MuiDialogTitle-root {
      padding: 0px 0px 0px 15px;
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
  }
  .error {
    margin-top: -7px;
    background: #ff3131;
    opacity: 1;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
    position: absolute;
    padding: 5px 10px;
    color: #fff;
    border-radius: 5px;
  }
  .error:empty {
    opacity: 0;
    background-color: #fff;
  }
  .error:empty:before {
    border-bottom-color: transparent;
  }
  .error::before {
    border-width: 10px;
    border-style: solid;
    border-color: transparent;
    content: "";
    border-bottom-color: red;
    position: absolute;
    top: -20px;
}
`;

export const Main = styled.main`
  flex-grow: 1;
  height: 100vh;

  .container {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .test {
    margin-bottom: 10px;
  }

  .pageTitle {
    font-size: 26px;
    font-weight: bold;
    line-height: 30px;
    color: #1c1c1c;
    margin-top: 16px;
  }

  .membersContainer {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    margin-top: 24px;
    padding-left: 130px;
    padding-right: 130px;
    justify-content: space-between;
  }
`;
