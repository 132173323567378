import { useState } from 'react';

const useDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(prev => !prev);

  return { isOpen, toggle };
};

export default useDrawer;
