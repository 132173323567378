import gql from 'graphql-tag';

const COURSE_PRIMARY_FIELDS = `
  id
  name
  description
  initials
  version
  timeOfDedication
  startDate
  endDate
  public
  studentQuantity
  moduleQuantity
  unitQuantity
  price
  minimumGrade
  folder
  thumbnail
  trailer
  year
  age
  tags {
    id
    name
  }
  modules{
    id
    name
    units{
      id
      name
      folder
      content
      hlsContent
      duration
      description
      subtitles {
        id
        idiom
        content
      }
      unitUserPlayHistoric {
        id
        UserId
        ElapsedTime
        Ended
      }
    }
  }
  categories{
    id
    name
  }
  material{
    id
    name
    url
  }
`;


export const UPLOAD_THUMBNAIL = gql`
  mutation($file: Upload!) {
    uploadThumbnail(file: $file){
      filename
      mimetype
    }
  }`;

export const GET_COURSES = gql`
  query {
    getCourses {
      ${COURSE_PRIMARY_FIELDS}
      disabled
      createdAt
      updatedAt
    }
  }
`;


export const GET_COURSES_AVAILABLE = gql`
  query {
    getCoursesAvailable(filterVisible: true) {
      ${COURSE_PRIMARY_FIELDS}
    }
  }
`;

export const GET_COURSE_MODULES = gql`
  query($id: ID!) {
    getCourseById(id: $id) {
      modules {
        id
        name
      }
    }
  }
`;

export const GET_ALGORITHM_BANK = gql`
  query($id: ID!) {
    getAlgorithmBank(id: $id) {
      id
      name
      questions {
        __typename
        ... on ObjectiveQuestion {
          id
          stem
          alternatives {
            id
            text
            isCorrect
          }
          tags {
            id
            name
          }
        }
        ... on TrueFalseQuestion {
          id
          stem
          alternatives {
            id
            text
            isCorrect
          }
          tags {
            id
            name
          }
        }
        ... on SubjectiveQuestion {
          id
          stem
          tags {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_COURSE_SUBSCRIPTIONS = gql`
  query($id: ID!) {
    getCourseById(id: $id) {
      pendingSubscriptions {
        id
        fullName
        email
        avatar
      }
    }
  }
`;

export const GET_COURSES_BY_INSTITUTION = gql`
  query($id: ID!) {
    getInstitutionById(id: $id) {
      courses {
        ${COURSE_PRIMARY_FIELDS}
      }
    }
  }
`;

export const GET_MY_COURSES_BY_USER = gql`
  query($id: ID!, $role: RoleTypes) {
    getUserById(id: $id) {
      courses(filterVisible: true, role: $role) {
        ${COURSE_PRIMARY_FIELDS}
      }
    }
  }
`;

export const GET_COURSE_CURRENT_UNIT = gql`
  query($CourseId: ID!) {
    getCourseCurrentUnitHistoric(CourseId: $CourseId) {
        id
        UserId
        UnitId
        ModuleId
        CourseId
        ElapsedTime
        Ended
        unit{
          id
          name
          order
          folder
          content
          hlsContent
          type
          duration
          topic {
            id
          }
          subtitles {
            id
            idiom
            content
          }
          unitUserPlayHistoric {
            id
            UserId
            ElapsedTime
            Ended
          }
        }
    }
  }
`

export const GET_MY_USER_ROLE_COURSES = gql`
  query($id: ID!, $role: RoleTypes) {
    getUserById(id: $id) {
      userRolesCourses(role: $role) {
        Course{
          id
          name
        }
        createdAt
      }
    }
  }
`;

export const GET_MEMBERS_BY_COURSE = gql`
  query($id: ID!) {
    getCourseById(id: $id) {
      students {
        id
        fullName
        email
        avatar
        roles {
          id
          name
        }
      }
      teachers {
        id
        fullName
        email
        avatar
        roles {
          id
          name
        }
      }
      assistants {
        id
        fullName
        email
        avatar
        roles {
          id
          name
        }
      }
    }
  }
`;
