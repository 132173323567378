import {
  DialogTitle, DialogContent, Grid, DialogActions, Button,
} from '@material-ui/core';
import React, { useEffect } from 'react';
import Select from 'react-select';
import GlobalContext from '../../../context';
import { StyledDialog } from './style';

const ModalAddTagToQuestion = () => {
  const {
    selectedCourse: {
      questionBank: {
        getAlgorithmBank,
        modalAddTagToQuestion: {
          modalAddTagToQuestionOpen,
          setModalAddTagToQuestionOpen,
          searchText,
          setSearchText,
          selectedQuestion,
          addTagToQuestion,
        },
      },
    },
    tags: {
      getTags
    },
  } = GlobalContext();

  useEffect(() => {
    if (!getTags.data) {
      getTags.fetch()
    }
  }, [])

  if (!selectedQuestion || !getTags.data) {
    return null;
  }

  const { data: { getTags: tags } } = getTags

  const { tags: questionTags } = selectedQuestion;

  const options = tags.filter(
    courseTag => questionTags.every(
      questionTag => questionTag.id !== courseTag.id,
    ),
  ).map(({ id: value, name: label }) => ({ value, label }));

  const cleanAndClose = () => {
    setSearchText('');
    setModalAddTagToQuestionOpen(false);
  };

  return (
    <StyledDialog
      open={modalAddTagToQuestionOpen}
      onClose={cleanAndClose}
      classes={{
        paper: 'paper',
      }}
    >
      <DialogTitle>Adicionar tag</DialogTitle>
      <DialogContent>
        <Select
          value={searchText}
          onChange={(text) => {
            setSearchText(text);
          }}
          options={options}
          menuPlacement="bottom"
          menuPosition="fixed"
        />
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => setModalAddTagToQuestionOpen(false)}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={async () => {
                await addTagToQuestion({
                  variables: {
                    tag: searchText.value,
                    question: selectedQuestion.id,
                    type: selectedQuestion.__typename.slice(0, -8),
                  },
                });
                getAlgorithmBank.refetch();
                cleanAndClose();
              }}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

export default ModalAddTagToQuestion;
