import styled from 'styled-components';
import { Card, Dialog } from '@material-ui/core';

export const ButtonDelete = styled.button`
display: flex;
border:none;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 16px;
gap: 8px;
height: 36px;
background: #FFFFFF;
border-radius: 16px;
cursor:pointer;
color:#6D6D6D;
margin-right:16px;
transition: filter 0.2;
&:hover{
filter: brightness(0.9)
}
`
export const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    margin: 10px;

    .MuiDialogTitle-root {
      padding: 0px 0px 0px 15px;
    }
  }
  .container {
    display: flex;
    flex-wrap: wrap;
  }
`;


export const ButtonPublic = styled.button`
display: flex;
cursor: pointer;
border:none;
flex-direction: row;
align-items: center;
padding: 8px 16px;
gap: 8px;
width: 107px;
height: 36px;
background: #EDEDED;
margin-right:16px;
border-radius: 24px;
cursor:pointer;
transition: filter 0.2;
&:hover{
filter: brightness(0.9)
}
`

export const ButtonPrivate = styled.button`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 8px 16px;
gap: 8px;
width: 140px;
height: 36px;
background: #FFFFFF;
border: 1px solid #FF4C00;
border-radius: 16px;
cursor:pointer;
&:hover{
background-color:#FF4C00;
}
`
export const LabelTab = styled.div`
display:flex;
justify-content:center;
align-items:center;
padding: 16px 24px;
width: 180px;
height:56px;
border: 2px solid #EDEDED;
borderRadius: 16px;
color: #6D6D6D;
`
