import styled from 'styled-components';


export const ContainerSearchBar = styled.div`
width: 85vw;
height: 64px;
display: flex;
flex-direction: row;
background-color: #FFFFFF;
align-items: center;
justify-content: space-between;
box-sizing: border-box;
border-bottom: 1px solid #DCDDDF;
padding: 0 30px;
color: #080808;
overflow:hidden;
`

export const TitleSearch = styled.p`
font-weight: 500;
font-size: 18px;
line-height: 24px;
color: #080808;
`

export const ColumnRight = styled.div`
display:flex;
align-items:center;
`

export const SearchInput = styled.div`
display:flex;
align-item:center;
justify-content:start;
padding: 14px 24px;
width: 256px;
height: 48px;
background: #EDEDED;
border-radius: 8px;
margin:16px;
`

export const Input = styled.input`
background-color: transparent;
outline: none;
color: #080808;
border: none;
margin-left: 15px;
font-size: 0.9rem;
width: 180px;
`

export const ButtonModal = styled.button`
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
border:none;
background: transparent;
border: 1px solid #FF4C00;
border-radius: 8px;
font-weight: 700;
font-size: 12px;
color: #080808;
width: 157px;
height: 48px;
`
