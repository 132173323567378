import styled from 'styled-components';

export const ContainerVideos =styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
padding: 16px;
gap: 16px;
margin:30px;
width: 1092px;
height: 224px;
background: #FFFFFF;
border: 1px solid #EDEDED;
border-radius: 8px;
justify-content:center;
`

export const Content =styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
padding: 16px;
gap: 16px;
margin:30px;
width: 517px;
height: 224px;
background: #FFFFFF;
border: 1px solid #EDEDED;
border-radius: 8px;
justify-content:center;
`
export const Header =styled.div`
display:flex;
justify-content: space-between;
width: 500px;
margin-top:60px;
`
export const Title =styled.p`
font-family: 'Montserrat';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 20px;
color: #080808;
padding-left: 20px;
`
export const ButtonGroup =styled.div`
display:flex;
justify-content:center;
align-items:center;`
export const ButtonHeader =styled.div`
margin-right:7px;
border:none;
font-weight: 500;
font-size: 14px;
color: #080808;
display: flex;
justify-content: center;
padding: 4px 8px;
height: 24px;
background: #FFFFFF;
cursor:pointer;
&:hover{
  border: 1px solid #DCDDDF;
  border-radius: 24px;
}
&:focus{
  border: 1px solid #DCDDDF;
  border-radius: 24px;
  font-weight: 700;
}
`
export const ContentInfo =styled.div`
display:flex;
justify-content: space-between;
width: 500px;
`

export const ContentInfoVideos =styled.div`
display:flex;
justify-content: space-between;
width: 1000px;
`

export const TableContainer = styled.section`
    width: 100%;
    padding-bottom:60px;
    overflow: hidden;
    `
export const TableComponent = styled.table`
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;

`
export const ColumnTitle = styled.tr`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 5px 0;
    box-sizing: border-box;

    th {
        color: #6D6D6D;
        font-size: 14px;
        font-weight: normal;
        margin: 0 0px;
        text-align: center;

    }

`

export const TitleColumn = styled(ColumnTitle)`
   justify-content: space-between
   th {
     padding: 0;
     color: #6D6D6D;
     font-size: 14px;
     font-weight: normal;
     width:1%;
     text-align: center;
     column-width: 100px;

    }
    .first{

        text-align: left;


    }
    .firstSerie{

      text-align: left;


}
    .others{
      text-align: left;
      &:last-child{
        text-align:right;
      }

    }
    .othersSeries{
    text-align: right;

    }


`


export const TableData = styled.tr`
    display: flex;
    width: 100%;
    align-items: center;
    padding: 16px 0;
    justify-content: space-between;
    color:#080808;
    td {
        box-sizing: border-box;
        margin: 0 15px;
        font-size: 14px;
        min-width: 9%;
        max-width: 300px;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        &:first-child{
          text-align: left

        }
    }
    &:last-child {

    }
    `

export const TitleData = styled(TableData)`
justify-content: space-between;
td {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space:normal;
  width: 100px;
  column-width: 50px;
  height:150px;
  margin: 0 0 0 0;
  flex-wrap: wrap;
  .datePub{
    display: flex;
  align-items: center;
  white-space:normal;
  width: 100px;
  column-width: 100px;
  height:150px;
  margin: 0 0 0 0;
  flex-wrap: wrap;
  }
    }
`

export const TitleDiv = styled.div`
display: Flex;
flex-direction: row;
.detailsButton{
  button{
    text-transform:none;
    text-decoration: none;
    background-color:transparent;
    border:none;
    cursor:pointer;
    img{
      opacity:0.5;
      &:hover{
        opacity:1
      }
  }
  }
}
.titleThumb{
  position:relative;
  height:180px;
  width:255px;
  object-fit: content;
  border-radius:4px
}

.texts{
  text-overflow: ellipsis;

  h4{

    margin-top:5px;
    margin-bottom: 5px;
    span{
      position:absolute
    }
  }
  margin-left:5px;
  display:flex;
  flex-wrap: nowrap;
  flex-direction: column;
  max-height:80%;
  width: 280px;
  font-size: 14px;
.parentalRating{
  p{margin-bottom:15px
  }

}
}
.visibility{

  background-color:rgba(230,230,230,0.8);
  border-radius:4px;
  padding:2px;
  display:flex;
  flex-direction:row;
  width: max-content;
  margin-bottom: 4px;
  /* position:absolute;
  left: 35rem;
  transform: translateY(-20%) */

}
.time{
position: absolute;
background-color: rgba(230,230,230,0.8);
color: #6D6D6D;
padding: 2px;
border-radius:4px;
left: 24.2rem;


}


`
export const ContentData = styled.div`
position:absolute;
left:48.5%;
display:flex;
flex-direction:row;
justify-content:space-between;
td{
  width: 100px;
  column-width: 4px;
  margin: 0;
  flex-wrap: wrap;
  white-space: normal;
  &:last-child{

  }
}


`

export const GridIndexes =styled.div`
box-sizing: border-box;
gap: 16px;
margin:30px;
width: 517px;
height: 224px;
background: transparent;
display: grid;
grid-template-columns: 250px 250px;
`

export const GridIndexesItem =styled.div`
box-sizing: border-box;
display: flex;
flex-direction: column;
align-items: center;
width: 250px;
height: 105px;
background: #FFFFFF;
border: 1px solid #EDEDED;
border-radius: 8px;
justify-content:center;
`

export const HeaderIndex =styled.div`
display: flex;
justify-content:space-between;
height: 35px;
width: 230px;
margin-top:10px;
`

export const HeaderVideos =styled.div`
display: flex;
justify-content:space-between;
height: 35px;
width: 1012px;
margin-top:10px;
`

export const TitleFlex =styled.div`
display: flex;
flex-direction: column;
`

export const Total =styled.p`
font-weight: 500;
font-size: 12px;
color: #080808;
`

export const NoData =styled.p`
font-weight: 500;
font-size: 14px;
color: #080808;
`

export const TitleIndex =styled.p`
font-weight: 700;
font-size: 16px;
color: #080808;
`

export const NumberIndex =styled.p`
font-weight: 700;
font-size: 24px;
color: #080808;
`


