import React, { useEffect, useRef, useState } from "react";
import { array } from "prop-types";
import { AiOutlineDoubleLeft } from "react-icons/ai";
import { AiOutlineDoubleRight } from "react-icons/ai";
import {
  MovieRow,
  MovieRowList,
  MovieRowListArea,
  LeftArrow,
  RightArrow,
  Shadow,
  ProgressBar,
} from "./styles";
import {
  LinearProgress
} from '@material-ui/core';

const CustomSlider = ({ courses = [], itemWidth = 10 }) => {
  const wrapperRef = useRef();
  const [scrollX, setScrollX] = useState(0);
  const [isLeftArrowEnabled, setIsLeftArrowEnabled] = useState(false);
  const [hasMoreCourses, setHasMoreCourses] = useState(
    Boolean(courses.length * itemWidth > window.innerWidth)
  );

  const getCoursesOnScreen = Math.floor(
    (wrapperRef.current?.getBoundingClientRect().width ?? 0) / itemWidth
  );
  const [isSize, setIsSize] = useState(true);

  const handleLeftArrow = () => {
    let x = scrollX + getCoursesOnScreen * itemWidth;
    // Chegou ao inicio
    if (x >= 0) {
      x = 0;
      setIsLeftArrowEnabled(false);
    }
    setHasMoreCourses(Boolean(courses.length * itemWidth > window.innerWidth));
    setScrollX(x);
  };
  const handleRightArrow = () => {
    let x = scrollX - getCoursesOnScreen * itemWidth;
    if (getCoursesOnScreen === 0) {
      x =
        scrollX -
        Math.floor(
          (wrapperRef.current?.getBoundingClientRect().width ?? 0) / itemWidth
        ) *
          itemWidth;
    }
    const listW = courses.length * itemWidth;

    // Chegou ao fim
    if (window.innerWidth - listW > x) {
      setHasMoreCourses(false);
    }

    if (listW > x) {
      setScrollX(x);
      setIsLeftArrowEnabled(true);
    }
  };

  const autoResize = () => {
    if (courses.length * itemWidth < window.innerWidth) {
      setScrollX(0);
      setIsLeftArrowEnabled(false);
    } else if (courses.length * itemWidth > window.innerWidth && scrollX <= 0) {
      setHasMoreCourses(true);
    }
    setIsSize(Boolean(courses.length * itemWidth > window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener("resize", autoResize);
    autoResize();
  }, []);

  const [progress, setProgress] = useState(66);

  useEffect(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    ;

    return () => {
      clearInterval(progress);
    };
  }, []);


  return (
    <MovieRow ref={wrapperRef}>
      <MovieRowListArea>
        {isLeftArrowEnabled && isSize && (
          <LeftArrow onClick={handleLeftArrow}>
            <AiOutlineDoubleLeft
              style={{ color: "#FF4C00", fontSize: "40px" }}
            />
          </LeftArrow>
        )}
        {hasMoreCourses && isSize &&(
          <>
            <Shadow/>
            <RightArrow onClick={handleRightArrow}>
              <AiOutlineDoubleRight
                style={{ color: "#FF4C00", fontSize: "40px" }}
              />
            </RightArrow>
          </>
        )}

        <MovieRowList
          width={courses.length * itemWidth}
          marginLeft={scrollX}
          onClick={() => console.log("clicked")}
        >
          {courses.map((course) => (
            <div>{course}
            {progress > 0 &&(

                <LinearProgress style={{marginTop:"-13px", marginRight:"23px",  marginLeft:"23px", background:"gray"}}
                variant="determinate" value={progress} />
            )}
            </div>

          ))}
        </MovieRowList>
      </MovieRowListArea>
    </MovieRow>
  );
};

CustomSlider.propTypes = {
  courses: array.isRequired,
};

export default CustomSlider;
