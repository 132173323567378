import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { shape, string, oneOf } from 'prop-types';
import AddIcon from '@material-ui/icons/AddOutlined';
import {
  GET_OBJECTIVE_QUESTION_BY_ID,
  GET_TRUEFALSE_QUESTION_BY_ID,
  GET_SUBJECTIVE_QUESTION_BY_ID,
} from '../../../services/queries/questions';
import { StyledChip } from './style';
import GlobalContext from '../../../context';
import { REMOVE_TAG_FROM_QUESTION } from '../../../services/mutations/tag';

const TagsFromQuestion = ({ question }) => {
  const { id, __typename: type } = question;
  const [gql, setGql] = useState(GET_OBJECTIVE_QUESTION_BY_ID);
  const [skip, setSkip] = useState(true);
  const [removeTagFromQuestion] = useMutation(REMOVE_TAG_FROM_QUESTION);
  const getTagsFromQuestion = useQuery(gql, { skip, variables: { id } });
  const {
    selectedCourse: {
      questionBank: {
        modalAddTagToQuestion: {
          setModalAddTagToQuestionOpen,
          setSelectedQuestion,
        },
        getAlgorithmBank,
      },
    },
  } = GlobalContext();

  useEffect(() => {
    let query;
    switch (type) {
      case 'ObjectiveQuestion':
        query = GET_OBJECTIVE_QUESTION_BY_ID;
        break;
      case 'TrueFalseQuestion':
        query = GET_TRUEFALSE_QUESTION_BY_ID;
        break;
      case 'SubjectiveQuestion':
        query = GET_SUBJECTIVE_QUESTION_BY_ID;
        break;
      default:
        throw new Error(`Tipo de questão ${type} não implementado!`);
    }

    if (query) {
      setGql(query);
      setSkip(false);
    }
  }, []);

  if (!getTagsFromQuestion.data || getTagsFromQuestion.loading) {
    return 'Carregando...';
  }

  const initialChip = () => (
    <StyledChip
      label="adicionar..."
      onClick={() => {
        setSelectedQuestion(question);
        setModalAddTagToQuestionOpen(true);
      }}
      variant="outlined"
      size="small"
      icon={<AddIcon />}
    />
  );

  return (
    <>
      {initialChip()}
      {getTagsFromQuestion.data.question.tags.map(i => (
        <StyledChip
          key={i.id}
          label={i.name}
          onDelete={async () => {
            await removeTagFromQuestion({
              variables: {
                tag: i.id,
                question: question.id,
                type: question.__typename.slice(0, -8),
              }
            })
            getAlgorithmBank.refetch()
          }}
          size="small"
        />
      ))}
    </>
  );
};

TagsFromQuestion.propTypes = {
  question: shape({
    id: string.isRequired,
    __typename: oneOf(['ObjectiveQuestion', 'TrueFalseQuestion', 'SubjectiveQuestion']).isRequired,
  }).isRequired,
};

export default TagsFromQuestion;
