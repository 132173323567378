import React from "react";
import { oneOfType, arrayOf, node, shape, func } from "prop-types";
import { withRouter } from "react-router-dom";
import { AiOutlineHome , AiOutlineUnorderedList} from 'react-icons/ai';
import {MdOutlineCategory } from 'react-icons/md';
import { AppBar, Toolbar, IconButton, CssBaseline } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import CientikIcon from "../../assets/images/cientik_logo.svg";
import logomobile from '../../assets/images/logomobile.png'
import Drawer from "../Drawer";
import { Container, NavLinks, NavSocial, IconesMenu, IconDesktop, IconMobile, NavLinksMobile, MobileColumn, SearchbarContainer, ButtonAdmin } from "./styles";
import GlobalContext from "../../context";
import UserDropdown from "./UserDropdown";
import {AiOutlineSearch} from 'react-icons/ai';
import {MdOutlineAdminPanelSettings} from 'react-icons/md';
import {TbScreenShare} from 'react-icons/tb';
import Searchbar from "./Searchbar";
import { NavLink } from "react-router-dom";
import {TbSchool}   from 'react-icons/tb';

const AppBarWrapper = ({ children, history }) => {
  const {
    drawer: { toggle },
    visualization: { visualization },
  } = GlobalContext();

  const isManager = ["Administrador", "Gestor"].includes(visualization);

  return (
    <MobileColumn>
    <Container>
      <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}}>
        <div>

          <IconDesktop className="title">
            <img
              src={CientikIcon}
              onClickCapture={() => history.push("/")}
              alt="logo"
            />
          </IconDesktop>
          <IconMobile className="title">
            <img
              src={logomobile}
              onClickCapture={() => history.push("/")}
              alt="logo" />
          </IconMobile>

          </div>

          <div>
          <NavLinks>
          <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/"
            >
	          <a style={{display:"flex", paddingLeft:"80px"}}
            >   <AiOutlineHome size={18}/>
             Inicio
             </a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/mylist"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>
              <AiOutlineUnorderedList size={18}/>
              Minha lista</a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>
              <MdOutlineCategory size={18}/>
              Categorias</a>
          </NavLink>

          {visualization !== "Estudante" && (

          <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>
            <TbSchool size={18}/>
              Capacitação de professores</a>
          </NavLink>
           )}
          </NavLinks>
          </div>
          </div>

          <div style={{display:"flex", justifyContent: "space-between", alignItems:"center"}}>
          <NavSocial>
            <Searchbar/>
          </NavSocial>

          <IconesMenu>
            <AiOutlineSearch size={24} style={{marginRight:"20px"
            , cursor:"pointer"}}
            onClick={() => history.push("/searchpage")}/>
            {/* <TbScreenShare size={24}/> */}
          </IconesMenu>
          <UserDropdown/>

          {isManager &&
          <ButtonAdmin
          edge="start"
          className="menuButton"
          onClick={() =>history.push("/publishermain")}
          color="inherit"
          aria-label="Menu">
            <MdOutlineAdminPanelSettings size={18}/> <p style={{paddingLeft:"5px"}}>Acessar Admin</p>
        </ButtonAdmin>}

      {isManager && <Drawer />}
      {/* <main className="content">
        <div className="appBarSpacer" />
        {children}
      </main> */}
      </div>
    </Container>

    <NavLinksMobile>
          <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/"
            >
	          <a>
             Inicio
             </a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/mylist"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>

              Minha lista</a>
            </NavLink>

            <NavLink
            style={{color: "#ffffff", textDecoration:"none"}}
            to="/categories"
            >
	          <a style={{display:"flex", paddingLeft:"50px"}}>

              Categorias</a>
          </NavLink>
          </NavLinksMobile>
</MobileColumn>
  );
};

AppBarWrapper.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(AppBarWrapper);
