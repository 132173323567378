import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import useManualQuery from '../../../useManualQuery';
import { GET_EXERCISE_QUESTIONS } from '../../../../services/queries/exercise';
import { CREATE_SUBJECTIVE_QUESTION, CREATE_SUBJECTIVE_ANSWER, CREATE_OBJECTIVE_QUESTION, REMOVE_OBJECTIVE_QUESTION, CREATE_OBJECTIVE_QUESTION_ALTERNATIVE, UPDATE_OBJECTIVE_QUESTION_ALTERNATIVE, REMOVE_OBJECTIVE_QUESTION_ALTERNATIVE, SET_CORRECT_OBJECTIVE_QUESTION_ALTERNATIVE, CREATE_TRUEFALSE_QUESTION, UPDATE_TRUEFALSE_QUESTION, REMOVE_TRUEFALSE_QUESTION, CREATE_TRUEFALSE_QUESTION_ALTERNATIVE, UPDATE_TRUEFALSE_QUESTION_ALTERNATIVE, REMOVE_TRUEFALSE_QUESTION_ALTERNATIVE, UPDATE_SUBJECTIVE_QUESTION, REMOVE_SUBJECTIVE_QUESTION } from '../../../../services/mutations/question';
import { GET_ANSWERS_FROM_QUESTION, GET_QUESTIONS } from '../../../../services/queries/questions';

const useQuestions = () => {
  const getQuestions = useManualQuery(GET_QUESTIONS);
  const [modalNewQuestionOpen, setModalNewQuestionOpen] = useState(false);
  const [modalNewAlternativeOpen, setModalNewAlternativeOpen] = useState(false);
  const [ editDialogQuestionOpen,  setEditDialogQuestionOpen]= useState(false);
  const [questionType, setQuestionType] = useState('');
  const [stem, setStem] = useState('');
  const [createSubjectiveQuestion] = useMutation(CREATE_SUBJECTIVE_QUESTION);
  const [createObjectiveQuestion] = useMutation(CREATE_OBJECTIVE_QUESTION);
  const [createTrueFalseQuestion] = useMutation(CREATE_TRUEFALSE_QUESTION);
  const [createTrueFalseQuestionAlternative] = useMutation(CREATE_TRUEFALSE_QUESTION_ALTERNATIVE);
  const [createObjectiveQuestionAlternative] = useMutation(CREATE_OBJECTIVE_QUESTION_ALTERNATIVE);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDialogQuestionOpen, setDeleteDialogQuestionOpen] = useState(false);
  const [removeSubjectiveQuestion] = useMutation(REMOVE_SUBJECTIVE_QUESTION);;
  const [removeObjectiveQuestionAlternative] = useMutation(REMOVE_OBJECTIVE_QUESTION_ALTERNATIVE);
  const [removeObjectiveQuestion] = useMutation(REMOVE_OBJECTIVE_QUESTION);
  const [removeTrueFalseQuestion] = useMutation(REMOVE_TRUEFALSE_QUESTION);
  const [removeTrueFalseQuestionAlternativen] = useMutation(REMOVE_TRUEFALSE_QUESTION_ALTERNATIVE);
  const [setCorrectObjectiveQuestionAlternative] = useMutation(SET_CORRECT_OBJECTIVE_QUESTION_ALTERNATIVE);
  const [modalEdit, setModalEdit] = useState();
  const [removeQuestion, setRemoveQuestion] =useState();
  const [editQuestion, setEditQuestion] = useState();
  const [updateObjectiveQuestionAlternative] = useMutation(UPDATE_OBJECTIVE_QUESTION_ALTERNATIVE);
  const [updateTrueFalseQuestion] = useMutation(UPDATE_TRUEFALSE_QUESTION);
  const [updateTrueFalseQuestionAlternative] = useMutation(UPDATE_TRUEFALSE_QUESTION_ALTERNATIVE);
  const [updateSubjectiveQuestion] = useMutation(UPDATE_SUBJECTIVE_QUESTION);

  return {
    getQuestions,
    modalNewQuestionOpen,
    setModalNewQuestionOpen,
    questionType,
    setQuestionType,
    stem,
    setStem,
    createSubjectiveQuestion,
    createObjectiveQuestion,
    createTrueFalseQuestion,
    createTrueFalseQuestionAlternative,
    createObjectiveQuestionAlternative,
    deleteDialogOpen, setDeleteDialogOpen,
    removeSubjectiveQuestion,
    removeObjectiveQuestionAlternative,
    removeObjectiveQuestion,
    removeTrueFalseQuestion,
    removeTrueFalseQuestionAlternativen,
    setCorrectObjectiveQuestionAlternative,
    modalEdit, setModalEdit,
    updateObjectiveQuestionAlternative,
    updateTrueFalseQuestion,
    updateTrueFalseQuestionAlternative,
    updateSubjectiveQuestion,
    removeQuestion, setRemoveQuestion,
    modalNewAlternativeOpen, setModalNewAlternativeOpen,
    deleteDialogQuestionOpen, setDeleteDialogQuestionOpen,
    editQuestion, setEditQuestion,
    editDialogQuestionOpen, setEditDialogQuestionOpen,
  };
};

export default useQuestions;
