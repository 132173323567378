import React, { useEffect } from 'react';
import { shape, func } from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import CourseCard from '../../../components/CourseCard';
import { StyledContainer } from '../../../styles/global';
import GlobalContext from '../../../context';

const CoursesAvailableList = ({ history }) => {
  const {
    selectedCourse: { setCourse, getCoursesAvailable, setCourseAccess },
  } = GlobalContext();

  useEffect(() => {
    getCoursesAvailable.fetch();
  }, []);

  if (!getCoursesAvailable.data) {
    return 'Carregando...';
  }

  const { data } = getCoursesAvailable;

  const enterCourse = (course) => {
    setCourse(course);
    setCourseAccess(false);
    history.push('/course');
  };

  return (
    <StyledContainer>
      <Grid container spacing={2}>
        {data.getCoursesAvailable.map(course => (
          <Grid item key={course.id} xs={12} sm={6} md={3}>
            <CourseCard
              course={course}
              enterFunction={enterCourse}
            />
          </Grid>
        ))}
        {data.getCoursesAvailable.length === 0 && (
          <Typography variant="h6">
            Nenhum curso no momento
          </Typography>
        )}
      </Grid>
    </StyledContainer>
  );
};

CoursesAvailableList.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(CoursesAvailableList);
