import gql from 'graphql-tag';

export const VIEW_UNIT_BY_DATA = gql`
  query($days: Int) {
    getUnitViewsByData(days: $days){
        id
        name
        module {
            name
            course {
                name
            }
        }
        UnitVideoPlayHistorics { 
          id
        }
    }
  }
`

export const VIEW_COURSE_BY_DATA = gql`
  query($days: Int) {
    getCourseViewsByData(days: $days){
        id
        name
        UnitVideoPlayHistorics { 
          id
        }
    }
  }
`

export const LIKES_UNITS_BY_DATA = gql`
  query {
    getMostLikedUnits {
        id
        name
        module {
          name
          course {
              name
          }
        }
        LikedUnitsUsers{
            id
        }
    }
  }
`