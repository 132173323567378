import React from 'react';
import AppBarWrapper from '../../../components/AppBarWrapper';
import Header from '../Header/Header';
import { FormContainer } from '../ProfileData/styles';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import { Container } from '../styles';
import ListVideos from './ListVideos';


const LikesVideos = () => {


 return (
  <>
  <AppBarWrapper/>
    <Header/>
    <Container>
    <SideBarMenu/>
    <FormContainer>
    <ListVideos/>
    </FormContainer>
    </Container>
    </>
 )
};

export default LikesVideos;
