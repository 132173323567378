import React from 'react';
import { Dot, DotContainer, LoadingContainer, LoadingContainerAdmin, LoadingText } from './style';

const LoadingAdmin = () => {

  return (
    <LoadingContainerAdmin>
      <Dot delay="1s" />
      <Dot delay="0.2s" />
      <Dot delay="0.3s" />
    </LoadingContainerAdmin>
  );
};


export default LoadingAdmin;
