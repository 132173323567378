import React from 'react';
import { FooterContainer, FooterLink, LinksContainer, LowerRow, SocialNetworksContainer, SocialNetworksIconsContainer, UpperRow , SocialNetworksTitle, Column} from './styles';
import { ReactComponent as CientikIcon } from '../../assets/images/cientik_logo.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/images/youtube_icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/images/twitter_icon.svg';
import { ReactComponent as InstagramIcon } from '../../assets/images/instagram_icon.svg';
import { ReactComponent as TiktokIcon } from '../../assets/images/tiktok_icon.svg';


const Footer = () => {

    return (
        <FooterContainer>
            <UpperRow>
                <p style={{fontSize: "32px", fontWeight:"700", paddingBottom:"15px"}}>O conhecimento <br></br>é uma viagem.</p>
                <div>
                 <CientikIcon />
                </div>
            </UpperRow>

                <LowerRow>
                <LinksContainer>
                <Column>
                <FooterLink to="/">Inicio</FooterLink>
                    <FooterLink to="/minhalista">Minha lista</FooterLink>
                    <FooterLink to="/categorias">Categorias</FooterLink>
                </Column>
                <Column>
                    <FooterLink to="/sobre">Sobre o Cientik</FooterLink>
                    <FooterLink to="/imprensa">Imprensa</FooterLink>
                </Column>
                <Column>
                    <FooterLink to="/termos-de-uso">Termos de uso</FooterLink>
                    <FooterLink to="/privacidade">Privacidade</FooterLink>
                </Column>
                </LinksContainer>

                <SocialNetworksContainer>
                   <p style={{fontSize: "12px"}}>@ 2022 Cientik. Todos os direitos reservados.</p>
                    <SocialNetworksIconsContainer>
                    <a  href={`https://www.youtube.com/channel/UCxwB0dosCONBkmXmUtIe2Hg`} target="_blank"> <YoutubeIcon style={{width: '32px', marginLeft: '8px'}}/> </a>
                    <a  href={`https://twitter.com/cientik`} target="_blank">  <TwitterIcon style={{width: '32px', marginLeft: '6px'}}/> </a>
                    <a  href={`https://www.instagram.com/cientik/`} target="_blank">   <InstagramIcon style={{width: '32px', marginLeft: '6px'}}/> </a>
                    <a  href={`https://www.tiktok.com/@cientik.edu`} target="_blank">    <TiktokIcon style={{width: '32px', marginLeft: '6px'}}/> </a>
                    </SocialNetworksIconsContainer>
                </SocialNetworksContainer>
                </LowerRow>
        </FooterContainer>
    )

}

export default Footer;
