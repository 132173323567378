import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  DialogActions,
  Button,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import update from 'immutable-assign';
import { useMutation } from '@apollo/react-hooks';
import GlobalContext from '../../../../context';
import { Alternative, QuestionTitle, RadioInput } from './styles';
import { ADD_QUESTION_TO_EXERCISE } from '../../../../services/mutations/question';

const ModalNewQuestion = ({ refetch }) => {
  const {
    selectedCourse: {
      exercises: {
        questions: {
          stem,
          setStem,
          questionType,
          setQuestionType,
          modalNewQuestionOpen,
          setModalNewQuestionOpen,
          createSubjectiveQuestion,
          createObjectiveQuestion,
          createTrueFalseQuestion,
        },
        selectedExercise,
      },
      questionBank: {
        getAlgorithmBank,
      },
      course,
    },
  } = GlobalContext();

  const [tabValue, setTabValue] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [addQuestionToExercise] = useMutation(ADD_QUESTION_TO_EXERCISE);

  useEffect(() => {
    if (!getAlgorithmBank.data) {
      getAlgorithmBank.fetch({
        variables: {
          id: course.id,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (getAlgorithmBank.data) {
      const { data: { getAlgorithmBank: { questions: q } } } = getAlgorithmBank;
      setQuestions(q);
    }
  }, [getAlgorithmBank]);

  const clean = () => {

  };

  const cleanAndClose = () => {
    clean();
    setModalNewQuestionOpen(false);
  };

  const submit = async () => {
    // if (tabValue === 0) {
    //   if (questionType === 'Objective') {
    //     await createObjectiveQuestion({
    //       variables: {
    //         stem,
    //         exercise: selectedExercise.id,
    //       },
    //       })
    //       refetch();
    //   } if (questionType === 'Subjective') {
    //     await createSubjectiveQuestion({
    //       variables: {
    //         stem,
    //         exercise: selectedExercise.id,
    //       },

    //     });
    //     refetch();
    //   } if (questionType === 'TrueFalse') {
    //     await  createTrueFalseQuestion({
    //       variables: {
    //         stem,
    //         exercise: selectedExercise.id,
    //       },
    //     });
    //   }
    //   refetch();
    // } else if (tabValue === 1) {
      await addQuestionToExercise({
        variables: {
          question: selectedQuestion.id,
          exercise: selectedExercise.id,
          type: selectedQuestion.__typename.slice(0, -8),
        },
      });
      await refetch();
    // }
    cleanAndClose();
  };

  const searchOnQuestion = (text, question) => question.stem.includes(text);

  const selectQuestion = (index) => {
    setSelectedQuestion(questions[index]);
  };

  const renderContent = () => {
    // if (tabValue === 0) {
    //   return (
    //     <>
    //       <Grid item xs={12}>
    //         <RadioGroup
    //           value={questionType}
    //           onChange={e => setQuestionType(e.target.value)}
    //         >
    //           {/* <FormControlLabel value="Objective" control={<Radio />} label="Objetiva" />
    //           <FormControlLabel value="TrueFalse" control={<Radio />} label="Verdadeira ou Falsa" /> */}
    //           <FormControlLabel value="Subjective" control={<Radio />} label="Subjetiva" />
    //         </RadioGroup>
    //       </Grid>
    //       { questionType === 'Subjective' ?

    //       <Grid item xs={12}>
    //         <TextField
    //           fullWidth
    //           label="Enunciado"
    //           variant="outlined"
    //           value={stem}
    //           onChange={e => setStem(e.target.value)}
    //           multiline
    //         />
    //          </Grid>
    //           :null}
    //         {questionType === 'Objective' ?
    //         <>
    //         <Grid item xs={12}>
    //         <TextField
    //           fullWidth
    //           label="Enunciado"
    //           variant="outlined"
    //           value={stem}
    //           onChange={e => setStem(e.target.value)}
    //           multiline
    //         />
    //          </Grid>

    //           </>: null}

    //       {questionType === 'TrueFalse' ?
    //       <>
    //       <Grid item xs={12}>
    //       <TextField
    //       fullWidth
    //       label="Enunciado"
    //       variant="outlined"
    //       value={stem}
    //       onChange={e => setStem(e.target.value)}
    //       multiline
    //       />
    //       </Grid>
    // </>: null}
    //     </>
    //   );
    // }

    // if (tabValue === 1) {
      if (!getAlgorithmBank.data) {
        return 'Carregando...';
      }

      return (
        <>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Pesquisar questão"
              variant="outlined"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              multiline
            />
          </Grid>
          <Grid item xs={12}>
            {questions.filter(i => searchOnQuestion(searchText, i)).map((i, indexI) => (
              <Grid key={i.id} container justify="flex-start" alignItems="center" spacing={2}>
                <Grid item>
                  <QuestionTitle
                    className={`clickable question-title${selectedQuestion && selectedQuestion.id === i.id && ' selected'}`}
                    component="span"
                    onClick={() => selectQuestion(indexI)}
                  >
                    {i.stem}
                  </QuestionTitle>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </>
      );
    // }
  };

  return (
    <Dialog open={modalNewQuestionOpen} onClose={cleanAndClose}>
      <DialogTitle>
        Nova questão
      </DialogTitle>
      {/* <Tabs
        value={tabValue}
        onChange={(e, newValue) => setTabValue(newValue)}
      >
        <Tab value={0} label="Nova questão subjetiva" />
        <Tab value={1} label="Do banco de questões" />
      </Tabs> */}
      <DialogContent>
        <Grid container spacing={2}>
          {renderContent()}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              variant="outlined"
              onClick={() => cleanAndClose()}
            >
            Cancelar
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => submit()}
            >
            Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ModalNewQuestion;
