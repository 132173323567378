import styled, { createGlobalStyle } from 'styled-components';
import { Container } from '@material-ui/core';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Montserrat', sans-serif;
    ::-webkit-scrollbar {
      width: 7px;
      height: 7px;
      }
      ::-webkit-scrollbar-button {
        width: 0px;
        height: 0px;
      }
      ::-webkit-scrollbar-thumb {
        background: #666666;
        border: 0px none #ffffff;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: #e84810;
      }
      ::-webkit-scrollbar-thumb:active {
        background: #e84810;

      }
      ::-webkit-scrollbar-track {
        background:#EDEDED;
        border: 0px none #ffffff;
        border-radius: 4px;
      }
      ::-webkit-scrollbar-track:hover {
        background: #666666;
      }
      ::-webkit-scrollbar-track:active {
        background: #666666;
      }
      ::-webkit-scrollbar-corner {
        background: #666666;
      }
  }

  body {
    font-family: 'Montserrat', sans-serif;
    background-color: #080808;
    color: #DCDDDF;
  }
  .notification-container {
    top: 64px;
    width: initial;
    max-width: 500px;
  }
  .padding-containers {
    padding: 15px 0;
  }
  .padding-containers > div {
    padding: 7px;
  }

  .form-change-course {
    background-color: #fff;
    margin: 1rem 0;
  }
  .padding-children > div {
    padding: 7px !important;
  }
  .autocomplete .MuiPaper-root {
    position: absolute;
    z-index: 999;
    min-width: initial !important;
    max-width: calc(100% - 53px);
  }
`;

export const StyledContainer = styled(Container)`
  padding: 20px;
  background-color: #080808;
  color: #DCDDDF;
`;

export default GlobalStyle;
