import React from 'react';
import {
  string, func, shape, arrayOf,
} from 'prop-types';

import {
  CardContent, CardMedia, Typography, Checkbox, IconButton,
} from '@material-ui/core';

import { Edit } from '@material-ui/icons';

import { StyledCard } from './styles';
import Empty from '../../assets/images/institution-empty.jpg';

const InstitutionCard = ({
  data: {
    id, name, image, courses, users,
  },
  checkFunction,
  editFunction,
}) => (
  <StyledCard>
    <CardMedia className="cardMedia" image={image || Empty} title="Course">
      <div className="actions">
        <Checkbox
          className="action"
          onChange={() => checkFunction(id, name)}
          value="checkedB"
          color="primary"
          inputProps={{
            'aria-label': 'primary checkbox',
          }}
        />
        <IconButton className="action" color="default" onClick={() => editFunction(id)}>
          <Edit />
        </IconButton>
      </div>
    </CardMedia>

    <CardContent className="cardContent">
      <Typography className="title" color="textPrimary" gutterBottom variant="h2" component="h5">
        {name}
      </Typography>
      <Typography className="institution-info" variant="subtitle1" component="p">
        {`${courses.length} cursos | ${users.length} membros`}
      </Typography>
    </CardContent>
  </StyledCard>
);

InstitutionCard.propTypes = {
  data: shape({
    id: string,
    name: string,
    image: string,
    courses: arrayOf(
      shape({
        id: string,
      }),
    ),
    users: arrayOf(
      shape({
        id: string,
      }),
    ),
  }).isRequired,
  checkFunction: func,
  editFunction: func,
};

InstitutionCard.defaultProps = {
  checkFunction: () => {},
  editFunction: () => {},
};
export default InstitutionCard;
