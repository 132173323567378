import React from 'react';
import { arrayOf, object } from 'prop-types';
import { StyledList } from './styles';

const ListComponent = (props) => {
  const nameList = props.tags.map(tag => <li key={tag.id}>{tag.name}</li>);
  return (
    <div>
      <StyledList>
        <ul>
          <b>{nameList}</b>
        </ul>
      </StyledList>
    </div>
  );
};
ListComponent.propTypes = {
  tags: arrayOf(object).isRequired,
};

export default ListComponent;
