import * as Dialog from '@radix-ui/react-dialog'
import styled from 'styled-components'

export const Overlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100vw;
  height: 100vh;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.75);
`

export const Content = styled(Dialog.Content)`
  min-width: 32rem;
  border-radius: 6px;
  padding: 2.5rem 3rem;
  background: #141414;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  form {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    input {
      border-radius: 6px;
      border: 0;
      background: #ffffff;
      box-shadow: 0 0 0 0;
      border: 0 none;
      outline: 0;
      padding: 1rem;
      color: #A6A6A6;

    }
    button {
      padding: 12px 32px;
      gap: 8px;
      height: 48px;
      background: #FF4C00;
      border-radius: 8px;
      border:none;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      color: #FFFFFF;
      margin-top:30px;
      margin-bottom: 80px;
      cursor: pointer;
      transition: filter 0.2;
        &:hover{
        filter: brightness(0.9)
        }
    }
  }
`

export const CloseButton = styled(Dialog.Close)`
  position: absolute;
  color: #FFFFFF;
  background-color: transparent;
  font-size:22px;
  border: 0;
  top: 1.5rem;
  right: 1.5rem;
  line-height: 0;
  cursor: pointer;
;
`


