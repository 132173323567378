import { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import useManualQuery from '../useManualQuery';
import { CREATE_POST, REMOVE_POST, FIXED_POST } from '../../services/mutations/post';
import { GET_POSTS_BY_TOPIC } from '../../services/queries/module';

const useLesson = () => {
  const [lesson, setLesson] = useState();
  const [start, setStart] = useState(false);
  const [answer, setAnswer] = useState('');
  const [createPost] = useMutation(CREATE_POST);
  const [removePost] = useMutation(REMOVE_POST);
  const [fixedPost] = useMutation(FIXED_POST);
  const getPostsByTopic = useManualQuery(GET_POSTS_BY_TOPIC);

  return {
    lesson,
    setLesson,
    start,
    setStart,
    answer,
    setAnswer,
    createPost,
    removePost,
    fixedPost,
    getPostsByTopic,
  };
};

export default useLesson;
