import React, { useEffect, useRef, useState } from "react";
import GlobalContext from "../../../context";
import ExercisesList from "../../course/ExercisesList";



const ExercisesContents = () => {
  const {
    selectedCourse: {
        course
      }
  } = GlobalContext()

const id = course.id

return (
            <>
              <ExercisesList CourseId={id}/>
              </>
)}
export default ExercisesContents;
