import { useState, useRef } from 'react';

const useDropdown = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  return {
    open,
    setOpen,
    anchorRef,
  };
};

export default useDropdown;
