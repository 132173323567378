import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html, fileName) => {
    savePDF(html, { 
      paperSize: 'A4',
      author: 'CIENTIK',
      fileName: fileName + '.pdf',
      margin: '.5cm',
      scale: 0.5,
    })
  }
}

const Doc = new DocService();
export default Doc;