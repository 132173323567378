import React, { useEffect } from 'react';
import {
  Button,
  Card,
  CardContent,
  Container,
  List,
  ListItem,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import GlobalContext from '../../../context';
import { QuestionTitle, StyledContainer } from './style';
import ModalQuestion from './ModalQuestion';
import ModalRemoveQuestion from './ModalRemoveQuestion';
import ModalQuestionAlternatives from './ModalQuestionAlternatives';
import TagsFromQuestion from './TagsFromQuestion';
import ModalAddTagToQuestion from './ModalAddTagToQuestion';
import AppBarWrapper from '../../../components/AppBarWrapper';
import Footer from '../../../components/Footer';
import { useHistory } from 'react-router-dom';

const ExerciseBank = () => {
  const {
    selectedCourse: {
      course,
      questionBank: {
        setQuestion,
        getAlgorithmBank,
        createAlgorithmBank,
        setSelectedQuestionBank,
        setModalQuestionOpen,
        setModalRemoveQuestionOpen,
        modalQuestionAlternatives: {
          setSelectedQuestion,
          setModalQuestionAlternativesOpen,
        },
      },
    },
    visualization: {
      visualization,
    },
  } = GlobalContext();

  const isManager = visualization !== 'Estudante';

  const history = useHistory();

  useEffect(() => {
    getAlgorithmBank.fetch({
      variables: {
        id: course.id,
      },
    });
    // eslint-disable-next-line
  }, []);

  const renderQuestions = (questionBank) => {
    const hasAlternatives = question => ['ObjectiveQuestion', 'TrueFalseQuestion'].indexOf(question.__typename) > -1;

    if (!questionBank) {
      return 'Carregando...';
    }
    const { questions } = questionBank;

    if (questions.length === 0) {
      return (
        <Container>
          Nenhuma questão cadastrada.
        </Container>
      );
    }

    return (
      <List>
        {questions.map((q, i) => (
          <ListItem key={q.id} divider>
            <Grid container>
              <Grid item container justify="space-between" alignItems="center">
                <Grid item xs={10}>
                  <Typography variant="h6" component="span">
                    {i + 1}
                    {'. '}
                  </Typography>
                  <QuestionTitle className="question-title" component="span">{q.stem}</QuestionTitle>
                </Grid>
                <Grid item>
                  {hasAlternatives(q) && (
                    <>
                      <Tooltip title="Editar Alternativas">
                        <IconButton
                          aria-label="edit alternatives"
                          onClick={() => {
                            setSelectedQuestion(q);
                            setModalQuestionAlternativesOpen(true);
                          }}
                        >
                          <PlaylistAddCheckIcon fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                      <ModalQuestionAlternatives question={q} />
                    </>
                  )}
                  <Tooltip title="Editar">
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        setQuestion(q);
                        setModalQuestionOpen(true);
                      }}
                    >
                      <EditIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Remover">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setQuestion(q);
                        setModalRemoveQuestionOpen(true);
                      }}
                    >
                      <DeleteIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid item>
                <TagsFromQuestion question={q} />
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    );
  };

  const renderInteligentQuestionBank = () => {
    if (!getAlgorithmBank.data) {
      return 'Carregando...';
    }

    if (!getAlgorithmBank.data.getAlgorithmBank) {
      return (
        <Grid item xs={12}>
          <StyledContainer>
            <Typography>
              Você ainda não criou um Banco de Questões para as questões inteligentes do curso.
              {' '}
              <br />
              Deseja criar?
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                await createAlgorithmBank({
                  variables: {
                    course: course.id,
                  },
                });
                getAlgorithmBank.refetch();
              }}
            >
              Criar
            </Button>
          </StyledContainer>
        </Grid>
      );
    }

    return (
      <CardContent>
        <Grid container justify="space-between" alignItems="center" style={{ padding: '14px 16px' }}>
          <Grid item>
            <Typography variant="h4">Banco de Questões</Typography>
          </Grid>
          {isManager && (
            <Grid item>
               <Button
                variant="outlined"
                color="primary"
                style={{marginRight:"10px"}}
                onClick={() => {
                  history.goBack();
              }}
              >

                Voltar
              </Button>

              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSelectedQuestionBank(getAlgorithmBank.data.getAlgorithmBank);
                  setQuestion({});
                  setModalQuestionOpen(true);
                }}
              >
                    Nova Questão
              </Button>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {renderQuestions(getAlgorithmBank.data && getAlgorithmBank.data.getAlgorithmBank)}
        </Grid>
      </CardContent>
    );
  };

  return (
    <>
    <AppBarWrapper/>
    <StyledContainer maxWidth="lg">
      <Card>
        {renderInteligentQuestionBank()}
      </Card>
      <ModalQuestion />
      <ModalRemoveQuestion />
      <ModalAddTagToQuestion />
    </StyledContainer>
    <Footer/>
    </>
  );
};

export default ExerciseBank;
