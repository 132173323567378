import styled from 'styled-components';


export const Text = styled.p`
font-size: 14px;
margin-top:5px;
color: #C6C6C6;
`

export const TextTitle = styled.p`
font-size: 16px;
color: #FFFFFF;
`

export const FlexContent = styled.div`
display:flex;
flex-direction:column;
background: #312F30;
padding:15px;
border-top-right-radius: 16px;
border-bottom-right-radius: 16px;
&:hover {
  cursor: pointer;
}
`

export const IconContent = styled.div`
color: #FFFFFF;
background: #FF4C00;
display:flex;
border-top-left-radius: 16px;
border-bottom-left-radius: 16px;
justify-content:center;
align-items:center;
width:80px;
`

export const FlexItem = styled.div`
display:flex;
border-radius: 16px;
margin:35px;
height: 90px;
width: 400px;
text-decoration:none;
@media(max-width: 900px) {
  width: 320px;
  height: 90px;
  margin-right:0px;
  margin-bottom:35px;
  justify-content:center;
 }
`

export const FlexCard = styled.div`
display:flex;
margin-top:40px;
align-items:center;
@media(max-width: 900px) {
flex-direction:column;
 }

`

