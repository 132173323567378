import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { useApolloClient } from '@apollo/react-hooks';
import {
  Grid,
  FormControl,
  Typography,
  Icon,
  Link,
  Tooltip,
  Button,
} from '@material-ui/core';

import {
  ExtensionRounded,
} from '@material-ui/icons';

import {
  Main,
  TitlePaper,
} from './../styles';


import TabsBar from '../../../components/TabsBar';
import AppBarWrapper from '../../../components/AppBarWrapper';

import { GET_ACTIVITY_REPORT } from '../../../services/queries/reports';
import { getEvaluativeActivitiesReport } from './service';
import Footer from '../../../components/Footer';

const reportTitle = "Relatório de atividades avaliativas";
const course = JSON.parse(localStorage.getItem('course'));

function buildTitle () {
  return (
    <Grid container>
      <Grid xs={6}>
        <Typography variant="h6">
          <Tooltip title="Voltar" aria-label="add">
            <Link href="/reports">
              <Icon style={{marginRight: "1em", color:"#044C6C", marginLeft: "1em", verticalAlign: "middle"}}>keyboard_backspace</Icon>
            </Link>
          </Tooltip>
            {reportTitle}
        </Typography>
      </Grid>
      <Grid xs={4}>      
        <Button variant="contained" color="primary" style={{float: "right"}}>
          <Icon>file_download</Icon>
          Baixar Relatório
        </Button>
      </Grid>
    </Grid>
  );
}

function buildDescription () {
  return (
    <TitlePaper square={true}>
      <Typography variant="h6" gutterBottom style={{textTransform: "uppercase"}}>
        {reportTitle} - {course.name}
      </Typography>
    </TitlePaper>
  );
}

const EvaluativeActivitiesReport = () => {
  const [evaluativeActivitiesReport, setEvaluativeActivitiesReport] = useState(null);

  const client = useApolloClient();
  useEffect( () => {
    async function fetchData() {
      const { loading, data, error } = await  client.query({
        query: GET_ACTIVITY_REPORT,
        variables: {
          CourseId: course.id,
        },
        forceFetch: true,
      });
      setEvaluativeActivitiesReport(data.getReportExercise);
    }
    fetchData();
  }, [evaluativeActivitiesReport]);
  return (
    <AppBarWrapper user={{ avatar: null, name: 'Alfred' }}>
    <Main maxWidth="md" >
      <TabsBar title={buildTitle()}>
        <FormControl style={{width: "80%", display: 'flex'}}  alignItems="center">
          {buildDescription()}
          {getEvaluativeActivitiesReport(evaluativeActivitiesReport)}
        </FormControl>
      </TabsBar>
      <Footer />
    </Main>
    </AppBarWrapper>
  );
}
export default withRouter(EvaluativeActivitiesReport);