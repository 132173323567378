import React, {useState} from 'react';
import { Button, ContainerList, Image, Info, InfoTexts, LikesVideosList, NameCourse, TitleVideo } from './styles';
import { BiTrashAlt} from 'react-icons/bi';
import { Description } from './styles';
import { DayTitle } from './styles';
import { GET_MY_COURSES_BY_USER } from '../../../../services/queries/courses';
import GlobalContext from '../../../../context';
import { useQuery } from '@apollo/react-hooks';
import Loading from '../../../../components/Loading/Loading';
import { Alert } from '@material-ui/lab';


const ListHistory = () => {

  const {
    user: { id },
    visualization: { visualization },
  }  =  GlobalContext();

  const [videosHistory, setVideosHistory] = useState();

  const {refetch} = useQuery(GET_MY_COURSES_BY_USER, {
    variables: { id, role: visualization === "Professor" ? "TEACHER" : "STUDENT" },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const courses = data?.getUserById?.courses;
      const videosInfos = [];
      for(let i = 0; i < courses.length; ++i){
        const videoInfo = {
          courseName: courses[i].name,
          courseDescription: courses[i].description,
          episodeName: (courses[i].modules && courses[i].modules[0].units) ? courses[i].modules[0].units[0].name : null,
          episodeThumb: (courses[i].modules && courses[i].modules[0].units) ? courses[i].modules[0].units[0].folder : null,
        }
        videosInfos.push(videoInfo);
      }
      setVideosHistory([...videosInfos]);
    }
  })

  if (!videosHistory) {
    return <p></p>;
  }
  console.log("videosHistory", videosHistory)



 return (
  <ContainerList>
    {videosHistory.length > 0 ? videosHistory.map((video) =>
      <LikesVideosList>
      <Info>
        <Image backgroundUrl={video.episodeThumb}/>
        <InfoTexts>
          <TitleVideo>{video.episodeName}</TitleVideo>
          <NameCourse>{video.courseName}</NameCourse>
          <Description>
          {/* {video.courseDescription} */}
          </Description>
        </InfoTexts>
      </Info>
      {/* <Button><BiTrashAlt/> Remover do histórico</Button> */}
      </LikesVideosList>
    ) : <Alert severity="warning">Nenhum conteúdo assistido até o momento. Assita a algum vídeo para que apareceça aqui!</Alert>}
    {/* <DayTitle>
      Hoje
    </DayTitle>

    <LikesVideosList>
    <Info>
      <Image backgroundUrl="https://t.ctcdn.com.br/8Q_WlNjbkUhra-4MBBWOIzAo0g0=/512x288/smart/filters:format(webp)/i533291.png"/>
      <InfoTexts>
        <TitleVideo>Episódio 1</TitleVideo>
        <NameCourse>Multiletramentos</NameCourse>
        <Description>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex exercit Lorem ipsum dolor sit amet.
        </Description>
      </InfoTexts>
    </Info>
    <Button><BiTrashAlt/> Remover dos favoritos</Button>
    </LikesVideosList>

    <DayTitle>
      Ontem
    </DayTitle>

    <LikesVideosList>
    <Info>
      <Image backgroundUrl="https://t.ctcdn.com.br/8Q_WlNjbkUhra-4MBBWOIzAo0g0=/512x288/smart/filters:format(webp)/i533291.png"/>
      <InfoTexts>
        <TitleVideo>Episódio 1</TitleVideo>
        <NameCourse>Multiletramentos</NameCourse>
        <Description>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex exercit Lorem ipsum dolor sit amet.
        </Description>
      </InfoTexts>
    </Info>
    <Button><BiTrashAlt/> Remover dos favoritos</Button>
    </LikesVideosList>

    <LikesVideosList>
    <Info>
      <Image backgroundUrl="https://t.ctcdn.com.br/8Q_WlNjbkUhra-4MBBWOIzAo0g0=/512x288/smart/filters:format(webp)/i533291.png"/>
      <InfoTexts>
        <TitleVideo>Episódio 1</TitleVideo>
        <NameCourse>Multiletramentos</NameCourse>
        <Description>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex exercit Lorem ipsum dolor sit amet.
        </Description>
      </InfoTexts>
    </Info>
    <Button><BiTrashAlt/> Remover dos favoritos</Button>
    </LikesVideosList>

    <LikesVideosList>
    <Info>
      <Image backgroundUrl="https://t.ctcdn.com.br/8Q_WlNjbkUhra-4MBBWOIzAo0g0=/512x288/smart/filters:format(webp)/i533291.png"/>
      <InfoTexts>
        <TitleVideo>Episódio 1</TitleVideo>
        <NameCourse>Multiletramentos</NameCourse>
        <Description>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex exercit Lorem ipsum dolor sit amet.
        </Description>
      </InfoTexts>
    </Info>
    <Button><BiTrashAlt/> Remover dos favoritos</Button>
  </LikesVideosList> */}
  </ContainerList>
 )
};

export default ListHistory;
