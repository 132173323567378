import styled from 'styled-components';
import {
  Container, TableCell, Button, TableRow,
} from '@material-ui/core';

export const StyledContainer = styled(Container)`
  padding: 20px;
  margin-top:60px;
  background: #312f30;
  color: #c6c6c6;
  margin-bottom:60px;
  @media (max-width: 600px)
{
 margin-top:200px;
 margin-bottom:200px;
}
  .button-grid {
    text-align: left;
  }
  .pr {
    float: inline-end;
    > * {
      margin-left: 16px;
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  && {
    .title {
      font-weight: bold;
      font-size: 22px;
    }
    color: rgba(0, 0, 0, 0.75);
  }
`;

export const StyledButton = styled(Button)`
  && {
    padding: 7px;
    border-radius: 50%;
    min-width: 0;
  }
`;

export const StyledTableRow = styled(TableRow)`

  && {
    cursor: pointer;
    background-color: #e6e6e6;
    &:hover {
      background-color: #e6e6e6;
    }
  }
`;
