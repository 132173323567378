import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import PlayArrowOutlinedIcon from "@material-ui/icons/PlayArrowOutlined";
import AddIcon from "@material-ui/icons/Add";
import SlideshowIcon from "@material-ui/icons/Slideshow";
import { AiOutlineDoubleLeft, AiOutlineDoubleRight } from "react-icons/ai";
import { oneOfType, arrayOf, node, shape, func } from "prop-types";
import { formatDateToUTC, formatDateToUTCWithBar, formatDateToUTCWithBars } from "../../../components/Helpers/formatDate";


import {
  SlideshowContainer,
  SlideshowContainerMobile,
  SlideInfo,
  CourseInfo,
  ButtonGroup,
  Button,
  ProgressSlider,
  ProgressVideo,
  Prev,
  Next,
  SliderInfo,
  HeroDesktop,
  HeroMobile,
  ButtonMobile,
} from "./styles";
import { NotificationManager } from "react-notifications";
import GlobalContext from "../../../context";
import Dialog from "../../../components/Helpers/Dialog";
import { ADD_COURSE_SUBSCRIPTION } from "../../../services/mutations/course";
import { VIEW_UNIT } from "../../../services/mutations/lesson";
import { GET_MODULES_BY_COURSE } from "../../../services/queries/module";
import {
  GET_COURSES,
  GET_COURSES_BY_INSTITUTION,
  GET_MY_COURSES_BY_USER,
} from "../../../services/queries/courses";
import Loading from "../../../components/Loading/Loading";
import { withRouter } from "react-router-dom";
import { use } from "i18next";


const Hero = ({interval = 6000}) => {
  const {
    user: { id, institution },
    signIn: {loading},
    selectedCourse: {
      course,
      setCourse,
      getMyCoursesByUser,
      setCourseAccess,
      getCoursesByInstitution,
      getModulesByCourse,
      getCoursesAvailable,
    },
    selectedLesson: { lesson, setLesson },
    selectedTrailer: { trailer, setTrailer},
    visualization: { visualization },
  } = GlobalContext();

  const heroCoursesNames = [
    "A Era Digital",
    "Introdução à Robótica",
    "Por Dentro de Tudo",
    "Quando a Máquina Pensa",
    "Quando Sinto que Ja Sei",
    "Vida de Programador",
    "Céu de Brigadeiro",
    "Jovens Transformadores",
    "teste curso",
    'teste basquiat Hirix'
  ];
  const [heroCourses, setHeroCourses] = useState([]);
  const [userCourses, setUserCoursers] = useState([]);
  const [coursesAccesses, setCoursesAccesses] = useState([]);
  const [heroCoursesName, setHeroCoursesName] = useState([]);
  const [activeSlide, setActiveSlide] = useState(0);
  const [courseOnSlideshow, setCourseOnSlideshow] = useState();

  const history = useHistory()


  useEffect(() => {
    let role;
    switch (visualization) {
      case "Estudante":
        role = "STUDENT";
        break;
      case "Professor":
        role = "TEACHER";
        break;
      default:
        break;
    }

    // console.log('aq1 ', id)
    // getMyCoursesByUser.fetch({ variables: { id } });
  }, []);


  useEffect(()=>{
    if(!loading){
      console.log('OXE MUDOU3 ', id)
      getMyCoursesByUser.fetch({ variables: { id } });
    }
  }, [id])

  useEffect(() => {
    getCoursesByInstitution.fetch({ variables: { id: institution.id } });
  }, []);


  useEffect(() => {
    if (course) {
      getModulesByCourse.fetch({
        variables: { id: course.id, filter: visualization === "Estudante" },
      });
    }
  }, [ course]);


  // Filtrar nomes de destaque

  const { data: getAllCourses } = useQuery(GET_COURSES,{
    onCompleted: e =>console.log(e),
    fetchPolicy: 'no-cache',
  });
  console.log("getAllCourses", getAllCourses)

  const dates = [getAllCourses?.getCourses?.filter((courses)=> courses.endDate)]

  const current = new Date();
  const today = `${current.getFullYear()}/${current.getMonth()+1}/${current.getDate()}`;
  const dateNumber = parseInt(today.replace('/', '').replace('/', ''));
  console.log(dateNumber)

  let ListDates = [
    20221223,
    20211022,
    20222010
  ];

  function filterDates(ListDate) {
    return ListDate > dateNumber;
  }

  let datesFilters = ListDates.filter(filterDates);

  console.log(datesFilters)


//   const filtered =  getAllCourses?.getCourses?.map((Course) => {
//   formatDateToUTCWithBars(Course.endDate).includes(isDate)
// })


//   console.log('isDateEnough', isDateEnough)

//   useEffect(() => {
//   if (getDate) {
//   var filtered = getDate.filter(isDateEnough);
//   var names = getAllCourses?.courses?.includes(filtered)
//   setHeroCoursesName(names.name)}
// },[heroCoursesName])


//   console.log("heroCourseNames", heroCoursesName)

  // fim dos destaques

  const { data: dataModules, refetch: refetchModules } = useQuery(
    GET_MODULES_BY_COURSE,
    {
      variables: { id: course?.id, filter: visualization === "Estudante" },
      skip: !course,
      fetchPolicy: "no-cache",
    }
  );

  const { data: dataUserCourses, refetch: refetchUserCourses } = useQuery(
    GET_MY_COURSES_BY_USER,
    {
      variables: { id, role: "STUDENT" },
      skip: !id,
      onCompleted: ({ getUserById: { courses } }) => {
        setUserCoursers([...courses]);
      },
    }
  );

  const { data: dataAllCourses, refetch: refetchAllCourses } = useQuery(
    GET_COURSES_BY_INSTITUTION,
    {
      variables: { id: institution?.id },
      skip: !institution,
      fetchPolicy: "no-cache",
      onCompleted: ({ getInstitutionById: { courses: allCourses } }) => {
        const heroCoursesAux = allCourses.filter((course) =>
          heroCoursesNames.includes(course.name)
        );
        setHeroCourses([...heroCoursesAux]);
        if (heroCoursesAux) {
          setCourseOnSlideshow(heroCoursesAux[0]);
        }
      },
      onError: (e) => {
        console.log(e)
      }
    }
  );

  useEffect(() => {
    const coursesAccessesAux = [];
    for (let i = 0; i < heroCourses.length; i += 1) {
      coursesAccessesAux.push(false);
      for (let j = 0; j < userCourses.length; j += 1) {
        if (heroCourses[i].id === userCourses[j].id) {
          coursesAccessesAux[i] = true;
          break;
        }
      }
    }
    console.log(coursesAccessesAux)
    setCoursesAccesses([...coursesAccessesAux]);
  }, [heroCourses, userCourses]);

  const [addCourseSubscription] = useMutation(ADD_COURSE_SUBSCRIPTION);
  const [viewUnit] = useMutation(VIEW_UNIT, {
    onCompleted: e => console.log(e),
    onError: e=> console.log(e)
  });
  const [openAddCourseSubscription, setOpenAddCourseSubscription] =
    useState(false);

  const confirmAddCourseSubscription = async () => {
    try {
      const response =  await addCourseSubscription({ variables: { id: courseOnSlideshow.id } });
      if(response.data.addCourseSubscription.id){
      setCourse(courseOnSlideshow)
      const coursesAccessesAux = coursesAccesses;
      coursesAccessesAux[activeSlide] = true;
      setCoursesAccesses(coursesAccessesAux);
      NotificationManager.success("Inscrição realizada com sucesso");
      getMyCoursesByUser.refetch();
      getCoursesAvailable.refetch();
    }
    } catch (e) {
      NotificationManager.error(
        "Não foi possível realizar a solicitação, aparentemente você já teve uma solicitação recusada"
      );
    }
    finally {
      history.push("/course");
      setOpenAddCourseSubscription(false);
     }
  };

  useEffect(() => {
    const loop = setInterval(() => {
      console.log(coursesAccesses)
      if (activeSlide === heroCourses.length - 1) {
        setCourseOnSlideshow(heroCourses[0]);
        setActiveSlide(0);
      } else {
        setCourseOnSlideshow(heroCourses[activeSlide + 1]);
        setActiveSlide(activeSlide + 1);
        getCoursesAvailable.refetch();
      }
    }, interval);
    return () => clearInterval(loop);
  }, [heroCourses, activeSlide, interval]);

  if ( !getMyCoursesByUser.data || !getCoursesByInstitution.data ||!courseOnSlideshow
  ) {
    // console.log("AQUI")
    // console.log(heroCourses)
    // console.log(courseOnSlideshow)
    // console.log(getMyCoursesByUser.data)
    // console.log(getCoursesByInstitution.data)
    // console.log("AQUI1")
    return <Loading/>;
  }

  const {
    data: {
      getUserById: { courses },
    },
  } = getMyCoursesByUser;

  const selectLesson = (_lesson) => {
    setLesson(_lesson);
    history.push("/lesson");
  };

  const watchFromThemBeginning = async (_course) => {
    setCourse(_course);

    const {

      data: {
        getCourseById: { modules },
      },
    } = await refetchModules({
      id: _course?.id,
      filter: visualization === "Estudante",
    });
    if (
      modules?.length > 0 &&
      modules[0].units &&
      modules[0].units?.length > 0
    ) {
      viewUnit({
        variables: {
          UnitId: modules[0].units[0].id
        }
      })
      selectLesson(modules[0].units[0]);
    }
  };

  const watchedButtonClicked = (_course) => {
    if (coursesAccesses[activeSlide]) {
      watchFromThemBeginning(_course);
    } else {
      setOpenAddCourseSubscription(true);
    }
  };

  const previousSlide = () => {
    if (activeSlide - 1 < 0) {
      setCourseOnSlideshow(heroCourses[heroCoursesNames.length - 1]);
      setActiveSlide(heroCoursesNames.length - 1);
    } else {
      setCourseOnSlideshow(heroCourses[activeSlide - 1]);
      setActiveSlide(activeSlide - 1);
    }
  };

  const nextSlide = () => {
    if (activeSlide + 1 >= heroCoursesNames.length) {
      setCourseOnSlideshow(heroCourses[0]);
      setActiveSlide(0);
    } else {
      setCourseOnSlideshow(heroCourses[activeSlide + 1]);
      setActiveSlide(activeSlide + 1);
    }
  };

  const watchTrailer = () => {
    console.log(courseOnSlideshow.trailer)
    if (courseOnSlideshow.trailer) {
      setTrailer(courseOnSlideshow.trailer);
      history.push("/trailer");
    }
  };

  console.log("courseonslideshow", courseOnSlideshow)

  return (
    <div>

      <HeroDesktop>
      <SlideshowContainer>
        <SliderInfo
          backgroundUrl={courseOnSlideshow?.thumbnail}
          style={{ width: "100%" }}
        >
          <SlideInfo>
            <CourseInfo>
              <div>
              <h1 style={{ color: "#f4f4f4", paddingBottom: "15px" }}>
                {courseOnSlideshow?.name}
              </h1>
              <strong style={{ color: "#FF9466" }}>
              {courseOnSlideshow?.year}
              {/* Autor */}
              </strong>
              </div>
              <p style={{ color: "#f4f4f4", padding: "23px 0px" }}>
                {courseOnSlideshow?.description}
              </p>

              {/* <p style={{ color: "#f4f4f4" }}>Publicação: viagens.</p> */}
              {/* <img src={course.folder}></img> */}
            </CourseInfo>
            {courseOnSlideshow && coursesAccesses[activeSlide] && (
              <ButtonGroup>
                <Button
                  onClick={() => watchedButtonClicked(courseOnSlideshow)}
                  style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
                >
                  <PlayArrowOutlinedIcon style={{ paddingRight: "5px" }} />
                  <strong>Assistir</strong>
                </Button>
                {courseOnSlideshow.trailer ? (
                <Button
                  style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                >
                  <SlideshowIcon style={{ paddingRight: "5px" }} />
                  <p>Ver trailer</p>
                </Button>
                  ): null}
                <Button
                  style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                >
                  <AddIcon style={{ paddingRight: "5px" }} />
                  <p>Mais informações</p>
                </Button>
              </ButtonGroup>
            )}
            {courseOnSlideshow && !coursesAccesses[activeSlide] && (
              <ButtonGroup>
                <Button
                  onClick={() => watchedButtonClicked(courseOnSlideshow)}
                  style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
                >
                  <PlayArrowOutlinedIcon style={{ paddingRight: "5px" }} />
                  <strong>Inscrever-se</strong>
                </Button>
                {courseOnSlideshow.trailer ? (
                <Button
                  style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                >
                  <SlideshowIcon style={{ paddingRight: "5px" }} />
                  <p>Ver trailer</p>
                </Button>
                 ): null}
              </ButtonGroup>
            )}
          </SlideInfo>
          <ProgressSlider>
            <Prev onClick={() => previousSlide()} style={{ color: "#e84810" }}>
              <AiOutlineDoubleLeft
                style={{ color: "#FF4C00", fontSize: "40px" }}
              />
            </Prev>
            <ProgressVideo></ProgressVideo>
            <Next onClick={() => nextSlide()} style={{ color: "#e84810" }}>
              <AiOutlineDoubleRight
                style={{ color: "#FF4C00", fontSize: "40px" }}
              />
            </Next>
          </ProgressSlider>
        </SliderInfo>
        <Dialog
          onCancel={() => setOpenAddCourseSubscription(false)}
          onConfirm={confirmAddCourseSubscription}
          colorConfirm="secondary"
          textConfirm="Ingressar"
          open={openAddCourseSubscription}
          title={"Inscrever-se"}
        >
          {`Deseja ingressar no curso ${courseOnSlideshow?.name}?`}
        </Dialog>
      </SlideshowContainer>
      </HeroDesktop>



      <HeroMobile>
      <SlideshowContainerMobile>
        <SliderInfo
          backgroundUrl={courseOnSlideshow?.folder}
          style={{ width: "100%" }}
        >
          <SlideInfo>
            <CourseInfo>
              <h1 style={{ color: "#f4f4f4", paddingBottom: "15px" }}>
                {/* {courseOnSlideshow?.name} */}
              </h1>
              <br></br>
              <strong style={{ color: "#FF9466" }}></strong>
              <br></br>
            </CourseInfo>
            {courseOnSlideshow && coursesAccesses[activeSlide] && (
              <ButtonGroup>

                <ButtonMobile
                  style={{ color: "#A6A6A6" }}
                >
                  <SlideshowIcon />
                  <p>Ver trailer</p>
                </ButtonMobile>

                <Button
                  onClick={() => watchedButtonClicked(courseOnSlideshow)}
                  style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
                >
                  <PlayArrowOutlinedIcon />
                  <strong>Assistir</strong>
                </Button>

                <ButtonMobile
                  style={{ color: "#A6A6A6" }}>
                  <AddIcon/>
                  <p>Saiba mais</p>
                </ButtonMobile>
              </ButtonGroup>
            )}
            {courseOnSlideshow && !coursesAccesses[activeSlide] && (
              <ButtonGroup style={{justifyContent: "center"}}>

                <Button
                   onClick={() => watchedButtonClicked(courseOnSlideshow)}
                  style={{ backgroundColor: "#e84810", color: "#f4f4f4" }}
                >
                  <PlayArrowOutlinedIcon />
                  <strong>Inscrever-se</strong>
                </Button>

                <Button
                  style={{ backgroundColor: "#312F30", color: "#A6A6A6" }}
                >
                  <SlideshowIcon />
                  <p>Ver trailer</p>
                </Button>



              </ButtonGroup>
            )}
          </SlideInfo>
          <ProgressSlider>
            <Prev onClick={() => previousSlide()} style={{ color: "#e84810" }}>
              <AiOutlineDoubleLeft
                style={{ color: "#FF4C00", fontSize: "40px" }}
              />
            </Prev>
            <ProgressVideo></ProgressVideo>
            <Next onClick={() => nextSlide()} style={{ color: "#e84810" }}>
              <AiOutlineDoubleRight
                style={{ color: "#FF4C00", fontSize: "40px" }}
              />
            </Next>
          </ProgressSlider>
        </SliderInfo>
        <Dialog
          onCancel={() => setOpenAddCourseSubscription(false)}
          onConfirm={confirmAddCourseSubscription}
          colorConfirm="secondary"
          textConfirm="Ingressar"
          open={openAddCourseSubscription}
          title={"Inscrever-se"}
        >
          {`Deseja ingressar no curso ${courseOnSlideshow?.name}?`}
        </Dialog>
      </SlideshowContainerMobile>

      </HeroMobile>
    </div>
  );
};

Hero.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  history: shape({
    push: func,
  }).isRequired,
};

export default withRouter(Hero);
