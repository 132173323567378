import React, { useState, useEffect } from 'react';
import {
  FormControlLabel,
  FormLabel,
  Grid,
  TextField,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import Dialog from '../../../components/Helpers/Dialog';
import GlobalContext from '../../../context';

const ModalQuestion = () => {
  const {
    selectedCourse: {
      questionBank: {
        modalQuestionOpen,
        setModalQuestionOpen,
        selectedQuestionBank,
        createObjectiveQuestion,
        updateObjectiveQuestion,
        createTrueFalseQuestion,
        updateTrueFalseQuestion,
        createSubjectiveQuestion,
        updateSubjectiveQuestion,
        getAlgorithmBank,
        question,
        setQuestion,
      },
    },
  } = GlobalContext();

  const [questionType, setQuestionType] = useState('');

  useEffect(() => {
    if (question.__typename) {
      setQuestionType(question.__typename);
    }
  }, [question]);

  const setPropByEvent = (e, state, setState) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const isEdit = question.id != null;

  const clean = () => {
    setQuestion({});
    setQuestionType('');
  };

  const cleanAndClose = () => {
    clean();
    setModalQuestionOpen(false);
  };

  const confirm = async () => {
    let createQuestion;
    let updateQuestion;

    switch (questionType) {
      case 'ObjectiveQuestion':
        createQuestion = createObjectiveQuestion;
        updateQuestion = updateObjectiveQuestion;
        break;
      case 'TrueFalseQuestion':
        createQuestion = createTrueFalseQuestion;
        updateQuestion = updateTrueFalseQuestion;
        break;
      case 'SubjectiveQuestion':
        createQuestion = createSubjectiveQuestion;
        updateQuestion = updateSubjectiveQuestion;
        break;
      default:
        return;
    }

    if (question.id) {
      await updateQuestion({
        variables: {
          id: question.id,
          stem: question.stem,
        },
      });
    } else {
      await createQuestion({
        variables: {
          stem: question.stem,
          questionBank: selectedQuestionBank.id,
        },
      });
    }
    cleanAndClose();
    getAlgorithmBank.refetch();
  };

  return (
    <Dialog
      open={modalQuestionOpen}
      title={isEdit ? 'Editar questão' : 'Nova questão'}
      onConfirm={confirm}
      onCancel={cleanAndClose}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormLabel component="title">Tipo da questão</FormLabel>
          <RadioGroup
            value={questionType}
            onChange={e => setQuestionType(e.target.value)}
          >
            <FormControlLabel value="ObjectiveQuestion" control={<Radio />} label="Objetiva" disabled={isEdit} />
            <FormControlLabel value="TrueFalseQuestion" control={<Radio />} label="Verdadeira e Falsa" disabled={isEdit} />
            <FormControlLabel value="SubjectiveQuestion" control={<Radio />} label="Subjetiva" disabled={isEdit} />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Enunciado"
            variant="outlined"
            name="stem"
            value={question.stem}
            onChange={e => setPropByEvent(e, question, setQuestion)}
            multiline
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalQuestion;
