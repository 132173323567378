import createUseContext from 'constate';
import useUser from './user';
import useSignIn from './signIn';
import useDrawer from './drawer';
import useTags from './tags';
import useUsers from './users';
import useCourse from './course';
import useLesson from './lesson';
import useVisualization from './visualization';
import useDropdown from './dropdown';
import useSpeedDial from './speeddial';
import useForum from './forum';
import useModule from './modules';
import useTrailer from './trailer';

const useGlobal = () => {
  const visualization = useVisualization();
  const user = useUser(visualization);
  const signIn = useSignIn(user.refetch);
  const drawer = useDrawer();
  const tags = useTags();
  const users = useUsers();
  const selectedCourse = useCourse();
  const selectedModule = useModule();
  const selectedLesson = useLesson();
  const selectedTrailer = useTrailer();
  const dropdown = useDropdown();
  const speedDial = useSpeedDial();
  const forum = useForum();

  if (!signIn.token && !user.data) {
    return {
      signIn,
    };
  }

  return {
    user: user?.data?.getUserMe,
    drawer,
    signIn,
    tags,
    users,
    selectedCourse,
    selectedLesson,
    selectedTrailer,
    selectedModule,
    visualization,
    dropdown,
    speedDial,
    forum,
  };
};

const GlobalContext = createUseContext(useGlobal);

export default GlobalContext;
