import styled from 'styled-components';
import Card from '@material-ui/core/Card';

export const StyledCard = styled(Card)`
  height: 100%;
  display: flex;
  flex-direction: column;

  .cardMedia {
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .actions {
      display: flex;
      justify-content: space-between;

      .PrivateSwitchBase-root-285 {
        padding: 10px 12px;
      }
    }

    .progressBar {
      height: 10px;
      background-color: #fff;

      .MuiLinearProgress-barColorPrimary {
        border-radius: 20px;
        background-color: #22aaf5;
      }
    }
  }

  .cardContent {
    flex-grow: 1;

    .duration {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #333333;
    }
  }

  .title {
    font-weight: bold;
    font-size: 17px;
    line-height: 18px;
    color: #333333;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 14px;
    line-height: 14.06px;
    color: #036b9a;
  }

  .description {
    font-size: 12px;
    color: #575757;
    margin: 5px 0;
  }

  .price {
    font-style: 'Medium';
    font-size: 17px;
    font-weight: 500;
    line-height: 17.84px;
    color: #1c1c1c;
  }

  .footer {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: #333333;
    margin: 5px 0;
  }

  .info-institution {
    font-size: 14px;
    line-height: 16px;
    #color: #DCDDDF;
  }
`;
