
import React, {  useState } from 'react';
import * as DialogRadix from '@radix-ui/react-dialog'
import { AiOutlineArrowLeft} from 'react-icons/ai'
import {BiCircle} from 'react-icons/bi'
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  IconButton,
  Tooltip,
  Checkbox,
  Grid,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog,
  Typography,
  TextField,
  Button,
} from '@material-ui/core';
import { NotificationManager } from "react-notifications";
import { Edit, Close } from '@material-ui/icons';
import Box from '@material-ui/core/Box';
import { Overlay, CloseButton, Content  } from '../DialogAdd/styles';
import ContentDetails from './ContentDetails';
import { MdDelete, MdOutlineVisibility, MdOutlineVisibilityOff, MdVisibilityOff } from 'react-icons/md';
import GlobalContext from '../../../../context'
import { ButtonDelete, ButtonPrivate, ButtonPublic, LabelTab, StyledDialog } from './styles';
import Seasons from './Seasons';
import { Add } from '@material-ui/icons';
import LoadingAdmin from "../../../../components/Loading/LoadingAdmin";
import { useEffect } from 'react';
import AddSeasons from './AddSeasons';

export const UploadContext = React.createContext(null);
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

    export function EditContent({ marginTop, course, refetch, enterFunction }) {
      const [data, setData] = useState();
      const [dataModule, setDataModule] = useState();
      const [value, setValue] = useState(0);
      const [status, setStatus] = useState(course.public)
      const [loading, setLoading] = useState(false)

      const handleChange = (event, newValue) => {
        setValue(newValue);
      };

      const {
        selectedCourse: {
          setPublic,
          removeCourse
        },
        visualization: { visualization },
      } = GlobalContext();

      const handlePublic = (id) => {
        setLoading(true)
        setPublic({
          variables:
          {
            id: course.id
          }})
        .then(e => {
          setStatus(status ? false : true)
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
        })
      }

      const deleteCourse = (id) => {
        setLoading(true)
        removeCourse({
          variables:
          {
            id: course.id
          }})
        .then(e => {
          setLoading(false)
          NotificationManager.success("Curso excluido com sucesso!");
          refetch()
        })
        .catch(err => {
          setLoading(false)
          NotificationManager.error("Não foi possível excluir o curso.");
        })
        console.log(id)
      }

  return (

<UploadContext.Provider value={{data, setData, dataModule, setDataModule}}>
    <DialogRadix.Portal enterFunction={course}>
    <Overlay/>
    <Content>
      <Box sx={{ width: '100%' }} style={{display:"flex", justifyContent:"center", flexDirection:"column"}}>
      <CloseButton onClick={()=>refetch()} style={{left:"2%"}}>
          <AiOutlineArrowLeft/> Voltar
          </CloseButton>
          <div style={{display:"flex", right:"0", justifyContent:"end", marginBottom:"50px"}}>
          <ButtonDelete disabled={loading} onClick={() => deleteCourse()}>
            <MdDelete/>
            Excluir Curso
            </ButtonDelete>

            {status ? (
              <ButtonPublic>
              <MdOutlineVisibility size={16}
              style={{color:"#12805C"}}/>
              Público
            </ButtonPublic>
            ):(
              <ButtonPublic>
              <MdOutlineVisibility size={16}
              style={{color:"#e62323"}}/>
              Privado
            </ButtonPublic>
            )}

            <ButtonPrivate disabled={loading} onClick={()=> {handlePublic(course.id)}}>
              Deixar {status ? 'Privado' : 'Público'}
            </ButtonPrivate>

            </div>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }} style={{display:"flex", justifyContent:"center"}}>

      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="secondary"
        textColor="inherit"
        variant="fullWidth"
        aria-label="full width tabs example"
      >


            <Tab  label={<LabelTab>
            <p style={{textTransform:"none"}}>Dados do Curso</p>
            </LabelTab>}  {...a11yProps(0)} />

              {/* Map de temporadas */}
            {course?.modules?.map((module, index)=>(
               <Tab  label={<LabelTab>
              <p style={{textTransform:"none"}}>
                <strong style={{color:"#080808"}}>{index+1}</strong> Temporada</p>
            </LabelTab>} {...a11yProps(index+1)}/>
            ))}

            {/* Fim de temporadas */}

            <Tab  label={<LabelTab style={{textTransform:"none", color:"#080808"}}>
              <Add/>
              <p >
               Adicionar</p>
            </LabelTab>} {...a11yProps(course?.modules?.length + 1)}/>

      </Tabs>

    </Box>
    <TabPanel value={value} index={0}>
        <ContentDetails course={course} nextStep={()=>setValue(1)}/>
        </TabPanel>
        {course?.modules?.map((module, index)=>(
          <TabPanel value={value} index={index+1}>
          <Seasons course={course} index={index} nextStep={()=>setValue(index+2)}/>
          </TabPanel>
        ))}
        <TabPanel value={value} index={course?.modules?.length + 1}>
        <AddSeasons course={course} nextStep={()=>setValue(0)}/>
        </TabPanel>
   </Box>
    </Content>

      </DialogRadix.Portal>
      </UploadContext.Provider>
  )
}

export default EditContent;
