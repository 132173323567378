import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useApolloClient } from '@apollo/react-hooks';
import Doc from '../pdf';
import {
  FormControl,
  Typography,
  Link,
  Icon,
  Tooltip,
  Grid,
  Button,
} from '@material-ui/core';

import {
  Main,
  TitlePaper,
} from './../styles';
import TabsBar from '../../../components/TabsBar';
import AppBarWrapper from '../../../components/AppBarWrapper';
import { GET_TRAJECTORY_REPORT } from '../../../services/queries/reports';
import { getTrajectoryReportTable } from './service';import Footer from '../../../components/Footer';
;

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
const reportTitle = "Relatório de trajetórias percorridas";
const course = JSON.parse(localStorage.getItem('course'));

function buildTitle () {
  return (
    <Grid container>
      <Grid xs={6}>
        <Typography variant="h6">
          <Tooltip title="Voltar" aria-label="add">
            <Link href="/reports">
              <Icon style={{marginRight: "1em", color:"#044C6C", marginLeft: "1em", verticalAlign: "middle"}}>keyboard_backspace</Icon>
            </Link>
          </Tooltip>
            {reportTitle}
        </Typography>
      </Grid>
      <Grid xs={4}>      
        <Button onClick={exportPdf}  variant="contained" color="primary" style={{float: "right"}}>
          <Icon>file_download</Icon>
          Baixar Relatório
        </Button>
      </Grid>
    </Grid>
  );
}

function exportPdf () {
  const input = document.getElementById('report');
  Doc.createPdf(input, reportTitle);
}

function buildDescription () {
  return (
    <TitlePaper square={true}>
      <Typography variant="h6" gutterBottom style={{textTransform: "uppercase"}}>
      {reportTitle} - {course.name}
      </Typography>
    </TitlePaper>
  );
}
const TrajectoryReport = () => {
  const classes = useStyles();
  const [trajectoryReport, setTrajectoryReport] = useState(null);

  const client = useApolloClient();
  useEffect( () => {
    async function fetchData() {
      const { loading, data, error } = await client.query({
        query: GET_TRAJECTORY_REPORT,
        variables: {
          CourseId: course.id,
        },
        forceFetch: true,
      });
      setTrajectoryReport(data.getReportTrajectory);
    }
    fetchData();
  }, [trajectoryReport]);
  
  return (
    <AppBarWrapper user={{ avatar: null, name: 'Alfred' }}>
    <Main maxWidth="md" >
      <TabsBar title={buildTitle()}>
        <div id="report">
          <FormControl style={{width: "80%", display: 'flex'}}  alignItems="center">
              {buildDescription()}
              <div className={classes.root}>
              {getTrajectoryReportTable(trajectoryReport)}
              </div>
          </FormControl>
        </div>
      </TabsBar>
    </Main>
    <Footer />
    </AppBarWrapper>
  );
};
export default withRouter(TrajectoryReport);