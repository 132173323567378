import { Checkbox, FormControl, FormControlLabel, FormGroup, Radio, RadioGroup } from '@material-ui/core';
import React, { useState, useContext } from 'react';
import { CategoryInputData,
  TextArea,
  DataTitle,
  FormEpisodes,
  StyledFormControlLabel,
  FlexData,
  FlexDataInput,
  Input,
  InputContainer,
  Small,
  TextAreaContainer,
  Count } from '../../DialogAdd/styles';
import { useForm, Controller } from 'react-hook-form';
import { FormHelperText } from "@material-ui/core";
import { UploadContext } from '../index'
import { ReactComponent as Livre } from '../../../../../assets/icons/faixa_livre.svg';
import { ReactComponent as DezAnos } from '../../../../../assets/icons/faixa_10.svg';
import { ReactComponent as DozeAnos } from '../../../../../assets/icons/faixa_12.svg';
import { ReactComponent as QuatorzeAnos } from '../../../../../assets/icons/faixa_14.svg';
import { ReactComponent as DeseseisAnos } from '../../../../../assets/icons/faixa_16.svg';
import { ReactComponent as DesoitoAnos } from '../../../../../assets/icons/faixa_18.svg';



export  function FormDetails({errors, control, index}){
  const [count, setCount] = useState(0);
  const {data, setData} = useContext(UploadContext);

  const handleName = (e) => {
    e.preventDefault();
     setCount(e.target.value.length);
   };

   console.log(data?.modules)
  return(
    <>
        <FormEpisodes style={{marginLeft: "0%"}}>
          <label>
            Nome da temporada <Small>(Obrigatório)</Small>
          </label>
          <TextAreaContainer>
          <Controller
            name="nameModule"
            control={control}
            defaultValue={data?.modules && data?.modules[index]?.name}
            render={props => (
             <TextArea
                type="text"
                defaultValue={data?.modules && data?.modules[index]?.name}
                maxLength="100"
                placeholder='Insira aqui o título do conteúdo'
                name="nameModule"
                onChange={(e)=>{
                  console.log([...data?.modules])
                  const newArray = [...data?.modules]
                  newArray[index].name = e.target.value
                  console.log(newArray)
                  setData({...data, modules: newArray});
                  props.field.onChange(e.target.value)}}
              />
            )}
          />
             <FormHelperText error={Boolean(errors?.nameModule)}>
            {errors?.nameModule?.message}
            </FormHelperText>
              <Count>
                <span>{count}</span>/500
              </Count>
            </TextAreaContainer>

        </FormEpisodes>

    </>
  )

}
export default FormDetails;
