import React from 'react';
import { withRouter } from 'react-router-dom';
import { NotificationManager } from 'react-notifications';
import { shape, func } from 'prop-types';
import {
  Container, Grid, TextField, Switch,
} from '@material-ui/core';
import { Save } from '@material-ui/icons';
import AppBarWrapper from '../../components/AppBarWrapper';
import TabsBar from '../../components/TabsBar';
import SpeedDial from '../../components/Helpers/SpeedDial';
import createUpload from '../../services/upload';
import Upload from '../../components/Upload';
import GlobalContext from '../../context';
import Footer from '../../components/Footer';

const uploadVideo = createUpload(['video/*'], 'courses');
const uploadImage = createUpload(['image/*'], 'courses');

const ManageCourse = ({ history }) => {
  const {
    selectedCourse: {
      course, setCourse, createCourse, updateCourse, getCoursesByInstitution,
    },
    visualization: { visualization },
  } = GlobalContext();

  if (!['Administrador', 'Gestor'].includes(visualization)) {
    history.goBack();
    return '';
  }

  const setValue = (e) => {
    const {
      name, type, value, checked,
    } = e.target;

    if (type === 'number') {
      setCourse({ ...course, [name]: Number(value.replace(',', '.')) });
    } else if (type === 'checkbox') {
      setCourse({ ...course, [name]: checked });
    } else {
      setCourse({ ...course, [name]: value });
    }
  };

  const getMinimumGradeUp = () => (
    course.minimumGrade && (
      Math.floor(course.minimumGrade) === 0
        ? Math.floor(course.minimumGrade * 100)
        : course.minimumGrade)
  );

  const getMinimumGradeDown = () => (
    course.minimumGrade && (
      Math.floor(course.minimumGrade) === 0
        ? course.minimumGrade
        : course.minimumGrade / 100)
  );

  const save = async () => {
    if (!course || !course.name || !course.description || !course.initials) {
      return NotificationManager.error('Informações obrigatórias: Nome, Sigla e Ementa');
    }
    let folder = null;
    let trailer = null;
    await uploadVideo.upload().then((result) => {
      result.successful.map(({ uploadURL }) => {
        trailer = uploadURL;
        return uploadURL;
      });
    });
    await uploadImage.upload().then((result) => {
      result.successful.map(({ uploadURL }) => {
        folder = uploadURL;
        return uploadURL;
      });
    });
    if (course.id) {
      await updateCourse({
        variables: {
          ...course,
          trailer: trailer || course.trailer,
          folder: folder || course.folder,
          minimumGrade: getMinimumGradeDown(),
        },
      });
    } else {
      await createCourse({
        variables: {
          ...course,
          public: course.public || false,
          trailer: trailer || course.trailer,
          folder: folder || course.folder,
          minimumGrade: getMinimumGradeDown(),
        },
      });
    }
    getCoursesByInstitution.refetch();
    setCourse({});
    uploadVideo.cancelAll();
    uploadImage.cancelAll();
    NotificationManager.success('Operação realizada com sucesso');
    return history.goBack();
  };

  return (
    <div style={{backgroundColor: "#080808"}}>
    <AppBarWrapper/>

      <Grid container>
        <TabsBar title={course && course.id ? 'Alterar Curso' : 'Cadastrar Curso'}>
          <Container maxWidth="md">
            <Grid container className="padding-containers form-change-course">
              <Grid item sm={9} xs={12}>
                <TextField
                  label="Nome do curso"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={(course && course.name) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item sm={3} xs={12}>
                <TextField
                  label="Sigla do curso"
                  variant="outlined"
                  fullWidth
                  name="initials"
                  value={(course && course.initials) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  label="Data de início"
                  variant="outlined"
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  name="startDate"
                  value={(course && course.startDate && course.startDate.substr(0, 10)) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  label="Data de término"
                  variant="outlined"
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  name="endDate"
                  value={(course && course.endDate && course.endDate.substr(0, 10)) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  label="CH aprox. p/ conclusão"
                  variant="outlined"
                  fullWidth
                  type="number"
                  className="timeCourse"
                  name="timeOfDedication"
                  value={(course && course.timeOfDedication) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={2} sm={4} xs={10}>
                <TextField
                  label="Preço"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="price"
                  value={(course && course.price) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={1} sm={2} xs={2}>
                <div className="MuiFormLabel-root">Público</div>
                <Switch
                  color="primary"
                  name="public"
                  checked={Boolean(course && course.public)}
                  onChange={setValue}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ementa"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows="2"
                  rowsMax="8"
                  name="description"
                  value={(course && course.description) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  label="Versão"
                  variant="outlined"
                  fullWidth
                  name="version"
                  value={(course && course.version) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  label="Quantidade de alunos"
                  variant="outlined"
                  fullWidth
                  type="number"
                  name="studentQuantity"
                  value={(course && course.studentQuantity) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <TextField
                  label="Percentual p/ aprovação"
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 100 } }}
                  name="minimumGrade"
                  value={(course && getMinimumGradeUp()) || ''}
                  onChange={setValue}
                />
              </Grid>
              <Grid item md={3} />
              <Grid item md={3} sm={6} xs={12}>
                <div className="MuiFormLabel-root" style={{ margin: 7 }}>Video de apresentação</div>
                <Upload upload={uploadVideo} />
              </Grid>
              <Grid item md={3} sm={6} xs={12}>
                <div className="MuiFormLabel-root" style={{ margin: 7 }}>Imagem do curso</div>
                <Upload upload={uploadImage} />
              </Grid>
            </Grid>
            <SpeedDial actions={[{
              icon: <Save />,
              title: 'Salvar alteraçãoes',
              onClick: save,
            }]}
            />
          </Container>
        </TabsBar>
      </Grid>
      <Footer />
              </div>
  );
};

ManageCourse.propTypes = {
  history: shape({
    goBack: func.isRequired,
  }).isRequired,
};

export default withRouter(ManageCourse);
