import React from "react";
import { ColumnRight, ContainerSearchBar, TitleSearch } from "../components/searchbar/styles";
import TextEditor from "./TextEditor";

const PublisherUseTerms = () => {
  console.log('ue')
  return (
            <>
              <ContainerSearchBar>
              <TitleSearch>
              Termos de Uso
              </TitleSearch>
              <ColumnRight>
              </ColumnRight>
              </ContainerSearchBar>

              <TextEditor/>
              </>
)}
export default PublisherUseTerms;
